.text-box-list {
    list-style: none;
    padding-left: 0px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 40px 0;
}
.text-box {
    width:370px;
    height:210px;
    color:white;
    font-family:'Raleway', Arial, sans-serif;
    background: #036;
    font-weight:500;
    position:relative;
    border:#999 solid 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align:left;
    margin-bottom:20px;

    span {
        padding: 20px;
        font-size: 150%;
    }

    &::before {
        content:"";
        display:block;
        position:relative;
        height:210px;
        background-image:url('https://grayson.edu/about-us/institutional-effectiveness/quality-enhancement-plan/triangle.svg');
        background-repeat: no-repeat;
        fill:#999;
        left:0px;
        flex: 0 0 13%;
        background-size: contain;
    }
}