
.paddingtop80 {
	padding-top: 80px !important;
}
.articleShowTop {
	display: none;
}
.articleShowBottom {
	display: block;
}
.icons {
	position: absolute;
	margin-left: 220px;
}
.searchSet {
	width: 220px !important;
	margin-top: -15px;
}


/* BEGIN max width 1200px */
@media (max-width: 1200px) {
	.firstBannerHeight{
		height: 300px !important;
	}

	.content-form-page .control-label {
		padding-left: 30px;
		padding-bottom: 5px;
	}
	.content-form-page .col-md-offset-4 {
		margin-left: 15px;
	}
	.content-form-page .pull-right {
		float: left !important;
	}

	.quote-v1 span {
		font-size: 20px;
	}
	.padViewRight{
		float: right;

	}
	.logoipad {

		width: 250px;
		margin: 0;
	}
	.icons {
		position: absolute;
		margin-left: 110px;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -90px;
	}
}
@media (max-width: 1160px) {
	.icons {
		position: absolute;
		margin-left: 150px;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -50px;
	}
}


@media (max-width: 1110px) {
	.icons {
		position: absolute;
		margin-left: 150px;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -50px;
	}
}
@media (max-width: 1080px) {
	.icons {
		position: absolute;
		margin-left: 180px;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -25px;
	}
}
@media (max-width: 1050px) {
	.icons {
		position: absolute;
		margin-left: 190px;
	}
	.headNavRight50 {
		margin-right: -50px !important;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -10px;
	}
}
/* END max width 1200px */


@media (min-width: 1024px) and (max-width: 1199px) {
	
	.pullleftPadding{
		float: left !important;
		margin-left: 200px;
	}
	.header-navigation > ul > li > a {
		color: #FFF;
		display: block;
		padding: 29px 13px 30px;
		height: 33px;
		margin-top: 5px;
	}
}

/* BEGIN min width 1024px */
@media (min-width: 1024px) {

	.marginleftneg60 {
		margin-left: -60px !important;
	}
	.GCResourcesShow {
		
		display: none;
	}
	.GCCampusShow {
		display: none;
	}
	.GCAlertShow {
		display: none;
	}

	.noShow {
		display: none;
	}
	.topNavHideTop {
		display: block;
	}
	.header .header-navigation {
		display: block !important;
		padding-top: 12px;
		margin-top: -60px;
	}
	.containerFull {
		width: 1170px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}

/* BEGIN max width 1024px */
@media (max-width: 1023px) {
	.firstBannerHeight{
		height: 280px !important;
	}

	.marginleftneg60 {

	}
	.logoipad {
		width: 250px;
		margin: 0;
	}
	.GCResourcesShow {

		display: none;
	}
	.GCCampusShow {
		display: none;
	}
	.GCAlertShow {
		display: none;
	}
	.icons {
		position: absolute;
		margin-left: 200px;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -5px;
	}
	.pre-header .pull-right {
		/*padding-left: 30px;*/
		float: right !important;
	}
	.moveLeftTitle {
		font-size: 48px !important;
		line-height: 20px !important;
	}
	.moveLeftLearnMore {
		margin-left: -30px !important;
	}
	.moveLeft {
		margin-left: -80px !important;
	}

	.topNavShow {
		display:block;
	}
	.topNavHideTop {
		display: none;
	}

	body.page-header-fixed {
		padding-top: 63px !important; /* height of the header */
	}

	.header .mobi-toggler {
		display: block;
		margin-top: 8px;
	}
	.marginTop48 {
		margin-top: 48px;
	}
	.header .header-navigation {
		margin: 0 -10px 0;
		padding: 0 10px;
		margin: 0 auto;
		width: 100%;
		float: left !important; 
		display: none;
	}

	.header .header-navigation li {
		float: none;
		padding: 0;
		clear: both;
	}
	.header .header-navigation li > a,
	.header .dropdown.open .dropdown-toggle,
	.header .header-navigation li.open > a {
		border-bottom: 1px solid #efefef !important;
		padding: 8px 10px 9px;
		margin: 0 -10px;
		position: relative;
		color: #666 !important;
		background: none !important;
	}
	.ecommerce .header .header-navigation li > a,
	.ecommerce .header .dropdown.open .dropdown-toggle,
	.ecommerce .header .header-navigation li.open > a {
		padding: 12px 10px 9px;
	}
	.header .header-navigation li > a:hover,
	.header .header-navigation li.active > a,
	.header .header-navigation li.open > a:hover {
		border-bottom: 1px solid #efefef !important;
		background: none !important;
		color: #002265 !important;
		text-decoration: none;
	}

	.header-navigation > ul > li.dropdown:hover > a:after {
		border: none;
	}

	.header-navigation > ul > li > a > i.fa-angle-down {
		display: block;
		float: right;
		margin: 3px 0 0;
	}

	/* dropdown menu */
	.header-navigation .dropdown-menu,
	.header-navigation > ul > li > .dropdown-menu {
		position: static;
		width: 100%;
		margin: 0;
		border: none;
		box-shadow: none;
		background: none;		
		margin-top: 0;
		border-top: none;
		z-index: 22;
	}
	.header-navigation .dropdown-menu li {
		border: none !important;
		margin-left: 20px;
	}
	.header-navigation .dropdown-menu li > a {
		padding: 10px 10px 9px;
		z-index: 999;
		position: relative;
	}
	.header-navigation .dropdown-menu li > a > i {
		display: none;
	}

	.header-navigation .dropdown-menu .dropdown-menu {
		display: block;
	}
	.header-navigation .header-navigation-content {
		border: none;
	}
	.dropdown-megamenu .header-navigation-content {
		padding: 5px 0 0;
		margin-left: -20px;
	}
	.dropdown-megamenu .header-navigation-content .row {
		margin: 0;
	}
	.dropdown-megamenu .header-navigation-content .header-navigation-col {
		padding: 0;
		float: none;
		width: 100%;
	}
	.dropdown-megamenu .header-navigation-content h4 {
		font-size: 13px;
		padding: 10px 10px 9px;
		margin: 0 -10px 0 10px;
	}
	.header-navigation .dropdown-megamenu .header-navigation-content li > a {
		padding: 10px 10px 9px;
	}

	.header-navigation-content-ext {
		background: none;
	}
	.header-navigation-description {
		height: auto !important;
		background: none;
		box-shadow: none;
		padding: 0;
		margin: 0 10px;
	}
	.header-navigation-content-ext .col-md-6,
	.header-navigation-content-ext .col-md-3 {
		float: none;
		width: 100%;
	}
	.dropdown-megamenu .header-navigation-content-ext .col-md-3 h4 {
		border-bottom: 1px solid #EFEFEF;
	}
	.header-navigation .dropdown-menu .header-navigation-content-ext li > a {
		display: block;
	}
	.header-navigation-content-ext .col-md-3, .header-navigation-content-ext .col-md-6 {
		padding: 0;
	}
	.header-navigation-content-ext .col-md-6 {
		margin-left: 10px;
	}
	.header-navigation-content-ext .col-md-6 h4 {
		margin-left: 0;
		padding-left: 0;
	}

	.header .nav-brands {
		margin: 15px 10px;
		padding: 0;
		width: 100%;
		background: #fff;
	}
	.header .nav-brands ul {
		border: 1px solid #F0F0F0;
		padding: 10px;
	}
	.header .nav-brands li {
		max-width: 50%;
		display: inline-block;
		border: none;
		margin-left: 0
	}
	.header .nav-brands li a,
	.header .nav-brands li a:hover {
		border: none !important;
		margin: 0 !important;
	}

	.nav-catalogue .dropdown-menu > li {
		margin-left: 0;
	}
	.nav-catalogue .header-navigation-content {
		padding: 20px 0 0;
	}
	.nav-catalogue .product-item {
		margin-bottom: 20px;
	}

	.header-navigation li.menu-search,
	.ecommerce .header-navigation li.menu-search {
		top: 0;
		padding: 6px 10px 10px;
		position: relative;
	}
	.header-navigation li.menu-search span.sep {
		display: none;
	}
	.header-navigation .search-box {
		position: relative;
		right: auto;
		left: 0;
	}
	.header-navigation .search-box:after {
		right: auto;
		left: 0;
	}

	.top-cart-block {
		margin-top: 3px;
	}

	.page-header-fixed .header-navigation {
		/*height: 300px;*/
		overflow: auto;
	}
}
/* END max width 1024px */

/* BEGIN max width 992px */	
@media (max-width: 992px) {
	.firstBannerHeight{
		height: 250px !important;
	}
	.GCResourcesShow {

		display: none;
	}
	.GCCampusShow {
		display: none;
	}
	.GCAlertShow {
		display: none;
	}
	.icons {
		position: absolute;
		margin-left: 110px;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -15px;
		margin-left: -90px;
	}
	.moveLeftTitle {
		font-size: 37px !important;
		line-height: 20px !important;
	}
	.moveLeft {
		margin-left: -90px !important;
	}
	.moveLeftLearnMore {
		margin-left: -90px !important;
	}
	.moveLeftSmaller {
		font-size: 17px !important;
	}
	.padViewRight{
		float: left;

	}
	.shop-index-carousel {
		margin-left: 0;
		margin-right: 10px;
	}

	.brands .container {
		padding-right: 25px;
	}

	.sidebar-menu a:focus {
		color: #333 !important;
		text-decoration: none;
	}

	.sale-product___removed {
		margin-bottom: 40px;
	}
	.new-arrivals___removed {
		margin-left: 15px;
		padding-left: 0;
	}
	.two-items-bottom-items {
		margin-bottom: 40px;
	}
	.carousel img {
		width: 100%;
	}

	.steps-block-col {
		border: 2px solid #FFF;
		margin: 0 25px 20px 15px;
		padding: 20px 20px 15px;
		min-height: auto;
		float: none;
	}
	.steps-block-col:last-child {
		margin-right: 25px;
		margin-bottom: 0;
	}
	.steps-block .steps-block-col div {
		margin-left: 0;
		margin-right: 0;
	}
	.steps-block h2 {
		font-size: 43px;
	}
	.steps-block em {
		text-transform: uppercase;
	}
	.steps-block-col span {
		display: none;
	}
	.steps-block-col .fa {
		display: block;
	}

	.pre-footer-subscribe-box form {
		width: 100%;
	}

	.sidebar-products img {
		float: none;
		margin-bottom: 5px;
		width: 100%;
		height: auto;
	}
	.sidebar-products h3,
	.sidebar-products .price {
		margin-left: 0;
	}
	.product-page-options .pull-left {
		margin-bottom: 10px;

	}
	.product-quantity {
		margin-bottom: 10px;
	}


	.review .rateit {
		width: 100%;
		margin-bottom: 10px;
	}
	.list-view-sorting .pull-right {
		margin-bottom: 10px;
	}

	.service-box p, .service-box .service-box-heading em, .service-box .service-box-heading span {
		display: block;
		text-align: center;
	}
	.service-box .service-box-heading em {
		margin-bottom: 10px;
	}

	.quote-v1 {
		text-align: center;
	}
	.quote-v1 .text-right {
		text-align: center;
	}

	.recent-work .owl-carousel {
		margin-left: -10px;
	}
	.our-clients .owl-carousel {
		margin-left: -2px;
	}
	.recent-work .owl-carousel,
	.our-clients .owl-carousel {
		padding-top: 30px;
	}
	.recent-work .owl-buttons,
	.our-clients .owl-buttons {
		top: 0;
		left: -3px;
	}
	.our-clients .owl-buttons {
		left: -10px;
	}

	.testimonials-v1 {
		padding-top: 0px;
	}

	.recent-work .recent-work-item .fa {
		width: 36px;
		height: 36px;
		margin-top: -18px;
		font-size: 14px;
		padding: 12px;
	}

	.recent-news-inner {
		padding: 10px 15px 0;
	}

	.ecommerce .pre-footer-subscribe-box h2, .corporate .pre-footer-subscribe-box h2 {
		padding-top: 0;
	}
	.pre-footer .pre-footer-subscribe-box {
		padding-bottom: 25px;
	}
	.ecommerce .pre-footer-subscribe-box form {
		width: 100%;
	}

	.ecommerce .content-search h1 {
		padding-top: 0;
		margin-bottom: 3px;
	}
	.content-search form {
		margin: 0 0 6px;
	}
}
/* END max width 992px */
@media (max-width: 950px) {
	.searchSet {
		width: 210px !important;
		margin-top: -14px;
		margin-left: -60px;
	}
	.icons {
		position: absolute;
		margin-left: 150px;
	}
}
@media (max-width: 900px) {
	.searchSet {
		width: 210px !important;
		margin-top: -14px;
		margin-left: -40px;
	}
	.icons {
		position: absolute;
		margin-left: 170px;
	}
}
@media (max-width: 850px) {
	.searchSet {
		width: 220px !important;
		margin-top: -14px;
		margin-left: -30px;
	}
	.icons {
		position: absolute;
		margin-left: 190px;
	}
}
@media (max-width: 810px) {
	.searchSet {
		width: 220px !important;
		margin-top: -14px;
		margin-left: -10px;
	}
	.icons {
		position: absolute;
		margin-left: 200px;
	}
}
@media (min-width: 768px) {
	.padLower {
		margin-top: -10px;
		padding-bottom: 45px;
		display: none;
	}
	.padHigher{
		display: block;
	}
}
/* BEGIN max width 767px */
@media (max-width: 767px) {
	.paddingtop80 {
		padding-top: 20px !important;
	}
	.articleShowTop {
		display: block;
	}
	.articleShowBottom {
		display: none;
	}
	.firstBannerHeight{
		height: 310px !important;
	}
	.header .header-navigation li {
		float: none;
		padding: 0;
		clear: both;
		text-align: center;
	}
	.padLower {
		margin-top: -10px;
		padding-bottom: 45px;
		display: block;
	}
	.padHigher{
		display: none;
	}
	.icons {
		position: absolute;
		margin-left: 220px;
	}
	.GCResourcesShow {
		text-align: center;
		display: block;
	}
	.GCCampusShow {

		display: block;
	}
	.GCAlertShow {
		text-align: center;
		display: block;
	}
	.GCResources {
		display: none;
	}
	.GCCampus{
		display: none;
	}
	.GCAlert{
		display: none;
	}
	.moveLeft {
		margin-left: -150px !important;
	}

	.moveLeftLearnMore {
		margin-left: -80px !important;
	}
	.moveLeftLearnMore {
		margin-left: -50px !important;
	}
	.moveLeftTitle {
		font-size: 35px !important;
		line-height: 20px !important;
	}
	.moveLeftSmaller {
		font-size: 17px !important;
	}
	.pre-header .pull-right {
		/*padding-left: 80px;*/
		float: right !important;
	}
	.searchSet {
		width: 200px !important;
		margin-top: -26px;
		margin-left: 10px;
	}
	.pre-header .pull-right li:first-child {
		padding-left: 0;
	}
	.ecommerce .pre-footer-subscribe-box form {
		float: none;
		width: auto;
	}

	.header .header-navigation {
		margin: 0 -10px 0;
		padding: 0 10px;
		margin: 0 auto;
		width: 100%;
		float: left !important; 
		/*display: block !important;*/
	}
	.header-navigation-content {
		min-width: 100px;

	}

	.sidebar {
		margin-bottom: 40px;
	}
	.sidebar___removed {
		margin-bottom: 40px;
	}
	.sidebar-menu .fa-angle-right {
		display: inline-block;
	}
	.pre-footer .pull-right,
	.footer .pull-right {
		float: none !important;
	}
	.pre-footer .pull-right,
	.footer .pull-right {
		padding-top: 15px;
	}
	.footer .list-inline > li {
		padding-left: 0;
		margin-right: 5px;
	}


	.form-info {
		border-left: none;
		padding-left: 0;
		padding-top: 30px;
		border-top: 1px solid #E4E6E8;
		margin-bottom: 0;
	}

	.sidebar-products .item {
		float: left;
		width: 48%;
		margin-bottom: 20px;
		padding: 0 10px 10px;
	}
	.sidebar-products img {
		width: 100%;
		height: auto;
	}
	.list-view-sorting {
		/*background: #fff;*/
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 10px;
	}
	.list-view-sorting .col-md-10 {
		padding-right: 0;
	}
	.product-other-images {
		margin-bottom: 40px;
	}
	.product-page-options .pull-left {
		margin-bottom: 0;
	}
	.product-item .img-responsive {
		width: 100%;
	}

	/* COMMENTED OUT 10/14/21
	.pagination {
		float: left !important;
		padding-top: 10px;
	}
	.pagination > li {
		margin-left: 0;
		margin-right: 5px;
	}
	*/
	.pre-footer .margin-bottom-40 {
		margin-bottom: 0;
	}
	.ls-layer3 .ls-price strong {
		top: -15px;
	}

	.glyphicons-demo ul li {
		width: 12.5%;
	}

	.tab-style-1 .tab-content img {
		margin: 5px 0 10px;
	}
	.testimonials-v1 {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.front-steps-wrapper {
		margin-left: -10px;
		margin-right: -10px;
	}
	.front-steps-wrapper .front-step-col {
		padding-left: 15px;
		padding-right: 15px;
	}
	.front-steps-wrapper .front-step:after {
		display: none;
	}

	.blog-posts h2, .ecommerce .blog-posts h2 {
		padding-top: 15px;
	}

	.ecommerce .footer {
		padding: 10px 0 10px;
	}
	.ecommerce .footer .pull-right {
		padding-top: 10px;
	}
	.ecommerce .pre-footer address {
		margin-bottom: 15px !important;
	}
	.footer img {
		margin-bottom: 10px;
	}
	.ecommerce .pre-footer-subscribe-box h2, .corporate .pre-footer-subscribe-box h2 {
		padding-top: 7px;
	}
	.page-slider {
		display: block;
	}
	.header .mobi-toggler {
		display: block;
	}

}
/* END max width 767px */

/* BEGIN max width 600px */
@media (max-width: 600px) {
	.firstBannerHeight{
		height: 260px !important;
	}
	.page-slider {
		display: block;
	}
	.ls-layer3 .ls-price strong {
		top: -5px;
	}
	.moveLeftLearnMore {
		margin-left: -25px !important;
	}
}
/* END max width 600px */

/* BEGIN max width 480px */
@media (max-width: 480px) {
	.firstBannerHeight{
		height: 220px !important;
	}

	.header-navigationTOP .search-box {
		right: -7px;
		top: 100%;
		padding: 15px;
		display: none;
		background: #fcfafb;
		position: absolute;
		border-top: solid 2px #999;
		box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
		width: 274px;
		margin-top: 0;
		z-index: 1000;
		margin-right: -50px;
	}
	.searchSet {
		width: 180px !important;
		margin-top: -26px;
		margin-left: 20px;
	}
	.icons {
		position: absolute;
		margin-left: 210px;
	}
	.removeText{
		display: none;
	}
	.pre-header .pull-right {
		float: right !important;
		/*padding-left: 30px;*/
	}
	.pre-header .list-inline li {
		display: block;
		border: none;
		margin-bottom: 10px;
		padding: 0;
	}
	.pre-header .col-md-6 {
		float: left;
	}
	.pre-header .additional-nav {
		float: right;
		text-align: right;
	}

	.nav-catalogue .pi-price {
		margin-bottom: 10px;
	}
	.nav-catalogue .pi-price,
	.nav-catalogue .product-item .add2cart {
		float: none;
	}

	.top-cart-block {
		clear: both;
		float: left;
		padding-top: 0;
		margin: 0 0 29px;
		width: 100%;
	}
	.top-cart-info {
		float: none;
	}
	.top-cart-block .fa-shopping-cart {
		top: 0;
	}
	.top-cart-content-wrapper {
		left: 0;
		right: auto;
	}
	.top-cart-content:after {
		left: 15px;
	}

	.ls-fullwidth .ls-nav-prev, .ls-fullwidth .ls-nav-next {
		display: none;
	}

	.checkout-content {
		padding: 0 0 40px;
	}
	.checkout-page h2 a {
		padding: 9px 10px 8px;
	}
	.table-wrapper-responsive {
		overflow-x: scroll;
	}

	.shopping-cart-page .btn-default {
		float: right;
	}
	.shopping-cart-page .btn-primary {
		clear: both;
		margin-top: 10px;
	}
	.checkout-page .btn-default {
		clear: both;
		margin-top: 10px;
		margin-right: 0 !important;
	}

	.product-item:hover > .pi-img-wrapper>div {
		display: none;
	}

	.steps-block-simple h2 {
		font-size: 21px;
	}
	.steps-block-simple em {
		font-size: 14px;
		text-transform: inherit;
	}

	.ecommerce .pre-footer-subscribe-box h2,
	.corporate .pre-footer-subscribe-box h2 {
		float: none;
	}

	.list-view-sorting .pull-right {
		float: left !important;
		margin: 0 30px 10px 0;
	}

	.header .top-cart-block {
		margin-top: 0;
	}
	.reduce-header .top-cart-block {
		margin-top: 0;
		margin-bottom: 19px;
	}
}
/* END max width 480px */

/* BEGIN max width 450px */
@media (max-width: 450px) {
	.ecommerce .steps-block h2 {
		font-size: 33px;
	}
}
/* END max width 450px */

/* BEGIN max width 405px */
@media (max-width: 405px) {
	.top-cart-content {
		width: 100%;
	}
	.top-cart-block img,
	.top-cart-block .cart-content-count {
		display: none;	
	}
}
/* END max width 405px */

/* BEGIN max width 390px */
@media (max-width: 390px) {
	.steps-block .fa-truck, .steps-block .fa-gift, .steps-block .fa-phone {
		border: 2px solid #FFFFFF;
		border-radius: 30px !important;
		float: left;
		font-size: 20px;
		width: 40px;
		height: 40px;
		margin-right: 11px;
		padding-top: 12px;
		text-align: center;
		vertical-align: middle;	    
	}
	.ecommerce .steps-block h2 {
		font-size: 25px;
		padding-top: 2px;
	}
	.ecommerce .steps-block em {
		text-transform: inherit;
	}

	.mix-block .tab-content .col-md-3 {
		padding-right: 15px;
	}
}
/* END max width 390px */

/* BEGIN max width 330px */
@media (max-width: 330px) {
	.top-cart-content {
		width: 280px;
	}
}
/* END max width 330px */

/* BEGIN max width 320px */
@media (max-width: 320px) {
	.site-logo {
		margin-right: 0;
	}
	.content-form-page .btn-default {
		margin-top: 10px;
	}
	.price-availability-block .availability {
		clear: left;
		float: left;
	}
	.product-page-options .pull-left {
		margin-bottom: 10px;
	}
}
/* END max width 320px */