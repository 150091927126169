.item-list {
	dt {
		font-size: 1.3rem;
		margin: 1rem;
		font-family: Raleway;
		margin: 1rem 0;
	}
	dd  {
		margin-bottom:.5rem;
		padding-left:1.5rem;
	}
}