
.accordion-group--grayson {
  border-color: #002265 !important;
  background-color:white;
}

.career-data {
  .accordion-inner {
    padding:0; 
    @media (min-width: @screen-sm-min) {
      padding: 20px 15px;
    }
  }

  &__heading {
    font-family: Raleway, Arial, sans-serif;
    color: #002265;
    font-size: 1.2rem;//2.5rem;!important;
    @media (min-width: @screen-sm-min) {
      font-size: 1.6rem;//2.5rem;!important;
    }

    &[aria-expanded="true"] .accordion__icon{
        transform: rotate(90deg);
    }

    &::before {
      font-size:1.2rem !important;
      @media (min-width: @screen-sm-min) {
        font-size: 1.6rem;//2.5rem;!important;
      }

    }
  }

  &__section {
    margin-bottom: 3rem;
    padding: 0 2rem;

    p {
      margin-bottom:0;
    }

    &::after {
      content: "";
      width: 100px;
      position: relative;
      display: inline-block;
      height: 2px;
      background-color: #999;
      top: 1rem;
    }

    &--last {
      &::after {
        display:none;
      }
    }
  }

  &__data {
    font-family:Raleway, Arial, sans-serif;
    font-size:1.6rem;//2.5rem;
    font-weight:600;

    &--chart {
      font-family: Raleway, Arial, sans-serif;
      color: #333;
      font-size: 1.3rem;//2rem;
      text-align: center;
    }

  }

  &__stat {
    vertical-align: middle;
    font-size: 1rem;//1.5rem;
    

    &--big {
      font-family: bebas neue, Arial, sans-serif;
      font-size: 1.8rem;//3rem;
      color: #002265;
      font-style: normal;
      display: block;

      @media (min-width: @screen-sm-min) {
        padding: 0rem .8em; 
        vertical-align: middle;
        display: inline;
        font-size:1.8rem;
      }
    }
  }
}
