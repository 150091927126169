.orgs {
    list-style:none;
    margin-left:0;
    padding-left:0;
}

.club {
    display: flex;
    margin: 3rem 0;
    flex-wrap: wrap;

    @media (min-width: @screen-sm-min) {
    flex-wrap:nowrap;
    }

    &__graphic {
        width: 160px;
        max-width: 160px;
        flex: 0 0 auto;
        margin-right:1.2rem;

        img {
            width: 160px;
            height:160px;
            object-fit: contain;
            border-radius: 100%!important;
            margin-bottom:.4rem;
        }
    }
    &__name {
        text-align: left;
        font-weight: bold;
        color: #002265;
        font-family: Raleway, arial, sans-serif;
        font-size: 1.2rem;
    }
    &__advisors {
        width: 100%;
        display: flex;
        margin-top: 1rem;
        flex-wrap:wrap;

        @media (min-width: @screen-sm-min) {
            flex-wrap:nowrap;
            }
    }
    &__info {
        width: 100%;
        text-align: left;
        margin-top: 1rem;
    }
    &__advisor {
        padding-left:0;
        padding-right: 2rem;
        flex-basis:100%;
        display:flex;
        flex-wrap:wrap;

        @media (min-width: @screen-sm-min) {
            flex-basis:auto;
            }
    }
    &__description {
        margin-top: 1rem;
    }
}

.font-weight-bold {
    font-weight: bold;
}

