/*** New Font Styles ***/
@media (max-width:767px) {
    h2.new {
        font-size:2.5rem!important;
    }
}

caption {
    color:#707070; 
    /*accessibility fix */
}

html.new .content h3 {
    font-size: 1.7rem;
    margin-bottom: 1em;
    margin-top:1em;
}
html.new h1.page-title {
    margin-bottom:.5em;
}
/*html.new .content */ h2 {
    font-family:raleway,arial,sans-serif;
    font-size:2rem;
    color:#002265;
    font-weight:500;
    margin-bottom: .6em;
    margin-top: .6em;
}


a.alert {
    color:black;
}
a.alert:hover {
    transition:all .25s ease;
    color:#002265;
}


h1.page-title {
    font-family:bebas neue,arial,sans-serif;
    font-size:3.75rem;
}

.no-js body {
    opacity:1;
}

.btn{
white-space:nowrap;    
}

/*** Google Fixes ****/

.gsc-control-cse.gsc-control-cse-en {
padding-top:0px!important;
margin-top:2px!important;
}
form.gsc-search-box {
margin-bottom:0px!important;    
    
}
#___gcse_0 {
position: relative;
top: 9px;   
    
}

@media (max-width:768px) {
#___gcse_0 {
top: 18px!important;
left: -19px!important;
}
}
/*New Program Pages*/


h2.new {

font-family: bebas neue, sans-serif!important;
font-size: 3.6rem 

}

section#faculty .entry.panel {
	border:none!important;
}
section#faculty .faculty-gallery.flex a.faculty-link {
	text-decoration:none!important;
	color:#002265;
}
section#faculty .faculty-gallery.flex a.faculty-link:hover {
	text-decoration:underline!important;
	color:#002265;
}
section#faculty img.fac-headshot {
width: 145px;
box-shadow: 4px 3px 6px #707070;
}

section#faculty .faculty-gallery .panel-heading {
	background-color:none!important;
}
section#faculty .fac-info {
	font-size:1rem;

}
section#faculty h3.fac-name {
	color: #002655;
	font-family: raleway, sans-serif;
	font-size: 1.4rem;
}
section#faculty .fac-title, .fac-email {
	font-family: raleway, sans-serif;
	font-weight: 600;
	font-size:1rem;
}

/**********Stretchy Sides*********/

.stretchy {
    position:relative;
}

.stretchy-right {
    position:relative;
}
.stretchy-right:after {
    content:"";
    position:absolute;
    top:0;
    bottom:0;
    width:9600px;
    right:100%;
    background:#E5EDF2;
}

.stretchy-left


ul.spaced li {
    margin-bottom:.5rem;
}

figcaption {
    font-style:italic;
    font-weight:.8rem;
    color:#575757!important;
    padding-top:5px;
}

/**********New Submenu *********/


#sidebar-nav li {
    margin-bottom:9px;
}
.side-nav button#section {
padding: 0px 20px 0px 20px;
display:block;
text-align: center;
font-size: 1.5rem;
color: #00205B;
font-weight: bold;
text-decoration: none;
position:relative;
width: 100%;
border: none;
background: none;
} 
.side-nav button#section span {
    text-decoration:underline;
}

.side-nav button#section:after {
    content:"\f078";
    font-family:"Font Awesome 5 Free", "FontAwesome";
    position: absolute;
    right: 8px;
    transition:transform .35s ease;
    transform:rotate3d(0, 0, 0, 0deg);
}
.side-nav button#section.active:after {
    transition:transform .35s ease;
    transform:rotate3d(1, 0, 0, 180deg);
}



@media (max-width:768px){
.side-nav {
padding: 5px 20px 5px 20px!important;
/*box-shadow: 1px 2px 7px gray;   */

margin-bottom:30px!important;
}
.js #sidebar-nav {
    display:none;
}
.no-js button#section {
    display:none!important;
}
#sidebar-nav.active {
    display:block;
}
 }
.side-nav {
padding: 0px 20px 20px 20px;
/*box-shadow: 1px 2px 7px gray;   */
margin-bottom:10px;
}

ul.navcat > li.nav-parent {
    border-left: 4px solid #00205B;
    padding-left: 1em;
}

ul.navcat li a {
display:inline-block;    

}

ul.navcat li a:before {
    position:absolute;
    content: "";
    width: 0%;
    height: 1px;
    bottom: -1px;
    left: 0;
    border-bottom:2px solid #00205B;
    transition: width .15s ease;
    
}
ul.navcat li a:hover:before, ul.navcat li a:focus:before  {
    position:absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    border-bottom:2px solid #00205B;
    transition: width .15s ease;
   
}
ul.navcat li a {
position:relative;    

}

ul.navcat li {
width:100%!important;
}

ul.navcat li a {
text-decoration:none!important;
}
ul.navcat li {
background-color:transparent!important;
}
ul.navitems {
margin-top:10px;
background:transparent!important;
}
ul.navitems li {
background:transparent!important;
}
ul.navitems li a {
color: #707070;
}
ul.navitems li a.current {
color: #00205B;
background-color: #e8e8e8;
padding: 2px 5px 2px 5px;
}
#sidebar-nav  a.current {
color: #00205B;
background-color: #e8e8e8;
padding: 2px 5px 2px 5px;
}

ul.sub-navitems {
    padding-left:2em!important;    
    margin-top:10px;
    font-size:.9rem;
}
ul.navcat > li:nth-child(2) > a {
    font-size:1.5rem;
    font-weight:bold;
    color:#00205B!important;
}
h2.menu {
    font-size:1.6rem;
    font-weight:bold;
    color:#00205B!important;
}
h2.menu a {
    color:#00205B!important;
}

ul.navcat > li:nth-child(2) > a::after {
    content: " \f078";
    font-family: "Font Awesome", "Font Awesome 5 Free";
    position: absolute;
    right: -1em;
}
ul.navcat li.nav-parent.children > a::after {
 content: " \f078";
    font-family: "Font Awesome", "Font Awesome 5 Free";
    font-weight:bold;
}
/* ul.navcat > li > ul > li > a {

} */




.icons span a:hover {
 text-decoration:none!important;   
}

h1.logo {
    margin:0!important;
    display:inline-block;
    position: relative;
    z-index: 9999999;
}

#logo .site-logo {
margin-right: 0px!important;    
}

ul.list-2 {
   list-style:none;
   column-count:2;
   padding-left:0px;
        
}
ul.list-2 li::before {
    content: "\200B";
}
    
@media only screen and ( max-width: 768px ) {
    ul.list-2 {
        list-style:none;
        column-count:1!important;
        -moz-column-count:1;
        -webkit-column-count:1;
    }
}

table.finaid thead {
	color:white;
	background-color:#00205b;
}
table.finaid thead th {
	vertical-align:top;
}
table.finaid thead tr > th:last-child {
	width:40%;
}

body {
    overflow-x:hidden;    
}
article section {
    padding-bottom:2rem;    
    
}
article h3 {
font-size: 1.8rem;
margin-bottom:2rem;
    
}
a.btn-default {
background-color:rgb(0,51,102);    
border:unset;
color:white;
}

.site-logo img {
box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);    
}
/* Current link styling */
.mainAcademicResources a.current {
background-color: #A6CBE8;
color: #002265;
font-weight: bold;    
}

.slick-track .slick-track { display: none; }

/* Flex Video div element */
.flex-video {
  position: relative;
  padding-top: 1.5625rem;
  padding-bottom: 67.5%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden; }
  .flex-video.widescreen {
    padding-bottom: 56.34%; }
  .flex-video.vimeo {
    padding-top: 0; }
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    
/*homepage calendar edits */

h2#calendar  {
	font-size: 3.5rem;
	margin-bottom: 1.5rem;
	background-color: #333;
	color: white;
	padding: 9px;
	margin-top: 2rem;
	font-family: bebas neue, arial, sans-serif!important;
	padding: 9px 21px 3px 18px;
	display: inline-block;
}

/* Homepage News */

#news {
margin-bottom: 150px;
margin-top: 50px;
}

#news h2 {
	font-size: 3.5rem;
	margin-bottom: 1.5rem;
	background-color: #002265;
	color: white;
	padding: 9px;
	margin-top: 2rem;
	font-family: bebas neue;
	padding: 9px 21px 3px 18px;
	display: inline-block;
}
#news h3 {
	margin-top: .8rem !important;
	font-size: 1.3rem;
	text-decoration: none !important;
	padding: 0 15px 0 0;
	color: #002655;
	font-family: raleway, sans-serif;
	font-weight: bold;
	position: relative;
}
#news .slick-dots {
bottom:-40px!important;    

}

#news .news-date {
	color: #333;
	padding: 0 15px 5px 0;
	font-size: .9rem;
	font-weight: bold;
	padding-top: 6px;
}
#news .news-summary {
	font-size: .95rem;
	line-height: 1.6rem;
	padding: 5px 15px 5px 0;
}
.news-headline::after {
	content: "";
	position: absolute;
	width: 20%;
	bottom: -9px;

	height: 2px;
	background-color: #999;
	left: 0;
}
.news-article a {
	text-decoration: none !important;
	color: #002655;
}
.news-article a:hover {
	text-decoration: underline !important;
}
#news .news-thumb {
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:top;
}
#news .news-article a .news-thumb {
   transform:scale(1.0);
   transition: transform 0.45s;
}

#news .news-article a:hover .news-thumb {
   transform:scale(1.1);
   transition: transform 0.45s;
}

@media only screen and (max-width:768px) {
html.no-js #news .news-article {
    width:50%!important;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float:left;
}
html.js #news .articles {
margin-left: 50px;
margin-right: 50px;
}
}
@media only screen and (min-width:769px) {
html.no-js #news .news-article {
    width:25%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float:left;
}
}

html.no-js .no-js-hide {
display:none;    
}

html.js #news .slick-slide {
    margin-left:40px;
}

html.js .articles .slick-list {
    margin-left:-40px;
}
html.js .articles .slick-track {
    overflow-x:hidden;
}
#news .articles {
/*margin-left:-40px;    

*/}

.img-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 62.5%;
}
.img-wrapper span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow:hidden;
}



/* Accordion*/

/*Accordion additions*/
ul#syllabi {
    padding-left:0px!important;
}



.accordion {
 margin-bottom:20px
}
.accordion-group {
 margin-bottom:.5rem;
 border:1px solid #e5e5e5;
 -webkit-border-radius:4px;
 -moz-border-radius:4px;
 border-radius:4px
}
.accordion-heading {
 border-bottom:0
}
.accordion-heading .accordion-toggle {
 display:block;
 padding:8px 15px
}
.accordion-toggle {
    cursor:pointer;
}

.accordion__icon {
    font-size: 1.2rem;
    height: 100%;
    margin-right: .5rem;
    transition:.3s;
}

.active .accordion__icon {
transform: rotate(90deg);
}
button.accordion-toggle {
    cursor:pointer;
    display: block;
    padding: 8px 15px;
    text-align: left;
    /* color: #3076b3; */
    color:#002265;
    background-color: white;
    border: none;
    font-size: 1.2rem;
    font-weight: 400;
    width: 100%;
    height: 100%;
}
.accordion__heading {
margin:0!important;
}
.accordion-inner {
 padding:20px 15px;
 border-top:1px solid #e5e5e5
}

/*Further accordion*/
.accordion .accordion-group a.accordion-toggle:before {
content: "\f138";
font-family: "Font Awesome 5 Free", "FontAwesome";
font-weight: 900;
font-size: 17px;
margin-right: 10px;
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;    

}
.accordion .accordion-group a.accordion-toggle.active:before {
 content: "\f13a";
 font-family: "Font Awesome 5 Free", "FontAwesome";
 font-weight: 900;
 font-size: 17px;
 margin-right: 10px;
 -webkit-transition: 0.5s;
 -moz-transition: 0.5s;
 -ms-transition: 0.5s;
 -o-transition: 0.5s;
 transition: 0.5s;
}

a.accordion-heading:hover {
 text-decoration:none!important;   
}
a.accordion-heading {
    text-decoration:none!important;
}
.acordion .accordion-inner a {
text-decoration:underline!important;    

}

ul.accordion {
 list-style:none;   
}
/* li.accordion-group:before {
  content: "\200B";
}*/

ul.accordion .accordion-inner ul {
list-style-type:disc;
}
/*.accordion-heading {
display:inline-block;    
width:100%;
}*/
.accordion-toggle.accordion-heading {
display: block;
padding: 8px 15px;

}

ul.accordion {
margin-left:0px!important;    
padding-left:0px!important;
}
ul.accordion .accordion-inner ul a {
text-decoration:underline!important;    

}


/*hero*/

#news-and-events {
margin-top:14px;    

}

#hero {
display: block;
clear: both;    
overflow-y:hidden;

}
#hero .hero-content {
    padding:30px;
}
#hero .hero-image {
    height: calc(100vw * 0.5625);
    max-height: calc(88.1vh - 120px);
}
#hero .hero-text {
    color:white;
    font-size:1.2rem;
    
}
#hero h2.headline {
    color:white;
    font-size: 1.6875rem;
    font-family:bebas neue, arial, sans-serif;
    margin: 0;
}
    @media only screen and (min-width:768px) {
        #hero .hero-background {
        position: absolute;
        width: 100%;
       /* bottom: 0px;
        background-color: rgba(0, 34, 101, 0.6); /*Fallback*/ 
      /*  background: linear-gradient(rgba(0, 34, 101, 0), rgba(0, 34, 101, 0.6), rgba(0, 34, 101, 0.7), rgba(0, 34, 101, 0.9));
        background: -webkit-linear-gradient(rgba(0, 34, 101, 0), rgba(0, 34, 101, 0.6), rgba(0, 34, 101, 0.7), rgba(0, 34, 101, 0.9));
        background: -ms-linear-gradient(rgba(0, 34, 101, 0), rgba(0, 34, 101, 0.6), rgba(0, 34, 101, 0.7), rgba(0, 34, 101, 0.9)); */
        padding-bottom: 2rem;    
            
        }
    }

     @media only screen and (min-width: 1200px){
            #hero h2.headline {
         /*   margin-top: 50px; */
            font-size: 4rem;
          /*  font-family: arial; */
            color:white;
            padding-left:0px!important; 
        }
        
          #hero .hero-text {
                font-size:1.8rem;
            }
        }
         @media only screen and (min-width: 992px) and (max-width: 1199px) {
            #hero h2.headline {
          /*  margin-top: 50px; */
            font-size: 3.6rem;
          /*  font-family: arial; */
            color:white;
            padding-left:0px!important; 
        }
            #hero .hero-text {
                font-size:1.6rem;
            }
        
        }
        
         @media only screen and (min-width: 768px) and (max-width: 991px) {
          #hero h2.headline {
         /*   margin-top: 50px; */
            font-size: 2.5rem;
           /* font-family: arial;*/
            color:white;
            padding-left:0px!important; 
        }
         }
        @media only screen and (max-width: 767px) {
            #hero .hero-image {
                position:relative!important;
            }
            #hero .hero-overlay {
                position:relative!important;
            }
              #hero .hero-content {
                margin-top:0px!important;
            }
            #hero .hero-background {
            position:relative;
            background-color: rgb(0, 34, 101);
            }
            #hero .hero-overlay {
            height:auto!important;
            width:auto!important;
                
            }
            #hero .hero-overlay {
    		 height:auto!important;
    		 width:auto!important;
    	}
    	 #hero .hero-image {
    		 height: auto!important;
    		 max-height: auto!important;
    	}
        }
            
            
#hero .btn {
margin-right: 10px;
border: solid 4px #fff;
background-color: rgba(0, 0, 0, 0.1);
margin-bottom: 6px;
}

#hero .btn:hover,
#hero .btn:focus {
background-color:rgb(66, 139, 202);    
border-color:rgb(66, 139, 202);
transition: all 250ms ease 0s; 
} 

/* here you can put your own css to customize and override the theme */
.mainCalendar {
font-size:.9rem;
}

.mainNews {
font-size:.9rem;    
}

.pre-footer {
font-size:.9rem;    
    
}
.mainAcademicResources {
font-size: 13px!important;    

}
.article-title {
  margin-bottom: .8rem;
font-size: 1.2rem;
line-height: 1.3;
    
}

.main a {
text-decoration:underline;    
}

/*Links Underline for better visibility*/
.navbar-default .navbar-nav>li>a:hover {
    text-decoration: underline;
}
.header-navigation ul > li.active > a:hover {
    text-decoration: underline;
}
.header-navigation ul > li > a:hover {
    text-decoration: underline;
}
.header-navigation .dropdown-menu li > a:hover {
    text-decoration: underline;
    color:#002059 !important;
}
 .header-navigation .dropdown-menu li > a:focus {
	color:#002059;
}
.header-navigation ul > li > a:focus{
    outline: #6cace4 solid;
}

/*Focus Indicators for ADA Compliance*/
a:focus{
    outline: #6cace4 solid;
}
div:focus{
    outline: #6cace4 solid;
}
button:focus {
    outline: #6cace4 solid;
}
.tawk-button:focus {
    outline: #6cace4 solid!important;
}

/*Skip to Main content for ADA Compliance */
a.skip-main {
    left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
    -webkit-transition: top 1s ease-out, background 1s linear;
    transition: top 1s ease-out, background 1s linear;
}
a.skip-main:focus, a.skip-main:active {
    color: #ffffff;
    background-color:#BF1722;
    left: 0;
    top: 0;
    width: 200px;
    height: auto;
    overflow:auto;
    margin: 15px 15px;
    padding:5px;
    border-radius: 30px;
    border:5px solid #BF1722;
    text-align:center;
    font-size:14px;
    z-index:1999;
    text-transform:uppercase;
    letter-spacing:0.5px;
}

@media (min-width: 1024px) and (max-width: 1080px) {
        .headNavRight25 {
        margin-right: -25px !important;
}      
}
   

.white {
       color: #fff !important;
      /* line-height: 20px;*/
}
.topnavHeight {
    height: 40px;
}
.topnavHeight40 {
	height: 50px;
	background-color: #002265
}
.topnavPaddingTop{
	padding-top: 15px;
}
.marginUP {
	margin-top: -90px;
}
.marginUP100 {
	margin-top: -100px;
}
.noPadding {
	padding-top: 0px !important;
	padding-bottom: 10px !important;
}
.noPaddingSub {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	margin: 0px;
}
.menuTopMargin {
	margin-top: 50px !important;
}
.menuLeftNegMargin {
	margin-left: -20px !important;
}
.menuLeftNegMargin14 {
	margin-left: -14px !important;
padding-left: 30px !important;
}
/*.verticalAlign7{
	margin:7px auto;
}*/
.read-more {
	font-family:"Times New Roman", "Palatino Linotype", Palatino, "Century Schoolbook L", serif;
/*	color:#6cace4;*/
color: #3076b3;
	font-style:italic;
	text-decoration:underline;
}

.rssImage {
	 height: 20px;
}
.rssFeed {
	 padding-left: 5px;
	 height: 20px;
	 
}
.rssFeed a{
	
	 color: #00205b;
}
.rssFeedFeed {
	color: #A40000;
	padding-left: 10px;
}
.paddingBottom20 {
	padding-bottom: 20px;
}
.rssRightSide {
	font: 12.8px Arial;
	padding: 0;	
	/* float: right;  */

	
}

@media (min-width:769px) {
    .rssRightSide {
    float:right;
    }
}
/* @media (max-width:768px) {
.rssRightSide  {
float:left!important;    
}  
} */

.rssRightSide ul {
	margin: 0;
	list-style: none;
	padding:0px!important;
/*	text-align:right; */
	-webkit-padding-start: 0px;
	
}
.rssRightSide li {
	text-align: center;
	/*display:inline-block;*/
	float:left;
/*	padding: 0 15px; */
	color: #00205B;
	width:20%;
	height:65px;
	text-decoration: underline;
	box-sizing: border-box;
    vertical-align: top;
}
.rssRightSide li a {
padding: 10px 5px;    

}
.rssRightSide li a:hover,
.rssRightSide li a:focus {
background-color:#00205B;
color:white;
-webkit-transition: all .25s ease 0ms;
 -moz-transition: all .25s ease 0ms;
 -ms-transition: all .25s ease 0ms;
 -o-transition: all .25s ease 0ms;
 transition:all .25s ease 0ms;
}

.table-el {
    display:table;
    width:100%;
    height:100%;
    
}
.rssRightSide ul li {
    position:relative;
    transition:all .25s;
}

.rssRightSide ul li {
    position:relative;
  
}

.rssRightSide ul li:after {
    content: "";
    height: 50%;
    width: 0px;
    border-right: 1px #00205B solid;
    display: block;
    top: 25%;
    position: absolute;
    right:-1px;
}
.rssRightSide ul li:last-child:after {
    content: "";
    height: 50%;
    width: 0px;
    border-right: none!important;
    display: block;
    top: 25%;
    position: absolute;
    right:-1px;
}

.rssRightSide ul li:hover:after {
    content: "";
    height: 50%;
    width: 0px;
    border-right: 1px #00205B solid;
    display: block;
    top: 25%;
    position: absolute;
    right:1px;
    -webkit-transition: all .25s ease 0ms;
     -moz-transition: all .25s ease 0ms;
     -ms-transition: all .25s ease 0ms;
     -o-transition: all .25s ease 0ms;
     transition:all .25s ease 0ms;
}

.rssRightSide ul li:hover:last-child:after {
    content: "";
    height: 50%;
    width: 0px;
    border-right: none!important;
    display: block;
    top: 25%;
    position: absolute;
    right:1px;
    -webkit-transition: all .25s ease 0ms;
     -moz-transition: all .25s ease 0ms;
     -ms-transition: all .25s ease 0ms;
     -o-transition: all .25s ease 0ms;
     transition:all .25s ease 0ms;
}


a.tcenter {
display:table-cell;
vertical-align:middle;

}

.rssRightSide a {
	color: #00205B;
	background-color:none;
    -webkit-transition: all .25s ease 0ms;
    -moz-transition: all .25s ease 0ms;
    -ms-transition: all .25s ease 0ms;
    -o-transition: all .25s ease 0ms;
    transition:all .25s ease 0ms;
}


.noLine {
	border-right:none !important;
	margin-right: -10px;
	
}
.calendar{
	height: 53px;
	background-color: #555555;
	
}
.news {
	height: 53px;
	background-color: #00246b;
	
}

.paddingDownLeft {
	margin-left: -15px;
	padding-top: 20px;
	float: left;
}
.paddingDownRight {
	margin-right: -15px;
	padding-top: 20px;
	float: right;
}
.coeFont {
	font-family: "Times New Roman", serif;
	font-size: 25px !important;
	padding-left: 20px;
	padding-top: 12px;
	color: #fff;
}
.newsFont {
	font-family: "Times New Roman", serif;
	font-size: 25px !important;
	padding-left: 20px;
	padding-top: 12px;
	color: #fff;
}

.underline {
	border-bottom: thin solid #b0b0b4;
	opacity: 0.6;
	padding-top: 10px;
}
.mainCalendar {	
	border:thin solid #b0b0b4;
	/*height: 604px;*/
	margin-top: 10px;
}
.mainCalendarSub {	
	border:thin solid #b0b0b4;
	/*height: 604px;*/
	margin-top: 10px;
}
.mainCalendar ul{
	 list-style: none;
	 padding: 0;
}
.mainCalendar li{
	padding: 20px;
	height: 100px;  
}
.mainCalendar a{
	 
}
.mainCalendar li img{
	padding-right: 20px; 
	float: left;
}
.mainNews {
/*	background:url(../framework/img/debut_light.png) repeat;*/
/*	border:thin solid #b0b0b4; */
	/*height: 604px;*/
	margin-top: 10px;
    
}
.paddingtop10 {
     padding-top: 10px;
}
.mainNews ul{
	 list-style: none; 
	  padding: 0;
	  -webkit-padding-start: 0px;
}
.mainNews li{
	 padding: 0px 10px 0px 10px;
	  line-height: 1.5; /* changed from 1.7 */
}

.mainNews li a img{
	  padding-right: 20px; 
}
.paddingLeftDiv {
	padding-left: 20px;
        width: 100%;
}
.paddingLeftDiv img{
	width: 100%;
}
.paddingleft30 {
    Padding-left: 30px;
}
.marginleftneg10 {
     margin-left: -10px;
}
.marginleftneg76 {
     margin-left: -76px;
}
.footer-logo-bar{
	/* clean-css ignore:end */background:url('[system-asset]/_files/framework/img/footer-pattern.jpg][/system-asset]') repeat-x;/* clean-css ignore:end */
	width:100%;
	height:30px;
}
.footer-logo-bar-img{
	margin:0 auto;
	text-align:center;
	float:left;
	margin-top:-50px;
	display:table;
	width: 100%;
}

.coeText80 {
/*	width: 77%;
display: inline-block; */
padding-left: 60px; 
width: 100%;
display: block;
/* padding-left: 23%; */
min-height:68px;
/*height: 68px; */
}

.coeText80 a{
	color:#444444;
	font-family: raleway, arial, sans-serif;
    font-size: 1rem;
}
.coeText80 a:hover{
	color:#00205B;
}

.coeText75 {
	width: 75%;
display: inline-block;
padding-left: 15px;
height: 68px;
}
/*.coeText70 img{
	float: left;
}*/
.footer-logo-bar{
	/* clean-css ignore:start */background:url('[system-asset]/_files/framework/img/footer-pattern.jpg[/system-asset]') repeat-x;/* clean-css ignore:end */
	width:100%;
	height:30px;
}
.footer-logo-bar-img{
	margin:0 auto;
	text-align:center;
	float:left;
	margin-top:-50px;
	display:table;
	width:100%;
}
.footer-center-campus{
	margin: 0 auto;
	display: table;
}
.footer-list-unstyled ul{
	list-style:none;
	font-family:Arial, "Helvetica Neue", Helvetica, sans-serif;
	color:#ffffff;
	-webkit-padding-start: 00px;
}
.footer-list-unstyled ul li{
	padding: 10px 0;
	font-weight:normal;
	color:#ffffff;
}
.footer-list-unstyled ul li a{
	color:#ffffff;
	text-decoration:underline;
	font-weight:bold;
}
.footer-list-unstyled-rt ul{
	list-style:none;
	font-family:Arial, "Helvetica Neue", Helvetica, sans-serif;
	color:#ffffff;
	-webkit-padding-start: 00px;
}
.footer-list-unstyled-rt ul li{
	padding: 10px 0;
	font-weight:normal;
	color:#ffffff;
	margin-top: -5px;
}
.footer-list-unstyled-rt ul li a{
	color:#ffffff;
	text-decoration:underline;
	font-weight:bold;
}
.date {
	border-right: solid thin;
	width: 50px;
	float: left;
}
.see-all  {
	text-align: right;
	padding: 5px 0;
}
.see-all a {
	text-align: right;
	padding: 5px 0;
	text-decoration: underline;
	color: #00205B;
	font-weight:bold;
}
.paddingLeft20 {
	padding-left: 20px;
}
.textCal {
	/*float: left;*/
	height: 68px;
	color: #444444;
}
.social-spacing {
	padding-right: 5px;
}
.colorBlue {
	color: #00205b;
	letter-spacing: 1px;
}
.subPageLeft {
	padding-left: 0px;
}
.subPageLeft ul{
	list-style: none;
	 padding: 0;
	  -webkit-padding-start: 0px;
}
.subPageLeft ul li{
	/* padding: 5px; */
	/* background: url(../framework/img/debut_light.png) repeat; */
	background-color:#f4f4f4;
/*	 padding-top: 8px; */
	 line-height: 1.5;
	 margin-bottom: 5px;
	 width: 230px;
	 color: #444444;
}
.subPageLeft ul li a{
color: #444444;	
}
.subAcademicResourcesFont {
	font-family: "Times New Roman", serif;
	font-size: 20px !important;
	padding-left: 10px;
	padding-top: 8px;
	color: #fff;
}
.academicResources {
	height: 40px;
	background-color: #00205b;
	/*width: 230px; */
	min-width:230px;
	width:100%;
}
/* Patch for menu width */	
.mainAcademicResources ul > li {
min-width:230px;
width:100%!important;
}

.mainAcademicResources ul > li > a {
display:block;
width:100%;
padding:8px 5px 5px 5px;
}
.mainAcademicResources ul > li > a:hover, .mainAcademicResources ul > li > a:focus {
background: white;
}

@media only screen and (max-width:768px) {
.academicResources, 
.mainAcademicResources, 
.academicResourcesCal {
text-align:center;   
}

}    
  
.academicResourcesCal {
	height: 40px;
	background-color: #999;
	min-width: 230px;
	max-width:100%;
}
.mainAcademicResources {
	border-left: solid thin #b0b0b4;
	margin-bottom: 30px;
}
.mainAcademicResourcesCal {
/*	border: solid thin #b0b0b4; */
	margin-bottom: 30px;
	margin-top: 10px;
	min-width: 230px;
	max-width:100%;
}
.mainAcademicResourcesCal ul li{
	background: none !important;
	padding: 15px 15px 0px 15px;
	/*height: 70px;*/
	margin-bottom: 0px !important;
	width:100%;
}
.paddingLeft10 {
	padding-left: 10px !important;
}
.rightBottomSubImage {
	padding: 0px 0px 20px 0px;
	
}
.rightBottomSubImage img{
	
	width: 100%;
}
.colorGray {
	color: #444444;
}
.FontTimes {
	font-family: "Times New Roman", Times, serif;
	padding-left: 0px;
}
.padBottom20 {
	padding-bottom: 20px;
	width: 100%;
}
.padBottom20 img{
	
	width: 100%;
}
.date-month-day {
font-family: Arial,Helvetica,sans-serif;
font-weight:bold;;
line-height:23px;
text-transform:uppercase;
float: left;
font-size:16px;
width: 40px;
text-align: center;
height:60px;
color: #444444;
padding: 5px 0 0;
}
.date-month-day strong {
font-size: 35px;
font-family: Arial,Helvetica,sans-serif;
display: block;
font-weight: bold;
color:#00205B;
}
.marginBottom50 {
	margin-bottom: 50px;
}
.marginTop50 {
	margin-top: 100px;
}
.sliderTextTop {
	text-align: right;
	
}
.sliderTextBottom {
	text-align: right;
	
}
.positionRelative {
	position: relative;
	padding-left: 0;
	padding-right: 0;
	margin-right: 0;
margin-left: 0;
}
/*  Kepp all new css code above here  */

/*   The pre head nav used the same as the main nav, added TOP to end to seperate the css below is all the css to make the pre head nav work correctly    */
.header-navigationTOP {
	font: 14px Arial, sans-serif;
	margin: 0 10px 0 0;
	padding: 0;
	float: left;
	text-transform: uppercase;
}
.header-navigationTOP ul {
	/*margin: 0;*/
	padding: 0;
	list-style: none;
	
}
.header-navigationTOP > ul > li {
	float: left;
}
.header-navigationTOP > ul > li > a {
	color: #FFF;
	display: block;	
	padding: 29px 15px 30px;
}
.header-navigationTOP ul > li.active > a,
.header-navigationTOP ul > li > a:hover,
.header-navigationTOP ul > li > a:focus,
.header-navigationTOP ul > li.open > a,
.header-navigationTOP ul > li.open > a:hover,
.header-navigationTOP ul > li.open > a:focus {
	/*color: #FFF;*/
	/*background: #fcfcfc;*/
	text-decoration: none;
}
.header-navigationTOP ul > li.active > a {
	border-bottom: 2px solid #e64f00;
}
/* dropdown menu */


@media (min-width: 1025px) {
	.header-navigationTOP > ul > li:hover > .dropdown-menu {
	  display: block;
	}
}

.header-navigationTOP,
.header-navigationTOP .dropdown {
  position: static;
  margin-right: 0px;
}
.header-navigationTOP .dropdown-fix-left,
.header-navigationTOP .dropdown-fix-right {
	position: relative;
}
.header-navigationTOP .dropdown-menu {
  left: auto;
  top: 100%;  
  padding: 0;
  background: #fff;
  position: absolute;
  border: none;  
  box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
  text-transform: none;
  font: 13px Arial, sans-serif;
  letter-spacing: 0;
  border-radius: 0;
  z-index: 9999;
  
}
.dropdown-fix-left .dropdown-menu {
	left: 0;
	width: 700px;
}
.dropdown-fix-right .dropdown-menu {
	right: 0;
	width: 700px;
}
.header-navigationTOP > ul > li > .dropdown-menu {
	margin-top: -5px;
	border-top: solid 3px transparent;
	z-index: 1001;
	
}
.header-navigationTOP .dropdown100 > .dropdown-menu {
  left: 15px;
  right: 15px;
}
.header-navigationTOP > ul > li.dropdown:hover > a:after { 
  bottom: 0;
  width: 0; 
  height: 0;
  z-index: 1002; 
  content: " "; 
  display: block !important; 
  position: absolute;
  border-bottom: 8px solid #00205B;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  background-color: #999999;
}
.ie8 .header-navigationTOP > ul > li.dropdown:hover > a:after {
	display: none !important;
}
.header-navigationTOP li.dropdown100 > .dropdown-menu:after {
	display: none !important;
}
.header-navigationTOP .dropdown-menu > li:first-child {
	border-top: solid 2px #444444;
}
.header-navigationTOP .dropdown-menu li {
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #efefef;
  position: relative;
  float: none;
}
.header-navigationTOP .dropdown-menu li:last-child {
  border: none;
}
.header-navigationTOP .dropdown-menu li > a {
  padding: 10px 15px;
  font-weight: 400;
  color: #444444;
}
.header-navigationTOP .dropdown-menu > li > a:hover,
.header-navigationTOP .dropdown-menu > li.active > a,
.header-navigationTOP .header-navigation-content .header-navigation-col li > a:hover,
.header-navigationTOP .header-navigation-content .header-navigation-col li.active > a {
  background: #fff;
  color: #444444;
}
.header-navigationTOP .dropdown-menu .header-navigation-content-ext li {
  border: none;
}
.header-navigationTOP .dropdown-menu .header-navigation-content-ext li > a {
  padding: 0 0 10px;
  color: #767f88;
  display: inline-block;
}
.header-navigationTOP .dropdown-menu .header-navigation-content-ext li > a:hover,
.header-navigationTOP .dropdown-menu .header-navigation-content-ext li.active > a {
  background: #fff;
  color: #00205B;
}

/* n-level submenu */
.header-navigationTOP .dropdown-menu .dropdown-menu {
  left: 100%;
  right: auto;
  top: 6px;
}
.header-navigationTOP .dropdown-menu a {
  position: relative;
}
.header-navigationTOP .dropdown-menu .fa {
  position: absolute;
  top: 13px;
  right: 15px;
}

/* Top Search */
.header-navigationTOP li.menu-search {
	position: relative;
	top: 1px;
	padding-left: 10px;
	color:#777;
}
.header-navigationTOP li.menu-search span.sep {
	top: 0;
	width: 1px;
	height: 26px;
	background: #eaeaea;
	position: relative;
	margin: 0 18px 0 16px;
	display: inline-block;
}
.header-navigationTOP li.menu-search i {
	color: #333;
	font-size: 13px;
	cursor: pointer;
	position: relative;
	top:-1px;
	/*background: url(../img/icons/top-search-icon.png[/system-asset]) no-repeat;*/
	width: 15px;
	height: 15px;
	opacity: 0.6;
}
.header-navigationTOP li.menu-search i:hover {
	color: #00205B;
	background-position: 0 -15px;
}
/*.header-navigation .fa-search:before {
	content: none;
}*/
.header-navigationTOP .search-box {
	right: -7px;
	top: 100%;
	padding: 15px;
	display: none;
	background: #fcfafb;
	position: absolute;
	border-top: solid 2px #444;
	box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
	width: 274px;
	margin-top: 0;
	z-index: 1000;
}
.header-navigationTOP .search-box:after { 
	top: -8px;
	width: 0; 
	height: 0;
	right: 8px;
	z-index: 2; 
	content: " "; 
	display: block; 
	position: absolute;
	border-bottom: 8px solid #999;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}
.header-navigationTOP .search-box button {
	margin-left: 0 !important;
}
@media (max-width: 767px) {
	.rssRightSide ul {
		text-align:center;
		padding: 7px 5px 0;
		font-size:11.5px;
	}
	.footer-align-center{
		text-align:center;
	}
}
.megamenu-adjust{
	margin-left: -5px;
}
.mainAcademicResourcesCal .date .date-month-day{
    padding-top: 0px;
}

.mainAcademicResourcesCal .date .coeText75{
    padding-top:2px;
}

ul.sitemap li:last-child{
    margin-bottom:10px;
}

ul.sitemap a{
    color: #00205B;
}

ul.sitemap .subfolder{
    color: #444444;
}
ul.sitemap{
    margin-top:5px;
}

div#gsc-iw-id1 {
    height: 29px;
    width: 100%;
}

input.gsc-search-button.gsc-search-button-v2 {
    padding: 7px 10px;
    height: 31px;
    width: 37px;
    margin: 0px;
}

.cse .gsc-control-cse, .gsc-control-cse {
    padding-bottom: 0px !important;
}

.gsc-control-cse.gsc-control-cse-en {
    padding-top: .75em;
}

td.gsc-input {
    padding-right: 2px !important;
}

@media (max-width: 767px) {

   .gsc-control-cse.gsc-control-cse-en {
    padding-top: 1.5em;
}
    input.gsc-search-button.gsc-search-button-v2 {
        padding: 7px 10px;
    }

}


/* LOGIN FORM
   ================================================== */   
#login-header 
{
    margin-bottom: 25px;
}
#login-form input
{
	margin-top: 2px;
	margin-bottom: 10px;
	padding: 5px;
	width: 250px;
}
#login-error
{
	display: none;
	margin-bottom: 25px;
	font-weight: bold;
}
#login-error span
{
	color: #da0000;
	font-weight: bold;
}
#login-credentials
{
    margin-left: 10%;
	width: 250px;
}
#login-submit
{
	max-width: 250px;
	margin-top: 10px;
	text-align: center;
}
#login-form #btnLogin
{
	width: 80px;
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 1;
}
#login-forgot-password
{
	max-width: 450px;
	margin-top: 15px;
}
#login-forgot-password p
{
	max-width: 450px;
}