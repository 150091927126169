.page-heading {
    height:250px;
    margin-bottom:0px;
    clear:both;
    background-size:cover;
    position:relative;
    @media (min-width: @screen-sm-min) {
        height:400px;   
        margin-bottom:40px; 
    }

}

.box-title {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 34, 101, 0.60);
;    @media (min-width: @screen-sm-min) {
        position:absolute;
        bottom:-33px;
        height:auto; 
        background: none;
    }
}

.box-title__inner {
    position: absolute;
    bottom: 0;
    box-shadow:none;
    background:none;
    padding-top: 31px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -10px;
    margin-top: 20px;  
    @media (min-width: @screen-sm-min) {
        position:static;
        display: inline-block;
        background: #002265;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);
    }
}

.box-title__title {
    font-size:3rem;
    font-family:"bebas neue", "impact", sans-serif;
    color:white;
    display:inline-block;
    position:relative;
    margin-bottom:1rem; 
    @media (min-width: @screen-sm-min) {
        font-size:4rem;          
    }
    &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        bottom: -11px;
        left: 0;
        border-bottom: 2px solid #999;  
    }
}

.box-title__breadcrumbs {
    display:inline-block;
    margin-top: 9px;
    color:white;
    font-size:.9rem;
    font-family:"raleway", "arial", sans-serif;
}

