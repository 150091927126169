@arts: #f8a326;
@business: #8b3c73;
@health: #df463e;
@industrial: #464155;
@public: #91d6e5;
@stem: #008244;

.pathway-icons {
    --arts: @arts;
    --business: @business;
    --health: @health;
    --industrial: @industrial;
    --public: @public;
    --stem: @stem;
    
    &__items {
        &:hover {
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.16);
            border-radius: 13px!important;
            transition:all .25s ease-in;
        }
        svg #Layer_1 path {
            transition:all .25s ease-in;
        }

        .arts-and-humanities:hover  {
            svg #Layer_1 path {
                fill:darken(@arts, 15%);
                
            }
        }
        .business-and-entrepreneurship:hover  {
            svg #Layer_1 path {
                fill:darken(@business,15%);
            }
        }
        .health-sciences:hover  {
            svg #Layer_1 path {
                fill:darken(@health,15%);
            }
        }
        .industrial-technologies:hover  {
            svg #Layer_1 path {
                fill:darken(@industrial,15%);
            }
        }
        .public-services:hover  {
            svg #Layer_1 path {
                fill:darken(@public,15%);
            }
        }
        .stem:hover  {
            svg #Layer_1 path {
                fill:darken(@stem,15%);
            }
        }
    }

}



.pathway {
    text-align: left;
    font-family:'Raleway', Arial, sans-serif;
    font-size: 1rem;
    padding: 1.6em 1.4em;
    box-shadow: 2px 3px 6px rgba(0,0,0,.16);
    margin-bottom:30px;
    border-left:10px solid;
    --arts: #f8a326;
    --business: #8b3c73;
    --health: #df463e;
    --industrial: #464155;
    --public: #91d6e5;
    --stem: #008244;

    &__icon {
        flex:0 0 auto;
        width:75px;
        height:75px;
        margin-right:20px;
        @media (min-width: @screen-sm-min) {
            width:100px;
            height:100px;
        }

    }

    &__title {
        font-family:'Bebas Neue';
        font-size:2.2rem;

        @media (min-width: @screen-sm-min) {
            font-size:3rem;
        }
    }

    &__area-heading {
        font-family:'Raleway', Arial, sans-serif;
        color:#002265;
    }

    &__heading {
        display:flex;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    &__areas {
        list-style: none;
        padding-left: 0;
    
        &--columns {
            @media (min-width: @screen-lg-min) {
            column-count:2;
            }
        }
    
    }

    &__area {
        margin-bottom:8px;
    }


    &__links {
        margin-left:15px;
        display:inline-block;
    }

    &__main {
      
        display:block;
    }
    &__main a {
        .button-variant(#ffffff; #002265; #002265);
        .button-size(0.6rem; 1rem; 1.4rem; @line-height-base; @btn-border-radius-base);
    
        font-family: raleway, arial, sans-serif;
        font-weight: 300;
        text-decoration: none !important;
        text-align: center;
        transition: all .15s ease-in;
        display: inline-block;
        text-align: right;
        
        text-decoration: none!important;
        border: 2px solid;
        border-color: #036;
        border-right:none;

        &::after {
            content: '\f054';
            font-family: 'Font Awesome 5 Free';
            margin-left: 5px;
            font-weight:900;
        }
        
        &:focus,
        &:hover {
            background-color: #fff;
            border-color: #036;
            color: #036;
            font-weight:500;
        }
    }
}
.pathways__area-heading {
        font-family:'Raleway', Arial, sans-serif;
        color:#002265;
        font-weight:500;
    }
