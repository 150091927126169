.hero {
  clear: both;
  display: block;
  margin-bottom: 1rem;
  overflow-y: hidden;

  &__wrapper {
    display: block;
    height: calc(100vw * 0.5625);
    max-height: calc(88.1vh - 120px);
    position: relative;
  }

  &__image {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  &__overlay {
    display: block;
    background-color: #036;

    @media (min-width: @screen-sm-min) {
      bottom: 0;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: transparent;

      &--dark {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__column {
    &--center {
      left: 50%;
      transform: translateX(-50%);
    }

    &--right {
      float: right;
    }

    &--left {
      float: left;
    }

    @media (max-width: @screen-xs-max) {
      text-align: left;
    }
  }

  &__overlay-wrapper {
    width: 100%;

    @media (min-width: @screen-sm-min) {
      position: absolute;
      padding: 30px 0;

      &--top {
        top: 0;
      }

      &--middle {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
      }

      &--bottom {
        bottom: 0;
      }
    }
  }

  &__cta {
    margin-bottom: 0px;
  }

  &__content {
    color: #fff;
    font-family: 'Raleway', Arial, sans-serif;
    font-size: 1.2rem;
    margin: 0.5rem;
    padding: 30px;

    @media (min-width: @screen-sm-min) {
      font-size: 1.5rem;

      &--background {
        background-color: rgba(0, 34, 101, 0.7);
      }
    }
  }

  &__headline {
    color: #fff;
    font-family: 'bebas neue', Arial, sans-serif;
    font-size: 2.3rem;
    margin: 0.1em 0;

    @media (min-width: @screen-sm-min) {
      font-size: 4rem;
      margin:0;
    }
  }

  /*
  &__box {
    .container-fixed();

    @media (min-width: @screen-sm-min) {
      width: @container-sm;
    }

    @media (min-width: @screen-md-min) {
      width: @container-md;
    }

    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }

    position: relative;
    z-index: 1;
  }

  &__row {
    .make-row();
  }
  */
}
