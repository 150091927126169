.sidebar {
  h2 {
    color: white;
    background-color: #002265;
    padding: 1rem;
    margin: 0;
  }
  .sidebar__content {
    background-color: #ececec;
    padding: 1rem;
  }
  dd {
    margin-bottom: 0.5rem;
  }
}
