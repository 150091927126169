.sl-gallery {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;

    &__person {
        padding: 5px;
        text-align: left;
        max-width: 380px;
    }

    &__name {
        margin: 0;
        color: #002265;
        font-family: 'Bebas Neue', 'Raleway';
        margin: 1rem 0 0 0 !important;
        font-size: 2rem !important;
    }

    &__title {
        color: #707070;
        font-weight: bold;
        font-size: 1.1rem;
    }

    &__contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__contact-info {
    flex: 1 1 auto;
    }
}