/* a {
	color: #6cace4;
} This color does not have sufficient contrast for ADA accessibility, replacing with below color #3076b3 */
a {
color:#3076b3;    
}

a:hover {
	color: #002265;
}
.pre-header a:hover {
	color: #002265;
}
.shop-currencies a.current {
	color: #002265;
}
.header-navigation ul > li.active > a,
.header-navigation ul > li > a:hover,
.header-navigation ul > li > a:focus,
.header-navigation ul > li.open > a,
.header-navigation ul > li.open > a:hover,
.header-navigation ul > li.open > a:focus {
	color: #FFF;
}
.header-navigation li.menu-search i:hover {
	color: #002265;
}
.sidebar a:hover {
	color: #002265;
}
.sidebar .dropdown.open .dropdown-toggle:hover {
	color: #00205B;
}
.sidebar-menu .dropdown-menu li > a:hover, .sidebar-menu .dropdown-menu li > a:focus, .sidebar-menu li.active > a, .sidebar-menu li.active > a:hover {
	color: #00205B;
}
.content-page a:hover, 
.sidebar2 a:hover {
	color: #00205B;
}
.content-page .link, .content-page .link:hover, .content-page .link:active {
	color: #00205B;
}
.page-404 .number,
.page-500 .number {
	color: #00205B;
}
.content-form-page a:hover {
	color: #00205B;
}
.quote-v1 a.btn-transparent:hover {
  background: #00205B;
}
.recent-work h2 a:hover {
	color: #00205B;
}
.recent-work .recent-work-item .fa:hover {
	color: #00205B;
}
.our-clients h2 a:hover {
	color: #00205B;
}
.front-team h3 strong {
  color: #00205B;
}
.ecommerce .header-navigation ul > li.active > a,
.ecommerce .header-navigation ul > li > a:hover,
.ecommerce .header-navigation ul > li > a:focus,
.ecommerce .header-navigation ul > li.open > a,
.ecommerce .header-navigation ul > li.open > a:hover,
.ecommerce .header-navigation ul > li.open > a:focus {
	color: #00205B;
}
.product-item h3 a:hover {
	color: #00205B;
}
.checkout-page a:hover {
	color: #00205B;
}


.langs-block-others:after { 
	border-bottom: 8px solid #002265;
}
.header-navigation > ul > li.dropdown:hover > a:after { 
  border-bottom: 8px solid #999;
  
  display: table;
  clear: both;
  
}
.header-navigation .dropdown-menu > li > a:hover,
.header-navigation .dropdown-menu > li.active > a,
.header-navigation .header-navigation-content .header-navigation-col li > a:hover,
.header-navigation .header-navigation-content .header-navigation-col li.active > a {
  background: #002265;
  color: #fff;
}
.header-navigation .dropdown-menu .header-navigation-content-ext li > a:hover,
.header-navigation .dropdown-menu .header-navigation-content-ext li.active > a {
  background: #000;
  color: #002265;
}
.header-navigation .search-box:after { 
	border-bottom: 8px solid #002265;
}
.header-navigation .search-box {
	border-top: solid 2px #ea4c1d;
}
.title-wrapper h1 span {
	color: #002265;
}
.breadcrumb > .active {
	color: #002265;
}
.form-info h2 em {
	color: #002265;
}
.nav-tabs {
	border-color: #002265;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	background: #002265;
}
.content-search h1 em {
	color: #002265;
}
.recent-work .recent-work-item:hover a.recent-work-description {
	background: #002265;
}
.testimonials-v1 blockquote:after { 
  background-color: #002265;
}
.testimonials-v1 span.testimonials-name {
	color: #002265;
}
.search-result-item h4 a {
	color: #002265;
}
.top-cart-content:after { 
	border-bottom: 8px solid #002265;
}
.goods-data a,
.checkout-page .checkout-description a {
	color: #002265;
}
.product-page .review a {
	color: #002265;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background: #002265;
    color: #fff;
}
.list-view-sorting a {
	background: #fff;
	color: #002265;
}

::-moz-selection {
  color: #fff;
  background: #002265;
}
::selection {
  color: #999;
  background: #fff;
}
.steps-block ::-moz-selection {
  color: #002265;
  background: #fff;
}
.steps-block ::selection {
  color: #002265;
  background: #fff;
}

.owl-buttons .owl-prev:hover {
    background-color: #e84d1c;
}
.owl-buttons .owl-next:hover {
    background-color: #e84d1c;
}
.steps-block-red {
	background: #e84d1c;
}
.pre-footer .photo-stream img:hover {
	border-color: #E84D1C;
}
.pre-footer-light dl.f-twitter dd a {
	color: #e84d1c;
}
.pre-footer-light address a {
	color: #e84d1c;
}
.testimonials-v1 .left-btn:hover {
    background-color: #e84d1c;
}
.testimonials-v1 .right-btn:hover {
    background-color: #e84d1c;
}
.blog-tags li i,
.blog-info li i {
  color: #E84D1C;
}
.blog-posts .more,
.blog-sidebar .more {
	color: #E84D1C;
}
.recent-news h3 a {
	color: #E84D1C;
}
.blog-photo-stream li img:hover {
  border-color: #E84D1C;
}
.blog-tags li a:hover {
  color: #fff;
  background: #E84D1C;
}
.blog-tags li a:hover:after {
  border-left-color: #E84D1C;
}
.sidebar-categories li > a:hover,
.sidebar-categories li.active > a,
.sidebar-categories li.active:hover > a {
  color: #E84D1C;
}
.blog-item blockquote {
  border-color: #E84D1C;
}
.blog-item h4.media-heading span a {
    color: #E84D1C;
}
.front-steps-wrapper .front-step1 {
	background: #E84D1C;
}
.pricing-active {
  border: 3px solid #E84D1C;
  box-shadow: 7px 7px rgba(232, 77, 22, 0.2);
}
.pricing:hover {
  border: 3px solid #E84D1C;
}
.pricing:hover h4 {
  color: #E84D1C;
}
.pricing-head h3 {
  background: #E84D1C;
}
.pricing-head-active h4 {
  color: #E84D1C;
}
.pricing-content li i {
  color: #E84D1C;
}
.top-cart-block .fa-shopping-cart {
	background: #e84d1c;
}
.product-item .btn:hover {
	background: #e84d1c;
}
.pi-price {
	color: #e84d1c;
}
.product-item .add2cart:hover {
	color: #fff !important;
	background: #E84D1C !important;
	border-color: #E84D1C;
}
.goods-page-price strong, 
.goods-page-total strong,
.checkout-price strong,
.checkout-total strong {
	color: #e84d1c;
}
.shopping-total strong,
.checkout-total-block strong {
	color: #e84d1c;
}
.compare-item strong {
	color: #E84D1C;
}
.sidebar-products .price {
    color: #E84D1C;
}
.price-availability-block .price strong {
	color: #e84d1c;
}


.require {
	color: #e94d1c;
}
.content-form-page .form-control:focus {
	border: solid 1px #e94d1c;
}
.content-search input:focus {
	border: solid 1px #e94d1c;
}

.btn-primary {
	background: #999;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background: #999;
}

.header-navigation .dropdown-menu > li:first-child {
    border-top: 2px solid #999999;
}
.front-steps-wrapper .front-step1:after {
    border-left: 15px solid #EC7049;
}

.del-goods:hover,
.add-goods:hover {
	background-color: #E94D1C;
}

.sidebar a:hover > .fa-angle-down {
	background-position: -11px 0;
}
.sidebar .collapsed:hover > .fa-angle-down {
	background-position: -11px -37px;
}
.top-cart-content {
	border-top: solid 2px #ea4c1d;
}

.front-skills .progress-bar {
  background: #EF4D2E;
}

.service-box-v1:hover {
  background: #d73d04;
}

/* .header .mobi-toggler:hover {
  background-color: #999999;
  border-color: #FFFFFF;
} */

@media (max-width: 1024px) {
	.header .header-navigation li > a:hover,
	.header .header-navigation li.active > a,
	.header .header-navigation li.open > a:hover {
		/*color: #002265 !important;*/
	}
}

.faq-tabbable {
  border-left: solid 2px #e44f00;
}
.faq-tabbable li:hover a,
.faq-tabbable li.active a{
  background: #e44f00;
}
.faq-tabbable li.active:after {
  border-left: 6px solid #e44f00;
}

.mix-filter li:hover, .mix-filter li.active {
    background: #e44f00;
    color: #fff;
}
.mix-grid .mix .mix-details {
	background: #e44f00;
}
.mix-grid .mix a.mix-link, 
.mix-grid .mix a.mix-preview {
  background: #DB3A1B;
}
.langs-block-others {
	border-top: solid 2px #ea4c1d;
}
.brands .owl-buttons .owl-prev:hover {
    background-position: 18px -217px;
}
.brands .owl-buttons .owl-next:hover {
    background-position: -249px -217px;
}
.header-navigation ul > li.active > a/*,
.ecommerce .header-navigation ul > li.active > a*/ {
	/*border-bottom: 2px solid #999999;*/
	/*background-color: #999999;*/
	margin-top: 5px;
	height: 33px;
}