.icon-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Raleway", Arial, sans-serif;
  justify-content: space-between;
  align-items: baseline;

  &__link {
    display: block!important;
    width: 100%!important;
    height: 100%;
    padding-bottom:15px;

  }

  @media (min-width: @screen-sm-min) {
    flex-wrap: nowrap;
  }

  &__item {
    flex: 1 0 50%;
    

    @media (min-width: @screen-sm-min) {
      flex: auto;
      max-width:200px;        
    }
  }

  &__icon {
    min-height: 150px;
    min-width:150px;
    max-width: 175px;
    width: 100%;
    padding:1em;

      &--white {
        stroke:#fff;
        path {
          stroke:#fff;
        }
        polygon {
          stroke:#fff;
        }
        polyline {
          stroke:#fff;
        }
      
      }  
  }


  &__text {
    font-family:"Raleway", Arial, sans-serif;
  }
}