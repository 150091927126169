/* section block */
.page-section {
	width: 100%;
	padding:2rem 0;
	font-family:'Raleway', Arial, sans-serif;

	@media (min-width: @screen-sm-min) {
	//padding-bottom:8rem;
	padding:5rem 0;
	}


	img {
		width:100%;
		height:auto;
	}
	.address {
		margin: 2rem 0 1.5rem 0;
	}
	&--top {
		@media (min-width: @screen-sm-min) {
			padding-top:0;
		}
	padding-top:0;
	}
	&--collapse {
		padding:0 0;
	}

	&--raleway {
		font-family:'Raleway', Arial, sans-serif;
	}

	&--blue {
		background-color:#002265;
		color:white;
		@media (min-width: @screen-sm-min) {
		//padding-top: 3rem;
		}
		a {
			color:white;
		}
	}
	&--beige {
		background-color: #f9f1e1;
	}
	&--lightgrey {
		background-color: #EFEFEF;
	}
	&--center {
		text-align:center;
	}
	&--fixed {
		@media (min-width: @screen-sm-min) {
		background-attachment:fixed;
		}
	}

	&--background-1 {
		background:url('https://grayson.edu/sunbackground.jpg');
		background-size:cover;
		background-position:center;
		padding-bottom:3rem;
		color:#FFF;
		//margin-bottom:3rem;
		position:relative;
		//padding-top:4rem;
		@media (min-width: @screen-sm-min) {
			//margin-bottom:6rem;
		}
		&:before {
			content:"";
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			background-color:rgba(51,51,51,0.7;);
			z-index:0;
		}
	}
	&--background-2 {
		background-image:url('https://grayson.edu/dolphinbackground.jpg');
		background-size:cover;
		background-position:center;
		padding-bottom:3rem;
		color:#FFF;
		//margin-bottom:3rem;
		position:relative;
		//padding-top:4rem;
		@media (min-width: @screen-sm-min) {
			//margin-bottom:6rem;
		}
		&:before {
			content:"";
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			background-color:rgba(51,51,51,0.7;);
			z-index:0;
		}
	}
	&__box {
		.container-fixed();
		@media (min-width: @screen-sm-min) {
		width: @container-sm;
		}
		@media (min-width: @screen-md-min) {
		width: @container-md;
		}
		@media (min-width: @screen-lg-min) {
		width: @container-lg;
		}
		position:relative;
		z-index:1;
	}
	&__fluid-box {
		.container-fixed();
		position:relative;
		z-index:1;
	}

	&__row {
		.make-row();	
		//display:flex;
		//flex-wrap:wrap;	
		&--limited {
			max-width:800px;
			margin:auto;
		}
	}
	&__header {
		font-family:'Raleway', 'Arial', 'Sans-serif';
		font-size:2.3rem;
		color:#002265;
		font-weight:300;
		margin-top:1.5rem;
		@media (min-width: @screen-sm-min) {
			font-size:3rem;
			margin-bottom:1.5rem;
			margin-top:0;
		}	
		&--white {
			color:#FFF;
		}
	}
	&__highlight {
		@media (min-width: @screen-sm-min){
		font-size:1.5rem;
		}
		&--center {
			.make-xs-column(12);
			.make-sm-column(10);
			text-align:center;
			margin: auto;
			float: none!important;
		}
	}
	&__col-6 {
		.make-xs-column(12);
		.make-sm-column(6);
		&--flip-top{
			order:1;
		}
		&--flip-bottom{
			order:0;
		}
	}
	&__col-12 {
		.make-xs-column(12);
	}
}


