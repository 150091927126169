.countdown {
	background:#ffffa9;
	color:black;
	font-weight:bold;
}

.countdown__text {
	padding:3px 0;
	display:block;
}
.countdown__message a {
	color:black;
	text-decoration:underline!important;
}