/*
Template Name: Metronic - Responsive Website Template build with Twitter Bootstrap 3.1.1
*/

/* General body settings */
body {
	color:#444;
  direction: ltr;
  font: 400 100% 'Arial', sans-serif;
	background: #fff;
	line-height: 1.5;
}

/***
General typography
***/
h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
	font: 300 'Arial', sans-serif;
	margin: 0 0 10px;
}
h1 {
	margin-bottom: 15px;
}
.main h1 {
	margin-top: -6px;
}

h4, 
h5, 
h6 {
  font: 300 'Arial', sans-serif;
	margin: 1.2rem 0 1.2rem;
}

/***
Fix link outlines after click
***/
a {
	color: #00205B;
	
}
a, a:focus, a:hover, a:active {
	outline: 0;
 	text-decoration: none; 
 /* text-decoration:underline; */
}
a:hover {
	color: #00205B;
	text-decoration: underline;
}

/***
Misc tools
***/
.no-padding {
	padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-bottom-space {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.no-top-space {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.no-space {
  margin: 0 !important;
  padding: 0 !important;
}
.no-text-shadow {
  text-shadow: none !important;
}

.padding-top-5 {
	padding-top: 5px !important;
}
.padding-top-10 {
	padding-top: 10px !important;
}
.padding-top-15 {
	padding-top: 15px !important;
}
.padding-top-20 {
	padding-top: 20px !important;
}
.padding-top-25 {
	padding-top: 25px !important;
}
.padding-top-30 {
	padding-top: 30px !important;
}
.padding-top-35 {
	padding-top: 35px !important;
}
.padding-top-40 {
	padding-top: 40px !important;
}
.padding-top-45 {
	padding-top: 45px !important;
}
.padding-top-50 {
	padding-top: 50px !important;
}
.padding-top-60 {
	padding-top: 60px !important;
}
.padding-top-80 {
	padding-top: 80px !important;
}
.margin-bottom-0 {
	margin-bottom: 0 !important;
}
.margin-bottom-5 {
	margin-bottom: 5px !important;
}
.margin-bottom-10 {
	margin-bottom: 10px !important;
}
.margin-bottom-15 {
	margin-bottom: 15px !important;
}
.margin-bottom-20 {
	margin-bottom: 20px !important;
}
.margin-bottom-25 {
	margin-bottom: 25px !important;
}
.margin-bottom-30 {
	margin-bottom: 30px !important;
}
.margin-bottom-35 {
	margin-bottom: 35px !important;
}
.margin-bottom-40 {
	margin-bottom: 40px !important;
}
.margin-bottom-50 {
	margin-bottom: 50px !important;
}
.margin-bottom-60 {
	margin-bottom: 60px !important;
}
.margin-bottom-65 {
	margin-bottom: 65px !important;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-20 {
	margin-right: 20px !important;
}
.margin-left-20 {
	margin-left: 20px !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-30 {
	padding-right: 30px !important;
}

.margin-right-10 {
	margin-right: 10px !important;
}

/*.btn {
	padding: 7px 14px;  
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 0;
}
.btn-primary {
	border: none;
	background: #e94d1c;
	color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	color: #fff;
	background: #cc3304;
	border: none;
}
.content-page .btn-primary, 
.content-page .btn-primary:hover {
	color: #fff;
	margin-left: 0 !important;
}
.btn-default {
	border: 1px solid #EDEDED;
	color: #A8AEB3;
	background: transparent;
	padding: 6px 13px; 
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
	border: 1px solid #A8AEB3;
	color: #fff;
	background: #A8AEB3;
	padding: 6px 13px; 
}*/

div.checker,
div.radio {
	top: -1px;
}

select.form-control {
	color: #5f6d7b;
	border-radius: 0;
	border-color: #e4e6e8;
	box-shadow: none;
}
select.form-control:focus {
	box-shadow: none;
	border-color: #999;
}

input.form-control,
textarea.form-control {
	border-color: #dbdbdb;
	color: #777;
	font: 14px Arial, sans-serif;
	border-radius: 0;
	box-shadow: none;
}
input.form-control:focus,
textarea.form-control:focus {
	box-shadow: none;
	border: solid 1px #dbdbdb;
}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
	font-size: 0;
	line-height: 0;
}
* html .clearfix {
	zoom: 1;
}
* + html .clearfix {
	zoom: 1;
}
.clear {
	clear: both; 
	height: 0; 
	font-size: 0; 
	line-height: 0; 
	overflow: hidden;
}

.lead {
    font-size: 16px;
}

.font-transform-inherit {
	text-transform: inherit !important;
}

.color-red {
	color: #00205B;
}

/***
Custom Scrollbars
***/

::-webkit-scrollbar {  
    width: 12px;  
}  

::-webkit-scrollbar-track {  
    background-color: #eaeaea;  
    border-left: 1px solid #cecece;  
}  

::-webkit-scrollbar-thumb {  
    background-color: #cecece;  
}  

::-webkit-scrollbar-thumb:hover {  
    background-color: #aaa;  
}  

::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: none;
    border: 0; 
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: none;
    border: 0; 
}

/* overides metro scrolbars in IE10 and makes them look like a default scrollbar */
@-ms-viewport{
	width: auto !important;
}

::-moz-selection {
  color: #fff;
  background: #00205B;
}
::selection {
  color: #fff;
  background: #00205B;
}

/* Global classes */
.min-hight500 {
	min-height: 500px;
}

/***
Pre-Header and pre-header elements
***/
.pre-header {
	color: #999999;
	border-bottom: 1px solid #eee;
	padding-top: 7px;
	line-height: 1.2;
	font-family: Arial, sans-serif;
	font-weight: normal;	
	height:35px;
}
.pre-header a {
	color: #999999;
}
.pre-header a:hover {
	color: #00205B;
	
}
.pre-header a:focus {
	text-decoration: none;
}
.pre-header li {
	/*padding-left: 11px;*/
	/*padding-right: 15px;*/
	border-right: solid 1px #d8d8d8;
}
.pre-header li:last-child {
	border: none;
}
.additional-shop-info li:first-child {
	padding-left: 0;
}
.additional-nav li:last-child {
	padding-right: 0;
}
.pre-header .fa {
	margin-right: 4px;
}
.shop-currencies a {
	margin-right: 6px;
}
.shop-currencies a:last-child {
	margin-right: 0;
}
.shop-currencies a.current {
	color: #00205B;
}

/* langs block */
.langs-block {
	position: relative;
}
.langs-block .fa {
	margin-right: 0;
}
.langs-block-others-wrapper {
	position: absolute;
	left: 0;
	top: 100%;
    z-index: 99999;
}
.langs-block-others {
	padding: 10px 10px 2px;
	background: #F9F9F9;
	z-index: 9999;
	width: 100px;
	display: none;
	margin-top: 12px;
	border-top: solid 2px #ea4c1d;
	box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
	position: relative;
	transition: opacity .3s ease-in-out;
	-moz-transition: opacity .3s ease-in-out;
	-webkit-transition: opacity .3s ease-in-out;
}
.langs-block-others:after { 
	top: -8px;
	width: 0; 
	height: 0;
	left: 8px;
	z-index: 2; 
	content: " "; 
	display: block; 
	position: absolute;
	border-bottom: 8px solid #00205B;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}
.langs-block:hover .langs-block-others {
	display: block;
}
.langs-block-others a {
	display: block;
	margin-bottom: 8px;
}

/***
Header and header elements
***/
.header {
	/*box-shadow: 0 1px 3px #ddd;	*/	
/*	background:url([system-asset:id=042a27eb0ab2ae5116b4832c99588710]/_files/framework/img/debut_light.png[/system-asset]) repeat;*/
	border-radius: 0;
	margin-bottom: -2px;
	z-index: 999;
	position: relative;
	border-top: 1px solid #ddd;
}

/* FIX styles BEGIN */
/*.page-header-fixed .header {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
}*/
body.page-header-fixed {
	padding-top: 88px !important; /* height of the header */
}
/* FIX styles END */

.header .container {
  position: relative;
  padding-top: 7px;
}
.ie8 .header {
	border-bottom: 1px solid #eee;
}

.site-logo {
  /*float: left;*/
  font-size: 23px;
  font-weight: 400;

  margin-right: 67px;
  /*padding-top: 22px;*/
  /*padding-bottom: 22px;*/
}

.header .mobi-toggler {
  float: right;
  color: #fff;
  border-radius: 100% !important;
  width: 33px;
  height: 33px;
  display: none;
  margin: 25px 0 0 30px;
  position: relative;
  background: #002265;
}
.header .mobi-toggler i {
  left:7px;
  position:absolute;
  bottom: 5px;
  font-size:1.3rem;
  color:#fff;
  font-size:1.3rem;
  margin-top:0;

}
.header .mobi-toggler:hover {
  background: #FFF url(../images/img/icons/toggler.png[/system-asset]) no-repeat 6px -28px;
  border-color: #fff;
  color: #666;
}
.header .mobi-toggler:hover i {
	color:#666;
}

/* Navigation */
.header-navigation {
	font: 14px Arial, sans-serif;
	margin: 0 10px 0 0;
	padding: 0;
	float: left;
	text-transform: uppercase;
}
.header-navigation ul {
	/*margin: 0;*/
	padding: 0;
	list-style: none;
}
.header-navigation > ul > li {
	float: left;
}
.header-navigation > ul > li > a {
	color: #FFF;
	display: block;	
	padding: 29px 15px 30px;
	height: 33px;
	margin-top: 5px;
}
.header-navigation ul > li.active > a,
.header-navigation ul > li > a:hover,
.header-navigation ul > li > a:focus,
.header-navigation ul > li.open > a,
.header-navigation ul > li.open > a:hover,
.header-navigation ul > li.open > a:focus {
	color: #FFF;
	/*background: #fcfcfc;*/
	text-decoration: none;
}
.header-navigation ul > li.active > a {
	border-bottom: 2px solid #002265;
}

/* dropdown menu */
@media (min-width: 1025px) {
	.header-navigation > ul > li:hover > .dropdown-menu {
	  display: block;
	}
}

.header-navigation,
.header-navigation .dropdown {
  position: static;
 /* padding-right: 20px;*/
}
.header-navigation .dropdown-fix-left,
.header-navigation .dropdown-fix-right {
	position: relative;
}
.header-navigation .dropdown-menu {
  left: auto;
  top: 100%;  
  padding: 0;
  background:none;
  position: absolute;
  border: none;  
  /*box-shadow: 5px 5px rgba(91, 91, 91, 0.2);*/
  text-transform: none;
  font: 13px Arial, sans-serif;
  letter-spacing: 0;
  border-radius: 0;
  z-index: 9999;
}
.dropdown-fix-left .dropdown-menu {
	left: 0;
	width: 700px;
}
.dropdown-fix-right .dropdown-menu {
	right: 0;
	width: 700px;
}
.header-navigation > ul > li > .dropdown-menu {
	margin-top: -5px;
	border-top: solid 3px transparent;
	z-index: 1001;
	
}
.header-navigation .dropdown100 > .dropdown-menu {
  left: 15px;
  right: 15px;
}
.header-navigation > ul > li.dropdown:hover > a:after { 
  bottom: 0;
  width: 0; 
  height: 0;
  z-index: 1002; 
  content: " "; 
  display: block !important; 
  position: absolute;
  /*border-bottom: 8px solid #999;*/
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
 
}
.ie8 .header-navigation > ul > li.dropdown:hover > a:after {
	display: none !important;
}
.header-navigation li.dropdown100 > .dropdown-menu:after {
	display: none !important;
}
.header-navigation .dropdown-menu > li:first-child {
	border-top: solid 2px #ea4c1d;
}
.header-navigation .dropdown-menu li {
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #efefef;
  position: relative;
  float: none;
  background: #FFF;
}
.header-navigation .dropdown-menu li:last-child {
  border: none;
}
.header-navigation .dropdown-menu li > a {
  padding: 10px 15px;
  font-weight: 400;
  color: #767f88;
}
.header-navigation .dropdown-menu > li > a:hover,
.header-navigation .dropdown-menu > li.active > a,
.header-navigation .header-navigation-content .header-navigation-col li > a:hover,
.header-navigation .header-navigation-content .header-navigation-col li.active > a {
  background: #fff !important;
  color: #767f88 !important;
}
.dropdown-submenu > a:after {
  display: none;
}

/* navigation content */
.header-navigation-content {
  padding: 20px 30px;
  min-width: 250px;
}
.header-navigation-content .header-navigation-col {
	padding: 0;
}
.header-navigation-content h4,
.ecommerce .header-navigation-content h4 {
  font: bold 15px 'Open Sans', Arial, sans-serif;
  margin: 0 0 6px;
  padding: 10px 10px 5px;
  border-bottom: solid 1px #f0f0f0;
}
.header-navigation-content ul {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
}
.header-navigation-content li {
  padding: 0;
  border: none !important;
}
.header-navigation-content li > a {
  display: block;
}

/* header navigation description */
.header-navigation-content-ext {
  padding: 20px 30px 2px;
  background: #fff;
}
.header-navigation-description {
  background: #fcfafb; 
  margin: -20px 0 -2px -30px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(91, 91, 91, 0.2) inset;
}
.header-navigation-content-ext h4,
.ecommerce .header-navigation-content-ext h4 {
  border: none;
  padding: 10px 0 0;
}
.header-navigation .dropdown-menu .header-navigation-content-ext li {
  border: none;
}
.header-navigation .dropdown-menu .header-navigation-content-ext li > a {
  padding: 0 0 10px;
  color: #767f88;
  display: inline-block;
}
.header-navigation .dropdown-menu .header-navigation-content-ext li > a:hover,
.header-navigation .dropdown-menu .header-navigation-content-ext li.active > a {
  background: #fff;
  color: #00205B;
}

/* n-level submenu */
.header-navigation .dropdown-menu .dropdown-menu {
  left: 100%;
  right: auto;
  top: 6px;
}
.header-navigation .dropdown-menu a {
  position: relative;
}
.header-navigation .dropdown-menu .fa {
  position: absolute;
  top: 13px;
  right: 15px;
}

/* Top Search */
.header-navigation li.menu-search {
	position: relative;
	top: 1px;
	padding-left: 10px;
	color:#777;
}
.header-navigation li.menu-search span.sep {
	top: 0;
	width: 1px;
	height: 26px;
	background: #eaeaea;
	position: relative;
	margin: 0 18px 0 16px;
	display: inline-block;
}
.header-navigation li.menu-search i {
	color: #333;
	font-size: 13px;
	cursor: pointer;
	position: relative;
	top:-1px;
	/*background: url(../img/icons/top-search-icon.png[/system-asset]) no-repeat;*/
	width: 15px;
	height: 15px;
	opacity: 0.6;
}
.header-navigation li.menu-search i:hover {
	color: #00205B;
	background-position: 0 -15px;
}
/*.header-navigation .fa-search:before {
	content: none;
}*/
.header-navigation .search-box {
	right: -7px;
	top: 100%;
	padding: 15px;
	display: none;
	background: #fcfafb;
	position: absolute;
	border-top: solid 2px #ea4c1d;
	box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
	width: 274px;
	margin-top: 0;
	z-index: 1000;
}
.header-navigation .search-box:after { 
	top: -8px;
	width: 0; 
	height: 0;
	right: 8px;
	z-index: 2; 
	content: " "; 
	display: block; 
	position: absolute;
	border-bottom: 8px solid #00205B;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}
.header-navigation .search-box button {
	margin-left: 0 !important;
}

/* reduce navigation */
/*.page-header-fixed .site-logo,
.page-header-fixed .header-navigation > ul > li > a,
.page-header-fixed .header-navigation li.menu-search,
.page-header-fixed .top-cart-block {
 	transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	-ms-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-webkit-transition: all 0.1s ease;
}*/
.reduce-header .site-logo {
	padding-top: 9px;
	padding-bottom: 11px;
}
@media (min-width: 1025px) {
	.reduce-header .header-navigation > ul > li > a {
		padding: 16px 12px 17px;
	}
	.ecommerce .reduce-header .header-navigation > ul > li > a {
		padding: 23px 12px 20px;
	}
	.reduce-header .header-navigation li.menu-search {
		top: 14px;
	}
	.ecommerce .reduce-header .header-navigation li.menu-search {
		top: 21px;
	}
}
.reduce-header .mobi-toggler {
	margin-top: 12px;
}
.page-header-fixed .header .mobi-toggler {
 	transition: margin 0.3s ease;
	-o-transition: margin 0.3s ease;
	-ms-transition: margin 0.3s ease;
	-moz-transition: margin 0.3s ease;
	-webkit-transition: margin 0.3s ease;
}

/* page slider */
.page-slider {
	/*margin-top: -65px;*/
	clear: both;
}

/* OWL styles */
.owl-buttons {
	position: absolute;
	top: -40px;
	right: 10px;
}
.owl-buttons div {
	display: inline-block;
	width: 26px;
	height: 26px;
	margin-left: 5px;
	position: static;
	border-radius: 26px !important;
	text-indent: -100000px;
}
.owl-buttons .owl-prev {
    background: #fff url(../images/img/fa-angles.png[/system-asset]) no-repeat 8px 8px;
}
.owl-buttons .owl-next {
    background: #fff url(../images/img/fa-angles.png[/system-asset]) no-repeat -33px 8px;
}
.owl-buttons .owl-prev:hover {
    background: #e84d1c url(../images/img/fa-angles.png[/system-asset]) no-repeat 8px -31px;
}
.owl-buttons .owl-next:hover {
    background: #e84d1c url(../images/img/fa-angles.png[/system-asset]) no-repeat -33px -31px;
}

/*  */
.carousel-indicators {
	text-align: left;
	margin: 0;
	width: auto;
	left: 33px;
}
.carousel-indicators li,
.carousel-indicators li.active {
	width: 18px;
	height: 18px;
	margin: 0 9px 0 0;
	background: transparent;
	border: solid 2px #c9cdce;
	border-radius: 18px !important;
	z-index: 9999 !important;
}
.carousel-indicators li.active {
	background: #6f7a7c;
	border-color: #9ea7a9;
}
/* END LAYER SLIDER */

/*  */
.product-list .product-item {
	margin-bottom: 30px;
}

/***
Sidebar
***/
.sidebar {
	color: #3E4D5C;
}
.sidebar .list-group-item {
    background: rgba(244,244,244,0.5);
    border: none;
    display: block;
    margin-bottom: 2px;
    padding: 7px 10px;
    position: relative;
    border-radius: 0;
}
.sidebar .list-group-item:last-child {
	margin: 0;
}
.sidebar a {
	color: #3E4D5C;
}
.sidebar a:hover {
	color: #00205B;
}
 .sidebar-menu a {
	display: block;
}
 .sidebar-menu a:hover {
 	text-decoration: none;
}
.sidebar-menu .fa-angle-right {
	position: relative;
	top: 1px;
	margin-right: 2px;
	float: left;
}
.sidebar .dropdown.open .dropdown-toggle {
    background: #fff;
    color: #3E4D5C;
}
.sidebar .dropdown.open .dropdown-toggle:hover {
	color: #00205B;
}
.sidebar .dropup.open > .dropdown-toggle, .dropdown.open > .dropdown-toggle {
    border-color: none !important;
}

.sidebar .dropdown-menu {
    background-clip: padding-box;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: none;
    display: none;
    float: none;
    font-size: 14px;
    list-style: none;
    margin: 0 -10px;
    min-width: auto;
    padding: 5px 10px 1px 19px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1000;
    font: 15px 'PT Sans Narrow', sans-serif;
}
.sidebar .dropdown-menu .dropdown-menu {
	border-top: none;
	margin-bottom: -5px;
	margin-right: -10px;
}
.sidebar .open>.dropdown-menu {
	display: block;
}
.sidebar .dropdown-menu li {
	padding: 4px 0 3px 11px;
	position: relative;
}
.sidebar .dropdown-menu ul {
	padding: 0;
	margin: 0 0 0 4px;
	list-style: none;
	text-transform: none;
	font-size: 15px;
}
.sidebar .dropdown-menu > li > a {
    clear: both;
    line-height: inherit;
    padding: 0;
    white-space: nowrap;
}
.sidebar-menu .dropdown-menu li > a:hover, .sidebar-menu .dropdown-menu li > a:focus, .sidebar-menu li.active > a, .sidebar-menu li.active > a:hover {
	background: none !important;
	color: #00205B;
}
.sidebar .dropdown-menu > li > a .fa-angle-down {
	position: absolute;
	top: 8px;
	right: 0;
}
.sidebar .fa-angle-down:before {
    content: "";
}
.sidebar .fa-angle-down {
	width: 11px;
	height: 11px;
	background: url(../images/img/icons/sidebar-toggle-icons.png[/system-asset]) no-repeat 0 0;
	position: absolute;
	top: 12px;
	right: 10px;
}
.sidebar a:hover > .fa-angle-down {
	background-position: -11px 0;
}
.sidebar .collapsed .fa-angle-down {
	background-position: 0 -37px;
}
.sidebar .collapsed:hover > .fa-angle-down {
	background-position: -11px -37px;
}

/***
Brands
***/
.brands .container {
	padding-right: 25px;
}
.brands .owl-carousel {
    margin-bottom: 41px;
    padding: 0 75px;
    background: #fff;
}
.brands .owl-buttons div {
    position: absolute;
    top: 0;
    width: 70px;
    height: 102px;
    text-indent: -10000px;
    border-radius: 0 !important;
    display: block;
}
.brands .owl-buttons {
	position: static;
}
.brands .owl-buttons .owl-prev {
    left: 0;
    background: #fff url(../images/img/fa-angle-brands.png[/system-asset]) no-repeat 18px 18px;
    margin-left: 0;
}
.brands .owl-buttons .owl-prev:hover {
    background: #fff url(../images/img/fa-angle-brands.png[/system-asset]) no-repeat 18px -217px;
}
.brands .owl-buttons .owl-next {
    left: auto;
    right: 0;
    background: #fff url(../images/img/fa-angle-brands.png[/system-asset]) no-repeat -249px 18px;
    margin-right: 0;
}
.brands .owl-buttons .owl-next:hover {
    background: #fff url(../images/img/fa-angle-brands.png[/system-asset]) no-repeat -249px -217px;
}

/***
steps block and steps block elements
***/
/* shop */
.steps-block {
	color: #fff;
	padding: 30px 0;	
}
.steps-block ::-moz-selection {
  color: #002265;
  background: #fff;
}
.steps-block ::selection {
  color: #002265;
  background: #fff;
}
.steps-block-gray {
	background: #a0a3a4;
}
.steps-block-red {
	background: #e84d1c;
}
.steps-block-col {
	overflow: hidden;
	min-height: 71px;
}
.steps-block-col span {
	display: block;
	width: 36px;
	height: 71px;
	background: url(../images/img/step3-angle-right.png[/system-asset]) no-repeat 100% 50%;
	position: absolute;
	top: 0;
	right: 10px;
}
.steps-block h2,
.ecommerce .steps-block h2 {
	color: #fff;
	font-size: 43px;
	margin: 0;
	padding: 0;
	line-height: 1;
}
/* steps-block-simple – step block without image */
.steps-block-simple h2,
.ecommerce .steps-block-simple h2 {
	font-size: 33px;
	margin-bottom: 5px;
}
.steps-block em {
	font: normal 400 13px 'Opan Sans', sans-serif;
	text-transform: uppercase;
}
.steps-block-simple em {
	text-transform: inherit;
	line-height: 1.2;
}
.steps-block .steps-block-col div {
	margin-left: 70px;
	margin-right: 40px;
}
.steps-block-simple .steps-block-col div {
	margin-left: 0;
}
.steps-block .fa-truck,
.steps-block .fa-gift,
.steps-block .fa-phone {
	font-size: 30px;
	border: solid 2px #fff;
	border-radius: 30px !important;
	width: 60px;
	height: 60px;
	text-align: center;
	vertical-align: middle;
	padding-top: 22px;
	margin-right: 11px;
	float: left;
}
.steps-block .fa-angle-right {
	font-size: 84px;
}

/* title wrapper */
.title-wrapper {
	margin: -23px 0 23px;
	position: relative;
	overflow: hidden;
	background: #72c2ff url(../images/img2/title-bg/man.jpg[/system-asset]) no-repeat 100% 100%;
	min-height: 280px;
	padding-top: 79px;
	width: 100%;
	overflow: hidden;
}
.title-wrapper .container-inner {
	float: left;
}
.ecommerce .title-wrapper h1, .title-wrapper em {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 400;
}
.ecommerce .title-wrapper h1 {
	font-size: 45px;
	padding: 0 9px 13px;
	border-bottom: solid 1px rgba(255,255,255,0.6);
	margin: 0 -9px 17px;
}
.title-wrapper h1 span {
	color: #00205B;
}
.title-wrapper em {
	font-size: 20px;
	font-style: normal;
	display: block;
	text-align: center;
}

/* breadcrumb */
.breadcrumb {
	background: none;
	padding: 0;
	font: 14px Arial, sans-serif;
	color: #5f6d7b;
	margin-bottom: 22px;
}
.breadcrumb > li + li:before {
	content: "\f105 ";
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    color: #5f6d7b;
}
.breadcrumb a {
	color: #5f6d7b;
}
.breadcrumb > .active {
	color: #00205B;
}

/* paginations */
.pagination {
	border-radius: 0;
	margin: 0;
}
.pagination > li {
    display: inline-block;
    margin-left: 5px;
}
.pagination > li > a, .pagination > li > span,
.pagination > li:first-child > a, .pagination > li:first-child > span,
.pagination > li:last-child > a, .pagination > li:last-child > span {
	border-radius: 25px !important;
	border: none;
	color: #868c93;
}
.pagination > li > span,
.pagination > li > span:hover {
	background: #555;
	color: #fff;
}
.pagination > li:first-child > a,
.pagination > li:last-child > a {
	padding: 4px 12px 8px;
}
.items-info {
	padding-top: 7px;
	color: #868c93;
}

/* content page */
.content-page {
	background: #fff;
	padding: 20px;
	margin-right: 10px;
}
.corporate .content-page {
	padding: 0 0 20px;
}
.content-page a, 
.sidebar2 a {
	color: #767F88;
}
.content-page a:hover, 
.sidebar2 a:hover {
	color: #00205B;
}
.content-page .link, .content-page .link:hover, .content-page .link:active {
	color: #00205B;
}
.content-page h2, 
.content-page h3, 
.content-page h4, 
.content-page h5, 
.content-page h6 {
	/*text-transform: inherit;*/
	padding-top: 10px;
}
.content-page h3 {
	font-size: 20px;
	margin-bottom: 7px;
}
.content-page h4 {
	font-size: 17px;
	margin-bottom: 4px;
}
.content-page h5, 
.content-page h6 {
	font: 700 15px 'Open Sans', Arial, sans-serif;
	margin-bottom: 2px;
}
.content-page p,
.content-page .list-unstyled {
	margin-bottom: 20px;
}
.content-page .list-unstyled .list-unstyled {
	margin-left: 20px;
	padding-top: 3px;
	margin-bottom: 0;
}
.content-page .list-unstyled li {
	margin-bottom: 3px;
}

/* 404 page */
.corporate .page-404 {
 	text-align: center;
 	padding-top: 60px;
 	padding-bottom: 125px;
}
.page-404 .number {
	position: relative;
	top: 35px;
	display: inline-block;
	letter-spacing: -10px;
	margin-top: 0px;
	margin-bottom: 10px;
	line-height: 128px;
	font-size: 128px;
	font-weight: 300;
	color: #00205B;
	text-align: right;
}
.page-404 .details {
	margin-left: 40px;
	display: inline-block;
	padding-top: 0px;
	text-align: left;
	top: 15px;
	position: relative;
}

/* 500 page */
.corporate .page-500 {
	text-align: center;
 	padding-top: 60px;
 	padding-bottom: 60px;
}
.page-500 .number {  
	display: inline-block;
	letter-spacing: -10px;
	line-height: 128px;
	font-size: 128px;
	font-weight: 300;
	color: #00205B;
	text-align: right;
}
.page-500 .details {
	margin-left: 40px;
	display: inline-block;
	text-align: left;
}

/* content form page */
div.content-form-page {
	background: #fff;
	padding: 5px 20px 30px;
	margin-right: 10px;
}
.content-form-page a {
	color: #767F88;
}
.content-form-page a:hover {
	color: #00205B;
}
.form-without-legend {
	padding-top: 25px;
}
.content-form-page legend {
	border: none;
	font-size: 18px;
	padding-top: 20px;
	margin-bottom: 10px;
	color: #3E4D5C;
}
.content-form-page .control-label {
	font-weight: normal;
	padding-right: 5px;
	margin-left: -15px;
}
.default-form label,
.checkout-page label {
	font-weight: normal;
}
.require {
	color: #e94d1c;
}
.content-form-page input.form-control {
	border-color: #dbdbdb;
	color: #aaa;
	font: 14px Arial, sans-serif;
	border-radius: 0;
}
.content-form-page .form-control:focus {
	box-shadow: none;
	border: solid 1px #e94d1c;
}
.content-form-page .checkbox .col-lg-4 {
	padding-right: 0;
	margin-left: -15px;
}
/*.content-form-page .checker {
	margin-left: -15px;
}*/
.content-form-page .btn {
	margin-right: 10px;
	/*padding: 7px 12px 6px;*/
}
div.content-form-page .checkbox-list {
	margin-left: -3px;
}

/* info at the right of the forms */
.form-info {
	padding: 0 10px 0 30px;
	border-left: solid 1px #e4e6e8;
	margin: 25px 0;
}
.form-info h2,
.ecommerce .form-info h2 {
	font-size: 18px;
	position: relative;
	top: -5px;
	margin-bottom: 5px;
}
.form-info h2 em {
	font-style: normal;
	color: #00205B;
}
.form-info p {
	margin: 0 0 20px;
}


.nav-tabs {
	border-color: #00205B;
	padding-bottom: 1px;
	font-size: 14px;
	margin-bottom: 0;
}
.nav-tabs > li > a, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
	background: #F4F4F4;
	color: #647484;
	border-radius: 0;
	padding: 5px 15px 4px;
	border: none !important;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	background: #00205B;
	color: #fff;
	padding: 5px 15px 4px;
}
.tab-content {
	background: #FAFAFA;
	padding: 20px 15px;
}

/* content search */
.content-search {
	background: #fff;
	padding: 10px 15px;
}
.ecommerce .content-search h1 {
	padding-top: 3px;
	margin: 0;
}
.content-search h1 em {
	font-style: normal;
	color: #00205B;
}
.content-search form {
	margin: 0;
}
.content-search input {
	border-radius: 0;
	border-color: #dbdbdb;
	color: #b0b4b7;
	font: 14px Arial, sans-serif;
	height: 35px;
}
.content-search input:focus {
	box-shadow: none;
	border: solid 1px #e94d1c;
}
.content-search button {
	margin-left: 0 !important;
	font-size: 14px;
	/*padding: 7px 12px 5px;*/
	height: 35px;
}

/***
Pre-Footer and pre-footer elements
***/

.pre-footer {
	background: #666;
}

.pre-footer .container {
	padding-top: 0px;
}
.pre-footer a {
	color: #57c8eb;
}
.pre-footer h2,
.ecommerce .pre-footer h2 {
	font-size: 21px;
	color: #c2c1c1;
}
.pre-footer p {
	margin-bottom: 20px;
}
.pre-footer li {
	margin-bottom: 6px;
}

/* pre-footer twitter block */
.pre-footer dl.f-twitter {
	overflow: hidden;
	margin-bottom: 7px;
}
.pre-footer dl.f-twitter dt {
	width: 30px;
	float: left;
	text-align: left;
	padding-top: 2px;
}
.pre-footer dl.f-twitter dt i {
	color: #57c8eb;
	font-size: 20px;
}
.pre-footer dl.f-twitter dd {
	color: #b0b0b0;
	margin-left: 30px;
}
.pre-footer dl.f-twitter dd span {
	color: #6b9cab;
	font-size: 12px;
	margin: 0 5px;
}
.pre-footer address {
	line-height: 1.7;
}
.pre-footer-col {
	padding-bottom: 22px;
}
.pre-footer .top-line {
	border-top: solid 1px #535353;
	padding-top: 24px;
	padding-bottom: 17px;
}
.pre-footer .social-icons {
	padding-top: 5px;
}
.pre-footer .social-icons li a, 
.pre-footer a.social-icon {
  transition: all 0.1s ease-in-out !important;
  -o-transition: all 0.1s ease-in-out !important;
  -ms-transition: all 0.1s ease-in-out !important;
  -moz-transition: all 0.1s ease-in-out !important;
  -webkit-transition: all 0.1s ease-in-out !important;
}
.pre-footer .social-icons li a:hover {
  opacity: 1;
}

.pre-footer hr {
	margin-top: 0;
	border-color: #404040;
}
.pre-footer .form-control,
.pre-footer .form-control:focus {
	border: none;
}

/* subscribe */
.pre-footer .pre-footer-subscribe-box {
	padding-bottom: 14px;
}
.ecommerce .pre-footer-subscribe-box form {
    float: left;
    width: 330px;
}
.pre-footer .pre-footer-subscribe-box p {
	clear: both;
}
.pre-footer-subscribe-box .input-group {
	border: solid 1px #545454;
	padding: 1px 1px 0 0;
}
.pre-footer-light .pre-footer-subscribe-box .input-group {
	border: solid 1px #DBDBDB;
}
.ecommerce .pre-footer-subscribe-box h2,
.corporate .pre-footer-subscribe-box h2 {
	float: left;
	margin-right: 20px;
	padding-top: 7px;
}
.pre-footer-subscribe-box input {
	border-radius: 0;
	background: #313030;
	border: solid 1px #313030;
	color: #909090;
	font: 13px Arial, sans-serif;
}
.pre-footer-subscribe-box input:focus {
	box-shadow: none;
	border: solid 1px #313030;
}
.pre-footer-subscribe-box .btn-primary {
	margin-left: 0 !important;
	border-bottom: none;
	margin-bottom: 1px;
}

/* pre-footer photostream */
.pre-footer .photo-stream li {
	margin: 4px 5px 4px 0;
	display: inline-block;
}
.pre-footer .photo-stream img {
	width: 54px;
	height: 54px;
	border: solid 2px #9ca5ae;
}
.pre-footer .photo-stream img:hover {
	border-color: #E84D1C;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;  
}

/* light pre-footer */
.pre-footer-light {
	background: #F9F9F9;
	color: #3E4D5C;
	margin-top: 20px;
}
.pre-footer-light .container {
	background: #fff;
	padding-top: 25px;
}
.pre-footer-light h2,
.ecommerce .pre-footer-light h2 {
	color: #3E4D5C;
}
.pre-footer-light a {
	color: #3E4D5C;
}
.pre-footer-light dl.f-twitter dd {
	color: #3E4D5C;
}
.pre-footer-light dl.f-twitter dd span {
	color: #99a5b1;
}
.pre-footer-light dl.f-twitter dd a {
	color: #e84d1c;
}

.pre-footer-light address a {
	color: #e84d1c;
}

.pre-footer-light hr {
	border-color: #e6e6e6;
}

.pre-footer-light .social-icons li a {
  opacity: 1;
}
.pre-footer-light .social-icons li a:hover {
  opacity: 0.7;
}

.pre-footer-light .input-group {
	border: none;
	padding: 1px 1px 0 0;
}
.pre-footer-light .pre-footer-subscribe-box input {
	background: #fff;
	border: none;
	color: #909090;
}
.pre-footer-light .pre-footer-subscribe-box button:hover {
	color: #fff;
}

/* light pre-footer */
.pre-footer-gray {
	background: #62707F;
	color: #fff;
}
.pre-footer-gray h2,
.ecommerce .pre-footer-gray h2 {
	color: #fff;
}
.pre-footer-gray dl.f-twitter dd {
	color: #fff;
}
.pre-footer-gray dl.f-twitter dd span {
	color: #fff;
	opacity: 0.5;
}

.pre-footer-gray hr {
	border-color: #94a5b6;
}

.pre-footer-gray .social-icons li a {
  opacity: 1;
}
.pre-footer-gray .social-icons li a:hover {
  opacity: 0.7;
}

.pre-footer-gray .pre-footer-subscribe-box fomr {
	color: #fff;
}
.pre-footer-gray .pre-footer-subscribe-box .input-group {
    border: 1px solid #94a5b6;
}
.pre-footer-gray .pre-footer-subscribe-box input {
	background: #62707F;
	border: solid 1px #62707F;
	color: #fff;
}
.pre-footer-gray .pre-footer-subscribe-box input:focus {
	border: solid 1px #62707F;
}
.pre-footer-gray ::-webkit-input-placeholder { /* WebKit browsers */
    color: #fff;
}
.pre-footer-gray :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;
}
.pre-footer-gray ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
}
.pre-footer-gray :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #fff;
}

/* footer */
.footer {
	background: #002265;
	color: #fff;
	font-size: 11px;
	padding: 5px 0;
}
.footer-light {
	background: #F9F9F9;
	color: #3e4d5c;
}
.footer-gray {
	background: #4A5866;
}
.footer a {
	color: #fff;
	text-decoration: underline;
}
.footer a:hover {
	text-decoration: none;
	color: #999;
}
.footer-light a {
	color: inherit;
}
.footer .padding-top-10 {
	opacity: 1.0;
}
.footer .list-inline > li:last-child {
	padding-right: 0;
}
.footer ul {
	margin: 0;
	padding: 0;
}
.footer ul.social-footer {
	font-size: 18px;
	padding-top: 5px;
}
.footer ul.social-footer a {
	opacity: 0.5;
}
.footer ul.social-footer a:hover {
	opacity: 1;
}

/***
Custom icon buttons
***/
.icon-btn {
  height: 60px;
  min-width: 80px;
  margin: 0 5px 5px 0;
  border: 1px solid #ddd;
  padding: 12px 0px 0px 0px;
  background-color: #fafafa !important;
  background-image: none !important;
  filter:none !important;
  -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;
  display:inline-block !important;
  color: #646464 !important;
  text-shadow: none !important;
  text-align: center;
  cursor: pointer;
  position: relative;  
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.icon-btn i {
  font-size: 18px;
}

.ie8 .icon-btn:hover {
  filter: none !important;
} 

.icon-btn:hover {
  text-decoration: none !important;
  border-color: #999 !important;
  color: #444 !important;
  text-shadow: 0 1px 0px rgba(255, 255, 255, 1) !important;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.icon-btn:hover .badge {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  -webkit-box-shadow: none !important;
       -moz-box-shadow: none !important;
            box-shadow: none !important;
}

.icon-btn div {
  font-family: 'Open Sans', sans-serif;
  margin-top: 5px;
  margin-bottom: 10px;  
  color: #000;
  font-size: 12px;
  font-weight: 300;
}

.icon-btn .badge {
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px !important;
  font-weight: 300;
  top: -5px;
  right: -5px;
  padding: 3px 6px 3px 6px;
  color: white !important;
  text-shadow: none;
  border-width: 0;
  border-style: solid;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/***
Notes
***/

/* Common styles for all types */
.note {
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.note h1,
.note h2,
.note h3,
.note h4 {
  margin-top: 0;
}

.note p:last-child {
  margin-bottom: 0;
}
.note code,
.note .highlight {
  background-color: #fff;
}

/* Variations */
.note-danger {
  background-color: #FAEAE6;
  border-color: #ed4e2a;
}

.note-warning {
  background-color: #FCF3E1;
  border-color: #fcb322;
}

.note-info {
  background-color: #E8F6FC;
  border-color: #57b5e3;
}

.note-success {
  background-color: #EBFCEE;
  border-color: #3cc051;
}

/*  panel styles */
.panel-default > .panel-heading {
    color: inherit;
}
.panel .panel-title {
	padding-top: 0;
	margin-bottom: 0;
}

/***
Font awesome icons styles
***/
.fontawesome-icon-list [class^="fa-"],
.fontawesome-icon-list [class*=" fa-"],
li [class^="fa-"],
li [class*=" fa-"] {
	display: inline-block;
	width: 1.25em;
	text-align: center;
}
.fontawesome-icon-list [class^="fa-"].icon-large,
.fontawesome-icon-list [class*=" fa-"].icon-large,
li [class^="fa-"].icon-large,
li [class*=" fa-"].icon-large {
  	width: 1.5625em;
}
.fontawesome-icon-list .col-md-3 {
    height: 32px;
    line-height: 32px;
}


/***
Demo Utils
***/
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto;
}

.util-btn-margin-bottom-5 .btn {
  margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
  margin-bottom: 5px !important;
}

.fontawesome-demo i {
  font-size: 18px;
}

.fontawesome-demo li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}
.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px;
}
.glyphicons-demo ul li {
	width: 12.5%;
}
.glyphicons-demo ul li [class^="glyphicon-"], .glyphicons-demo ul li [class*=" glyphicon-"] {
	display: block;
	text-align: center !important;
	width: auto;
	line-height: 1.2;
}
.glyphicons-demo ul li:hover {
  background-color: rgba(86,61,124,.1);
}

.buttons-page .btn {
    margin-bottom: 5px !important;
}
.buttons-page .clearfix {
	margin-bottom: 20px;
}
#topcontrol {
	z-index: 99999;
}

/* faq page */
.faq-page .panel-heading {
	padding: 0;
}
.faq-page .panel-heading h4 {
	font-size: 17px;
}
.faq-page .panel-heading a {
	display: block;
	padding: 10px 15px 9px;
}
.faq-page .panel-heading a:hover {
	color: #333;
	text-decoration: underline;
}

/* Services Box */
.service-box p {
  color: #656565;
  font-size: 13px;
}
.service-box .service-box-heading {
  padding: 12px 0;
}
.service-box .service-box-heading i {
  padding: 0;
  -webkit-border-radius: 34px;
     -moz-border-radius: 34px;
          border-radius: 34px;
    font-size:18px;
    height:32px;
    width:32px;
    line-height: 32px;
    text-align:center;
    vertical-align: baseline;
}
.service-box .service-box-heading i.blue {
  color: #0da3e2;
  border:1px solid #0da3e2;
}
.service-box .service-box-heading i.red {
  color: #db3a1b;
  border:1px solid #db3a1b;
}
.service-box .service-box-heading i.green {
  color: #35aa47;
  border:1px solid #35aa47;
}
.service-box .service-box-heading span {
  color: #444;
  font-size: 21px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 10px; 
  margin-left: 10px;
  display: inline-block;
}
.service-box .service-box-heading [class^="icon-"],
.service-box [class*=" icon-"] {
  display: inline-block;
  text-align: center;
}
.service-box .service-box-heading [class^="icon-"].icon-large,
.service-box .service-box-heading [class*=" icon-"].icon-large {
  /* increased font size for icon-large */
  width: 1.5625em;
}

/* Blockquote Box */
.quote-v1 {
  background: #7C858E;
  padding: 10px 0;
  margin-left: 0;
  margin-right: 0;
}
.quote-v1 a {
  margin: 5px;
  display: inline-block;
}
.quote-v1 span {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  margin: 13px 5px 8px;
  line-height: 26px;
  display: inline-block;
}
.quote-v1 a.btn-transparent {
  color: #fff;
  font-size: 16px;
  padding: 8px 18px;
  white-space: nowrap;   
  text-decoration: none;
  border: solid 1px #fff;
  background: none;
}
.quote-v1 a.btn-transparent:hover {
  background: #00205B;
  color: #fff;
  transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;      
}
.quote-v1 a.btn-transparent i {
	margin-right: 10px;
}

/* Recent Works */
.recent-work {
  position: relative;
}
.recent-work a:hover {
  text-decoration: none;
}
.recent-work h2,
.ecommerce .recent-work h2 {
  margin: 3px 0 5px;
}
.recent-work h2 a {
	color: #3e4d5c;
}
.recent-work h2 a:hover {
	color: #00205B;
}
.recent-work-item {
	padding-left: 10px;
}
.recent-work .owl-buttons {
	left: -74px;
	right: auto;
    top: 0;
}
.recent-work .recent-work-item a.recent-work-description {
	padding: 10px;
	display: block;
	background: #fff;
}
.recent-work .recent-work-item:hover a.recent-work-description {
	background: #00205B;
    transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    -moz-transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;		
}
.recent-work .recent-work-item strong {
	color: #000;
	display: block;
	font-size: 16px;
	font-weight: 300;
}
.recent-work .recent-work-item b {
	color: #656565;
	font-weight: 300;
}
.recent-work .recent-work-item:hover b,
.recent-work .recent-work-item:hover strong {
	color: #fff;
}
.recent-work .recent-work-item em {
	display: block;
	overflow: hidden;
	position: relative;
}
.recent-work .recent-work-item .fa {
	color: #fff;
	padding: 16px 30px 16px 18px;
	font-size: 16px;
	position: absolute;
	top: 50%;
	width: 47px;
	height: 47px;
	margin-top: -23.5px;
	border-radius: 47px;
	background: rgba(0,0,0,0.5);
    transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;	
}
.recent-work .recent-work-item .fa:hover {
	color: #00205B;
}
.recent-work .recent-work-item .fa-link {
	left: -100px;
}
.recent-work .recent-work-item .fa-search {
	right: -100px;
}
.recent-work .recent-work-item:hover .fa-link {
	left: 30%;
}
.recent-work .recent-work-item:hover .fa-search {
	right: 30%;
}

/* Mixed Block */
/* Testimonials */
.testimonials-v1 blockquote {
	padding: 15px; 
	border: none;
	color: #666;
	display: block;
	font-size: 14px;
	line-height: 20px;
	background: #F8F8F8;
	position: relative;
}
.testimonials-v1 blockquote:before {
  content: " ";
  display: block;
  position: absolute;
  bottom: -20px;
  left: 70px;
  width: 0;
  height: 0;
  border-left: 0 inset rgba(0, 0, 0, 0);
  border-right: 22px inset rgba(0, 0, 0, 0);
  border-top: 22px solid #F8F8F8;
}
.testimonials-v1 blockquote:after { 
  width: 13px; 
  height: 13px;
  z-index: 23; 
  content: " "; 
  display: block !important; 
  position: absolute;
  right: 0;
  bottom: 0;
  background: #00205B url(../images/img/blockquote-corner.png[/system-asset]) no-repeat;
}

.testimonials-v1 .carousel-info img {
	width: 75px;
	height: 75px;
	padding: 3px; 
	border-radius: 150px !important;
	border: solid 1px #f5f5f5;
}
.testimonials-v1 .carousel-info {
	overflow: hidden;
}
.testimonials-v1 .carousel-info img {
	margin-right: 15px;
}
.testimonials-v1 .carousel-info span {
	display: block;
}
.testimonials-v1 span.testimonials-name {
	color: #00205B;
	font-size: 16px;
	font-weight: 300;
	margin: 23px 0 7px;
}
.testimonials-v1 span.testimonials-post {
	color: #656565;
	font-size: 12px;
}
.testimonials-v1 .left-btn,
.testimonials-v1 .right-btn {
	right: 0;
	width: 26px;
	height: 26px;
	bottom: 30px;
	border-radius: 26px !important;
	position: absolute;
	display: inline-block;
}
.testimonials-v1 .left-btn {
	right: 30px;
    background: url(../images/img/fa-angles.png[/system-asset]) no-repeat 8px 8px;
}
.testimonials-v1 .right-btn {
    background: url(../images/img/fa-angles.png[/system-asset]) no-repeat -33px 8px;
}
.testimonials-v1 .left-btn:hover {
    background: #e84d1c url(../images/img/fa-angles.png[/system-asset]) no-repeat 8px -31px;
}
.testimonials-v1 .right-btn:hover {
    background: #e84d1c url(../images/img/fa-angles.png[/system-asset]) no-repeat -33px -31px;
}

/* our clients */
.our-clients {
	position: relative;
}
.our-clients h2,
.ecommerce .our-clients h2 {
	margin: 3px 0 5px;
}
.our-clients h2 a {
	color: #3e4d5c;
}
.our-clients h2 a:hover {
	color: #00205B;
}
.our-clients .client-item {
	background: #f8f8f8;
	margin-left: 2px;
	position: relative;
}
.our-clients .owl-buttons {
	left: -74px;
	right: auto;
    top: 0;
}
.our-clients .client-item img {
	margin-left: auto;
	margin-right: auto;
}
.our-clients .client-item .color-img {
	display: none;
}
.our-clients .client-item:hover img {
	display: none;
}
.our-clients .client-item:hover .color-img {
	display: block;
}

/* Front Carousel */
.front-carousel .carousel-control {
  margin: 0;
  border: none;
  height: 35px;
  font-size: 24px;
  font-weight: normal;
  top: 30%;
  width: 35px;
  background: #222;
  opacity: 0.5;
}
.front-carousel .carousel-control:hover {
  opacity: 0.6;
}
.front-carousel .carousel-control i {
  font-size: 27px;
}
.front-carousel .carousel-control.right i {
  position: relative;
  left: 1px;
}
.front-carousel .carousel-caption {
padding: 10px 0px 0;
background: rgba(255,255,255,1);
left: 0;
right: 0;
bottom: 0;
text-align: left;
margin-bottom: -110px;
color: #999;
}
.front-carousel .carousel-control.left {
  left: 0;
}
.front-carousel .carousel-control.right {
  right: 0;
}

/* Skils */
.front-skills span {
  color: #555;
  display: block;
  font-size: 15px;
  margin-bottom: 7px;
}
.front-skills .progress {
  background: #f2f2f2;
}
.front-skills .progress-bar {
  background: #EF4D2E;
}
.front-skills .progress,
.front-skills .progress-bar {
  box-shadow: none;
}

/* Team Blocks */
.front-team .thumbnail {
  border: none;
}
.front-team h3,
.ecommerce .front-team h3 {
  margin:10px 0 12px;
  line-height:40px;
}
.front-team h3 strong {
  color: #00205B;
  font-size: 20px;
  font-weight: 400; 
}
.front-team h3 small {
  display: block;
  font-size: 13px; 
}
.front-team ul {
  padding: 4px 0 0;
}
.front-team .social-icons {
  overflow: hidden;
}

/* Service Box v1 */
.service-box-v1 {
  text-align: center;
  padding: 15px;
}
.service-box-v1 i {
  padding: 15px;
  font-size: 35px;
}
.service-box-v1:hover {
  background: #d73d04;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.service-box-v1:hover i,
.service-box-v1:hover p,
.service-box-v1:hover h2 {
  color: #fff;
}

/* corp. search result */
.content-search-view2 {
	background: #EDEFF1;
	padding: 20px;
	margin: 0 0 20px;
}
.search-result-item {
    margin: 0 -10px 20px;
    padding: 0 10px 15px;
    border-bottom: 1px solid #ECEBEB;
}
.search-result-item h4,
.ecommerce .search-result-item h4 {
    margin-bottom: 5px;
    padding: 0;
}
.search-result-item h4 a {
	color: #00205B;
}
.search-result-item p {
	margin-bottom: 10px;
}
.search-link {
    color: #999;
    font-size: 12px;
}

/* FAQ */
.faq-tabbable {
  padding: 0;
  margin: 0;
  list-style: none;
  border-left: solid 2px #e44f00;
}
.faq-tabbable li {
  position: relative;
  margin-bottom: 1px;
}
.faq-tabbable li a {
  font-size: 14px;
  color: #7C858E;
  display: block;
  background: #F4F4F4;
  padding: 12px 10px 11px 8px;
}
.faq-tabbable li:hover a,
.faq-tabbable li.active a{
  background: #e44f00;
  text-decoration: none;
  color: #fff;
}
.faq-tabbable li.active:after {
  content: '';
  display: inline-block;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid #e44f00;
  position: absolute;
  top: 16px;
  right: -5px;
}

/* Lists */
.front-lists-v1 li {
  font-size: 14px;
  margin-bottom: 5px;
}
.content-page .front-lists-v2 li {
  color: #555;
  font-size: 16px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.front-lists-v2 li i {
  margin-right: 5px;
  font-size: 17px;
}

/* Blog Page */
.blog-posts h2,
.ecommerce .blog-posts h2 {
  padding-top: 0;
}
.blog-posts p {
  text-align: justify;
}
.blog-posts .carousel {
  margin-bottom: 0;
}
.blog-posts hr.blog-post-sep {
  margin: 40px 0;
}
.blog-info {
  list-style: none;
  margin: 15px 0 12px 0;
  padding-left:0;
}
.blog-info li {
  padding: 0;
  color: #555;
  font-size: 13px;
  margin-right: 10px; 
  display: inline-block;
}
.blog-info li i {
  color: #E84D1C;
}

.blog-posts .pagination li {
	margin-left: -12px;
	margin-right: 17px;
}

.blog-posts .more,
.blog-sidebar .more {
	color: #E84D1C;
}

/* Blog Sidebar */
.recent-news {
  overflow: hidden;
}
.recent-news h3,
.ecommerce .recent-news h3 {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}
.recent-news h3 a {
	color: #E84D1C;
}
.recent-news-inner{
	padding: 0;
}
.blog-talks .tab-style-1 ul.nav-tabs li > a {
  min-width: 50px;
}
.blog-photo-stream li {
  overflow: hidden;
  margin: 0 5px 8px 0;
  display: inline-block;
}
.blog-photo-stream li img {
  width: 54px;
  height: 54px;
  padding: 2px; 
  border: solid 1px #eee;
}
.blog-photo-stream li img:hover {
  border-color: #E84D1C;
  box-shadow: 0 0 1px #fff;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.blog-tags ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.blog-tags li {
  position: relative;
  margin: 0 16px 7px 0;
  display: inline-block;
}
.blog-tags li a {
  color: #555;
  background: #f5f5f5;
  display: inline-block; 
  padding: 3px 5px 3px 3px;
}
.blog-tags li a:hover {
  color: #fff;
  background: #E84D1C;
  text-decoration: none;
}
.blog-tags li a:hover i {
  color: #fff;
}
.blog-tags li i {
  color: #E84D1C;
  margin-right: 5px;
  display: inline-block;
}
.blog-tags li a:after { 
  top: 50%;
  width: 0; 
  left: 100%;
  height: 0;
  z-index: 2; 
  content: " "; 
  display: block; 
  margin-top: -12px; 
  position: absolute;
  border-left: 12px solid #f5f5f5;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;  
}
.blog-tags li a:hover:after { 
  border-left-color: #E84D1C;
}

.blog-talks .tab-content {
	background: #FAFAFA;
	padding: 20px 15px;
}
.blog-talks .nav-tabs {
	margin-bottom: 0;
}
.blog-talks .nav-tabs > li {
	margin-top: 1px;
}

/* Blog Sidebar categories BEGIN */ 
.sidebar-categories li a {
  padding: 10px 0;
  border-bottom: 1px solid #ECEBEB;
  color: #333;
}
.sidebar-categories li a:hover {
  background: none;
}
.sidebar-categories li > a:hover,
.sidebar-categories li.active > a,
.sidebar-categories li.active:hover > a {
  color: #E84D1C;
}
.sidebar-categories li a:before {
    content: "";
    font-family: FontAwesome !important;
    font-size: 8px;
    padding-right: 10px;
    position: relative;
    top: -1px;
}
/* Blog Sidebar categories END */

/* Blog Item */
.blog-item .blog-item-img {
  margin: 6px 0 25px;
}
.blog-item .blog-item-img .carousel {
  margin-bottom: 0;
}
.blog-item blockquote {
  margin: 20px 40px 18px;
  border-color: #E84D1C;
}
.blog-item .blog-info {
  margin: 20px 0;
  padding: 8px 0;
  border-top: solid 1px #ECEBEB;
  border-bottom: solid 1px #ECEBEB;
}
.blog-item .blog-info li {
  padding-left: 13px; 
  border-left: solid 1px #ECEBEB;
}
.blog-item .blog-info li:first-child {
  padding-left: 0; 
  border-left: none; 
}
.blog-item .blog-item-img .carousel-control {
  top: 45%;
}

.blog-item .media {
	border-bottom: solid 1px #ECEBEB;
	margin-bottom: -1px;
}
.blog-item .comments {
	border-top: solid 1px #ECEBEB;
	padding-top: 15px;
}
.blog-item img.media-object {
    top: 2px;
	width: 60px;
	height: 60px;
    position: relative;
}
.blog-item h4.media-heading {
    position: relative;
    padding-top: 0;
}
.blog-item h4.media-heading span {
    right: 0;
    top: 3px;
    color: #777;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
}
.blog-item h4.media-heading span a {
    color: #E84D1C;
}

.mix-block .tab-content .col-md-3 {
	padding-right: 0;
}
.mix-block .nav-tabs > li {
    margin-top: 1px;
}

/* front steps */
.front-steps-wrapper h2,
.ecommerce .front-steps-wrapper h2 {
	color: #f0f0f0;
	font-size: 22px;
	line-height: 1.2;
	padding-top: 10px;
	margin-bottom: 5px;
}
.front-steps-wrapper .front-step1 h2,
.ecommerce .front-steps-wrapper .front-step1 h2 {
	color: #fefefe;
}
.front-steps-wrapper p {
	color: #fff;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 0;
}

.front-steps-wrapper .front-step-col {
	padding-left: 0;
	padding-right: 0;
}
.front-steps-wrapper .front-step-col:first-child {
	padding-left: 15px;
}
.front-steps-wrapper .front-step-col:last-child {
	padding-right: 15px;
}

.front-steps-wrapper .front-step {
	position: relative;
	padding: 15px 20px 33px;
}
.front-steps-wrapper .front-step1 {
	background: #E84D1C;
}
.front-steps-wrapper .front-step2 {
	background: #7c858e;
}
.front-steps-wrapper .front-step3 {
	background: #68727c;
}
.front-steps-wrapper .front-step4 {
	background: #636a72;
}
.front-steps-wrapper .front-step5 {
	background: #5e6369;
}
.front-steps-wrapper .front-step6 {
	background: #55585d;
}

.front-steps-wrapper .front-step:before {	
	color: #fff;
	font: 300 125px 'Open Sans', sans-serif;
	font-weight: bold;
	display: block;
	position: absolute;
	right: -20px;
	bottom: -40px;
	opacity: 0.1;
}
.ie8 .front-steps-wrapper .front-step:before {
	display: none !important;
}
.front-steps-wrapper .front-step1:before {
	content: "1";
	opacity: 0.2;
}
.front-steps-wrapper .front-step2:before {
	content: "2";
	right: -15px;
}
.front-steps-wrapper .front-step3:before {
	content: "3";
}
.front-steps-wrapper .front-step4:before {
	content: "4";
}
.front-steps-wrapper .front-step5:before {
	content: "5";
}
.front-steps-wrapper .front-step6:before {
	content: "6";
}

.front-steps-wrapper .front-step:after { 
	top: 50%;
	width: 0; 
	height: 0;
	left: 100%;
	z-index: 2; 
	content: " "; 
	display: block; 
	margin-top: -1em; 
	position: absolute;
	border-left: 15px solid #EC7049;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
}
.ie8 .front-steps-wrapper .front-step:after {
	display: none !important;
}
.front-steps-wrapper .front-step-col:last-child .front-step:after {
	display: none;
}
.front-steps-wrapper .front-step2:after {
	border-left-color: #899199;	
}
.front-steps-wrapper .front-step3:after {
	border-left-color: #778089;	
}
.front-steps-wrapper .front-step4:after {
	border-left-color: #727980;	
}
.front-steps-wrapper .front-step5:after {
	border-left-color: #6E7278;	
	top: auto;
	bottom: 10%;
}

/* pricing */
.pricing {
  position: relative;
  margin-bottom: 15px;
  border: 3px solid #eee;
}
.pricing-active {
  border: 3px solid #E84D1C;
  margin-top: -10px;
  box-shadow: 7px 7px rgba(232, 77, 22, 0.2);
}
.pricing:hover {
  border: 3px solid #E84D1C;
}
.pricing:hover h4 {
  color: #E84D1C;
}
.pricing-head {
  text-align: center;
}
.pricing-head h3,
.pricing-head h4 {
  margin: 0;
  line-height: normal;
}
.pricing-head h3 span, 
.pricing-head h4 span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-style: italic;
}
.pricing-head h3 {
  font-weight: 300;
  color: #fafafa;
  padding: 12px 0;
  font-size: 27px;
  background: #E84D1C;
  border-bottom: none;
}
.pricing-head h4 {
  color: #bac39f;
  padding: 5px 0;
  font-size: 54px;
  font-weight: 300;
  background: #fbfef2;
  border-bottom: solid 1px #f5f9e7;
}
.pricing-head-active h4 {
  color: #E84D1C;
}
.pricing-head h4 i {
  top: -8px;
  font-size: 28px;
  font-style: normal;
  position: relative;
}
.pricing-head h4 span {
  top: -10px;
  font-size: 14px;
  font-style: normal;
  position: relative;
}
  
/* Pricing Content */
.pricing-content li {
  color: #888;
  font-size: 12px;
  padding: 7px 15px;
  border-bottom: solid 1px #f5f9e7;
}
.pricing-content li i {
  top: 2px;
  color: #E84D1C;
  font-size: 16px;
  margin-right: 5px;
  position: relative;
}

/* Pricing Footer */
.pricing-footer {
  color: #777;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  padding: 0 20px 19px;
}

.pricing-footer .btn {
  color: #fff;
}

.pricing-footer .btn:hover {
  color: #fafafa;
}

/* Priceing Active */
.price-active,
.pricing:hover {
  z-index: 9;
}
.price-active h4 {
  color: #36d7ac;
}

.no-space-pricing .pricing:hover {
  transition:box-shadow 0.2s ease-in-out;
}
.no-space-pricing .price-active .pricing-head h4,
.no-space-pricing .pricing:hover .pricing-head h4 {
  color: #36d7ac;
  padding: 15px 0;
  font-size: 80px;
  transition:color 0.5s ease-in-out;
}


/***
Styler Panel
***/
.color-panel {
  z-index: 9999;
  position: fixed;
  top: 120px;
  right: 0;
}
.color-panel .color-mode-icons {
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  margin: 0;
}
.color-panel .icon-color {
  background: #c9c9c9 url(../images/img/icon-color.png[/system-asset]) center no-repeat;
}
.color-panel .icon-color:hover {
  background-color: #3d3d3d;
}
.color-panel .icon-color-close {
  display: none;
  background: #3d3d3d url(../images/img/icon-color-close.png[/system-asset]) center no-repeat;
}
.color-panel .icon-color-close:hover {
  background-color: #222;
}
.color-mode {
  top: 0;
  right: 40px;
  width: 200px;
  display: none;
  padding: 20px 10px 10px;
  position: absolute;
  background: #3d3d3d;
}
.color-mode p {
  color: #cfcfcf;
  padding: 0 15px;
  font-size: 15px;
}
.color-mode ul {
  list-style: none;
  padding: 4px 11px 5px;
  display: block;
  margin-bottom: 1px !important;
}
.color-mode li {
  width: 28px;
  height: 28px;
  margin: 4px;
  cursor: pointer;
  list-style: none;
  border: solid 1px #707070;
  display: inline-block;
  padding: 0 5px;
}
.color-mode li:hover,
.color-mode li.current {
  border: solid 1px #ebebeb;
}
.color-mode li.current {
  border: solid 2px #ebebeb;
}

.color-mode li.color-blue {
  background: #64aed9;
}
.color-mode li.color-red {
  background: #e84d1c;
}
.color-mode li.color-green {
  background: #67bd3c;
}
.color-mode li.color-orange {
  background: #e18604;
}
.color-gray {
	background: #798b97;
}
.color-turquoise {
	background: #44b1c1;
}
.color-mode label {
  color: #cfcfcf;
  border-top: 1px solid #585858;
  padding: 10px 10px 0;
  margin: 0 5px;
  display: block;
}
.color-mode label span {  
  text-transform: uppercase;
}
.color-mode label > span {
  display: inline-block;
  width: 85px;
}
.color-mode label > select {
  margin-top: 5px;
  text-transform: lowercase;
}
.color-mode label  span.color-mode-label {
  top: 2px;
  position: relative;
}

.fancybox-overlay {
  z-index: 100000 ;
}

.fancybox-opened {
  z-index: 100001;
}

/* Webcam Page */
.webcam-list { text-align: center; }

.webcam-list img { margin: 0px 3px 6px 3px; }