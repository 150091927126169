/********************
 METRONIC COMPONENTS 
********************/
/***
General reset
***/
/* Set default body */
body {
  color: #333333;
  font-family: Arial, sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px;
  direction: ltr;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't 
 properly apply the media queries in Bootstrap's CSS. To address this, 
 you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, 
 and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied 
*/
@-ms-viewport {
  width: auto !important;
}
/* Custom scrollbars for webkit browsers */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-left: 1px solid #cecece;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

/* Reset round corners */
div,
input,
select,
textarea,
span,
img,
table,
label,
td,
th,
p,
a,
button,
ul,
code,
pre,
li {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.img-circle {
  border-radius: 50% !important;
}

.img-rounded {
  border-radius: 6px !important;
}

/***
General typography
***/
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, sans-serif;
  font-weight: 300;
}
h.page-title1 {
  font-size:2.7rem;
  margin-bottom:1.5rem;
}
h1 {
  font-size:2.7rem;
  margin-bottom:1.5rem;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 1.7rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1.1rem;
}

/* Headings helper text */
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444444;
}

/* Block headings */
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Links */
a {
  text-shadow: none;
  color: #428bca;
}
a:hover {
  color: #2a6496;
}
a:focus, a:hover, a:active {
  outline: 0;
}

/* Horizontal break */
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 0;
}

/* Unstyled List */
.list-unstyled li > .list-unstyled {
  margin-left: 25px;
}

/* Code */
code {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Disabled Navigation Link */
.disabled-link .disable-target {
  opacity: 0.5 !important;
  filter: alpha(opacity=50) !important;
}
.disabled-link .disable-target:hover {
  cursor: not-allowed !important;
}
.disabled-link:hover {
  cursor: not-allowed !important;
}

/***
Utilities
***/
/* Primary Link */
.primary-link {
  color: #65a0d0;
  font-weight: 600;
}
.primary-link:hover {
  color: #5194ca;
}

/* Rounded Element */
.rounded-2 {
  border-radius: 2px !important;
}

.rounded-3 {
  border-radius: 3px !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

/* Circle Element */
.circle {
  border-radius: 25px !important;
}

.circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.circle-left {
  border-radius: 25px 0 0 25px !important;
}

.circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.circle-top {
  border-radius: 25px 25px 0 0 !important;
}

/* General utilities */
.display-none,
.display-hide {
  display: none;
}

.hidden {
  display: none !important;
}

.primary-font {
  font-family: Arial, sans-serif !important;
}

.bold {
  font-weight: 700 !important;
}

.thin {
  font-weight: 300 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.fix-margin {
  margin-left: 0px !important;
}

.border {
  border: 1px solid red;
}

.font-hg {
  font-size: 23px;
}

.font-lg {
  font-size: 18px;
}

.font-md {
  font-size: 14px;
}

.font-sm {
  font-size: 13px;
}

.font-xs {
  font-size: 11px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.text-align-reverse {
  text-align: right;
}

/* Margin and padding utilities */
.no-space {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margin {
  margin: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

/* IE8 & IE9 mode utilities */
.visible-ie8 {
  display: none;
}

.ie8 .visible-ie8 {
  display: inherit !important;
}

.visible-ie9 {
  display: none;
}

.ie9 .visible-ie9 {
  display: inherit !important;
}

.hidden-ie8 {
  display: inherit;
}

.ie8 .hidden-ie8 {
  display: none !important;
}

.hidden-ie9 {
  display: inherit;
}

.ie9 .hidden-ie9 {
  display: none !important;
}

/***
Responsive Utils
***/
@media (max-width: 1024px) {
  .hidden-1024 {
    display: none;
  }
}
@media (max-width: 480px) {
  .hidden-480 {
    display: none;
  }
}
@media (max-width: 320px) {
  .hidden-320 {
    display: none;
  }
}
/***
Demo Utils
***/
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto;
}

.util-btn-margin-bottom-5 .btn {
  margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
  margin-bottom: 5px !important;
}

/***
Users
***/
.user-info {
  margin-bottom: 10px !important;
}
.user-info img {
  float: left;
  margin-right: 5px;
}
.user-info .details {
  display: inline-block;
}
.user-info .label {
  font-weight: 300;
  font-size: 11px;
}

/***
Top News Blocks(new in v1.2.2) 
***/
.top-news {
  color: #fff;
  margin: 8px 0;
}
.top-news em {
  font-size: 13px;
  margin-bottom: 8px;
  margin-bottom: 0;
  font-style: normal;
  display: block;
}
.top-news em i {
  font-size: 14px;
}
.top-news span {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}
.top-news a {
  display: block;
  text-align: left;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
}
.top-news a .top-news-icon {
  right: 8px;
  bottom: 15px;
  opacity: 0.3;
  font-size: 35px;
  position: absolute;
  filter: alpha(opacity=30);
  /*For IE8*/
}

/***
Block Images(new in v1.2.2)
***/
.blog-images {
  margin-bottom: 0;
}
.blog-images li {
  padding: 0;
  margin: 0;
  display: inline;
}
.blog-images li a:hover {
  text-decoration: none;
}
.blog-images li img {
  width: 50px;
  height: 50px;
  opacity: 0.6;
  margin: 0 2px 8px;
}
.blog-images li img:hover {
  opacity: 1;
  box-shadow: 0 0 0 4px #72c02c;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}

/***
Sidebar Tags
***/
.sidebar-tags li {
  padding: 0;
}
.sidebar-tags li a {
  color: #555;
  font-size: 12px;
  padding: 3px 5px;
  background: #f7f7f7;
  margin: 0 2px 5px 0;
  display: inline-block;
}
.sidebar-tags li a:hover, .sidebar-tags li a:hover i {
  background: #EEE;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar-tags li a i {
  color: #777;
}

/***
Custom vertical inline menu
***/
.ver-inline-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ver-inline-menu li {
  position: relative;
  margin-bottom: 1px;
}
.ver-inline-menu li i {
  width: 37px;
  height: 37px;
  display: inline-block;
  color: #b9cbd5;
  font-size: 15px;
  padding: 12px 10px 10px 8px;
  margin: 0 8px 0 0;
  text-align: center;
  background: #e0eaf0 !important;
}
.ver-inline-menu li a {
  font-size: 14px;
  font-weight: 300;
  color: #557386;
  display: block;
  background: #f0f6fa;
  border-left: solid 2px #c4d5df;
}
.ver-inline-menu li:hover a {
  background: #e0eaf0;
  text-decoration: none;
}
.ver-inline-menu li:hover i {
  color: #fff;
  background: #c4d5df !important;
}
.ver-inline-menu li.active a {
  border-left: solid 2px #0c91e5;
}
.ver-inline-menu li.active i {
  background: #0c91e5 !important;
}
.ver-inline-menu li.active a, .ver-inline-menu li.active i {
  color: #fff;
  background: #169ef4;
  text-decoration: none;
}
.ver-inline-menu li.active a, .ver-inline-menu li:hover a {
  font-size: 14px;
}
.ver-inline-menu li.active:after {
  content: '';
  display: inline-block;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid #169ef4;
  position: absolute;
  top: 12px;
  right: -5px;
}

@media (max-width: 767px) {
  .ver-inline-menu > li.active:after {
    display: none;
  }
}
/***
Separated List
***/
.list-separated {
  margin-top: 10px;
  margin-bottom: 15px;
}
.list-separated > div {
  border-right1: 1px solid #f5f5f5;
}
.list-separated > div:last-child {
  border-right: 0;
}
@media (max-width: 767px) {
  .list-separated {
    /* 767px */
  }
  .list-separated > div {
    margin-bottom: 20px;
  }
}

/***
Number & Chart Stats
***/
.number-stats {
  margin: 10px 0;
}
.number-stats .stat-number {
  display: inline-block;
  margin: 0 5px;
}
.number-stats .stat-number .title {
  font-size: 13px;
  margin-bottom: 3px;
  color: #B8C3C7;
}
.number-stats .stat-number .number {
  font-size: 27px;
  line-height: 27px;
  color: #7D8C9D;
}
.number-stats .stat-chart {
  display: inline-block;
  margin: 0 5px;
}
.number-stats > div {
  border-right: 1px solid #f5f5f5;
}
.number-stats > div:last-child {
  border-right: 0;
}
.number-stats .stat-left {
  float: right;
}
.number-stats .stat-left .stat-number {
  float: right;
  text-align: right;
}
.number-stats .stat-left .stat-chart {
  float: right;
}
.number-stats .stat-right {
  float: left !important;
}
.number-stats .stat-right .stat-number {
  float: left;
  text-align: left;
}
.number-stats .stat-right .stat-chart {
  float: left;
}
.number-stats .stat-number {
  float: left;
  text-align: left;
}
.number-stats .stat-chart {
  float: left;
}

/***
General User Record Listing
***/
.general-item-list > .item {
  padding: 10px 0;
  border-bottom: 1px solid #F1F4F7;
}
.general-item-list > .item:last-child {
  border-bottom: 0;
}
.general-item-list > .item > .item-head {
  margin-bottom: 5px;
}
.general-item-list > .item > .item-head:before, .general-item-list > .item > .item-head:after {
  content: " ";
  display: table;
}
.general-item-list > .item > .item-head:after {
  clear: both;
}
.general-item-list > .item > .item-head > .item-details {
  display: inline-block;
  float: left;
}
.general-item-list > .item > .item-head > .item-details > .item-pic {
  height: 35px;
  margin-right: 10px;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
  border-radius: 100% !important;
}
.general-item-list > .item > .item-head > .item-details > .item-name {
  display: inline-block;
  margin-right: 10px;
}
.general-item-list > .item > .item-head > .item-details > .item-label {
  color: #C0C9CC;
}
.general-item-list > .item > .item-head > .item-status {
  color: #C0C9CC;
  top: 10px;
  position: relative;
  display: inline-block;
  float: right;
}
.general-item-list > .item > .item-head > .item-status > .badge {
  margin-top: -2px;
}
.general-item-list > .item > .item-body {
  color: #96a5aa;
}

/***
Fontawesome Icons
***/
[class^="fa-"],
[class^="glyphicon-"],
[class^="icon-"],
[class*=" fa-"],
[class*=" glyphicon-"],
[class*=" icon-"] {
  display: inline-block;
  margin-top: 1px;
  font-size: 14px;
  *margin-right: .3em;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
}

/* Make font awesome icons fixed width */
li [class^="fa-"],
li [class^="glyphicon-"],
li [class^="icon-"],
li [class*=" fa-"],
li [class*=" glyphicon-"],
li [class*=" icon-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

li [class^="glyphicon-"],
li [class*=" glyphicon-"] {
  top: 2px;
}

li [class^="icon-"],
li [class*=" icon-"] {
  top: 1px;
  position: relative;
}

li [class^="fa-"].icon-large,
li [class^="glyphicon-"].icon-large,
li [class^="icon-"].icon-large,
li [class*=" fa-"].icon-large,
li [class*=" glyphicon-"].icon-large,
li [class*=" icon-"].icon-large {
  /* increased font size for icon-large */
  width: 1.5625em;
}

/* Icon sizing */
.fa-sm,
.icon-sm {
  font-size: 12px;
}

.fa-lg,
.icon-lg {
  font-size: 16px !important;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

/* Icon coloring */
.icon-state-default {
  color: #c6c6c6;
}

.icon-state-success {
  color: #45b6af;
}

.icon-state-info {
  color: #89c4f4;
}

.icon-state-warning {
  color: #dfba49;
}

.icon-state-danger {
  color: #f3565d;
}

/***
Font Awesome 4.x Demo
***/
.fa-item {
  font-size: 14px;
  padding: 10px 10px 10px 20px;
}
.fa-item i {
  font-size: 16px;
  display: inline-block;
  width: 20px;
  color: #333;
}
.fa-item:hover {
  cursor: pointer;
  background: #eee;
}

/***
Simple Line Icons Demo
***/
.simplelineicons-demo .item-box {
  display: inline-block;
  font-size: 16px;
  margin: 0 -0.22em 1em 0;
  padding-left: 1em;
  width: 100%;
}
.simplelineicons-demo .item-box .item {
  background-color: #fff;
  color: #33383e;
  border-radius: 8px;
  display: inline-block;
  padding: 10px;
  width: 100%;
}
.simplelineicons-demo .item-box .item span {
  font-size: 22px;
}

@media only screen and (min-width: 768px) {
  .simplelineicons-demo .item-box {
    width: 33.333%;
  }
}
/*** 
Glyphicons Demo
***/
.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.bs-glyphicon-class {
  text-align: center;
}

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px;
  color: #444;
}

.glyphicons-demo ul li:hover {
  background-color: rgba(86, 61, 124, 0.1);
}

@media (min-width: 768px) {
  .glyphicons-demo ul li {
    width: 12.5%;
  }
}
/***
Customized Bootstrap Alerts
***/
.alert {
  border-width: 1px;
  border-radius: 0;
}
.alert.alert-borderless {
  border: 0;
}

/***
Bootstrap Alerts
***/
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

/***
Custom Bootstrap Badges
***/
.badge {
  font-size: 11px !important;
  font-weight: 300;
  text-align: center;
  height: 18px;
  padding: 3px 6px 3px 6px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  vertical-align: middle;
}
.badge.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.badge.badge-empty {
  display: inline-block;
  padding: 0;
  min-width: 8px;
  height: 8px;
  width: 8px;
}

/* Badge variants */
.badge-default {
  background-color: #c6c6c6;
  background-image: none;
}

.badge-primary {
  background-color: #428bca;
  background-image: none;
}

.badge-info {
  background-color: #89c4f4;
  background-image: none;
}

.badge-success {
  background-color: #45b6af;
  background-image: none;
}

.badge-danger {
  background-color: #f3565d;
  background-image: none;
}

.badge-warning {
  background-color: #dfba49;
  background-image: none;
}

/* Fix badge position for navs */
.nav.nav-pills > li > a > .badge,
.nav.nav-stacked > li > a > .badge {
  margin-top: -2px;
}

/***
Dropdown Menu Badges
***/
.dropdown-menu > li > a > .badge {
  position: absolute;
  margin-top: 1px;
  right: 3px;
  display: inline;
  font-size: 11px;
  font-weight: 300;
  text-shadow: none;
  height: 18px;
  padding: 3px 6px 3px 6px;
  text-align: center;
  vertical-align: middle;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}
.dropdown-menu.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/***
Customized Bootstrap Buttons
***/
/* Default buttons */
.btn {
  border-width: 0;
  padding: 7px 14px;
  font-size: 14px;
  outline: none !important;
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}
.btn.dropdown-toggle, .btn-group .btn.dropdown-toggle, .btn:hover, .btn:disabled, .btn[disabled], .btn:focus, .btn:active, .btn.active {
  outline: none !important;
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

.btn-default {
  border-width: 1px;
  padding: 6px 13px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/* Bootstrap buttons */
.btn-default {
  color: #333333;
  background-color: white;
  border-color: #cccccc;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
  color: #333333;
  background-color: #ebebeb;
  border-color: #b3b3b3;
}
.open .btn-default.dropdown-toggle {
  color: #333333;
  background-color: #ebebeb;
  border-color: #b3b3b3;
}
.btn-default:active, .btn-default.active {
  background-image: none;
}
.open .btn-default.dropdown-toggle {
  background-image: none;
}
.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: white;
  border-color: #cccccc;
}
.btn-default .badge {
  color: white;
  background-color: #333333;
}
.btn-default > i {
  color: #aaa;
}
.btn-default > i[class^="icon-"],
.btn-default > i[class*="icon-"] {
  color: #8c8c8c;
}

.btn-primary {
  color: white;
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  color: white;
  background-color: #3276b1;
  border-color: #2a6496;
}
.open .btn-primary.dropdown-toggle {
  color: white;
  background-color: #3276b1;
  border-color: #2a6496;
}
.btn-primary:active, .btn-primary.active {
  background-image: none;
}
.open .btn-primary.dropdown-toggle {
  background-image: none;
}
.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary .badge {
  color: #428bca;
  background-color: white;
}

.btn-success {
  color: white;
  background-color: #45b6af;
  border-color: #3ea49d;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
  color: white;
  background-color: #3a9893;
  border-color: #307f7a;
}
.open .btn-success.dropdown-toggle {
  color: white;
  background-color: #3a9893;
  border-color: #307f7a;
}
.btn-success:active, .btn-success.active {
  background-image: none;
}
.open .btn-success.dropdown-toggle {
  background-image: none;
}
.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: #45b6af;
  border-color: #3ea49d;
}
.btn-success .badge {
  color: #45b6af;
  background-color: white;
}

.btn-info {
  color: white;
  background-color: #89c4f4;
  border-color: #72b8f2;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  color: white;
  background-color: #64b1f1;
  border-color: #43a1ed;
}
.open .btn-info.dropdown-toggle {
  color: white;
  background-color: #64b1f1;
  border-color: #43a1ed;
}
.btn-info:active, .btn-info.active {
  background-image: none;
}
.open .btn-info.dropdown-toggle {
  background-image: none;
}
.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
  background-color: #89c4f4;
  border-color: #72b8f2;
}
.btn-info .badge {
  color: #89c4f4;
  background-color: white;
}

.btn-warning {
  color: white;
  background-color: #dfba49;
  border-color: #dbb233;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
  color: white;
  background-color: #d9ad26;
  border-color: #bb9521;
}
.open .btn-warning.dropdown-toggle {
  color: white;
  background-color: #d9ad26;
  border-color: #bb9521;
}
.btn-warning:active, .btn-warning.active {
  background-image: none;
}
.open .btn-warning.dropdown-toggle {
  background-image: none;
}
.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: #dfba49;
  border-color: #dbb233;
}
.btn-warning .badge {
  color: #dfba49;
  background-color: white;
}

.btn-danger {
  color: white;
  background-color: #f3565d;
  border-color: #f13e46;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active {
  color: white;
  background-color: #f03038;
  border-color: #ec111b;
}
.open .btn-danger.dropdown-toggle {
  color: white;
  background-color: #f03038;
  border-color: #ec111b;
}
.btn-danger:active, .btn-danger.active {
  background-image: none;
}
.open .btn-danger.dropdown-toggle {
  background-image: none;
}
.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: #f3565d;
  border-color: #f13e46;
}
.btn-danger .badge {
  color: #f3565d;
  background-color: white;
}

/* Button sizes */
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  vertical-align: middle;
}
.btn-lg > i {
  font-size: 18px;
}

.btn > i {
  font-size: 14px;
}

.btn-sm,
.btn-xs {
  padding: 4px 10px 5px 10px;
  font-size: 13px;
  line-height: 1.5;
}
.btn-sm > i,
.btn-xs > i {
  font-size: 13px;
}

.btn-xs {
  padding: 1px 5px;
}
.btn-xs > i {
  font-size: 12px;
}

/* Small button in button group */
.btn-group .input-sm .btn-default {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* Arrow link */
.btn-arrow-link {
  vertical-align: middle;
  display: inline-block;
  font-size: 13px;
}
.btn-arrow-link > i {
  font-size: 14px;
  line-height: 14px;
  vertical-align: top;
  margin: 2px 3px 2px 3px;
  color: #999;
}
.btn-arrow-link.btn-arrow-link-lg {
  font-size: 14px;
}
.btn-arrow-link.btn-arrow-link-lg > i {
  font-size: 16px;
  line-height: 16px;
  margin: 2px 3px 1px 3px;
}

/* Circle Buttons */
.btn-circle {
  border-radius: 25px !important;
}

.btn-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
  height: 34px;
  width: 34px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.btn-icon-only > [class^="icon-"],
.btn-icon-only > i {
  text-align: center;
  margin-top: 2px;
}

.btn-sm > [class^="glyphicon-"],
.btn-sm > [class*=" glyphicon-"],
.btn-sm > i {
  font-size: 13px;
}

.btn-xs > [class^="glyphicon-"],
.btn-xs > [class*=" glyphicon-"],
.btn-xs > i {
  font-size: 11px;
}

.btn-group.btn-group-circle > .btn:first-child {
  border-radius: 25px 0 0 25px !important;
}
.btn-group.btn-group-circle > .btn:last-child {
  border-radius: 0 25px 25px 0 !important;
}

.btn-group.btn-group-devided > .btn {
  margin-right: 5px;
}
.btn-group.btn-group-devided > .btn:last-child {
  margin-right: 0;
}

.btn-group-vertical.btn-group-vertical-circle > .btn:first-child {
  border-radius: 25px 25px 0 0 !important;
}
.btn-group-vertical.btn-group-vertical-circle > .btn:last-child {
  border-radius: 0 0 25px 25px !important;
}

/***
Image Carousel
***/
.carousel.image-carousel .carousel-inner {
  padding-top: 0;
  padding-bottom: 0;
}
.carousel.image-carousel .carousel-control i {
  position: absolute;
  top: 40%;
}
.carousel.image-carousel.image-carousel-hoverable .carousel-control i {
  display: none;
}
.carousel.image-carousel.image-carousel-hoverable:hover .carousel-control i {
  display: inline-block;
}
.carousel.image-carousel .carousel-control.left i {
  left: 10px;
}
.carousel.image-carousel .carousel-control.right i {
  right: 10px;
}
.carousel.image-carousel .carousel-indicators {
  margin-top: 10px;
  bottom: -7px;
}
.carousel.image-carousel .carousel-indicators li {
  background-color: #666;
}
.carousel.image-carousel .carousel-indicators li.active {
  background-color: #666;
}
.carousel.image-carousel .carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px 15px 25px 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}
.carousel.image-carousel .carousel-caption h4,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h2,
.carousel.image-carousel .carousel-caption p {
  text-align: left;
  line-height: 20px;
  color: #ffffff;
}
.carousel.image-carousel .carousel-caption h4,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h2 {
  margin: 0 0 5px;
}
.carousel.image-carousel .carousel-caption h4 a,
.carousel.image-carousel .carousel-caption h3 a,
.carousel.image-carousel .carousel-caption h2 a {
  color: #aaa;
}
.carousel.image-carousel .carousel-caption p {
  margin-bottom: 0;
}
.carousel.image-carousel .carousel-caption .item {
  margin: 0;
}

/***
Charts and statistics
***/
.chart,
.pie,
.bars {
  overflow: hidden;
  height: 300px;
}

/***
Statistic lists
***/
.item-list.table .percent {
  width: 30px;
  float: right;
  margin-right: 10px;
  margin-top: 3px;
}

/***
Chart tooltips
***/
.chart-tooltip {
  clear: both;
  z-index: 100;
  background-color: #736e6e !important;
  padding: 5px !important;
  color: #fff;
}
.chart-tooltip .label {
  clear: both;
  display: block;
  margin-bottom: 2px;
}

/***
Mini chart containers
***/
.bar-chart,
.line-chart {
  display: none;
}

/***
Chats
***/
.chats {
  margin: 0;
  padding: 0;
  margin-top: -15px;
}
.chats li {
  list-style: none;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 12px;
}
.chats li .body {
  display: block;
}
.chats li .avatar {
  height: 45px;
  width: 45px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
.chats li.in .avatar {
  float: left;
  margin-right: 10px;
}
.chats li.out .avatar {
  float: right;
  margin-left: 10px;
}
.chats li .name {
  color: #3590c1;
  font-size: 13px;
  font-weight: 400;
}
.chats li .datetime {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}
.chats li .message {
  display: block;
  padding: 5px;
  position: relative;
}
.chats li.in .message {
  text-align: left;
  border-left: 2px solid #1BBC9B;
  margin-left: 65px;
  background: #fafafa;
}
.chats li.in .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #1BBC9B;
}
.chats li.out .message {
  border-right: 2px solid #F3565D;
  margin-right: 65px;
  background: #fafafa;
  text-align: right;
}
.chats li.out .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  right: -8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #F3565D;
}
.chats li.out .name,
.chats li.out .datetime {
  text-align: right;
}

.chat-form {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9eff3;
  overflow: hidden;
  clear: both;
}
.chat-form .input-cont {
  margin-right: 40px;
}
.chat-form .input-cont .form-control {
  border: 1px solid #ddd;
  width: 100%  !important;
  margin-top: 0;
  background-color: #fff !important;
}
.chat-form .input-cont .form-control:focus {
  border: 1px solid #4b8df9 !important;
}
.chat-form .btn-cont {
  margin-top: -42px;
  position: relative;
  float: right;
  width: 44px;
}
.chat-form .btn-cont .arrow {
  position: absolute;
  top: 17px;
  right: 43px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #4d90fe;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.chat-form .btn-cont .btn {
  margin-top: 8px;
}
.chat-form .btn-cont:hover .arrow {
  border-right-color: #0362fd;
}
.chat-form .btn-cont:hover .btn {
  background-color: #0362fd;
}

/***
Customized Bootstrap Close Icon 
***/
.close {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  outline: none;
  background-image: url("../images/img3/remove-icon-small.png") !important;
}

/***
Customized Bootstrap Dropdowns
***/
/***
Dropdowns
***/
.dropup.open > .dropdown-toggle,
.dropdown.open > .dropdown-toggle {
  border-color: #ddd;
}

/***
Dropdown Menu
***/
.dropdown-menu {
  min-width: 175px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  background-color: #ffffff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.dropdown-menu li.divider {
  background: #f1f3f6;
}
.dropdown-menu li > a {
  padding: 8px 14px;
  color: #555;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}
.dropdown-menu li > a > [class^="fa-"],
.dropdown-menu li > a > [class*=" fa-"] {
  color: #888;
}
.dropdown-menu li > a > [class^="icon-"],
.dropdown-menu li > a > [class*=" icon-"] {
  color: #666;
}
.dropdown-menu li > a > [class^="glyphicon-"],
.dropdown-menu li > a > [class*=" glyphicon-"] {
  color: #888;
}
.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  filter: none;
}
.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.dropdown-toggle .dropdown-menu,
.btn-group .dropdown-menu {
  margin-top: 10px;
}
.dropdown-toggle .dropdown-menu:before,
.btn-group .dropdown-menu:before {
  position: absolute;
  top: -8px;
  right: 9px;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e0e0e0;
  border-left: 8px solid transparent;
  content: '';
}
.dropdown-toggle .dropdown-menu:after,
.btn-group .dropdown-menu:after {
  position: absolute;
  top: -7px;
  right: 10px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: '';
}
.dropdown-toggle .dropdown-menu.pull-left:before,
.btn-group .dropdown-menu.pull-left:before {
  left: 9px;
  right: auto;
}
.dropdown-toggle .dropdown-menu.pull-left:after,
.btn-group .dropdown-menu.pull-left:after {
  left: 10px;
  right: auto;
}

/* Dropdown submenu support for Bootsrap 3 */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 5px;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu > a:after {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  right: 7px;
  top: 7px;
  font-family: FontAwesome;
  height: auto;
  content: "\f105";
  font-weight: 300;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
}

.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu:before,
.nav > li > .dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu:after,
.nav > li > .dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu .dropdown-menu,
.nav > li > .dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
}

@media (max-width: 767px) {
  /* 767px */
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    width: auto;
    margin-top: 0;
    background-color: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 6px 0 6px 13px;
    color: #333;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:active {
    ackground-color: #eee;
  }
}
/***
Dropdown Checkboxes
***/
.dropdown-content {
  padding: 5px;
}
.dropdown-content form {
  margin: 0;
}

.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-radiobuttons,
.dropdown-checkboxes {
  padding: 5px;
}
.dropdown-radiobuttons label,
.dropdown-checkboxes label {
  display: block;
  font-weight: 300;
  color: #333;
  margin-bottom: 4px;
  margin-top: 4px;
}
.dropdown-radiobuttons label .radio,
.dropdown-checkboxes label .radio {
  margin-right: 3px;
}

/***
System feeds
***/
.feeds {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.feeds li {
  background-color: #fafafa;
  color: #82949a;
  margin-bottom: 7px;
}
.feeds li:before, .feeds li:after {
  display: table;
  line-height: 0;
  content: "";
}
.feeds li:after {
  clear: both;
}
.feeds li:last-child {
  margin-bottom: 0px;
}
.feeds li .col1 {
  float: left;
  width: 100%;
  clear: both;
}
.feeds li .col1 > .cont {
  float: left;
  margin-right: 75px;
  overflow: hidden;
}
.feeds li .col1 > .cont > .cont-col1 {
  float: left;
  margin-right: -100%;
}
.feeds li .col1 > .cont > .cont-col1 > .label {
  display: inline-block;
  padding: 5px 4px 6px 5px;
  vertical-align: middle;
  text-align: center;
}
.feeds li .col1 > .cont > .cont-col1 > .label > i {
  text-align: center;
  font-size: 14px;
}
.feeds li .col1 > .cont > .cont-col2 {
  float: left;
  width: 100%;
}
.feeds li .col1 > .cont > .cont-col2 > .desc {
  margin-left: 35px;
  padding-top: 4px;
  padding-bottom: 5px;
  overflow: hidden;
}
.feeds li .col2 {
  float: left;
  width: 75px;
  margin-left: -75px;
}
.feeds li .col2 > .date {
  padding: 4px 9px 5px 4px;
  text-align: right;
  font-style: italic;
  color: #c1cbd0;
}

/***
Form Layouts
****/
/* Static info */
.static-info {
  margin-bottom: 10px;
}
.static-info .name {
  font-size: 14px;
}
.static-info .value {
  font-size: 14px;
  font-weight: 600;
}
.static-info.align-reverse .name, .static-info.align-reverse .value {
  text-align: right;
}

/* Help blocks */
.help-block {
  margin-top: 5px;
  margin-bottom: 5px;
}

.help-inline {
  font-size: 13px;
  color: #737373;
  display: inline-block;
  padding: 5px;
}

.form-inline input {
  margin-bottom: 0px !important;
}

/* Control Label */
.control-label {
  margin-top: 1px;
}
.control-label .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

.form {
  padding: 0 !important;
}
.form .form-body {
  padding: 10px;
}
.portlet.light .form .form-body {
  padding-left: 0;
  padding-right: 0;
}
.form .form-actions {
  padding: 20px 10px;
  margin: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}
.portlet.light .form .form-actions {
  background: none;
  padding-left: 0;
  padding-right: 0;
}
.form .form-actions:before, .form .form-actions:after {
  content: " ";
  display: table;
}
.form .form-actions:after {
  clear: both;
}
.form .form-actions.right {
  padding-left: 0;
  padding-right: 10px;
  text-align: right;
}
.portlet.light .form .form-actions.right {
  padding-right: 0;
}
.form .form-actions.left {
  padding-left: 10px;
  padding-right: 0;
  text-align: left;
}
.portlet.light .form .form-actions.left {
  padding-left: 0;
}
.form .form-actions.nobg {
  background-color: transparent;
}
.form .form-actions.top {
  margin-top: 0;
  margin-bottom: 20px;
  border-top: 0;
  border-bottom: 1px solid #e5e5e5;
}
.portlet.light .form .form-actions.top {
  background: none;
}
.form .form-actions .btn-set {
  display: inline-block;
}
@media (max-width: 767px) {
  .form .form-actions {
    /* 767px */
  }
  .form .form-actions .btn-set {
    margin-bottom: 3px;
    margin-top: 3px;
    float: left !important;
  }
}
.form .form-section {
  margin: 30px 0px 25px 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}
.form .form-section:first-child {
  margin-top: 5px;
}

/* Checkboxes */
.checkbox,
.form-horizontal .checkbox {
  padding: 0;
}
.checkbox > label,
.form-horizontal .checkbox > label {
  padding-left: 0;
}

.checkbox-list > label {
  display: block;
}
.checkbox-list > label.checkbox-inline {
  display: inline-block;
}
.checkbox-list > label.checkbox-inline:first-child {
  padding-left: 0;
}

/* Radio buttons */
.radio-list > label {
  display: block;
}
.radio-list > label.radio-inline {
  display: inline-block;
}
.radio-list > label.radio-inline:first-child {
  padding-left: 0;
}

/* Radio buttons in horizontal forms */
.form-horizontal .radio-list .radio {
  padding-top: 1px;
}
.form-horizontal .radio-list > label {
  margin-bottom: 0;
}
.form-horizontal .radio > span {
  margin-top: 2px;
}

/* Rows seperated form layout */
.form .form-row-seperated .portlet-body {
  padding: 0;
}
.form .form-row-seperated .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
  padding: 15px 0;
}
.form .form-row-seperated .form-group.last {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 13px;
}
.form .form-row-seperated .form-group .help-block {
  margin-bottom: 0;
}
.form .form-row-seperated .form-body {
  padding: 0;
}
.form .form-row-seperated .form-actions {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* Form bordered */
.form .form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
}
.form .form-bordered .form-group > div {
  padding: 15px;
  border-left: 1px solid #efefef;
}
@media (max-width: 991px) {
  .form .form-bordered .form-group > div {
    /* 991px */
    border-left: 0;
  }
}
.form .form-bordered .form-group.last {
  border-bottom: 0;
}
.form .form-bordered .form-group .control-label {
  padding-top: 20px;
}
@media (max-width: 991px) {
  .form .form-bordered .form-group .control-label {
    /* 991px */
    padding-top: 10px;
  }
}
.form .form-bordered .form-group .help-block {
  margin-bottom: 0;
}
.form .form-bordered .form-group .form-control {
  margin: 0;
}
.form .form-bordered .form-body {
  margin: 0;
  padding: 0;
}
.form .form-bordered .form-actions {
  margin-top: 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
@media (max-width: 991px) {
  .form .form-bordered .form-actions {
    /* 991px */
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* Horizontal bordered form */
.form .form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}
.form .form-horizontal.form-bordered.form-row-stripped .form-control {
  background: #fff !important;
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) > div {
  background-color: #ffffff;
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

/***
Forms
***/
/* Input placeholder font color */
.form-control::-moz-placeholder {
  color: #b3b3b3;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #b3b3b3;
}
.form-control::-webkit-input-placeholder {
  color: #b3b3b3;
}

/* Form inputs */
.form-control {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.form-control:focus {
  border-color: #999999;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
}
.form-control.height-auto {
  height: auto;
}
.form-control.form-control-solid {
  background-color: #F1F3F8;
  border-color: #F1F3F8;
  color: #A6B2BA;
}
.form-control.form-control-solid:focus {
  border-color: #e3e7f1;
}
.form-control.form-control-solid::-moz-placeholder {
  color: #acb7be;
  opacity: 1;
}
.form-control.form-control-solid:-ms-input-placeholder {
  color: #acb7be;
}
.form-control.form-control-solid::-webkit-input-placeholder {
  color: #acb7be;
}

/* Form uneditable input */
.uneditable-input {
  padding: 6px 12px;
  min-width: 206px;
  font-size: 14px;
  font-weight: normal;
  height: 34px;
  color: #333333;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/* Form input sizing */
.input-mini {
  width: 45px !important;
}

.input-xsmall {
  width: 80px !important;
}

.input-small {
  width: 145px !important;
}

.input-medium {
  width: 240px !important;
}

.input-large {
  width: 320px !important;
}

.input-xlarge {
  width: 420px !important;
}

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-group .input-inline {
  margin-right: 5px;
}

.input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 13px;
}

select.input-sm {
  height: 28px;
  line-height: 28px;
  padding: 2px 10px;
}

@media (max-width: 768px) {
  /* 768px */
  .input-large {
    width: 250px !important;
  }

  .input-xlarge {
    width: 300px !important;
  }
}
/* Input  groups */
.input-group .btn-default {
  border-color: #e5e5e5;
}
.input-group .input-group-addon {
  border-color: #e5e5e5;
  background: #e5e5e5;
  min-width: 39px;
}
.input-group .input-group-addon > i {
  color: #999999;
}

/* Input spinner */
input[type="text"].spinner,
input[type="password"].spinner,
input[type="datetime"].spinner,
input[type="datetime-local"].spinner,
input[type="date"].spinner,
input[type="month"].spinner,
input[type="time"].spinner,
input[type="week"].spinner,
input[type="number"].spinner,
input[type="email"].spinner,
input[type="url"].spinner,
input[type="search"].spinner,
input[type="tel"].spinner,
input[type="color"].spinner {
  background-image: url("../images/img3/input-spinner.gif") !important;
  background-repeat: no-repeat;
  background-position: right 8px;
}

/* Form labels */
label {
  font-weight: 400;
  font-size: 14px;
}

/* Static form control */
.form-control-static {
  margin: 2px 0;
  display: inline-block;
}

/* Feedback states */
.has-success .help-block,
.has-success .help-inline,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d;
}
.has-success .form-control {
  border-color: #d6e9c6;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #bbdba1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #3c763d;
  border-color: #d6e9c6;
  background-color: #dff0d8;
}
.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .help-inline,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b;
}
.has-warning .form-control {
  border-color: #faebcc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #f5d89e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #faebcc;
  background-color: #fcf8e3;
}
.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .help-inline,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}
.has-error .form-control {
  border-color: #ebccd1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #dca7b0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #a94442;
  border-color: #ebccd1;
  background-color: #f2dede;
}
.has-error .form-control-feedback {
  color: #a94442;
}

/* Circle Inputs */
.input-circle {
  border-radius: 25px !important;
}

.input-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.input-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.input-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.input-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

/***
Custom icon buttons
***/
.icon-btn {
  height: 60px;
  min-width: 80px;
  margin: 5px 5px 0 0;
  border: 1px solid #ddd;
  padding: 12px 0px 0px 0px;
  background-color: #fafafa;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  color: #646464;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.icon-btn:hover {
  text-decoration: none;
  border-color: #999;
  color: #444;
  text-shadow: 0 1px 0px white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.icon-btn:hover > .badge {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.icon-btn > div {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #000;
  font-size: 12px;
  font-weight: 300;
}
.icon-btn > .badge {
  position: absolute;
  font-size: 11px;
  font-weight: 300;
  top: -5px;
  right: -5px;
  padding: 3px 6px 3px 6px;
  color: white;
  text-shadow: none;
  border-width: 0;
  border-style: solid;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.icon-btn > i {
  font-size: 18px;
}
.ie8 .icon-btn:hover {
  filter: none;
}

/***
Input icons
***/
.input-icon {
  position: relative;
}
.input-icon > .form-control {
  padding-left: 33px;
}
.input-icon > i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
}
.modal .input-icon > i {
  z-index: 10055;
}
.has-success .input-icon > i {
  color: #45b6af;
}
.has-warning .input-icon > i {
  color: #dfba49;
}
.has-info .input-icon > i {
  color: #89c4f4;
}
.has-error .input-icon > i {
  color: #f3565d;
}
.input-icon.right > .form-control {
  padding-right: 33px;
  padding-left: 12px;
}
.input-icon.right > i {
  right: 8px;
  float: right;
}

/***
Customized Bootstrap Labels
***/
.label {
  text-shadow: none !important;
  font-size: 13px;
  font-weight: 300;
  padding: 3px 6px 3px 6px;
  color: #fff;
  font-family: Arial, sans-serif;
}
.label.label-sm {
  font-size: 12px;
  padding: 0px 4px 1px 4px;
}
h1 .label, h2 .label, h3 .label, h4 .label, h5 .label, h6 .label {
  font-size: 75%;
}

/* Labels variants */
.label-default {
  background-color: #c6c6c6;
}
.label-default[href]:hover, .label-default[href]:focus {
  background-color: #adadad;
}

.label-primary {
  background-color: #428bca;
}
.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #3071a9;
}

.label-success {
  background-color: #45b6af;
}
.label-success[href]:hover, .label-success[href]:focus {
  background-color: #37918b;
}

.label-info {
  background-color: #89c4f4;
}
.label-info[href]:hover, .label-info[href]:focus {
  background-color: #5aadf0;
}

.label-warning {
  background-color: #dfba49;
}
.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #d0a625;
}

.label-danger {
  background-color: #f3565d;
}
.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #f0262f;
}

/***
Iconic labels
***/
.label.label-icon {
  padding: 4px 0px 4px 4px;
  margin-right: 2px;
  text-align: center !important;
}
.label.label-icon > i {
  font-size: 12px;
  text-align: center !important;
}
.ie8 .label.label-icon, .ie9 .label.label-icon {
  padding: 3px 0px 3px 3px;
}

/***
Customized List Group
***/
/* Contextual variants */
.list-group .list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}
.list-group a.list-group-item-success {
  color: #3c763d;
}
.list-group a.list-group-item-success .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-success:hover, .list-group a.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}
.list-group a.list-group-item-success.active, .list-group a.list-group-item-success.active:hover, .list-group a.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group .list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}
.list-group a.list-group-item-info {
  color: #31708f;
}
.list-group a.list-group-item-info .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-info:hover, .list-group a.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}
.list-group a.list-group-item-info.active, .list-group a.list-group-item-info.active:hover, .list-group a.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group .list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}
.list-group a.list-group-item-warning {
  color: #8a6d3b;
}
.list-group a.list-group-item-warning .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-warning:hover, .list-group a.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}
.list-group a.list-group-item-warning.active, .list-group a.list-group-item-warning.active:hover, .list-group a.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group .list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}
.list-group a.list-group-item-danger {
  color: #a94442;
}
.list-group a.list-group-item-danger .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-danger:hover, .list-group a.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}
.list-group a.list-group-item-danger.active, .list-group a.list-group-item-danger.active:hover, .list-group a.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

/***
UI Loading
***/
.loading-message {
  display: inline-block;
  min-width: 125px;
  padding: 10px;
  margin: 0 auto;
  color: #000 !important;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.loading-message.loading-message-boxed {
  border: 1px solid #ddd;
  background-color: #eee;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.loading-message > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: -50px;
  margin-top: -30px;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.page-loading > span {
  line-height: 20px;
  vertical-align: middle;
}

/***
Metro icons
***/
[class^="m-icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  line-height: 14px;
  vertical-align: top;
  background-image: url(../images/img3/syncfusion-icons.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

[class^="m-icon-big-"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 6px;
  vertical-align: middle;
  background-image: url(../images/img3/syncfusion-icons.png);
  background-position: 0 0px;
  background-repeat: no-repeat;
}

/* large icons */
.btn.m-icon-big {
  padding: 9px 16px 8px 16px;
}

.btn.m-icon-big.m-icon-only {
  padding: 9px 8px 8px 0px;
}

.btn.m-icon-big [class^="m-icon-big-"] {
  margin: 0 0 0 10px;
}

.btn.m-icon-ony > i {
  margin-left: 0px;
}

/* default icons */
.btn.m-icon {
  padding: 7px 14px 7px 14px;
}

.btn.m-icon [class^="m-icon-"] {
  margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
  padding: 7px 10px 7px 6px;
}

/* white icon */
.m-icon-white {
  background-image: url(../images/img3/syncfusion-icons-white.png);
}

/*  Misc */
.m-icon-swapright {
  background-position: -27px -10px;
}

.m-icon-swapdown {
  background-position: -68px -10px;
}

.m-icon-swapleft {
  background-position: -8px -10px;
}

.m-icon-swapup {
  background-position: -46px -10px;
}

.m-icon-big-swapright {
  background-position: -42px -28px;
}

.m-icon-big-swapdown {
  background-position: -115px -28px;
}

.m-icon-big-swapleft {
  background-position: -6px -28px;
}

.m-icon-big-swapup {
  background-position: -78px -28px;
}

/***
Customized Bootstrap Modal 
***/
.modal {
  z-index: 10050;
  outline: none;
  overflow-y: auto !important;
  /* Fix content shifting to the right on modal open due to scrollbar closed */
}
.modal .modal-header {
  border-bottom: 1px solid #EFEFEF;
}
.modal .modal-header h3 {
  font-weight: 300;
}
.modal .modal-header .close {
  margin-top: 0px !important;
}
.modal > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
}
.modal.in .page-loading {
  display: none;
}

.modal-open {
  overflow-y: auto !important;
}

.modal-open-noscroll {
  overflow-y: hidden !important;
}

.modal-backdrop {
  border: 0;
  outline: none;
  z-index: 10049;
}
.modal-backdrop, .modal-backdrop.fade.in {
  background-color: #333 !important;
}

/* Full width modal */
.modal-full.modal-dialog {
  width: 99%;
}

@media (max-width: 768px) {
  .modal-full.modal-dialog {
    width: auto;
  }
}
/***
Notes
***/
.note {
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}
.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  margin-top: 0;
}
.note p {
  font-size: 13px;
}
.note p:last-child {
  margin-bottom: 0;
}
.note code,
.note .highlight {
  background-color: #fff;
}
.note.note-default {
  background-color: lightgray;
  border-color: #a0a0a0;
  color: #333333, 80%;
}
.note.note-default.note-bordered {
  background-color: #c9c9c9;
  border-color: #a5a5a5;
}
.note.note-primary {
  background-color: #5697d0;
  border-color: #2a7696;
  color: #d8e3f2, 80%;
}
.note.note-primary.note-bordered {
  background-color: #468dcb;
  border-color: #2c7c9e;
}
.note.note-success {
  background-color: #eef7ea;
  border-color: #bbdba1;
  color: #3c763d, 80%;
}
.note.note-success.note-bordered {
  background-color: #e2f1dc;
  border-color: #c1dea8;
}
.note.note-info {
  background-color: #eef7fb;
  border-color: #91d9e8;
  color: #31708f, 80%;
}
.note.note-info.note-bordered {
  background-color: #ddeff8;
  border-color: #9adcea;
}
.note.note-warning {
  background-color: #fcf8e3;
  border-color: #f2cf87;
  color: #8a6d3b, 80%;
}
.note.note-warning.note-bordered {
  background-color: #faf3d1;
  border-color: #f3d390;
}
.note.note-danger {
  background-color: #f9f0f0;
  border-color: #dca7b0;
  color: #a94442, 80%;
}
.note.note-danger.note-bordered {
  background-color: #f3e2e2;
  border-color: #dfaeb7;
}

/***
Customized Bootstrap Pagination
***/
.pagination {
  margin: 10px 0;
}
.pagination.pagination-circle > li:first-child > a {
  border-radius: 25px 0 0 25px !important;
}
.pagination.pagination-circle > li:last-child > a {
  border-radius: 0 25px 25px 0 !important;
}
.pagination .active > a,
.pagination .active > a:hover {
  background: #eee;
  border-color: #dddddd;
  color: #333;
}

/***
Customized Bootstrap Panels
***/
.panel {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.panel-group .panel {
  overflow: visible;
}
.panel .panel-body {
  font-size: 13px;
}
.panel .panel-title > a:hover {
  text-decoration: none;
}
/*.accordion .panel .panel-heading {
  padding: 0;
}
.accordion .panel .panel-title {
  padding: 0;
}
.accordion .panel .panel-title .accordion-toggle {
  display: block;
  padding: 10px 15px;
}
.accordion .panel .panel-title .accordion-toggle.accordion-toggle-styled {
  background: url("../images/img3/accordion-plusminus.png") no-repeat;
  background-position: right -19px;
  margin-right: 15px;
}
.accordion .panel .panel-title .accordion-toggle.accordion-toggle-styled.collapsed {
  background-position: right 12px;
}
*/
.panel-default {
  border-color: #e0e0e0;
}
.panel-default > .panel-heading {
  color: #333333;
  background-color: #f9f9f9;
  border-color: #e0e0e0;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #e0e0e0;
}
.panel-default > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #e0e0e0;
}

.panel-primary {
  border-color: #3595bd;
}
.panel-primary > .panel-heading {
  color: #d8e3f2;
  background-color: #428bca;
  border-color: #3595bd;
}
.panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #3595bd;
}
.panel-primary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #3595bd;
}

.panel-success {
  border-color: #d6e9c6;
}
.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #d6e9c6;
}
.panel-success > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #bce8f1;
}
.panel-info > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}
.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #faebcc;
}
.panel-warning > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}
.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #ebccd1;
}
.panel-danger > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #ebccd1;
}

/***
Accordions
***/
/*
.accordion-heading {
  background: #eee;
}
.accordion-heading a {
  text-decoration: none;
}
.accordion-heading a:hover {
  text-decoration: none;
}
*/
/***
Customized Bootstrap Popover
***/
.popover {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  padding: 0;
}
.popover .popover-title {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #89c4f4;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #428bca;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #45b6af;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #dfba49;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #f3565d;
}

/* popover colors*/
.popovers.yellow + .popover {
  background: yellow;
}

.popovers.yellow + .popover .popover-title {
  background: yellow;
}

.popovers.yellow + .popover .popover-content {
  background: yellow;
}

/***
Portlets
***/
/* Basic portlet */
.portlet {
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
}
.portlet > .portlet-title {
  border-bottom: 1px solid #eee;
  padding: 0;
  margin-bottom: 10px;
  min-height: 41px;
}
.portlet > .portlet-title:before, .portlet > .portlet-title:after {
  content: " ";
  display: table;
}
.portlet > .portlet-title:after {
  clear: both;
}
.portlet > .portlet-title > .caption {
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  padding: 10px 0;
}
.portlet > .portlet-title > .caption.bold {
  font-weight: 400;
}
.portlet > .portlet-title > .caption > i {
  float: left;
  margin-top: 4px;
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
  color: #666;
}
.portlet > .portlet-title > .caption > i.glyphicon {
  margin-top: 2px;
}
.portlet > .portlet-title > .caption > .caption-helper {
  padding: 0;
  margin: 0;
  line-height: 13px;
  color: #9eacb4;
  font-size: 13px;
  font-weight: 400;
}
.portlet > .portlet-title > .actions {
  float: right;
  display: inline-block;
  padding: 6px 0;
}
.portlet > .portlet-title > .actions > .dropdown-menu i {
  color: #555555;
}
.portlet > .portlet-title > .actions > .btn,
.portlet > .portlet-title > .actions > .btn.btn-sm,
.portlet > .portlet-title > .actions > .btn-group > .btn,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm {
  padding: 4px 10px;
  font-size: 13px;
  line-height: 1.5;
}
.portlet > .portlet-title > .actions > .btn.btn-default,
.portlet > .portlet-title > .actions > .btn.btn-sm.btn-default,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-default,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm.btn-default {
  padding: 3px 9px;
}
.portlet > .portlet-title > .actions > .btn > i,
.portlet > .portlet-title > .actions > .btn.btn-sm > i,
.portlet > .portlet-title > .actions > .btn-group > .btn > i,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm > i {
  font-size: 13px;
}
.portlet > .portlet-title > .actions .btn-icon-only {
  padding: 5px 7px 3px 7px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default {
  padding: 4px 6px 2px 6px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default > i {
  font-size: 14px;
}
.portlet > .portlet-title > .tools {
  float: right;
  display: inline-block;
  padding: 12px 0 8px 0;
}
.portlet > .portlet-title > .tools > a {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
}
.portlet > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon.png);
  background-repeat: no-repeat;
  width: 11px;
}
.portlet > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon.png);
  background-repeat: no-repeat;
  width: 12px;
}
.portlet > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon.png);
  width: 13px;
}
.portlet > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon.png);
  width: 14px;
}
.portlet > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon.png);
  width: 14px;
}
.portlet > .portlet-title > .tools > a:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: .6;
  filter: 'alpha(opacity=60)';
}
.portlet > .portlet-title > .pagination {
  float: right;
  display: inline-block;
  margin: 0px;
  border: 0;
  padding: 5px 0;
}
.portlet > .portlet-title > .nav-tabs {
  background: none;
  margin: 0;
  float: right;
  display: inline-block;
  border: 0;
}
.portlet > .portlet-title > .nav-tabs > li {
  background: none;
  margin: 0;
  border: 0;
}
.portlet > .portlet-title > .nav-tabs > li > a {
  background: none;
  margin: 5px 0 0 1px;
  border: 0;
  padding: 8px 10px;
  color: #fff;
}
.portlet > .portlet-title > .nav-tabs > li.active > a, .portlet > .portlet-title > .nav-tabs > li:hover > a {
  color: #333;
  background: #fff;
  border: 0;
}
.portlet > .portlet-body {
  clear: both;
}
.portlet > .portlet-empty {
  min-height: 125px;
}
.portlet.full-height-content {
  margin-bottom: 0;
}

/* Portlet background colors */
/* Side bordered portlet */
.portlet.bordered {
  border-left: 2px solid #e6e9ec !important;
}
.portlet.bordered > .portlet-title {
  border-bottom: 0;
}

/* Solid colored portlet */
.portlet.solid {
  padding: 0 10px 10px 10px;
  border: 0px;
}
.portlet.solid > .portlet-title {
  border-bottom: 0;
  margin-bottom: 10px;
}
.portlet.solid > .portlet-title > .caption {
  padding: 16px 0 2px 0;
}
.portlet.solid > .portlet-title > .actions {
  padding: 12px 0 6px 0;
}
.portlet.solid > .portlet-title > .tools {
  padding: 14px 0 6px 0;
}

/* Solid bordered portlet */
.portlet.solid.bordered > .portlet-title {
  margin-bottom: 10px;
}

/* Box portlet */
.portlet.box {
  padding: 0px !important;
}
.portlet.box > .portlet-title {
  border-bottom: 0;
  padding: 0 10px;
  margin-bottom: 0;
  color: #fff;
}
.portlet.box > .portlet-title > .caption {
  padding: 11px 0 9px 0;
}
.portlet.box > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}
.portlet.box > .portlet-title > .actions {
  padding: 7px 0 5px 0;
}
.portlet.box > .portlet-body {
  background-color: #fff;
  padding: 10px;
}

/* Light Portlet */
.portlet.light {
  padding: 12px 20px 15px 20px;
  background-color: #fff;
}
.portlet.light.bordered {
  border: 1px solid #e1e1e1 !important;
}
.portlet.light.bordered > .portlet-title {
  border-bottom: 1px solid #eee;
}
.portlet.light.bg-inverse {
  background: #F7F7F7;
}
.portlet.light > .portlet-title {
  padding: 0;
  min-height: 48px;
}
.portlet.light > .portlet-title > .caption {
  color: #666;
  padding: 10px 0;
}
.portlet.light > .portlet-title > .caption > .caption-subject {
  font-size: 16px;
}
.portlet.light > .portlet-title > .caption > i {
  color: #777;
  font-size: 15px;
  font-weight: 300;
  margin-top: 3px;
}
.portlet.light > .portlet-title > .caption.caption-md > .caption-subject {
  font-size: 15px;
}
.portlet.light > .portlet-title > .caption.caption-md > i {
  font-size: 14px;
}
.portlet.light > .portlet-title > .actions {
  padding: 6px 0 14px 0;
}
.portlet.light > .portlet-title > .actions .btn-default {
  color: #666;
}
.portlet.light > .portlet-title > .actions .btn-icon-only {
  height: 27px;
  width: 27px;
}
.portlet.light > .portlet-title > .actions .dropdown-menu li > a {
  color: #555;
}
.portlet.light > .portlet-title > .inputs {
  float: right;
  display: inline-block;
  padding: 4px 0;
}
.portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > i {
  font-size: 14px;
  margin-top: 9px;
}
.portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > .form-control {
  height: 30px;
  padding: 2px 26px 3px 10px;
  font-size: 13px;
}
.portlet.light > .portlet-title > .inputs > .portlet-input > .form-control {
  height: 30px;
  padding: 3px 10px;
  font-size: 13px;
}
.portlet.light > .portlet-title > .pagination {
  padding: 2px 0 13px 0;
}
.portlet.light > .portlet-title > .tools {
  padding: 10px 0 13px 0;
  margin-top: 2px;
}
.portlet.light > .portlet-title > .nav-tabs > li {
  margin: 0;
  padding: 0;
}
.portlet.light > .portlet-title > .nav-tabs > li > a {
  margin: 0;
  padding: 13px 13px 13px 13px;
  font-size: 13px;
  color: #666;
}
.portlet.light > .portlet-title > .nav-tabs > li.active > a, .portlet.light > .portlet-title > .nav-tabs > li:hover > a {
  margin: 0;
  background: none;
  color: #333;
}
.portlet.light.form-fit {
  padding: 0;
}
.portlet.light.form-fit > .portlet-title {
  padding: 17px 20px 10px 20px;
  margin-bottom: 0;
}
.portlet.light .portlet-body {
  padding-top: 8px;
}

.tab-pane > p:last-child {
  margin-bottom: 0px;
}

/* Reverse aligned tabs */
.tabs-reversed > li {
  float: right;
  margin-right: 0;
}
.tabs-reversed > li > a {
  margin-right: 0;
}

/* jQuery UI Draggable Portlets */
.portlet-sortable > .portlet-title {
  cursor: move;
}

.portlet-sortable-placeholder {
  border: 2px dashed #eee;
  margin-bottom: 25px;
}

.portlet-sortable-empty {
  height: 45px;
}

/***
Customized Progress Bars
***/
.progress {
  border: 0;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.progress > .progress-bar-default {
  background-color: #c6c6c6;
}
.progress-striped .progress > .progress-bar-default {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-success {
  background-color: #45b6af;
}
.progress-striped .progress > .progress-bar-success {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-info {
  background-color: #89c4f4;
}
.progress-striped .progress > .progress-bar-info {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-danger {
  background-color: #f3565d;
}
.progress-striped .progress > .progress-bar-danger {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-warning {
  background-color: #dfba49;
}
.progress-striped .progress > .progress-bar-warning {
  background-image: none;
  background-image: none;
}

/***
Dashboard stats
***/
.dashboard-stat {
  display: block;
  margin-bottom: 25px;
  overflow: hidden;
}
.dashboard-stat:before, .dashboard-stat:after {
  content: " ";
  display: table;
}
.dashboard-stat:after {
  clear: both;
}
.portlet .dashboard-stat:last-child {
  margin-bottom: 0;
}
.dashboard-stat .visual {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 35px;
}
.dashboard-stat .visual > i {
  margin-left: -35px;
  font-size: 110px;
  line-height: 110px;
}
.dashboard-stat .details {
  position: absolute;
  right: 15px;
  padding-right: 15px;
}
.dashboard-stat .details .number {
  padding-top: 25px;
  text-align: right;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 0px;
  font-weight: 300;
}
.dashboard-stat .details .desc {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 300;
}
.dashboard-stat .more {
  clear: both;
  display: block;
  padding: 6px 10px 6px 10px;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.dashboard-stat .more:hover {
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.dashboard-stat .more > i {
  display: inline-block;
  margin-top: 1px;
  float: right;
}

.dashboard-stat-light {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.dashboard-stat-light .details {
  margin-bottom: 5px;
}
.dashboard-stat-light .details .number {
  font-weight: 300;
  margin-bottom: 0px;
}

/***
Text Stats
***/
.text-stat h3 {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 18px;
}
.text-stat span {
  font-size: 13px !important;
}

@media (max-width: 767px) {
  /* 767px */
  .text-stat {
    margin-top: 20px;
  }
}
/***
Social Icons
***/
.social-icons {
  padding: 0;
  margin: 0;
}
.social-icons:before, .social-icons:after {
  content: " ";
  display: table;
}
.social-icons:after {
  clear: both;
}
.social-icons li {
  float: left;
  display: inline;
  list-style: none;
  margin-right: 5px;
  margin-bottom: 5px;
  text-indent: -9999px;
}
.social-icons li > a {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.social-icons li:hover > a {
  background-position: 0 -38px;
}
.social-icons li .amazon {
  background: url(../images/img3/social/amazon.png) no-repeat;
}
.social-icons li .behance {
  background: url(../images/img3/social/behance.png) no-repeat;
}
.social-icons li .blogger {
  background: url(../images/img3/social/blogger.png) no-repeat;
}
.social-icons li .deviantart {
  background: url(../images/img3/social/deviantart.png) no-repeat;
}
.social-icons li .dribbble {
  background: url(../images/img3/social/dribbble.png) no-repeat;
}
.social-icons li .dropbox {
  background: url(../images/img3/social/dropbox.png) no-repeat;
}
.social-icons li .evernote {
  background: url(../images/img3/social/evernote.png) no-repeat;
}
.social-icons li .facebook {
  background: url(../images/img3/social/facebook.png) no-repeat;
}
.social-icons li .forrst {
  background: url(../images/img3/social/forrst.png) no-repeat;
}
.social-icons li .github {
  background: url(../images/img3/social/github.png) no-repeat;
}
.social-icons li .googleplus {
  background: url(../images/img3/social/googleplus.png) no-repeat;
}
.social-icons li .jolicloud {
  background: url(../images/img3/social/jolicloud.png) no-repeat;
}
.social-icons li .last-fm {
  background: url(../images/img3/social/last-fm.png) no-repeat;
}
.social-icons li .linkedin {
  background: url(../images/img3/social/linkedin.png) no-repeat;
}
.social-icons li .picasa {
  background: url(../images/img3/social/picasa.png) no-repeat;
}
.social-icons li .pintrest {
  background: url(../images/img3/social/pintrest.png) no-repeat;
}
.social-icons li .rss {
  background: url(../images/img3/social/rss.png) no-repeat;
}
.social-icons li .skype {
  background: url(../images/img3/social/skype.png) no-repeat;
}
.social-icons li .spotify {
  background: url(../images/img3/social/spotify.png) no-repeat;
}
.social-icons li .stumbleupon {
  background: url(../images/img3/social/stumbleupon.png) no-repeat;
}
.social-icons li .tumblr {
  background: url(../images/img3/social/tumblr.png) no-repeat;
}
.social-icons li .twitter {
  background: url(../images/img3/social/twitter.png) no-repeat;
}
.social-icons li .vimeo {
  background: url(../images/img3/social/vimeo.png) no-repeat;
}
.social-icons li .wordpress {
  background: url(../images/img3/social/wordpress.png) no-repeat;
}
.social-icons li .xing {
  background: url(../images/img3/social/xing.png) no-repeat;
}
.social-icons li .yahoo {
  background: url(../images/img3/social/yahoo.png) no-repeat;
}
.social-icons li .youtube {
  background: url(../images/img3/social/youtube.png) no-repeat;
}
.social-icons li .vk {
  background: url(../images/img3/social/vk.png) no-repeat;
}
.social-icons li .instagram {
  background: url(../images/img3/social/instagram.png) no-repeat;
}
.social-icons li .reddit {
  background: url(../images/img3/social/reddit.png) no-repeat;
}
.social-icons li .aboutme {
  background: url(../images/img3/social/aboutme.png) no-repeat;
}
.social-icons li .flickr {
  background: url(../images/img3/social/flickr.png) no-repeat;
}
.social-icons li .foursquare {
  background: url(../images/img3/social/foursquare.png) no-repeat;
}
.social-icons li .gravatar {
  background: url(../images/img3/social/gravatar.png) no-repeat;
}
.social-icons li .klout {
  background: url(../images/img3/social/klout.png) no-repeat;
}
.social-icons li .myspace {
  background: url(../images/img3/social/myspace.png) no-repeat;
}
.social-icons li .quora {
  background: url(../images/img3/social/quora.png) no-repeat;
}
.social-icons.social-icons-color > li > a {
  opacity: 0.7;
  background-position: 0 -38px !important;
}
.social-icons.social-icons-color > li > a:hover {
  opacity: 1;
}
.social-icons.social-icons-circle > li > a {
  border-radius: 25px !important;
}

/***
Inline Social Icons
***/
.social-icon {
  display: inline-block !important;
  width: 28px;
  height: 28px;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.social-icon.social-icon-circle {
  border-radius: 25px !important;
}

.social-icon.amazon {
  background: url(../images/img3/social/amazon.png) no-repeat;
}
.social-icon.behance {
  background: url(../images/img3/social/behance.png) no-repeat;
}
.social-icon.blogger {
  background: url(../images/img3/social/blogger.png) no-repeat;
}
.social-icon.deviantart {
  background: url(../images/img3/social/deviantart.png) no-repeat;
}
.social-icon.dribbble {
  background: url(../images/img3/social/dribbble.png) no-repeat;
}
.social-icon.dropbox {
  background: url(../images/img3/social/dropbox.png) no-repeat;
}
.social-icon.evernote {
  background: url(../images/img3/social/evernote.png) no-repeat;
}
.social-icon.facebook {
  background: url(../images/img3/social/facebook.png) no-repeat;
}
.social-icon.forrst {
  background: url(../images/img3/social/forrst.png) no-repeat;
}
.social-icon.github {
  background: url(../images/img3/social/github.png) no-repeat;
}
.social-icon.googleplus {
  background: url(../images/img3/social/googleplus.png) no-repeat;
}
.social-icon.jolicloud {
  background: url(../images/img3/social/jolicloud.png) no-repeat;
}
.social-icon.last-fm {
  background: url(../images/img3/social/last-fm.png) no-repeat;
}
.social-icon.linkedin {
  background: url(../images/img3/social/linkedin.png) no-repeat;
}
.social-icon.picasa {
  background: url(../images/img3/social/picasa.png) no-repeat;
}
.social-icon.pintrest {
  background: url(../images/img3/social/pintrest.png) no-repeat;
}
.social-icon.rss {
  background: url(../images/img3/social/rss.png) no-repeat;
}
.social-icon.skype {
  background: url(../images/img3/social/skype.png) no-repeat;
}
.social-icon.spotify {
  background: url(../images/img3/social/spotify.png) no-repeat;
}
.social-icon.stumbleupon {
  background: url(../images/img3/social/stumbleupon.png) no-repeat;
}
.social-icon.tumblr {
  background: url(../images/img3/social/tumblr.png) no-repeat;
}
.social-icon.twitter {
  background: url(../images/img3/social/twitter.png) no-repeat;
}
.social-icon.vimeo {
  background: url(../images/img3/social/vimeo.png) no-repeat;
}
.social-icon.wordpress {
  background: url(../images/img3/social/wordpress.png) no-repeat;
}
.social-icon.xing {
  background: url(../images/img3/social/xing.png) no-repeat;
}
.social-icon.yahoo {
  background: url(../images/img3/social/yahoo.png) no-repeat;
}
.social-icon.youtube {
  background: url(../images/img3/social/youtube.png) no-repeat;
}
.social-icon.vk {
  background: url(../images/img3/social/vk.png) no-repeat;
}
.social-icon.instagram {
  background: url(../images/img3/social/instagram.png) no-repeat;
}
.social-icon.reddit {
  background: url(../images/img3/social/reddit.png) no-repeat;
}
.social-icon.aboutme {
  background: url(../images/img3/social/aboutme.png) no-repeat;
}
.social-icon.flickr {
  background: url(../images/img3/social/flickr.png) no-repeat;
}
.social-icon.foursquare {
  background: url(../images/img3/social/foursquare.png) no-repeat;
}
.social-icon.gravatar {
  background: url(../images/img3/social/gravatar.png) no-repeat;
}
.social-icon.klout {
  background: url(../images/img3/social/klout.png) no-repeat;
}
.social-icon.myspace {
  background: url(../images/img3/social/myspace.png) no-repeat;
}
.social-icon.quora {
  background: url(../images/img3/social/quora.png) no-repeat;
}
.social-icon:hover {
  background-position: 0 -38px;
}

.social-icon-color {
  opacity: 0.7;
  background-position: 0 -38px !important;
}
.social-icon-color:hover {
  opacity: 1;
}

/***
Customized Bootstrap Tables
***/
/***
Default table
***/
.table.table-bordered thead > tr > th {
  border-bottom: 0;
}
.table tr.heading > th {
  background-color: #eee !important;
}
.table td .img-responsive {
  width: 100%;
}

/* Contextual table row variants */
.table > thead > tr > td.active,
.table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background: #a6c8e6;
  color: #2c5285;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr.active:hover > th {
  background: #92bce0;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background: #dff0d8;
  color: #3c763d;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr.success:hover > th {
  background: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background: #d9edf7;
  color: #31708f;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr.info:hover > th {
  background: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background: #fcf8e3;
  color: #8a6d3b;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr.warning:hover > th {
  background: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background: #f2dede;
  color: #a94442;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr.danger:hover > th {
  background: #ebcccc;
}

/***
Responsive & Scrollable Tables
***/
.table-scrollable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #dddddd;
  margin: 10px 0 !important;
}
.table-scrollable.table-scrollable-borderless {
  border: 0;
}
.table-scrollable > .table {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0;
  background-color: #fff;
}
.table-scrollable > .table > thead > tr > th,
.table-scrollable > .table > tbody > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > tfoot > tr > td {
  white-space: nowrap;
}
.table-scrollable > .table-bordered {
  border: 0;
}
.table-scrollable > .table-bordered > thead > tr > th:first-child,
.table-scrollable > .table-bordered > tbody > tr > th:first-child,
.table-scrollable > .table-bordered > tfoot > tr > th:first-child,
.table-scrollable > .table-bordered > thead > tr > td:first-child,
.table-scrollable > .table-bordered > tbody > tr > td:first-child,
.table-scrollable > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.table-scrollable > .table-bordered > thead > tr > th:last-child,
.table-scrollable > .table-bordered > tbody > tr > th:last-child,
.table-scrollable > .table-bordered > tfoot > tr > th:last-child,
.table-scrollable > .table-bordered > thead > tr > td:last-child,
.table-scrollable > .table-bordered > tbody > tr > td:last-child,
.table-scrollable > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.table-scrollable > .table-bordered > thead > tr:last-child > th,
.table-scrollable > .table-bordered > tbody > tr:last-child > th,
.table-scrollable > .table-bordered > tfoot > tr:last-child > th,
.table-scrollable > .table-bordered > thead > tr:last-child > td,
.table-scrollable > .table-bordered > tbody > tr:last-child > td,
.table-scrollable > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}

/***
Responsive Flip Scroll Tables
***/
.flip-scroll table {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* 768px */
  .flip-scroll .flip-content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .flip-scroll * html .flip-content {
    zoom: 1;
  }

  .flip-scroll *:first-child + html .flip-content {
    zoom: 1;
  }

  .flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-scroll th,
  .flip-scroll td {
    margin: 0;
    vertical-align: top;
  }

  .flip-scroll th {
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    font-size: 13px !important;
    padding: 5px;
    width: auto !important;
  }

  .flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }

  .flip-scroll thead {
    display: block;
    float: left;
  }

  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  .flip-scroll thead tr {
    display: block;
  }

  .flip-scroll th {
    display: block;
    text-align: right;
  }

  .flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
  }

  .flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  /* sort out borders */
  .flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }

  .flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .flip-scroll tbody tr {
    border-left: 1px solid #ddd;
  }

  .flip-scroll th:last-child,
  .flip-scroll td:last-child {
    border-bottom: 1px solid #ddd;
  }
}
/***
Custom tables
***/
.table-toolbar {
  margin-bottom: 15px;
}
.table-toolbar:before, .table-toolbar:after {
  content: " ";
  display: table;
}
.table-toolbar:after {
  clear: both;
}

.table.table-full-width {
  width: 100% !important;
}

.table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}

.table thead tr th {
  font-size: 14px;
  font-weight: 600;
}

.table-advance {
  margin-bottom: 10px !important;
}

.table-advance thead {
  color: #999;
}

.table-advance thead tr th {
  background-color: #DDD;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
  position: absolute;
  margin-top: -5px;
  float: left;
  width: 2px;
  height: 30px;
  margin-right: 20px !important;
}

.table-advance tr td {
  border-left-width: 0px;
}

.table-advance tr td:first-child {
  border-left-width: 1px !important;
}

.table-advance tr td.highlight:first-child a {
  margin-left: 15px;
}

.table-advance td.highlight div.primary {
  border-left: 2px solid #428bca;
}

.table-advance td.highlight div.success {
  border-left: 2px solid #45b6af;
}

.table-advance td.highlight div.info {
  border-left: 2px solid #89c4f4;
}

.table-advance td.highlight div.warning {
  border-left: 2px solid #dfba49;
}

.table-advance td.highlight div.danger {
  border-left: 2px solid #f3565d;
}

@media (max-width: 767px) {
  /* 767px */
  .table-advance tr > td.highlight:first-child a {
    margin-left: 8px;
  }
}
/***
Light Table
***/
.table.table-light {
  border: 0 !important;
}
.table.table-light > thead > tr:hover > th {
  background: none;
}
.table.table-light > thead > tr.uppercase {
  text-transform: uppercase;
}
.table.table-light > thead > tr > th {
  font-weight: 600;
  font-size: 13px;
  color: #a7b3b9;
  font-family: Arial, sans-serif;
  border: 0;
  border-bottom: 1px solid #F2F5F8;
}
.table.table-light > tbody > tr:last-child > td {
  border: 0;
}
.table.table-light > tbody > tr > td {
  border: 0;
  border-bottom: 1px solid #F2F5F8;
  color: #9ca8b0;
  vertical-align: middle;
}
.table.table-light > tbody > tr > td.fit {
  width: 1px;
  padding-right: 3px;
}
.table.table-light > tbody > tr > td .user-pic {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
  border-radius: 100% !important;
}
.table.table-light.table-hover > tbody > tr > td:hover,
.table.table-light.table-hover > tbody > tr > th:hover, .table.table-light.table-hover > tbody > tr:hover > td, .table.table-light.table-hover > tbody > tr:hover > th {
  background: #f9fafb;
}

/***
Customized Bootstrap Tabs 
***/
/* Tabs and pills */
.nav-tabs,
.nav-pills {
  margin-bottom: 10px;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  font-size: 14px;
}
.nav-tabs > li > a > .badge,
.nav-pills > li > a > .badge {
  margin-top: -3px;
}
.nav-tabs > li .dropdown-menu:before, .nav-tabs > li .dropdown-menu:after,
.nav-pills > li .dropdown-menu:before,
.nav-pills > li .dropdown-menu:after {
  display: none;
}
.nav-tabs.nav-tabs-sm > li > a, .nav-tabs.nav-pills-sm > li > a,
.nav-pills.nav-tabs-sm > li > a,
.nav-pills.nav-pills-sm > li > a {
  font-size: 13px;
}
.nav-tabs .dropdown.open > .dropdown-toggle,
.nav-pills .dropdown.open > .dropdown-toggle {
  background: #eee;
  color: #0d638f;
  border-color: transparent;
}

/* Left and right tabs */
.tabs-right.nav-tabs,
.tabs-left.nav-tabs {
  border-bottom: 0;
}
.tabs-right.nav-tabs > li,
.tabs-left.nav-tabs > li {
  float: none;
}
.tabs-right.nav-tabs > li > a,
.tabs-left.nav-tabs > li > a {
  margin-right: 0;
  margin-bottom: 3px;
}

/* Left tabs */
.tabs-left.nav-tabs {
  float1: left;
  border-right: 1px solid #ddd;
}
.tabs-left.nav-tabs > li > a {
  display: block;
  margin-right: -1px;
}
.tabs-left.nav-tabs > li > a:hover, .tabs-left.nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.tabs-left.nav-tabs > li.active > a,
.tabs-left.nav-tabs > li.active > a:hover
> li.active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

/* Right tabs */
.tabs-right.nav-tabs {
  float1: right;
  border-left: 1px solid #ddd;
}
.tabs-right.nav-tabs > li > a {
  display: block;
  margin-left: -1px;
}
.tabs-right.nav-tabs > li > a:hover, .tabs-right.nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right.nav-tabs > li.active > a,
.tabs-right.nav-tabs > li.active > a:hover
> li.active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

/* Below tabs */
.tabs-below > .nav-tabs,
.tabs-below > .nav-pills {
  border-bottom: 0;
  margin-bottom: 0px;
  margin-top: 10px;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
  margin-bottom: 0;
  margin-top: 10px;
}
.tabs-below > .nav-tabs > li > a {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}
.tabs-below > .nav-tabs .active a,
.tabs-below > .nav-tabs .active a:hover
.active a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

/***
Custom tabs
***/
/* In BS3.0.0 tabbable class was removed. We had to added it back */
.tabbable:before, .tabbable:after {
  content: " ";
  display: table;
}
.tabbable:after {
  clear: both;
}

.tabbable-custom {
  margin-bottom: 15px;
  padding: 0px;
  overflow: hidden;
  /* justified tabs */
  /* boxless tabs */
  /* below justified tabs */
  /* full width tabs */
  /* below tabs */
}
.tabbable-custom > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-custom > .nav-tabs > li {
  margin-right: 2px;
  border-top: 2px solid transparent;
}
.tabbable-custom > .nav-tabs > li > a {
  margin-right: 0;
}
.tabbable-custom > .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}
.tabbable-custom > .nav-tabs > li.active {
  border-top: 3px solid #f3565d;
  margin-top: 0;
  position: relative;
}
.tabbable-custom > .nav-tabs > li.active > a {
  border-top: none;
  font-weight: 400;
}
.tabbable-custom > .nav-tabs > li.active > a:hover {
  border-top: none;
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}
.tabbable-custom > .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
}
.tabbable-custom.nav-justified > .tab-content {
  margin-top: -1px;
}
.tabbable-custom.boxless > .tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.tabbable-custom.tabs-below.nav-justified .tab-content {
  margin-top: 0px;
  margin-bottom: -2px;
}
.tabbable-custom.tabbable-full-width > .nav-tabs > li > a {
  color: #424242;
  font-size: 15px;
  padding: 9px 15px;
}
.tabbable-custom.tabbable-full-width > .tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.tabbable-custom.tabs-below .nav-tabs > li > a {
  border-top: none;
  border-bottom: 2px solid transparent;
  margin-top: -1px;
}
.tabbable-custom.tabs-below .nav-tabs > li.active {
  border-top: none;
  border-bottom: 3px solid #d12610;
  margin-bottom: 0;
  position: relative;
}
.tabbable-custom.tabs-below .nav-tabs > li.active > a {
  border-bottom: none;
}
.tabbable-custom.tabs-below .nav-tabs > li.active > a:hover {
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom.tabbable-noborder > .nav-tabs > li > a {
  border: 0;
}
.tabbable-custom.tabbable-noborder .tab-content {
  border: 0;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}
.tabbable-line > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #737373;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid #fbcdcf;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #f3565d;
  position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333333;
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: 4px solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: 4px solid #fbcdcf;
}
.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid #f3565d;
}
.tabbable-line.tabs-below > .tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

/***
Tiles(new in v1.1.1)
***/
.tiles {
  margin-right: -10px;
}
.tiles:before, .tiles:after {
  display: table;
  content: " ";
}
.tiles:after {
  clear: both;
}
.tiles .tile {
  display: block;
  letter-spacing: 0.02em;
  float: left;
  height: 135px;
  width: 135px !important;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  position: relative;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 20px;
  overflow: hidden;
  border: 4px solid transparent;
  margin: 0 10px 10px 0;
}
.tiles .tile:after, .tiles .tile:before {
  content: "";
  float: left;
}
.tiles .tile.double {
  width: 280px !important;
}
.tiles .tile.double-down {
  height: 280px !important;
}
.tiles .tile.double-down i {
  margin-top: 95px;
}
.tiles .tile:hover {
  border-color: #aaa !important;
}
.tiles .tile:active, .tiles .tile.selected {
  border-color: #ccc !important;
}
.tiles .tile.selected .corner:after {
  content: "";
  display: inline-block;
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #ccc;
  position: absolute;
  top: -3px;
  right: -3px;
}
.tiles .tile.selected .check:after {
  content: "";
  font-family: FontAwesome;
  font-size: 13px;
  content: "\f00c";
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}
.tiles .tile.icon {
  padding: 0;
}
.tiles .tile.image .tile-body {
  padding: 0 !important;
}
.tiles .tile.image .tile-body > img {
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 100%;
}
.tiles .tile.image .tile-body h3 {
  display: inline-block;
}
.tiles .tile .tile-body {
  height: 100%;
  vertical-align: top;
  padding: 10px 10px;
  overflow: hidden;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  color: #ffffff;
  margin-bottom: 10px;
}
.tiles .tile .tile-body p {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  color: #ffffff;
  line-height: 20px;
  overflow: hidden;
}
.tiles .tile .tile-body p:hover {
  color: rgba(0, 0, 0, 0.8);
}
.tiles .tile .tile-body p:active {
  color: rgba(0, 0, 0, 0.4);
}
.tiles .tile .tile-body p:hover {
  color: #ffffff;
}
.tiles .tile .tile-body img {
  float: left;
  margin-right: 10px;
}
.tiles .tile .tile-body img.pull-right {
  float: right !important;
  margin-left: 10px;
  margin-right: 0px;
}
.tiles .tile .tile-body > .content {
  display: inline-block;
}
.tiles .tile .tile-body > i {
  margin-top: 17px;
  display: block;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}
.tiles .tile .tile-body h1,
.tiles .tile .tile-body h2,
.tiles .tile .tile-body h3,
.tiles .tile .tile-body h4,
.tiles .tile .tile-body h5,
.tiles .tile .tile-body h6,
.tiles .tile .tile-body p {
  padding: 0;
  margin: 0;
  line-height: 14px;
}
.tiles .tile .tile-body h1:hover,
.tiles .tile .tile-body h2:hover,
.tiles .tile .tile-body h3:hover,
.tiles .tile .tile-body h4:hover,
.tiles .tile .tile-body h5:hover,
.tiles .tile .tile-body h6:hover,
.tiles .tile .tile-body p:hover {
  color: #ffffff;
}
.tiles .tile .tile-body h3,
.tiles .tile .tile-body h4 {
  margin-bottom: 5px;
}
.tiles .tile .tile-object {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  background-color: transparent;
  *zoom: 1;
}
.tiles .tile .tile-object:before, .tiles .tile .tile-object:after {
  display: table;
  content: "";
}
.tiles .tile .tile-object:after {
  clear: both;
}
.tiles .tile .tile-object > .name {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 15px;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
}
.tiles .tile .tile-object > .name > i {
  vertical-align: middle;
  display: block;
  font-size: 24px;
  height: 18px;
  width: 24px;
}
.tiles .tile .tile-object > .number {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 14px;
  margin-bottom: 8px;
  margin-right: 10px;
}

/***
Custimized Bootstrap Wells
***/
.well {
  background-color: #eee;
  border: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/* Button */
.btn.default {
  color: #333333;
  background-color: #e5e5e5;
  border-color: "";
}
.btn.default:hover, .btn.default:focus, .btn.default:active, .btn.default.active {
  color: #333333;
  background-color: #d1d1d1;
}
.open .btn.default.dropdown-toggle {
  color: #333333;
  background-color: #d1d1d1;
}
.btn.default:active, .btn.default.active {
  background-image: none;
}
.open .btn.default.dropdown-toggle {
  background-image: none;
}
.btn.default.disabled, .btn.default.disabled:hover, .btn.default.disabled:focus, .btn.default.disabled:active, .btn.default.disabled.active, .btn.default[disabled], .btn.default[disabled]:hover, .btn.default[disabled]:focus, .btn.default[disabled]:active, .btn.default[disabled].active, fieldset[disabled] .btn.default, fieldset[disabled] .btn.default:hover, fieldset[disabled] .btn.default:focus, fieldset[disabled] .btn.default:active, fieldset[disabled] .btn.default.active {
  background-color: #e5e5e5;
}
.btn.default .badge {
  color: #e5e5e5;
  background-color: #333333;
}

/**********
Blue Colors
***********/
/***
Blue Default
***/
/* Button */
.blue.btn {
  color: white;
  background-color: #4b8df8;
  border-color: "";
}
.blue.btn:hover, .blue.btn:focus, .blue.btn:active, .blue.btn.active {
  color: white;
  background-color: #2474f6;
}
.open .blue.btn.dropdown-toggle {
  color: white;
  background-color: #2474f6;
}
.blue.btn:active, .blue.btn.active {
  background-image: none;
}
.open .blue.btn.dropdown-toggle {
  background-image: none;
}
.blue.btn.disabled, .blue.btn.disabled:hover, .blue.btn.disabled:focus, .blue.btn.disabled:active, .blue.btn.disabled.active, .blue.btn[disabled], .blue.btn[disabled]:hover, .blue.btn[disabled]:focus, .blue.btn[disabled]:active, .blue.btn[disabled].active, fieldset[disabled] .blue.btn, fieldset[disabled] .blue.btn:hover, fieldset[disabled] .blue.btn:focus, fieldset[disabled] .blue.btn:active, fieldset[disabled] .blue.btn.active {
  background-color: #4b8df8;
}
.blue.btn .badge {
  color: #4b8df8;
  background-color: white;
}

/* Button Strip */
.btn.blue-stripe {
  border-left: 3px solid #4b8df8;
}

/* Portlet */
.portlet > .portlet-body.blue,
.portlet.blue {
  background-color: #4b8df8;
}

.portlet.solid.blue > .portlet-title,
.portlet.solid.blue > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue {
  border: 1px solid #7cacfa;
  border-top: 0;
}
.portlet.box.blue > .portlet-title {
  background-color: #4b8df8;
}
.portlet.box.blue > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b7d1fc;
  color: #d0e1fd;
}
.portlet.box.blue > .portlet-title > .actions .btn-default > i {
  color: #deeafe;
}
.portlet.box.blue > .portlet-title > .actions .btn-default:hover, .portlet.box.blue > .portlet-title > .actions .btn-default:focus, .portlet.box.blue > .portlet-title > .actions .btn-default:active, .portlet.box.blue > .portlet-title > .actions .btn-default.active {
  border: 1px solid #e8f0fe;
  color: white;
}

/* Statistic Block */
.dashboard-stat.blue {
  background-color: #4b8df8;
}
.dashboard-stat.blue.dashboard-stat-light:hover {
  background-color: #3781f7;
}
.dashboard-stat.blue .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue .details .number {
  color: white;
}
.dashboard-stat.blue .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue .more {
  color: white;
  background-color: #3781f7;
}

/* General Element */
.bg-blue {
  border-color: #4b8df8 !important;
  background-image: none !important;
  background-color: #4b8df8 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue {
  color: #4b8df8 !important;
}

/***
Blue Madison
***/
/* Button */
.blue-madison.btn {
  color: white;
  background-color: #578ebe;
  border-color: "";
}
.blue-madison.btn:hover, .blue-madison.btn:focus, .blue-madison.btn:active, .blue-madison.btn.active {
  color: white;
  background-color: #427aaa;
}
.open .blue-madison.btn.dropdown-toggle {
  color: white;
  background-color: #427aaa;
}
.blue-madison.btn:active, .blue-madison.btn.active {
  background-image: none;
}
.open .blue-madison.btn.dropdown-toggle {
  background-image: none;
}
.blue-madison.btn.disabled, .blue-madison.btn.disabled:hover, .blue-madison.btn.disabled:focus, .blue-madison.btn.disabled:active, .blue-madison.btn.disabled.active, .blue-madison.btn[disabled], .blue-madison.btn[disabled]:hover, .blue-madison.btn[disabled]:focus, .blue-madison.btn[disabled]:active, .blue-madison.btn[disabled].active, fieldset[disabled] .blue-madison.btn, fieldset[disabled] .blue-madison.btn:hover, fieldset[disabled] .blue-madison.btn:focus, fieldset[disabled] .blue-madison.btn:active, fieldset[disabled] .blue-madison.btn.active {
  background-color: #578ebe;
}
.blue-madison.btn .badge {
  color: #578ebe;
  background-color: white;
}

/* Button Strip */
.btn.blue-madison-stripe {
  border-left: 3px solid #578ebe;
}

/* Portlet */
.portlet > .portlet-body.blue-madison,
.portlet.blue-madison {
  background-color: #578ebe;
}

.portlet.solid.blue-madison > .portlet-title,
.portlet.solid.blue-madison > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-madison > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-madison {
  border: 1px solid #7ca7cc;
  border-top: 0;
}
.portlet.box.blue-madison > .portlet-title {
  background-color: #578ebe;
}
.portlet.box.blue-madison > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-madison > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-madison > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #a8c4dd;
  color: #bad1e4;
}
.portlet.box.blue-madison > .portlet-title > .actions .btn-default > i {
  color: #c5d8e9;
}
.portlet.box.blue-madison > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-madison > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-madison > .portlet-title > .actions .btn-default:active, .portlet.box.blue-madison > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cdddec;
  color: #dfeaf3;
}

/* Statistic Block */
.dashboard-stat.blue-madison {
  background-color: #578ebe;
}
.dashboard-stat.blue-madison.dashboard-stat-light:hover {
  background-color: #4884b8;
}
.dashboard-stat.blue-madison .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-madison .details .number {
  color: white;
}
.dashboard-stat.blue-madison .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-madison .more {
  color: white;
  background-color: #4884b8;
}

/* General Element */
.bg-blue-madison {
  border-color: #578ebe !important;
  background-image: none !important;
  background-color: #578ebe !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-madison {
  color: #578ebe !important;
}

/***
Blue Chambray
***/
/* Button */
.blue-chambray.btn {
  color: white;
  background-color: #2c3e50;
  border-color: "";
}
.blue-chambray.btn:hover, .blue-chambray.btn:focus, .blue-chambray.btn:active, .blue-chambray.btn.active {
  color: white;
  background-color: #1e2a36;
}
.open .blue-chambray.btn.dropdown-toggle {
  color: white;
  background-color: #1e2a36;
}
.blue-chambray.btn:active, .blue-chambray.btn.active {
  background-image: none;
}
.open .blue-chambray.btn.dropdown-toggle {
  background-image: none;
}
.blue-chambray.btn.disabled, .blue-chambray.btn.disabled:hover, .blue-chambray.btn.disabled:focus, .blue-chambray.btn.disabled:active, .blue-chambray.btn.disabled.active, .blue-chambray.btn[disabled], .blue-chambray.btn[disabled]:hover, .blue-chambray.btn[disabled]:focus, .blue-chambray.btn[disabled]:active, .blue-chambray.btn[disabled].active, fieldset[disabled] .blue-chambray.btn, fieldset[disabled] .blue-chambray.btn:hover, fieldset[disabled] .blue-chambray.btn:focus, fieldset[disabled] .blue-chambray.btn:active, fieldset[disabled] .blue-chambray.btn.active {
  background-color: #2c3e50;
}
.blue-chambray.btn .badge {
  color: #2c3e50;
  background-color: white;
}

/* Button Strip */
.btn.blue-chambray-stripe {
  border-left: 3px solid #2c3e50;
}

/* Portlet */
.portlet > .portlet-body.blue-chambray,
.portlet.blue-chambray {
  background-color: #2c3e50;
}

.portlet.solid.blue-chambray > .portlet-title,
.portlet.solid.blue-chambray > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-chambray > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-chambray {
  border: 1px solid #3e5771;
  border-top: 0;
}
.portlet.box.blue-chambray > .portlet-title {
  background-color: #2c3e50;
}
.portlet.box.blue-chambray > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-chambray > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-chambray > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #547698;
  color: #5f83a7;
}
.portlet.box.blue-chambray > .portlet-title > .actions .btn-default > i {
  color: #698aac;
}
.portlet.box.blue-chambray > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default:active, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default.active {
  border: 1px solid #6f90b0;
  color: #809cb9;
}

/* Statistic Block */
.dashboard-stat.blue-chambray {
  background-color: #2c3e50;
}
.dashboard-stat.blue-chambray.dashboard-stat-light:hover {
  background-color: #253443;
}
.dashboard-stat.blue-chambray .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-chambray .details .number {
  color: white;
}
.dashboard-stat.blue-chambray .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-chambray .more {
  color: white;
  background-color: #253443;
}

/* General Element */
.bg-blue-chambray {
  border-color: #2c3e50 !important;
  background-image: none !important;
  background-color: #2c3e50 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-chambray {
  color: #2c3e50 !important;
}

/***
Blue Ebonyclay
***/
/* Button */
.blue-ebonyclay.btn {
  color: white;
  background-color: #22313f;
  border-color: "";
}
.blue-ebonyclay.btn:hover, .blue-ebonyclay.btn:focus, .blue-ebonyclay.btn:active, .blue-ebonyclay.btn.active {
  color: white;
  background-color: #141c25;
}
.open .blue-ebonyclay.btn.dropdown-toggle {
  color: white;
  background-color: #141c25;
}
.blue-ebonyclay.btn:active, .blue-ebonyclay.btn.active {
  background-image: none;
}
.open .blue-ebonyclay.btn.dropdown-toggle {
  background-image: none;
}
.blue-ebonyclay.btn.disabled, .blue-ebonyclay.btn.disabled:hover, .blue-ebonyclay.btn.disabled:focus, .blue-ebonyclay.btn.disabled:active, .blue-ebonyclay.btn.disabled.active, .blue-ebonyclay.btn[disabled], .blue-ebonyclay.btn[disabled]:hover, .blue-ebonyclay.btn[disabled]:focus, .blue-ebonyclay.btn[disabled]:active, .blue-ebonyclay.btn[disabled].active, fieldset[disabled] .blue-ebonyclay.btn, fieldset[disabled] .blue-ebonyclay.btn:hover, fieldset[disabled] .blue-ebonyclay.btn:focus, fieldset[disabled] .blue-ebonyclay.btn:active, fieldset[disabled] .blue-ebonyclay.btn.active {
  background-color: #22313f;
}
.blue-ebonyclay.btn .badge {
  color: #22313f;
  background-color: white;
}

/* Button Strip */
.btn.blue-ebonyclay-stripe {
  border-left: 3px solid #22313f;
}

/* Portlet */
.portlet > .portlet-body.blue-ebonyclay,
.portlet.blue-ebonyclay {
  background-color: #22313f;
}

.portlet.solid.blue-ebonyclay > .portlet-title,
.portlet.solid.blue-ebonyclay > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-ebonyclay > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-ebonyclay {
  border: 1px solid #344b60;
  border-top: 0;
}
.portlet.box.blue-ebonyclay > .portlet-title {
  background-color: #22313f;
}
.portlet.box.blue-ebonyclay > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-ebonyclay > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #496a88;
  color: #527798;
}
.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default > i {
  color: #587ea2;
}
.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:active, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default.active {
  border: 1px solid #5d83a7;
  color: #6d90b0;
}

/* Statistic Block */
.dashboard-stat.blue-ebonyclay {
  background-color: #22313f;
}
.dashboard-stat.blue-ebonyclay.dashboard-stat-light:hover {
  background-color: #1b2732;
}
.dashboard-stat.blue-ebonyclay .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-ebonyclay .details .number {
  color: white;
}
.dashboard-stat.blue-ebonyclay .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-ebonyclay .more {
  color: white;
  background-color: #1b2732;
}

/* General Element */
.bg-blue-ebonyclay {
  border-color: #22313f !important;
  background-image: none !important;
  background-color: #22313f !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-ebonyclay {
  color: #22313f !important;
}

/***
Blue Hoki
***/
/* Button */
.blue-hoki.btn {
  color: white;
  background-color: #67809f;
  border-color: "";
}
.blue-hoki.btn:hover, .blue-hoki.btn:focus, .blue-hoki.btn:active, .blue-hoki.btn.active {
  color: white;
  background-color: #566c88;
}
.open .blue-hoki.btn.dropdown-toggle {
  color: white;
  background-color: #566c88;
}
.blue-hoki.btn:active, .blue-hoki.btn.active {
  background-image: none;
}
.open .blue-hoki.btn.dropdown-toggle {
  background-image: none;
}
.blue-hoki.btn.disabled, .blue-hoki.btn.disabled:hover, .blue-hoki.btn.disabled:focus, .blue-hoki.btn.disabled:active, .blue-hoki.btn.disabled.active, .blue-hoki.btn[disabled], .blue-hoki.btn[disabled]:hover, .blue-hoki.btn[disabled]:focus, .blue-hoki.btn[disabled]:active, .blue-hoki.btn[disabled].active, fieldset[disabled] .blue-hoki.btn, fieldset[disabled] .blue-hoki.btn:hover, fieldset[disabled] .blue-hoki.btn:focus, fieldset[disabled] .blue-hoki.btn:active, fieldset[disabled] .blue-hoki.btn.active {
  background-color: #67809f;
}
.blue-hoki.btn .badge {
  color: #67809f;
  background-color: white;
}

/* Button Strip */
.btn.blue-hoki-stripe {
  border-left: 3px solid #67809f;
}

/* Portlet */
.portlet > .portlet-body.blue-hoki,
.portlet.blue-hoki {
  background-color: #67809f;
}

.portlet.solid.blue-hoki > .portlet-title,
.portlet.solid.blue-hoki > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-hoki > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-hoki {
  border: 1px solid #869ab3;
  border-top: 0;
}
.portlet.box.blue-hoki > .portlet-title {
  background-color: #67809f;
}
.portlet.box.blue-hoki > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-hoki > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-hoki > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #acb9ca;
  color: #bbc7d4;
}
.portlet.box.blue-hoki > .portlet-title > .actions .btn-default > i {
  color: #c5ceda;
}
.portlet.box.blue-hoki > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default:active, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cbd4de;
  color: #dbe1e8;
}

/* Statistic Block */
.dashboard-stat.blue-hoki {
  background-color: #67809f;
}
.dashboard-stat.blue-hoki.dashboard-stat-light:hover {
  background-color: #5e7694;
}
.dashboard-stat.blue-hoki .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-hoki .details .number {
  color: white;
}
.dashboard-stat.blue-hoki .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-hoki .more {
  color: white;
  background-color: #5e7694;
}

/* General Element */
.bg-blue-hoki {
  border-color: #67809f !important;
  background-image: none !important;
  background-color: #67809f !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-hoki {
  color: #67809f !important;
}

/***
Blue Stell
***/
/* Button */
.blue-steel.btn {
  color: white;
  background-color: #4b77be;
  border-color: "";
}
.blue-steel.btn:hover, .blue-steel.btn:focus, .blue-steel.btn:active, .blue-steel.btn.active {
  color: white;
  background-color: #3b64a5;
}
.open .blue-steel.btn.dropdown-toggle {
  color: white;
  background-color: #3b64a5;
}
.blue-steel.btn:active, .blue-steel.btn.active {
  background-image: none;
}
.open .blue-steel.btn.dropdown-toggle {
  background-image: none;
}
.blue-steel.btn.disabled, .blue-steel.btn.disabled:hover, .blue-steel.btn.disabled:focus, .blue-steel.btn.disabled:active, .blue-steel.btn.disabled.active, .blue-steel.btn[disabled], .blue-steel.btn[disabled]:hover, .blue-steel.btn[disabled]:focus, .blue-steel.btn[disabled]:active, .blue-steel.btn[disabled].active, fieldset[disabled] .blue-steel.btn, fieldset[disabled] .blue-steel.btn:hover, fieldset[disabled] .blue-steel.btn:focus, fieldset[disabled] .blue-steel.btn:active, fieldset[disabled] .blue-steel.btn.active {
  background-color: #4b77be;
}
.blue-steel.btn .badge {
  color: #4b77be;
  background-color: white;
}

/* Button Strip */
.btn.blue-steel-stripe {
  border-left: 3px solid #4b77be;
}

/* Portlet */
.portlet > .portlet-body.blue-steel,
.portlet.blue-steel {
  background-color: #4b77be;
}

.portlet.solid.blue-steel > .portlet-title,
.portlet.solid.blue-steel > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-steel > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-steel {
  border: 1px solid #7093cc;
  border-top: 0;
}
.portlet.box.blue-steel > .portlet-title {
  background-color: #4b77be;
}
.portlet.box.blue-steel > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-steel > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-steel > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9db5dc;
  color: #b0c3e3;
}
.portlet.box.blue-steel > .portlet-title > .actions .btn-default > i {
  color: #bbcce7;
}
.portlet.box.blue-steel > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-steel > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-steel > .portlet-title > .actions .btn-default:active, .portlet.box.blue-steel > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c3d2e9;
  color: #d6e0f0;
}

/* Statistic Block */
.dashboard-stat.blue-steel {
  background-color: #4b77be;
}
.dashboard-stat.blue-steel.dashboard-stat-light:hover {
  background-color: #416db4;
}
.dashboard-stat.blue-steel .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-steel .details .number {
  color: white;
}
.dashboard-stat.blue-steel .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-steel .more {
  color: white;
  background-color: #416db4;
}

/* General Element */
.bg-blue-steel {
  border-color: #4b77be !important;
  background-image: none !important;
  background-color: #4b77be !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-steel {
  color: #4b77be !important;
}

/***
Blue Soft
***/
/* Button */
.blue-soft.btn {
  color: white;
  background-color: #4c87b9;
  border-color: "";
}
.blue-soft.btn:hover, .blue-soft.btn:focus, .blue-soft.btn:active, .blue-soft.btn.active {
  color: white;
  background-color: #3e729e;
}
.open .blue-soft.btn.dropdown-toggle {
  color: white;
  background-color: #3e729e;
}
.blue-soft.btn:active, .blue-soft.btn.active {
  background-image: none;
}
.open .blue-soft.btn.dropdown-toggle {
  background-image: none;
}
.blue-soft.btn.disabled, .blue-soft.btn.disabled:hover, .blue-soft.btn.disabled:focus, .blue-soft.btn.disabled:active, .blue-soft.btn.disabled.active, .blue-soft.btn[disabled], .blue-soft.btn[disabled]:hover, .blue-soft.btn[disabled]:focus, .blue-soft.btn[disabled]:active, .blue-soft.btn[disabled].active, fieldset[disabled] .blue-soft.btn, fieldset[disabled] .blue-soft.btn:hover, fieldset[disabled] .blue-soft.btn:focus, fieldset[disabled] .blue-soft.btn:active, fieldset[disabled] .blue-soft.btn.active {
  background-color: #4c87b9;
}
.blue-soft.btn .badge {
  color: #4c87b9;
  background-color: white;
}

/* Button Strip */
.btn.blue-soft-stripe {
  border-left: 3px solid #4c87b9;
}

/* Portlet */
.portlet > .portlet-body.blue-soft,
.portlet.blue-soft {
  background-color: #4c87b9;
}

.portlet.solid.blue-soft > .portlet-title,
.portlet.solid.blue-soft > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-soft {
  border: 1px solid #71a0c7;
  border-top: 0;
}
.portlet.box.blue-soft > .portlet-title {
  background-color: #4c87b9;
}
.portlet.box.blue-soft > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dbdd9;
  color: #afc9e0;
}
.portlet.box.blue-soft > .portlet-title > .actions .btn-default > i {
  color: #bad1e4;
}
.portlet.box.blue-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-soft > .portlet-title > .actions .btn-default:active, .portlet.box.blue-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c1d6e7;
  color: #d4e2ee;
}

/* Statistic Block */
.dashboard-stat.blue-soft {
  background-color: #4c87b9;
}
.dashboard-stat.blue-soft.dashboard-stat-light:hover {
  background-color: #447dad;
}
.dashboard-stat.blue-soft .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-soft .details .number {
  color: white;
}
.dashboard-stat.blue-soft .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-soft .more {
  color: white;
  background-color: #447dad;
}

/* General Element */
.bg-blue-soft {
  border-color: #4c87b9 !important;
  background-image: none !important;
  background-color: #4c87b9 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-soft {
  color: #4c87b9 !important;
}

/***
Blue Dark
***/
/* Button */
.blue-dark.btn {
  color: white;
  background-color: #5e738b;
  border-color: "";
}
.blue-dark.btn:hover, .blue-dark.btn:focus, .blue-dark.btn:active, .blue-dark.btn.active {
  color: white;
  background-color: #4e5f73;
}
.open .blue-dark.btn.dropdown-toggle {
  color: white;
  background-color: #4e5f73;
}
.blue-dark.btn:active, .blue-dark.btn.active {
  background-image: none;
}
.open .blue-dark.btn.dropdown-toggle {
  background-image: none;
}
.blue-dark.btn.disabled, .blue-dark.btn.disabled:hover, .blue-dark.btn.disabled:focus, .blue-dark.btn.disabled:active, .blue-dark.btn.disabled.active, .blue-dark.btn[disabled], .blue-dark.btn[disabled]:hover, .blue-dark.btn[disabled]:focus, .blue-dark.btn[disabled]:active, .blue-dark.btn[disabled].active, fieldset[disabled] .blue-dark.btn, fieldset[disabled] .blue-dark.btn:hover, fieldset[disabled] .blue-dark.btn:focus, fieldset[disabled] .blue-dark.btn:active, fieldset[disabled] .blue-dark.btn.active {
  background-color: #5e738b;
}
.blue-dark.btn .badge {
  color: #5e738b;
  background-color: white;
}

/* Button Strip */
.btn.blue-dark-stripe {
  border-left: 3px solid #5e738b;
}

/* Portlet */
.portlet > .portlet-body.blue-dark,
.portlet.blue-dark {
  background-color: #5e738b;
}

.portlet.solid.blue-dark > .portlet-title,
.portlet.solid.blue-dark > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-dark > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-dark {
  border: 1px solid #788da4;
  border-top: 0;
}
.portlet.box.blue-dark > .portlet-title {
  background-color: #5e738b;
}
.portlet.box.blue-dark > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-dark > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-dark > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dacbd;
  color: #acb8c7;
}
.portlet.box.blue-dark > .portlet-title > .actions .btn-default > i {
  color: #b5c0cd;
}
.portlet.box.blue-dark > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-dark > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-dark > .portlet-title > .actions .btn-default:active, .portlet.box.blue-dark > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bbc5d1;
  color: #cad2db;
}

/* Statistic Block */
.dashboard-stat.blue-dark {
  background-color: #5e738b;
}
.dashboard-stat.blue-dark.dashboard-stat-light:hover {
  background-color: #56697f;
}
.dashboard-stat.blue-dark .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-dark .details .number {
  color: white;
}
.dashboard-stat.blue-dark .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-dark .more {
  color: white;
  background-color: #56697f;
}

/* General Element */
.bg-blue-dark {
  border-color: #5e738b !important;
  background-image: none !important;
  background-color: #5e738b !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-dark {
  color: #5e738b !important;
}

/***
Blue Sharp
***/
/* Button */
.blue-sharp.btn {
  color: white;
  background-color: #5c9bd1;
  border-color: "";
}
.blue-sharp.btn:hover, .blue-sharp.btn:focus, .blue-sharp.btn:active, .blue-sharp.btn.active {
  color: white;
  background-color: #3c87c8;
}
.open .blue-sharp.btn.dropdown-toggle {
  color: white;
  background-color: #3c87c8;
}
.blue-sharp.btn:active, .blue-sharp.btn.active {
  background-image: none;
}
.open .blue-sharp.btn.dropdown-toggle {
  background-image: none;
}
.blue-sharp.btn.disabled, .blue-sharp.btn.disabled:hover, .blue-sharp.btn.disabled:focus, .blue-sharp.btn.disabled:active, .blue-sharp.btn.disabled.active, .blue-sharp.btn[disabled], .blue-sharp.btn[disabled]:hover, .blue-sharp.btn[disabled]:focus, .blue-sharp.btn[disabled]:active, .blue-sharp.btn[disabled].active, fieldset[disabled] .blue-sharp.btn, fieldset[disabled] .blue-sharp.btn:hover, fieldset[disabled] .blue-sharp.btn:focus, fieldset[disabled] .blue-sharp.btn:active, fieldset[disabled] .blue-sharp.btn.active {
  background-color: #5c9bd1;
}
.blue-sharp.btn .badge {
  color: #5c9bd1;
  background-color: white;
}

/* Button Strip */
.btn.blue-sharp-stripe {
  border-left: 3px solid #5c9bd1;
}

/* Portlet */
.portlet > .portlet-body.blue-sharp,
.portlet.blue-sharp {
  background-color: #5c9bd1;
}

.portlet.solid.blue-sharp > .portlet-title,
.portlet.solid.blue-sharp > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.blue-sharp > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.blue-sharp {
  border: 1px solid #84b3dc;
  border-top: 0;
}
.portlet.box.blue-sharp > .portlet-title {
  background-color: #5c9bd1;
}
.portlet.box.blue-sharp > .portlet-title > .caption {
  color: white;
}
.portlet.box.blue-sharp > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.blue-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4d1ea;
  color: #c7ddef;
}
.portlet.box.blue-sharp > .portlet-title > .actions .btn-default > i {
  color: #d3e4f3;
}
.portlet.box.blue-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dbe9f5;
  color: #eff5fb;
}

/* Statistic Block */
.dashboard-stat.blue-sharp {
  background-color: #5c9bd1;
}
.dashboard-stat.blue-sharp.dashboard-stat-light:hover {
  background-color: #4c91cd;
}
.dashboard-stat.blue-sharp .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-sharp .details .number {
  color: white;
}
.dashboard-stat.blue-sharp .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-sharp .more {
  color: white;
  background-color: #4c91cd;
}

/* General Element */
.bg-blue-sharp {
  border-color: #5c9bd1 !important;
  background-image: none !important;
  background-color: #5c9bd1 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-blue-sharp {
  color: #5c9bd1 !important;
}

/***********
Green Colors
************/
/***
Green Default
***/
/* Button */
.green.btn {
  color: white;
background-color: transparent;
border: solid thin !important;
border-color: #fff;
}
.green.btn:hover, .green.btn:focus, .green.btn:active, .green.btn.active {
  color: white;
 background-color: transparent;
}
.open .green.btn.dropdown-toggle {
  color: white;
  background-color: #2b8b3a;
}
.green.btn:active, .green.btn.active {
  background-image: none;
}
.open .green.btn.dropdown-toggle {
  background-image: none;
}
.green.btn.disabled, .green.btn.disabled:hover, .green.btn.disabled:focus, .green.btn.disabled:active, .green.btn.disabled.active, .green.btn[disabled], .green.btn[disabled]:hover, .green.btn[disabled]:focus, .green.btn[disabled]:active, .green.btn[disabled].active, fieldset[disabled] .green.btn, fieldset[disabled] .green.btn:hover, fieldset[disabled] .green.btn:focus, fieldset[disabled] .green.btn:active, fieldset[disabled] .green.btn.active {
  background-color: #35aa47;
}
.green.btn .badge {
  color: #35aa47;
  background-color: white;
}

/* Button Strip */
.btn.green-stripe {
  border-left: 3px solid #35aa47;
}

/* Portlet */
.portlet > .portlet-body.green,
.portlet.green {
  background-color: #35aa47;
}

.portlet.solid.green > .portlet-title,
.portlet.solid.green > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green {
  border: 1px solid #4bc75e;
  border-top: 0;
}
.portlet.box.green > .portlet-title {
  background-color: #35aa47;
}
.portlet.box.green > .portlet-title > .caption {
  color: white;
}
.portlet.box.green > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #7ad588;
  color: #8ddc99;
}
.portlet.box.green > .portlet-title > .actions .btn-default > i {
  color: #99dfa4;
}
.portlet.box.green > .portlet-title > .actions .btn-default:hover, .portlet.box.green > .portlet-title > .actions .btn-default:focus, .portlet.box.green > .portlet-title > .actions .btn-default:active, .portlet.box.green > .portlet-title > .actions .btn-default.active {
  border: 1px solid #a1e2ab;
  color: #b4e8bc;
}

/* Statistic Block */
.dashboard-stat.green {
  background-color: #35aa47;
}
.dashboard-stat.green.dashboard-stat-light:hover {
  background-color: #309a41;
}
.dashboard-stat.green .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green .details .number {
  color: white;
}
.dashboard-stat.green .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green .more {
  color: white;
  background-color: #309a41;
}

/* General Element */
.bg-green {
  border-color: #35aa47 !important;
  background-image: none !important;
  background-color: #35aa47 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green {
  color: #35aa47 !important;
}

/***
Green Meadow
***/
/* Button */
.green-meadow.btn {
  color: white;
  background-color: #1bbc9b;
  border-color: "";
}
.green-meadow.btn:hover, .green-meadow.btn:focus, .green-meadow.btn:active, .green-meadow.btn.active {
  color: white;
  background-color: #16987e;
}
.open .green-meadow.btn.dropdown-toggle {
  color: white;
  background-color: #16987e;
}
.green-meadow.btn:active, .green-meadow.btn.active {
  background-image: none;
}
.open .green-meadow.btn.dropdown-toggle {
  background-image: none;
}
.green-meadow.btn.disabled, .green-meadow.btn.disabled:hover, .green-meadow.btn.disabled:focus, .green-meadow.btn.disabled:active, .green-meadow.btn.disabled.active, .green-meadow.btn[disabled], .green-meadow.btn[disabled]:hover, .green-meadow.btn[disabled]:focus, .green-meadow.btn[disabled]:active, .green-meadow.btn[disabled].active, fieldset[disabled] .green-meadow.btn, fieldset[disabled] .green-meadow.btn:hover, fieldset[disabled] .green-meadow.btn:focus, fieldset[disabled] .green-meadow.btn:active, fieldset[disabled] .green-meadow.btn.active {
  background-color: #1bbc9b;
}
.green-meadow.btn .badge {
  color: #1bbc9b;
  background-color: white;
}

/* Button Strip */
.btn.green-meadow-stripe {
  border-left: 3px solid #1bbc9b;
}

/* Portlet */
.portlet > .portlet-body.green-meadow,
.portlet.green-meadow {
  background-color: #1bbc9b;
}

.portlet.solid.green-meadow > .portlet-title,
.portlet.solid.green-meadow > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-meadow > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-meadow {
  border: 1px solid #2ae0bb;
  border-top: 0;
}
.portlet.box.green-meadow > .portlet-title {
  background-color: #1bbc9b;
}
.portlet.box.green-meadow > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-meadow > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-meadow > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #5fe8cc;
  color: #75ebd3;
}
.portlet.box.green-meadow > .portlet-title > .actions .btn-default > i {
  color: #83edd7;
}
.portlet.box.green-meadow > .portlet-title > .actions .btn-default:hover, .portlet.box.green-meadow > .portlet-title > .actions .btn-default:focus, .portlet.box.green-meadow > .portlet-title > .actions .btn-default:active, .portlet.box.green-meadow > .portlet-title > .actions .btn-default.active {
  border: 1px solid #8ceeda;
  color: #a2f2e1;
}

/* Statistic Block */
.dashboard-stat.green-meadow {
  background-color: #1bbc9b;
}
.dashboard-stat.green-meadow.dashboard-stat-light:hover {
  background-color: #18aa8c;
}
.dashboard-stat.green-meadow .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-meadow .details .number {
  color: white;
}
.dashboard-stat.green-meadow .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-meadow .more {
  color: white;
  background-color: #18aa8c;
}

/* General Element */
.bg-green-meadow {
  border-color: #1bbc9b !important;
  background-image: none !important;
  background-color: #1bbc9b !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-meadow {
  color: #1bbc9b !important;
}

/***
Green Seagreen
***/
/* Button */
.green-seagreen.btn {
  color: white;
  background-color: #1ba39c;
  border-color: "";
}
.green-seagreen.btn:hover, .green-seagreen.btn:focus, .green-seagreen.btn:active, .green-seagreen.btn.active {
  color: white;
  background-color: #15807b;
}
.open .green-seagreen.btn.dropdown-toggle {
  color: white;
  background-color: #15807b;
}
.green-seagreen.btn:active, .green-seagreen.btn.active {
  background-image: none;
}
.open .green-seagreen.btn.dropdown-toggle {
  background-image: none;
}
.green-seagreen.btn.disabled, .green-seagreen.btn.disabled:hover, .green-seagreen.btn.disabled:focus, .green-seagreen.btn.disabled:active, .green-seagreen.btn.disabled.active, .green-seagreen.btn[disabled], .green-seagreen.btn[disabled]:hover, .green-seagreen.btn[disabled]:focus, .green-seagreen.btn[disabled]:active, .green-seagreen.btn[disabled].active, fieldset[disabled] .green-seagreen.btn, fieldset[disabled] .green-seagreen.btn:hover, fieldset[disabled] .green-seagreen.btn:focus, fieldset[disabled] .green-seagreen.btn:active, fieldset[disabled] .green-seagreen.btn.active {
  background-color: #1ba39c;
}
.green-seagreen.btn .badge {
  color: #1ba39c;
  background-color: white;
}

/* Button Strip */
.btn.green-seagreen-stripe {
  border-left: 3px solid #1ba39c;
}

/* Portlet */
.portlet > .portlet-body.green-seagreen,
.portlet.green-seagreen {
  background-color: #1ba39c;
}

.portlet.solid.green-seagreen > .portlet-title,
.portlet.solid.green-seagreen > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-seagreen > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-seagreen {
  border: 1px solid #22cfc6;
  border-top: 0;
}
.portlet.box.green-seagreen > .portlet-title {
  background-color: #1ba39c;
}
.portlet.box.green-seagreen > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-seagreen > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-seagreen > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #4de1da;
  color: #63e5de;
}
.portlet.box.green-seagreen > .portlet-title > .actions .btn-default > i {
  color: #70e7e1;
}
.portlet.box.green-seagreen > .portlet-title > .actions .btn-default:hover, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default:focus, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default:active, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default.active {
  border: 1px solid #78e9e3;
  color: #8eece8;
}

/* Statistic Block */
.dashboard-stat.green-seagreen {
  background-color: #1ba39c;
}
.dashboard-stat.green-seagreen.dashboard-stat-light:hover {
  background-color: #18918b;
}
.dashboard-stat.green-seagreen .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-seagreen .details .number {
  color: white;
}
.dashboard-stat.green-seagreen .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-seagreen .more {
  color: white;
  background-color: #18918b;
}

/* General Element */
.bg-green-seagreen {
  border-color: #1ba39c !important;
  background-image: none !important;
  background-color: #1ba39c !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-seagreen {
  color: #1ba39c !important;
}

/***
Green Turquoise
***/
/* Button */
.green-turquoise.btn {
  color: white;
  background-color: #36d7b7;
  border-color: "";
}
.green-turquoise.btn:hover, .green-turquoise.btn:focus, .green-turquoise.btn:active, .green-turquoise.btn.active {
  color: white;
  background-color: #26bea0;
}
.open .green-turquoise.btn.dropdown-toggle {
  color: white;
  background-color: #26bea0;
}
.green-turquoise.btn:active, .green-turquoise.btn.active {
  background-image: none;
}
.open .green-turquoise.btn.dropdown-toggle {
  background-image: none;
}
.green-turquoise.btn.disabled, .green-turquoise.btn.disabled:hover, .green-turquoise.btn.disabled:focus, .green-turquoise.btn.disabled:active, .green-turquoise.btn.disabled.active, .green-turquoise.btn[disabled], .green-turquoise.btn[disabled]:hover, .green-turquoise.btn[disabled]:focus, .green-turquoise.btn[disabled]:active, .green-turquoise.btn[disabled].active, fieldset[disabled] .green-turquoise.btn, fieldset[disabled] .green-turquoise.btn:hover, fieldset[disabled] .green-turquoise.btn:focus, fieldset[disabled] .green-turquoise.btn:active, fieldset[disabled] .green-turquoise.btn.active {
  background-color: #36d7b7;
}
.green-turquoise.btn .badge {
  color: #36d7b7;
  background-color: white;
}

/* Button Strip */
.btn.green-turquoise-stripe {
  border-left: 3px solid #36d7b7;
}

/* Portlet */
.portlet > .portlet-body.green-turquoise,
.portlet.green-turquoise {
  background-color: #36d7b7;
}

.portlet.solid.green-turquoise > .portlet-title,
.portlet.solid.green-turquoise > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-turquoise > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-turquoise {
  border: 1px solid #61dfc6;
  border-top: 0;
}
.portlet.box.green-turquoise > .portlet-title {
  background-color: #36d7b7;
}
.portlet.box.green-turquoise > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-turquoise > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-turquoise > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #94ead9;
  color: #a9eee0;
}
.portlet.box.green-turquoise > .portlet-title > .actions .btn-default > i {
  color: #b6f0e5;
}
.portlet.box.green-turquoise > .portlet-title > .actions .btn-default:hover, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default:focus, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default:active, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bef2e8;
  color: #d3f6ef;
}

/* Statistic Block */
.dashboard-stat.green-turquoise {
  background-color: #36d7b7;
}
.dashboard-stat.green-turquoise.dashboard-stat-light:hover {
  background-color: #29cfae;
}
.dashboard-stat.green-turquoise .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-turquoise .details .number {
  color: white;
}
.dashboard-stat.green-turquoise .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-turquoise .more {
  color: white;
  background-color: #29cfae;
}

/* General Element */
.bg-green-turquoise {
  border-color: #36d7b7 !important;
  background-image: none !important;
  background-color: #36d7b7 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-turquoise {
  color: #36d7b7 !important;
}

/***
Green Haze
***/
/* Button */
.green-haze.btn {
  color: white;
  background-color: #44b6ae;
  border-color: "";
}
.green-haze.btn:hover, .green-haze.btn:focus, .green-haze.btn:active, .green-haze.btn.active {
  color: white;
  background-color: #399892;
}
.open .green-haze.btn.dropdown-toggle {
  color: white;
  background-color: #399892;
}
.green-haze.btn:active, .green-haze.btn.active {
  background-image: none;
}
.open .green-haze.btn.dropdown-toggle {
  background-image: none;
}
.green-haze.btn.disabled, .green-haze.btn.disabled:hover, .green-haze.btn.disabled:focus, .green-haze.btn.disabled:active, .green-haze.btn.disabled.active, .green-haze.btn[disabled], .green-haze.btn[disabled]:hover, .green-haze.btn[disabled]:focus, .green-haze.btn[disabled]:active, .green-haze.btn[disabled].active, fieldset[disabled] .green-haze.btn, fieldset[disabled] .green-haze.btn:hover, fieldset[disabled] .green-haze.btn:focus, fieldset[disabled] .green-haze.btn:active, fieldset[disabled] .green-haze.btn.active {
  background-color: #44b6ae;
}
.green-haze.btn .badge {
  color: #44b6ae;
  background-color: white;
}

/* Button Strip */
.btn.green-haze-stripe {
  border-left: 3px solid #44b6ae;
}

/* Portlet */
.portlet > .portlet-body.green-haze,
.portlet.green-haze {
  background-color: #44b6ae;
}

.portlet.solid.green-haze > .portlet-title,
.portlet.solid.green-haze > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-haze > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-haze > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-haze {
  border: 1px solid #67c6bf;
  border-top: 0;
}
.portlet.box.green-haze > .portlet-title {
  background-color: #44b6ae;
}
.portlet.box.green-haze > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-haze > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-haze > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #93d7d2;
  color: #a6deda;
}
.portlet.box.green-haze > .portlet-title > .actions .btn-default > i {
  color: #b1e2de;
}
.portlet.box.green-haze > .portlet-title > .actions .btn-default:hover, .portlet.box.green-haze > .portlet-title > .actions .btn-default:focus, .portlet.box.green-haze > .portlet-title > .actions .btn-default:active, .portlet.box.green-haze > .portlet-title > .actions .btn-default.active {
  border: 1px solid #b9e5e2;
  color: #cbece9;
}

/* Statistic Block */
.dashboard-stat.green-haze {
  background-color: #44b6ae;
}
.dashboard-stat.green-haze.dashboard-stat-light:hover {
  background-color: #3ea7a0;
}
.dashboard-stat.green-haze .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-haze .details .number {
  color: white;
}
.dashboard-stat.green-haze .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-haze .more {
  color: white;
  background-color: #3ea7a0;
}

/* General Element */
.bg-green-haze {
  border-color: #44b6ae !important;
  background-image: none !important;
  background-color: #44b6ae !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-haze {
  color: #44b6ae !important;
}

/***
Green Jungle
***/
/* Button */
.green-jungle.btn {
  color: white;
  background-color: #26c281;
  border-color: "";
}
.green-jungle.btn:hover, .green-jungle.btn:focus, .green-jungle.btn:active, .green-jungle.btn.active {
  color: white;
  background-color: #1fa06a;
}
.open .green-jungle.btn.dropdown-toggle {
  color: white;
  background-color: #1fa06a;
}
.green-jungle.btn:active, .green-jungle.btn.active {
  background-image: none;
}
.open .green-jungle.btn.dropdown-toggle {
  background-image: none;
}
.green-jungle.btn.disabled, .green-jungle.btn.disabled:hover, .green-jungle.btn.disabled:focus, .green-jungle.btn.disabled:active, .green-jungle.btn.disabled.active, .green-jungle.btn[disabled], .green-jungle.btn[disabled]:hover, .green-jungle.btn[disabled]:focus, .green-jungle.btn[disabled]:active, .green-jungle.btn[disabled].active, fieldset[disabled] .green-jungle.btn, fieldset[disabled] .green-jungle.btn:hover, fieldset[disabled] .green-jungle.btn:focus, fieldset[disabled] .green-jungle.btn:active, fieldset[disabled] .green-jungle.btn.active {
  background-color: #26c281;
}
.green-jungle.btn .badge {
  color: #26c281;
  background-color: white;
}

/* Button Strip */
.btn.green-jungle-stripe {
  border-left: 3px solid #26c281;
}

/* Portlet */
.portlet > .portlet-body.green-jungle,
.portlet.green-jungle {
  background-color: #26c281;
}

.portlet.solid.green-jungle > .portlet-title,
.portlet.solid.green-jungle > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-jungle > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-jungle {
  border: 1px solid #41da9a;
  border-top: 0;
}
.portlet.box.green-jungle > .portlet-title {
  background-color: #26c281;
}
.portlet.box.green-jungle > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-jungle > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-jungle > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #74e4b5;
  color: #8ae8c1;
}
.portlet.box.green-jungle > .portlet-title > .actions .btn-default > i {
  color: #96ebc8;
}
.portlet.box.green-jungle > .portlet-title > .actions .btn-default:hover, .portlet.box.green-jungle > .portlet-title > .actions .btn-default:focus, .portlet.box.green-jungle > .portlet-title > .actions .btn-default:active, .portlet.box.green-jungle > .portlet-title > .actions .btn-default.active {
  border: 1px solid #9feccc;
  color: #b4f0d7;
}

/* Statistic Block */
.dashboard-stat.green-jungle {
  background-color: #26c281;
}
.dashboard-stat.green-jungle.dashboard-stat-light:hover {
  background-color: #23b176;
}
.dashboard-stat.green-jungle .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-jungle .details .number {
  color: white;
}
.dashboard-stat.green-jungle .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-jungle .more {
  color: white;
  background-color: #23b176;
}

/* General Element */
.bg-green-jungle {
  border-color: #26c281 !important;
  background-image: none !important;
  background-color: #26c281 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-jungle {
  color: #26c281 !important;
}

/***
Green Sharp
***/
/* Button */
.green-sharp.btn {
  color: white;
  background-color: #4db3a2;
  border-color: "";
}
.green-sharp.btn:hover, .green-sharp.btn:focus, .green-sharp.btn:active, .green-sharp.btn.active {
  color: white;
  background-color: #409788;
}
.open .green-sharp.btn.dropdown-toggle {
  color: white;
  background-color: #409788;
}
.green-sharp.btn:active, .green-sharp.btn.active {
  background-image: none;
}
.open .green-sharp.btn.dropdown-toggle {
  background-image: none;
}
.green-sharp.btn.disabled, .green-sharp.btn.disabled:hover, .green-sharp.btn.disabled:focus, .green-sharp.btn.disabled:active, .green-sharp.btn.disabled.active, .green-sharp.btn[disabled], .green-sharp.btn[disabled]:hover, .green-sharp.btn[disabled]:focus, .green-sharp.btn[disabled]:active, .green-sharp.btn[disabled].active, fieldset[disabled] .green-sharp.btn, fieldset[disabled] .green-sharp.btn:hover, fieldset[disabled] .green-sharp.btn:focus, fieldset[disabled] .green-sharp.btn:active, fieldset[disabled] .green-sharp.btn.active {
  background-color: #4db3a2;
}
.green-sharp.btn .badge {
  color: #4db3a2;
  background-color: white;
}

/* Button Strip */
.btn.green-sharp-stripe {
  border-left: 3px solid #4db3a2;
}

/* Portlet */
.portlet > .portlet-body.green-sharp,
.portlet.green-sharp {
  background-color: #4db3a2;
}

.portlet.solid.green-sharp > .portlet-title,
.portlet.solid.green-sharp > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-sharp > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-sharp {
  border: 1px solid #71c2b5;
  border-top: 0;
}
.portlet.box.green-sharp > .portlet-title {
  background-color: #4db3a2;
}
.portlet.box.green-sharp > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-sharp > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9cd5cb;
  color: #addcd4;
}
.portlet.box.green-sharp > .portlet-title > .actions .btn-default > i {
  color: #b8e1da;
}
.portlet.box.green-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.green-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.green-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.green-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bfe4de;
  color: #d1ebe7;
}

/* Statistic Block */
.dashboard-stat.green-sharp {
  background-color: #4db3a2;
}
.dashboard-stat.green-sharp.dashboard-stat-light:hover {
  background-color: #46a595;
}
.dashboard-stat.green-sharp .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-sharp .details .number {
  color: white;
}
.dashboard-stat.green-sharp .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-sharp .more {
  color: white;
  background-color: #46a595;
}

/* General Element */
.bg-green-sharp {
  border-color: #4db3a2 !important;
  background-image: none !important;
  background-color: #4db3a2 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-sharp {
  color: #4db3a2 !important;
}

/***
Green Sharp
***/
/* Button */
.green-soft.btn {
  color: white;
  background-color: #3faba4;
  border-color: "";
}
.green-soft.btn:hover, .green-soft.btn:focus, .green-soft.btn:active, .green-soft.btn.active {
  color: white;
  background-color: #348d87;
}
.open .green-soft.btn.dropdown-toggle {
  color: white;
  background-color: #348d87;
}
.green-soft.btn:active, .green-soft.btn.active {
  background-image: none;
}
.open .green-soft.btn.dropdown-toggle {
  background-image: none;
}
.green-soft.btn.disabled, .green-soft.btn.disabled:hover, .green-soft.btn.disabled:focus, .green-soft.btn.disabled:active, .green-soft.btn.disabled.active, .green-soft.btn[disabled], .green-soft.btn[disabled]:hover, .green-soft.btn[disabled]:focus, .green-soft.btn[disabled]:active, .green-soft.btn[disabled].active, fieldset[disabled] .green-soft.btn, fieldset[disabled] .green-soft.btn:hover, fieldset[disabled] .green-soft.btn:focus, fieldset[disabled] .green-soft.btn:active, fieldset[disabled] .green-soft.btn.active {
  background-color: #3faba4;
}
.green-soft.btn .badge {
  color: #3faba4;
  background-color: white;
}

/* Button Strip */
.btn.green-soft-stripe {
  border-left: 3px solid #3faba4;
}

/* Portlet */
.portlet > .portlet-body.green-soft,
.portlet.green-soft {
  background-color: #3faba4;
}

.portlet.solid.green-soft > .portlet-title,
.portlet.solid.green-soft > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.green-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.green-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.green-soft {
  border: 1px solid #5bc2bc;
  border-top: 0;
}
.portlet.box.green-soft > .portlet-title {
  background-color: #3faba4;
}
.portlet.box.green-soft > .portlet-title > .caption {
  color: white;
}
.portlet.box.green-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.green-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #87d3ce;
  color: #9adad6;
}
.portlet.box.green-soft > .portlet-title > .actions .btn-default > i {
  color: #a5deda;
}
.portlet.box.green-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.green-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.green-soft > .portlet-title > .actions .btn-default:active, .portlet.box.green-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ade1dd;
  color: #bfe7e5;
}

/* Statistic Block */
.dashboard-stat.green-soft {
  background-color: #3faba4;
}
.dashboard-stat.green-soft.dashboard-stat-light:hover {
  background-color: #3a9c96;
}
.dashboard-stat.green-soft .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-soft .details .number {
  color: white;
}
.dashboard-stat.green-soft .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-soft .more {
  color: white;
  background-color: #3a9c96;
}

/* General Element */
.bg-green-soft {
  border-color: #3faba4 !important;
  background-image: none !important;
  background-color: #3faba4 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-green-soft {
  color: #3faba4 !important;
}

/***********
Grey Colors
************/
/***
Grey Default
***/
/* Button */
.grey.btn {
  color: #333333;
  background-color: #e5e5e5;
  border-color: "";
}
.grey.btn:hover, .grey.btn:focus, .grey.btn:active, .grey.btn.active {
  color: #333333;
  background-color: #d1d1d1;
}
.open .grey.btn.dropdown-toggle {
  color: #333333;
  background-color: #d1d1d1;
}
.grey.btn:active, .grey.btn.active {
  background-image: none;
}
.open .grey.btn.dropdown-toggle {
  background-image: none;
}
.grey.btn.disabled, .grey.btn.disabled:hover, .grey.btn.disabled:focus, .grey.btn.disabled:active, .grey.btn.disabled.active, .grey.btn[disabled], .grey.btn[disabled]:hover, .grey.btn[disabled]:focus, .grey.btn[disabled]:active, .grey.btn[disabled].active, fieldset[disabled] .grey.btn, fieldset[disabled] .grey.btn:hover, fieldset[disabled] .grey.btn:focus, fieldset[disabled] .grey.btn:active, fieldset[disabled] .grey.btn.active {
  background-color: #e5e5e5;
}
.grey.btn .badge {
  color: #e5e5e5;
  background-color: #333333;
}

/* Button Strip */
.btn.grey-stripe {
  border-left: 3px solid #e5e5e5;
}

/* Portlet */
.portlet > .portlet-body.grey,
.portlet.grey {
  background-color: #e5e5e5;
}

.portlet.solid.grey > .portlet-title,
.portlet.solid.grey > .portlet-body {
  border: 0;
  color: #333333;
}
.portlet.solid.grey > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.solid.grey > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey {
  border: 1px solid white;
  border-top: 0;
}
.portlet.box.grey > .portlet-title {
  background-color: #e5e5e5;
}
.portlet.box.grey > .portlet-title > .caption {
  color: #333333;
}
.portlet.box.grey > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.box.grey > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey > .portlet-title > .actions .btn-default:hover, .portlet.box.grey > .portlet-title > .actions .btn-default:focus, .portlet.box.grey > .portlet-title > .actions .btn-default:active, .portlet.box.grey > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey {
  background-color: #e5e5e5;
}
.dashboard-stat.grey.dashboard-stat-light:hover {
  background-color: #dbdbdb;
}
.dashboard-stat.grey .visual > i {
  color: #333333;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey .details .number {
  color: #333333;
}
.dashboard-stat.grey .details .desc {
  color: #333333;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey .more {
  color: #333333;
  background-color: #dbdbdb;
}

/* General Element */
.bg-grey {
  border-color: #e5e5e5 !important;
  background-image: none !important;
  background-color: #e5e5e5 !important;
  color: #333333 !important;
}

/* General Text & Icon Font Colors */
.font-grey {
  color: #e5e5e5 !important;
}

/***
Grey Steel
***/
/* Button */
.grey-steel.btn {
  color: #80898e;
  background-color: #e9edef;
  border-color: "";
}
.grey-steel.btn:hover, .grey-steel.btn:focus, .grey-steel.btn:active, .grey-steel.btn.active {
  color: #80898e;
  background-color: #d1dade;
}
.open .grey-steel.btn.dropdown-toggle {
  color: #80898e;
  background-color: #d1dade;
}
.grey-steel.btn:active, .grey-steel.btn.active {
  background-image: none;
}
.open .grey-steel.btn.dropdown-toggle {
  background-image: none;
}
.grey-steel.btn.disabled, .grey-steel.btn.disabled:hover, .grey-steel.btn.disabled:focus, .grey-steel.btn.disabled:active, .grey-steel.btn.disabled.active, .grey-steel.btn[disabled], .grey-steel.btn[disabled]:hover, .grey-steel.btn[disabled]:focus, .grey-steel.btn[disabled]:active, .grey-steel.btn[disabled].active, fieldset[disabled] .grey-steel.btn, fieldset[disabled] .grey-steel.btn:hover, fieldset[disabled] .grey-steel.btn:focus, fieldset[disabled] .grey-steel.btn:active, fieldset[disabled] .grey-steel.btn.active {
  background-color: #e9edef;
}
.grey-steel.btn .badge {
  color: #e9edef;
  background-color: #80898e;
}

/* Button Strip */
.btn.grey-steel-stripe {
  border-left: 3px solid #e9edef;
}

/* Portlet */
.portlet > .portlet-body.grey-steel,
.portlet.grey-steel {
  background-color: #e9edef;
}

.portlet.solid.grey-steel > .portlet-title,
.portlet.solid.grey-steel > .portlet-body {
  border: 0;
  color: #80898e;
}
.portlet.solid.grey-steel > .portlet-title > .caption > i {
  color: #80898e;
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-steel {
  border: 1px solid white;
  border-top: 0;
}
.portlet.box.grey-steel > .portlet-title {
  background-color: #e9edef;
}
.portlet.box.grey-steel > .portlet-title > .caption {
  color: #80898e;
}
.portlet.box.grey-steel > .portlet-title > .caption > i {
  color: #80898e;
}
.portlet.box.grey-steel > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey-steel > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-steel > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-steel > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-steel > .portlet-title > .actions .btn-default:active, .portlet.box.grey-steel > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-steel {
  background-color: #e9edef;
}
.dashboard-stat.grey-steel.dashboard-stat-light:hover {
  background-color: #dde3e6;
}
.dashboard-stat.grey-steel .visual > i {
  color: #80898e;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-steel .details .number {
  color: #80898e;
}
.dashboard-stat.grey-steel .details .desc {
  color: #80898e;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-steel .more {
  color: #80898e;
  background-color: #dde3e6;
}

/* General Element */
.bg-grey-steel {
  border-color: #e9edef !important;
  background-image: none !important;
  background-color: #e9edef !important;
  color: #80898e !important;
}

/* General Text & Icon Font Colors */
.font-grey-steel {
  color: #e9edef !important;
}

/***
Grey Cararra
***/
/* Button */
.grey-cararra.btn {
  color: #333333;
  background-color: #fafafa;
  border-color: "";
}
.grey-cararra.btn:hover, .grey-cararra.btn:focus, .grey-cararra.btn:active, .grey-cararra.btn.active {
  color: #333333;
  background-color: #e6e6e6;
}
.open .grey-cararra.btn.dropdown-toggle {
  color: #333333;
  background-color: #e6e6e6;
}
.grey-cararra.btn:active, .grey-cararra.btn.active {
  background-image: none;
}
.open .grey-cararra.btn.dropdown-toggle {
  background-image: none;
}
.grey-cararra.btn.disabled, .grey-cararra.btn.disabled:hover, .grey-cararra.btn.disabled:focus, .grey-cararra.btn.disabled:active, .grey-cararra.btn.disabled.active, .grey-cararra.btn[disabled], .grey-cararra.btn[disabled]:hover, .grey-cararra.btn[disabled]:focus, .grey-cararra.btn[disabled]:active, .grey-cararra.btn[disabled].active, fieldset[disabled] .grey-cararra.btn, fieldset[disabled] .grey-cararra.btn:hover, fieldset[disabled] .grey-cararra.btn:focus, fieldset[disabled] .grey-cararra.btn:active, fieldset[disabled] .grey-cararra.btn.active {
  background-color: #fafafa;
}
.grey-cararra.btn .badge {
  color: #fafafa;
  background-color: #333333;
}

/* Button Strip */
.btn.grey-cararra-stripe {
  border-left: 3px solid #fafafa;
}

/* Portlet */
.portlet > .portlet-body.grey-cararra,
.portlet.grey-cararra {
  background-color: #fafafa;
}

.portlet.solid.grey-cararra > .portlet-title,
.portlet.solid.grey-cararra > .portlet-body {
  border: 0;
  color: #333333;
}
.portlet.solid.grey-cararra > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-cararra {
  border: 1px solid white;
  border-top: 0;
}
.portlet.box.grey-cararra > .portlet-title {
  background-color: #fafafa;
}
.portlet.box.grey-cararra > .portlet-title > .caption {
  color: #333333;
}
.portlet.box.grey-cararra > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.box.grey-cararra > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey-cararra > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-cararra > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default:active, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-cararra {
  background-color: #fafafa;
}
.dashboard-stat.grey-cararra.dashboard-stat-light:hover {
  background-color: #f0f0f0;
}
.dashboard-stat.grey-cararra .visual > i {
  color: #333333;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-cararra .details .number {
  color: #333333;
}
.dashboard-stat.grey-cararra .details .desc {
  color: #333333;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-cararra .more {
  color: #333333;
  background-color: #f0f0f0;
}

/* General Element */
.bg-grey-cararra {
  border-color: #fafafa !important;
  background-image: none !important;
  background-color: #fafafa !important;
  color: #333333 !important;
}

/* General Text & Icon Font Colors */
.font-grey-cararra {
  color: #fafafa !important;
}

/***
Grey Gallery
***/
/* Button */
.grey-gallery.btn {
  color: white;
  background-color: #555555;
  border-color: "";
}
.grey-gallery.btn:hover, .grey-gallery.btn:focus, .grey-gallery.btn:active, .grey-gallery.btn.active {
  color: white;
  background-color: #414141;
}
.open .grey-gallery.btn.dropdown-toggle {
  color: white;
  background-color: #414141;
}
.grey-gallery.btn:active, .grey-gallery.btn.active {
  background-image: none;
}
.open .grey-gallery.btn.dropdown-toggle {
  background-image: none;
}
.grey-gallery.btn.disabled, .grey-gallery.btn.disabled:hover, .grey-gallery.btn.disabled:focus, .grey-gallery.btn.disabled:active, .grey-gallery.btn.disabled.active, .grey-gallery.btn[disabled], .grey-gallery.btn[disabled]:hover, .grey-gallery.btn[disabled]:focus, .grey-gallery.btn[disabled]:active, .grey-gallery.btn[disabled].active, fieldset[disabled] .grey-gallery.btn, fieldset[disabled] .grey-gallery.btn:hover, fieldset[disabled] .grey-gallery.btn:focus, fieldset[disabled] .grey-gallery.btn:active, fieldset[disabled] .grey-gallery.btn.active {
  background-color: #555555;
}
.grey-gallery.btn .badge {
  color: #555555;
  background-color: white;
}

/* Button Strip */
.btn.grey-gallery-stripe {
  border-left: 3px solid #555555;
}

/* Portlet */
.portlet > .portlet-body.grey-gallery,
.portlet.grey-gallery {
  background-color: #555555;
}

.portlet.solid.grey-gallery > .portlet-title,
.portlet.solid.grey-gallery > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.grey-gallery > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-gallery {
  border: 1px solid #6e6e6e;
  border-top: 0;
}
.portlet.box.grey-gallery > .portlet-title {
  background-color: #555555;
}
.portlet.box.grey-gallery > .portlet-title > .caption {
  color: white;
}
.portlet.box.grey-gallery > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.grey-gallery > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #8d8d8d;
  color: #9a9a9a;
}
.portlet.box.grey-gallery > .portlet-title > .actions .btn-default > i {
  color: #a2a2a2;
}
.portlet.box.grey-gallery > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default:active, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default.active {
  border: 1px solid #a7a7a7;
  color: #b3b3b3;
}

/* Statistic Block */
.dashboard-stat.grey-gallery {
  background-color: #555555;
}
.dashboard-stat.grey-gallery.dashboard-stat-light:hover {
  background-color: #4b4b4b;
}
.dashboard-stat.grey-gallery .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-gallery .details .number {
  color: white;
}
.dashboard-stat.grey-gallery .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-gallery .more {
  color: white;
  background-color: #4b4b4b;
}

/* General Element */
.bg-grey-gallery {
  border-color: #555555 !important;
  background-image: none !important;
  background-color: #555555 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-grey-gallery {
  color: #555555 !important;
}

/***
Grey Cascade
***/
/* Button */
.grey-cascade.btn {
  color: white;
  background-color: #95a5a6;
  border-color: "";
}
.grey-cascade.btn:hover, .grey-cascade.btn:focus, .grey-cascade.btn:active, .grey-cascade.btn.active {
  color: white;
  background-color: #7f9293;
}
.open .grey-cascade.btn.dropdown-toggle {
  color: white;
  background-color: #7f9293;
}
.grey-cascade.btn:active, .grey-cascade.btn.active {
  background-image: none;
}
.open .grey-cascade.btn.dropdown-toggle {
  background-image: none;
}
.grey-cascade.btn.disabled, .grey-cascade.btn.disabled:hover, .grey-cascade.btn.disabled:focus, .grey-cascade.btn.disabled:active, .grey-cascade.btn.disabled.active, .grey-cascade.btn[disabled], .grey-cascade.btn[disabled]:hover, .grey-cascade.btn[disabled]:focus, .grey-cascade.btn[disabled]:active, .grey-cascade.btn[disabled].active, fieldset[disabled] .grey-cascade.btn, fieldset[disabled] .grey-cascade.btn:hover, fieldset[disabled] .grey-cascade.btn:focus, fieldset[disabled] .grey-cascade.btn:active, fieldset[disabled] .grey-cascade.btn.active {
  background-color: #95a5a6;
}
.grey-cascade.btn .badge {
  color: #95a5a6;
  background-color: white;
}

/* Button Strip */
.btn.grey-cascade-stripe {
  border-left: 3px solid #95a5a6;
}

/* Portlet */
.portlet > .portlet-body.grey-cascade,
.portlet.grey-cascade {
  background-color: #95a5a6;
}

.portlet.solid.grey-cascade > .portlet-title,
.portlet.solid.grey-cascade > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.grey-cascade > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-cascade {
  border: 1px solid #b1bdbd;
  border-top: 0;
}
.portlet.box.grey-cascade > .portlet-title {
  background-color: #95a5a6;
}
.portlet.box.grey-cascade > .portlet-title > .caption {
  color: white;
}
.portlet.box.grey-cascade > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.grey-cascade > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #d2d9d9;
  color: #e0e5e5;
}
.portlet.box.grey-cascade > .portlet-title > .actions .btn-default > i {
  color: #e8ecec;
}
.portlet.box.grey-cascade > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default:active, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default.active {
  border: 1px solid #eef0f0;
  color: #fcfcfc;
}

/* Statistic Block */
.dashboard-stat.grey-cascade {
  background-color: #95a5a6;
}
.dashboard-stat.grey-cascade.dashboard-stat-light:hover {
  background-color: #8a9c9d;
}
.dashboard-stat.grey-cascade .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-cascade .details .number {
  color: white;
}
.dashboard-stat.grey-cascade .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-cascade .more {
  color: white;
  background-color: #8a9c9d;
}

/* General Element */
.bg-grey-cascade {
  border-color: #95a5a6 !important;
  background-image: none !important;
  background-color: #95a5a6 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-grey-cascade {
  color: #95a5a6 !important;
}

/***
Grey Silver
***/
/* Button */
.grey-silver.btn {
  color: #fafcfb;
  background-color: #bfbfbf;
  border-color: "";
}
.grey-silver.btn:hover, .grey-silver.btn:focus, .grey-silver.btn:active, .grey-silver.btn.active {
  color: #fafcfb;
  background-color: #ababab;
}
.open .grey-silver.btn.dropdown-toggle {
  color: #fafcfb;
  background-color: #ababab;
}
.grey-silver.btn:active, .grey-silver.btn.active {
  background-image: none;
}
.open .grey-silver.btn.dropdown-toggle {
  background-image: none;
}
.grey-silver.btn.disabled, .grey-silver.btn.disabled:hover, .grey-silver.btn.disabled:focus, .grey-silver.btn.disabled:active, .grey-silver.btn.disabled.active, .grey-silver.btn[disabled], .grey-silver.btn[disabled]:hover, .grey-silver.btn[disabled]:focus, .grey-silver.btn[disabled]:active, .grey-silver.btn[disabled].active, fieldset[disabled] .grey-silver.btn, fieldset[disabled] .grey-silver.btn:hover, fieldset[disabled] .grey-silver.btn:focus, fieldset[disabled] .grey-silver.btn:active, fieldset[disabled] .grey-silver.btn.active {
  background-color: #bfbfbf;
}
.grey-silver.btn .badge {
  color: #bfbfbf;
  background-color: #fafcfb;
}

/* Button Strip */
.btn.grey-silver-stripe {
  border-left: 3px solid #bfbfbf;
}

/* Portlet */
.portlet > .portlet-body.grey-silver,
.portlet.grey-silver {
  background-color: #bfbfbf;
}

.portlet.solid.grey-silver > .portlet-title,
.portlet.solid.grey-silver > .portlet-body {
  border: 0;
  color: #fafcfb;
}
.portlet.solid.grey-silver > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-silver {
  border: 1px solid #d9d9d9;
  border-top: 0;
}
.portlet.box.grey-silver > .portlet-title {
  background-color: #bfbfbf;
}
.portlet.box.grey-silver > .portlet-title > .caption {
  color: #fafcfb;
}
.portlet.box.grey-silver > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.box.grey-silver > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f7f7f7;
  color: white;
}
.portlet.box.grey-silver > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-silver > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-silver > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-silver > .portlet-title > .actions .btn-default:active, .portlet.box.grey-silver > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-silver {
  background-color: #bfbfbf;
}
.dashboard-stat.grey-silver.dashboard-stat-light:hover {
  background-color: #b5b5b5;
}
.dashboard-stat.grey-silver .visual > i {
  color: #fafcfb;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-silver .details .number {
  color: #fafcfb;
}
.dashboard-stat.grey-silver .details .desc {
  color: #fafcfb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-silver .more {
  color: #fafcfb;
  background-color: #b5b5b5;
}

/* General Element */
.bg-grey-silver {
  border-color: #bfbfbf !important;
  background-image: none !important;
  background-color: #bfbfbf !important;
  color: #fafcfb !important;
}

/* General Text & Icon Font Colors */
.font-grey-silver {
  color: #bfbfbf !important;
}

/***
Grey Salsa
***/
/* Button */
.grey-salsa.btn {
  color: #fafcfb;
  background-color: #acb5c3;
  border-color: "";
}
.grey-salsa.btn:hover, .grey-salsa.btn:focus, .grey-salsa.btn:active, .grey-salsa.btn.active {
  color: #fafcfb;
  background-color: #94a0b2;
}
.open .grey-salsa.btn.dropdown-toggle {
  color: #fafcfb;
  background-color: #94a0b2;
}
.grey-salsa.btn:active, .grey-salsa.btn.active {
  background-image: none;
}
.open .grey-salsa.btn.dropdown-toggle {
  background-image: none;
}
.grey-salsa.btn.disabled, .grey-salsa.btn.disabled:hover, .grey-salsa.btn.disabled:focus, .grey-salsa.btn.disabled:active, .grey-salsa.btn.disabled.active, .grey-salsa.btn[disabled], .grey-salsa.btn[disabled]:hover, .grey-salsa.btn[disabled]:focus, .grey-salsa.btn[disabled]:active, .grey-salsa.btn[disabled].active, fieldset[disabled] .grey-salsa.btn, fieldset[disabled] .grey-salsa.btn:hover, fieldset[disabled] .grey-salsa.btn:focus, fieldset[disabled] .grey-salsa.btn:active, fieldset[disabled] .grey-salsa.btn.active {
  background-color: #acb5c3;
}
.grey-salsa.btn .badge {
  color: #acb5c3;
  background-color: #fafcfb;
}

/* Button Strip */
.btn.grey-salsa-stripe {
  border-left: 3px solid #acb5c3;
}

/* Portlet */
.portlet > .portlet-body.grey-salsa,
.portlet.grey-salsa {
  background-color: #acb5c3;
}

.portlet.solid.grey-salsa > .portlet-title,
.portlet.solid.grey-salsa > .portlet-body {
  border: 0;
  color: #fafcfb;
}
.portlet.solid.grey-salsa > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-salsa {
  border: 1px solid #cacfd8;
  border-top: 0;
}
.portlet.box.grey-salsa > .portlet-title {
  background-color: #acb5c3;
}
.portlet.box.grey-salsa > .portlet-title > .caption {
  color: #fafcfb;
}
.portlet.box.grey-salsa > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.box.grey-salsa > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #edeff2;
  color: #fcfcfd;
}
.portlet.box.grey-salsa > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-salsa > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default:active, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-salsa {
  background-color: #acb5c3;
}
.dashboard-stat.grey-salsa.dashboard-stat-light:hover {
  background-color: #a0aaba;
}
.dashboard-stat.grey-salsa .visual > i {
  color: #fafcfb;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-salsa .details .number {
  color: #fafcfb;
}
.dashboard-stat.grey-salsa .details .desc {
  color: #fafcfb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-salsa .more {
  color: #fafcfb;
  background-color: #a0aaba;
}

/* General Element */
.bg-grey-salsa {
  border-color: #acb5c3 !important;
  background-image: none !important;
  background-color: #acb5c3 !important;
  color: #fafcfb !important;
}

/* General Text & Icon Font Colors */
.font-grey-salsa {
  color: #acb5c3 !important;
}

/***
Grey Salt
***/
/* Button */
.grey-salt.btn {
  color: #fafcfb;
  background-color: #bfcad1;
  border-color: "";
}
.grey-salt.btn:hover, .grey-salt.btn:focus, .grey-salt.btn:active, .grey-salt.btn.active {
  color: #fafcfb;
  background-color: #a7b6c0;
}
.open .grey-salt.btn.dropdown-toggle {
  color: #fafcfb;
  background-color: #a7b6c0;
}
.grey-salt.btn:active, .grey-salt.btn.active {
  background-image: none;
}
.open .grey-salt.btn.dropdown-toggle {
  background-image: none;
}
.grey-salt.btn.disabled, .grey-salt.btn.disabled:hover, .grey-salt.btn.disabled:focus, .grey-salt.btn.disabled:active, .grey-salt.btn.disabled.active, .grey-salt.btn[disabled], .grey-salt.btn[disabled]:hover, .grey-salt.btn[disabled]:focus, .grey-salt.btn[disabled]:active, .grey-salt.btn[disabled].active, fieldset[disabled] .grey-salt.btn, fieldset[disabled] .grey-salt.btn:hover, fieldset[disabled] .grey-salt.btn:focus, fieldset[disabled] .grey-salt.btn:active, fieldset[disabled] .grey-salt.btn.active {
  background-color: #bfcad1;
}
.grey-salt.btn .badge {
  color: #bfcad1;
  background-color: #fafcfb;
}

/* Button Strip */
.btn.grey-salt-stripe {
  border-left: 3px solid #bfcad1;
}

/* Portlet */
.portlet > .portlet-body.grey-salt,
.portlet.grey-salt {
  background-color: #bfcad1;
}

.portlet.solid.grey-salt > .portlet-title,
.portlet.solid.grey-salt > .portlet-body {
  border: 0;
  color: #fafcfb;
}
.portlet.solid.grey-salt > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-salt {
  border: 1px solid #dde3e6;
  border-top: 0;
}
.portlet.box.grey-salt > .portlet-title {
  background-color: #bfcad1;
}
.portlet.box.grey-salt > .portlet-title > .caption {
  color: #fafcfb;
}
.portlet.box.grey-salt > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.box.grey-salt > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey-salt > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-salt > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-salt > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-salt > .portlet-title > .actions .btn-default:active, .portlet.box.grey-salt > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-salt {
  background-color: #bfcad1;
}
.dashboard-stat.grey-salt.dashboard-stat-light:hover {
  background-color: #b3c0c8;
}
.dashboard-stat.grey-salt .visual > i {
  color: #fafcfb;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-salt .details .number {
  color: #fafcfb;
}
.dashboard-stat.grey-salt .details .desc {
  color: #fafcfb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-salt .more {
  color: #fafcfb;
  background-color: #b3c0c8;
}

/* General Element */
.bg-grey-salt {
  border-color: #bfcad1 !important;
  background-image: none !important;
  background-color: #bfcad1 !important;
  color: #fafcfb !important;
}

/* General Text & Icon Font Colors */
.font-grey-salt {
  color: #bfcad1 !important;
}

/***
Grey Mint
***/
/* Button */
.grey-mint.btn {
  color: #fafcfb;
  background-color: #9eacb4;
  border-color: "";
}
.grey-mint.btn:hover, .grey-mint.btn:focus, .grey-mint.btn:active, .grey-mint.btn.active {
  color: #fafcfb;
  background-color: #8798a2;
}
.open .grey-mint.btn.dropdown-toggle {
  color: #fafcfb;
  background-color: #8798a2;
}
.grey-mint.btn:active, .grey-mint.btn.active {
  background-image: none;
}
.open .grey-mint.btn.dropdown-toggle {
  background-image: none;
}
.grey-mint.btn.disabled, .grey-mint.btn.disabled:hover, .grey-mint.btn.disabled:focus, .grey-mint.btn.disabled:active, .grey-mint.btn.disabled.active, .grey-mint.btn[disabled], .grey-mint.btn[disabled]:hover, .grey-mint.btn[disabled]:focus, .grey-mint.btn[disabled]:active, .grey-mint.btn[disabled].active, fieldset[disabled] .grey-mint.btn, fieldset[disabled] .grey-mint.btn:hover, fieldset[disabled] .grey-mint.btn:focus, fieldset[disabled] .grey-mint.btn:active, fieldset[disabled] .grey-mint.btn.active {
  background-color: #9eacb4;
}
.grey-mint.btn .badge {
  color: #9eacb4;
  background-color: #fafcfb;
}

/* Button Strip */
.btn.grey-mint-stripe {
  border-left: 3px solid #9eacb4;
}

/* Portlet */
.portlet > .portlet-body.grey-mint,
.portlet.grey-mint {
  background-color: #9eacb4;
}

.portlet.solid.grey-mint > .portlet-title,
.portlet.solid.grey-mint > .portlet-body {
  border: 0;
  color: #fafcfb;
}
.portlet.solid.grey-mint > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.grey-mint {
  border: 1px solid #bbc5ca;
  border-top: 0;
}
.portlet.box.grey-mint > .portlet-title {
  background-color: #9eacb4;
}
.portlet.box.grey-mint > .portlet-title > .caption {
  color: #fafcfb;
}
.portlet.box.grey-mint > .portlet-title > .caption > i {
  color: #fafcfb;
}
.portlet.box.grey-mint > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #dde2e5;
  color: #eceef0;
}
.portlet.box.grey-mint > .portlet-title > .actions .btn-default > i {
  color: #f4f6f7;
}
.portlet.box.grey-mint > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-mint > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-mint > .portlet-title > .actions .btn-default:active, .portlet.box.grey-mint > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fafbfb;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-mint {
  background-color: #9eacb4;
}
.dashboard-stat.grey-mint.dashboard-stat-light:hover {
  background-color: #92a2ab;
}
.dashboard-stat.grey-mint .visual > i {
  color: #fafcfb;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-mint .details .number {
  color: #fafcfb;
}
.dashboard-stat.grey-mint .details .desc {
  color: #fafcfb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-mint .more {
  color: #fafcfb;
  background-color: #92a2ab;
}

/* General Element */
.bg-grey-mint {
  border-color: #9eacb4 !important;
  background-image: none !important;
  background-color: #9eacb4 !important;
  color: #fafcfb !important;
}

/* General Text & Icon Font Colors */
.font-grey-mint {
  color: #9eacb4 !important;
}

/***********
Red Colors
************/
/***
Red Default
***/
/* Button */
.red.btn {
  color: white;
  background-color: #d84a38;
  border-color: "";
}
.red.btn:hover, .red.btn:focus, .red.btn:active, .red.btn.active {
  color: white;
  background-color: #c13726;
}
.open .red.btn.dropdown-toggle {
  color: white;
  background-color: #c13726;
}
.red.btn:active, .red.btn.active {
  background-image: none;
}
.open .red.btn.dropdown-toggle {
  background-image: none;
}
.red.btn.disabled, .red.btn.disabled:hover, .red.btn.disabled:focus, .red.btn.disabled:active, .red.btn.disabled.active, .red.btn[disabled], .red.btn[disabled]:hover, .red.btn[disabled]:focus, .red.btn[disabled]:active, .red.btn[disabled].active, fieldset[disabled] .red.btn, fieldset[disabled] .red.btn:hover, fieldset[disabled] .red.btn:focus, fieldset[disabled] .red.btn:active, fieldset[disabled] .red.btn.active {
  background-color: #d84a38;
}
.red.btn .badge {
  color: #d84a38;
  background-color: white;
}

/* Button Strip */
.btn.red-stripe {
  border-left: 3px solid #d84a38;
}

/* Portlet */
.portlet > .portlet-body.red,
.portlet.red {
  background-color: #d84a38;
}

.portlet.solid.red > .portlet-title,
.portlet.solid.red > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red {
  border: 1px solid #e07163;
  border-top: 0;
}
.portlet.box.red > .portlet-title {
  background-color: #d84a38;
}
.portlet.box.red > .portlet-title > .caption {
  color: white;
}
.portlet.box.red > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ea9f96;
  color: #efb3ab;
}
.portlet.box.red > .portlet-title > .actions .btn-default > i {
  color: #f1beb8;
}
.portlet.box.red > .portlet-title > .actions .btn-default:hover, .portlet.box.red > .portlet-title > .actions .btn-default:focus, .portlet.box.red > .portlet-title > .actions .btn-default:active, .portlet.box.red > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f3c6c0;
  color: #f7dad6;
}

/* Statistic Block */
.dashboard-stat.red {
  background-color: #d84a38;
}
.dashboard-stat.red.dashboard-stat-light:hover {
  background-color: #d23c29;
}
.dashboard-stat.red .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red .details .number {
  color: white;
}
.dashboard-stat.red .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red .more {
  color: white;
  background-color: #d23c29;
}

/* General Element */
.bg-red {
  border-color: #d84a38 !important;
  background-image: none !important;
  background-color: #d84a38 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red {
  color: #d84a38 !important;
}

/***
Red Pink
***/
/* Button */
.red-pink.btn {
  color: white;
  background-color: #e08283;
  border-color: "";
}
.red-pink.btn:hover, .red-pink.btn:focus, .red-pink.btn:active, .red-pink.btn.active {
  color: white;
  background-color: #d86163;
}
.open .red-pink.btn.dropdown-toggle {
  color: white;
  background-color: #d86163;
}
.red-pink.btn:active, .red-pink.btn.active {
  background-image: none;
}
.open .red-pink.btn.dropdown-toggle {
  background-image: none;
}
.red-pink.btn.disabled, .red-pink.btn.disabled:hover, .red-pink.btn.disabled:focus, .red-pink.btn.disabled:active, .red-pink.btn.disabled.active, .red-pink.btn[disabled], .red-pink.btn[disabled]:hover, .red-pink.btn[disabled]:focus, .red-pink.btn[disabled]:active, .red-pink.btn[disabled].active, fieldset[disabled] .red-pink.btn, fieldset[disabled] .red-pink.btn:hover, fieldset[disabled] .red-pink.btn:focus, fieldset[disabled] .red-pink.btn:active, fieldset[disabled] .red-pink.btn.active {
  background-color: #e08283;
}
.red-pink.btn .badge {
  color: #e08283;
  background-color: white;
}

/* Button Strip */
.btn.red-pink-stripe {
  border-left: 3px solid #e08283;
}

/* Portlet */
.portlet > .portlet-body.red-pink,
.portlet.red-pink {
  background-color: #e08283;
}

.portlet.solid.red-pink > .portlet-title,
.portlet.solid.red-pink > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red-pink > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red-pink > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red-pink {
  border: 1px solid #eaabac;
  border-top: 0;
}
.portlet.box.red-pink > .portlet-title {
  background-color: #e08283;
}
.portlet.box.red-pink > .portlet-title > .caption {
  color: white;
}
.portlet.box.red-pink > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red-pink > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f6dcdc;
  color: #fbf0f0;
}
.portlet.box.red-pink > .portlet-title > .actions .btn-default > i {
  color: #fefdfd;
}
.portlet.box.red-pink > .portlet-title > .actions .btn-default:hover, .portlet.box.red-pink > .portlet-title > .actions .btn-default:focus, .portlet.box.red-pink > .portlet-title > .actions .btn-default:active, .portlet.box.red-pink > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.red-pink {
  background-color: #e08283;
}
.dashboard-stat.red-pink.dashboard-stat-light:hover {
  background-color: #dc7273;
}
.dashboard-stat.red-pink .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-pink .details .number {
  color: white;
}
.dashboard-stat.red-pink .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-pink .more {
  color: white;
  background-color: #dc7273;
}

/* General Element */
.bg-red-pink {
  border-color: #e08283 !important;
  background-image: none !important;
  background-color: #e08283 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red-pink {
  color: #e08283 !important;
}

/***
Red Sunglo
***/
/* Button */
.red-sunglo.btn {
  color: white;
  background-color: #e26a6a;
  border-color: "";
}
.red-sunglo.btn:hover, .red-sunglo.btn:focus, .red-sunglo.btn:active, .red-sunglo.btn.active {
  color: white;
  background-color: #db4848;
}
.open .red-sunglo.btn.dropdown-toggle {
  color: white;
  background-color: #db4848;
}
.red-sunglo.btn:active, .red-sunglo.btn.active {
  background-image: none;
}
.open .red-sunglo.btn.dropdown-toggle {
  background-image: none;
}
.red-sunglo.btn.disabled, .red-sunglo.btn.disabled:hover, .red-sunglo.btn.disabled:focus, .red-sunglo.btn.disabled:active, .red-sunglo.btn.disabled.active, .red-sunglo.btn[disabled], .red-sunglo.btn[disabled]:hover, .red-sunglo.btn[disabled]:focus, .red-sunglo.btn[disabled]:active, .red-sunglo.btn[disabled].active, fieldset[disabled] .red-sunglo.btn, fieldset[disabled] .red-sunglo.btn:hover, fieldset[disabled] .red-sunglo.btn:focus, fieldset[disabled] .red-sunglo.btn:active, fieldset[disabled] .red-sunglo.btn.active {
  background-color: #e26a6a;
}
.red-sunglo.btn .badge {
  color: #e26a6a;
  background-color: white;
}

/* Button Strip */
.btn.red-sunglo-stripe {
  border-left: 3px solid #e26a6a;
}

/* Portlet */
.portlet > .portlet-body.red-sunglo,
.portlet.red-sunglo {
  background-color: #e26a6a;
}

.portlet.solid.red-sunglo > .portlet-title,
.portlet.solid.red-sunglo > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red-sunglo > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red-sunglo {
  border: 1px solid #ea9595;
  border-top: 0;
}
.portlet.box.red-sunglo > .portlet-title {
  background-color: #e26a6a;
}
.portlet.box.red-sunglo > .portlet-title > .caption {
  color: white;
}
.portlet.box.red-sunglo > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red-sunglo > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f4c8c8;
  color: #f8dddd;
}
.portlet.box.red-sunglo > .portlet-title > .actions .btn-default > i {
  color: #fbeaea;
}
.portlet.box.red-sunglo > .portlet-title > .actions .btn-default:hover, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default:focus, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default:active, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdf3f3;
  color: white;
}

/* Statistic Block */
.dashboard-stat.red-sunglo {
  background-color: #e26a6a;
}
.dashboard-stat.red-sunglo.dashboard-stat-light:hover {
  background-color: #df5959;
}
.dashboard-stat.red-sunglo .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-sunglo .details .number {
  color: white;
}
.dashboard-stat.red-sunglo .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-sunglo .more {
  color: white;
  background-color: #df5959;
}

/* General Element */
.bg-red-sunglo {
  border-color: #e26a6a !important;
  background-image: none !important;
  background-color: #e26a6a !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red-sunglo {
  color: #e26a6a !important;
}

/***
Red Intense
***/
/* Button */
.red-intense.btn {
  color: white;
  background-color: #e35b5a;
  border-color: "";
}
.red-intense.btn:hover, .red-intense.btn:focus, .red-intense.btn:active, .red-intense.btn.active {
  color: white;
  background-color: #dd3837;
}
.open .red-intense.btn.dropdown-toggle {
  color: white;
  background-color: #dd3837;
}
.red-intense.btn:active, .red-intense.btn.active {
  background-image: none;
}
.open .red-intense.btn.dropdown-toggle {
  background-image: none;
}
.red-intense.btn.disabled, .red-intense.btn.disabled:hover, .red-intense.btn.disabled:focus, .red-intense.btn.disabled:active, .red-intense.btn.disabled.active, .red-intense.btn[disabled], .red-intense.btn[disabled]:hover, .red-intense.btn[disabled]:focus, .red-intense.btn[disabled]:active, .red-intense.btn[disabled].active, fieldset[disabled] .red-intense.btn, fieldset[disabled] .red-intense.btn:hover, fieldset[disabled] .red-intense.btn:focus, fieldset[disabled] .red-intense.btn:active, fieldset[disabled] .red-intense.btn.active {
  background-color: #e35b5a;
}
.red-intense.btn .badge {
  color: #e35b5a;
  background-color: white;
}

/* Button Strip */
.btn.red-intense-stripe {
  border-left: 3px solid #e35b5a;
}

/* Portlet */
.portlet > .portlet-body.red-intense,
.portlet.red-intense {
  background-color: #e35b5a;
}

.portlet.solid.red-intense > .portlet-title,
.portlet.solid.red-intense > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red-intense > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red-intense > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red-intense {
  border: 1px solid #ea8686;
  border-top: 0;
}
.portlet.box.red-intense > .portlet-title {
  background-color: #e35b5a;
}
.portlet.box.red-intense > .portlet-title > .caption {
  color: white;
}
.portlet.box.red-intense > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red-intense > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f3baba;
  color: #f7d0d0;
}
.portlet.box.red-intense > .portlet-title > .actions .btn-default > i {
  color: #f9dddd;
}
.portlet.box.red-intense > .portlet-title > .actions .btn-default:hover, .portlet.box.red-intense > .portlet-title > .actions .btn-default:focus, .portlet.box.red-intense > .portlet-title > .actions .btn-default:active, .portlet.box.red-intense > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbe6e6;
  color: #fefbfb;
}

/* Statistic Block */
.dashboard-stat.red-intense {
  background-color: #e35b5a;
}
.dashboard-stat.red-intense.dashboard-stat-light:hover {
  background-color: #e04a49;
}
.dashboard-stat.red-intense .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-intense .details .number {
  color: white;
}
.dashboard-stat.red-intense .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-intense .more {
  color: white;
  background-color: #e04a49;
}

/* General Element */
.bg-red-intense {
  border-color: #e35b5a !important;
  background-image: none !important;
  background-color: #e35b5a !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red-intense {
  color: #e35b5a !important;
}

/***
Red Thunderbird
***/
/* Button */
.red-thunderbird.btn {
  color: white;
  background-color: #d91e18;
  border-color: "";
}
.red-thunderbird.btn:hover, .red-thunderbird.btn:focus, .red-thunderbird.btn:active, .red-thunderbird.btn.active {
  color: white;
  background-color: #b41914;
}
.open .red-thunderbird.btn.dropdown-toggle {
  color: white;
  background-color: #b41914;
}
.red-thunderbird.btn:active, .red-thunderbird.btn.active {
  background-image: none;
}
.open .red-thunderbird.btn.dropdown-toggle {
  background-image: none;
}
.red-thunderbird.btn.disabled, .red-thunderbird.btn.disabled:hover, .red-thunderbird.btn.disabled:focus, .red-thunderbird.btn.disabled:active, .red-thunderbird.btn.disabled.active, .red-thunderbird.btn[disabled], .red-thunderbird.btn[disabled]:hover, .red-thunderbird.btn[disabled]:focus, .red-thunderbird.btn[disabled]:active, .red-thunderbird.btn[disabled].active, fieldset[disabled] .red-thunderbird.btn, fieldset[disabled] .red-thunderbird.btn:hover, fieldset[disabled] .red-thunderbird.btn:focus, fieldset[disabled] .red-thunderbird.btn:active, fieldset[disabled] .red-thunderbird.btn.active {
  background-color: #d91e18;
}
.red-thunderbird.btn .badge {
  color: #d91e18;
  background-color: white;
}

/* Button Strip */
.btn.red-thunderbird-stripe {
  border-left: 3px solid #d91e18;
}

/* Portlet */
.portlet > .portlet-body.red-thunderbird,
.portlet.red-thunderbird {
  background-color: #d91e18;
}

.portlet.solid.red-thunderbird > .portlet-title,
.portlet.solid.red-thunderbird > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red-thunderbird > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red-thunderbird {
  border: 1px solid #e9403b;
  border-top: 0;
}
.portlet.box.red-thunderbird > .portlet-title {
  background-color: #d91e18;
}
.portlet.box.red-thunderbird > .portlet-title > .caption {
  color: white;
}
.portlet.box.red-thunderbird > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ef7672;
  color: #f28c89;
}
.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default > i {
  color: #f39997;
}
.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:hover, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:focus, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:active, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f4a2a0;
  color: #f7b9b7;
}

/* Statistic Block */
.dashboard-stat.red-thunderbird {
  background-color: #d91e18;
}
.dashboard-stat.red-thunderbird.dashboard-stat-light:hover {
  background-color: #c71b16;
}
.dashboard-stat.red-thunderbird .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-thunderbird .details .number {
  color: white;
}
.dashboard-stat.red-thunderbird .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-thunderbird .more {
  color: white;
  background-color: #c71b16;
}

/* General Element */
.bg-red-thunderbird {
  border-color: #d91e18 !important;
  background-image: none !important;
  background-color: #d91e18 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red-thunderbird {
  color: #d91e18 !important;
}

/***
Red Flamingo
***/
/* Button */
.red-flamingo.btn {
  color: white;
  background-color: #ef4836;
  border-color: "";
}
.red-flamingo.btn:hover, .red-flamingo.btn:focus, .red-flamingo.btn:active, .red-flamingo.btn.active {
  color: white;
  background-color: #ea2813;
}
.open .red-flamingo.btn.dropdown-toggle {
  color: white;
  background-color: #ea2813;
}
.red-flamingo.btn:active, .red-flamingo.btn.active {
  background-image: none;
}
.open .red-flamingo.btn.dropdown-toggle {
  background-image: none;
}
.red-flamingo.btn.disabled, .red-flamingo.btn.disabled:hover, .red-flamingo.btn.disabled:focus, .red-flamingo.btn.disabled:active, .red-flamingo.btn.disabled.active, .red-flamingo.btn[disabled], .red-flamingo.btn[disabled]:hover, .red-flamingo.btn[disabled]:focus, .red-flamingo.btn[disabled]:active, .red-flamingo.btn[disabled].active, fieldset[disabled] .red-flamingo.btn, fieldset[disabled] .red-flamingo.btn:hover, fieldset[disabled] .red-flamingo.btn:focus, fieldset[disabled] .red-flamingo.btn:active, fieldset[disabled] .red-flamingo.btn.active {
  background-color: #ef4836;
}
.red-flamingo.btn .badge {
  color: #ef4836;
  background-color: white;
}

/* Button Strip */
.btn.red-flamingo-stripe {
  border-left: 3px solid #ef4836;
}

/* Portlet */
.portlet > .portlet-body.red-flamingo,
.portlet.red-flamingo {
  background-color: #ef4836;
}

.portlet.solid.red-flamingo > .portlet-title,
.portlet.solid.red-flamingo > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red-flamingo > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red-flamingo {
  border: 1px solid #f37365;
  border-top: 0;
}
.portlet.box.red-flamingo > .portlet-title {
  background-color: #ef4836;
}
.portlet.box.red-flamingo > .portlet-title > .caption {
  color: white;
}
.portlet.box.red-flamingo > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red-flamingo > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f7a79e;
  color: #f9bcb6;
}
.portlet.box.red-flamingo > .portlet-title > .actions .btn-default > i {
  color: #fac9c4;
}
.portlet.box.red-flamingo > .portlet-title > .actions .btn-default:hover, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default:focus, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default:active, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbd2cd;
  color: #fde7e5;
}

/* Statistic Block */
.dashboard-stat.red-flamingo {
  background-color: #ef4836;
}
.dashboard-stat.red-flamingo.dashboard-stat-light:hover {
  background-color: #ed3723;
}
.dashboard-stat.red-flamingo .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-flamingo .details .number {
  color: white;
}
.dashboard-stat.red-flamingo .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-flamingo .more {
  color: white;
  background-color: #ed3723;
}

/* General Element */
.bg-red-flamingo {
  border-color: #ef4836 !important;
  background-image: none !important;
  background-color: #ef4836 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red-flamingo {
  color: #ef4836 !important;
}

/***
Red Flamingo
***/
/* Button */
.red-soft.btn {
  color: white;
  background-color: #d05454;
  border-color: "";
}
.red-soft.btn:hover, .red-soft.btn:focus, .red-soft.btn:active, .red-soft.btn.active {
  color: white;
  background-color: #c53636;
}
.open .red-soft.btn.dropdown-toggle {
  color: white;
  background-color: #c53636;
}
.red-soft.btn:active, .red-soft.btn.active {
  background-image: none;
}
.open .red-soft.btn.dropdown-toggle {
  background-image: none;
}
.red-soft.btn.disabled, .red-soft.btn.disabled:hover, .red-soft.btn.disabled:focus, .red-soft.btn.disabled:active, .red-soft.btn.disabled.active, .red-soft.btn[disabled], .red-soft.btn[disabled]:hover, .red-soft.btn[disabled]:focus, .red-soft.btn[disabled]:active, .red-soft.btn[disabled].active, fieldset[disabled] .red-soft.btn, fieldset[disabled] .red-soft.btn:hover, fieldset[disabled] .red-soft.btn:focus, fieldset[disabled] .red-soft.btn:active, fieldset[disabled] .red-soft.btn.active {
  background-color: #d05454;
}
.red-soft.btn .badge {
  color: #d05454;
  background-color: white;
}

/* Button Strip */
.btn.red-soft-stripe {
  border-left: 3px solid #d05454;
}

/* Portlet */
.portlet > .portlet-body.red-soft,
.portlet.red-soft {
  background-color: #d05454;
}

.portlet.solid.red-soft > .portlet-title,
.portlet.solid.red-soft > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.red-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.red-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.red-soft {
  border: 1px solid #db7c7c;
  border-top: 0;
}
.portlet.box.red-soft > .portlet-title {
  background-color: #d05454;
}
.portlet.box.red-soft > .portlet-title > .caption {
  color: white;
}
.portlet.box.red-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.red-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e8acac;
  color: #eec0c0;
}
.portlet.box.red-soft > .portlet-title > .actions .btn-default > i {
  color: #f1cccc;
}
.portlet.box.red-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.red-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.red-soft > .portlet-title > .actions .btn-default:active, .portlet.box.red-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f3d4d4;
  color: #f9e8e8;
}

/* Statistic Block */
.dashboard-stat.red-soft {
  background-color: #d05454;
}
.dashboard-stat.red-soft.dashboard-stat-light:hover {
  background-color: #cc4444;
}
.dashboard-stat.red-soft .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-soft .details .number {
  color: white;
}
.dashboard-stat.red-soft .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-soft .more {
  color: white;
  background-color: #cc4444;
}

/* General Element */
.bg-red-soft {
  border-color: #d05454 !important;
  background-image: none !important;
  background-color: #d05454 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-red-soft {
  color: #d05454 !important;
}

/***********
Yellow Colors
************/
/***
Yellow Default
***/
/* Button */
.yellow.btn {
  color: white;
  background-color: #ffb848;
  border-color: "";
}
.yellow.btn:hover, .yellow.btn:focus, .yellow.btn:active, .yellow.btn.active {
  color: white;
  background-color: #ffa81f;
}
.open .yellow.btn.dropdown-toggle {
  color: white;
  background-color: #ffa81f;
}
.yellow.btn:active, .yellow.btn.active {
  background-image: none;
}
.open .yellow.btn.dropdown-toggle {
  background-image: none;
}
.yellow.btn.disabled, .yellow.btn.disabled:hover, .yellow.btn.disabled:focus, .yellow.btn.disabled:active, .yellow.btn.disabled.active, .yellow.btn[disabled], .yellow.btn[disabled]:hover, .yellow.btn[disabled]:focus, .yellow.btn[disabled]:active, .yellow.btn[disabled].active, fieldset[disabled] .yellow.btn, fieldset[disabled] .yellow.btn:hover, fieldset[disabled] .yellow.btn:focus, fieldset[disabled] .yellow.btn:active, fieldset[disabled] .yellow.btn.active {
  background-color: #ffb848;
}
.yellow.btn .badge {
  color: #ffb848;
  background-color: white;
}

/* Button Strip */
.btn.yellow-stripe {
  border-left: 3px solid #ffb848;
}

/* Portlet */
.portlet > .portlet-body.yellow,
.portlet.yellow {
  background-color: #ffb848;
}

.portlet.solid.yellow > .portlet-title,
.portlet.solid.yellow > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.yellow > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.yellow > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.yellow {
  border: 1px solid #ffcc7b;
  border-top: 0;
}
.portlet.box.yellow > .portlet-title {
  background-color: #ffb848;
}
.portlet.box.yellow > .portlet-title > .caption {
  color: white;
}
.portlet.box.yellow > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.yellow > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ffe4b8;
  color: #ffedd2;
}
.portlet.box.yellow > .portlet-title > .actions .btn-default > i {
  color: #fff3e1;
}
.portlet.box.yellow > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow > .portlet-title > .actions .btn-default:active, .portlet.box.yellow > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fff7eb;
  color: white;
}

/* Statistic Block */
.dashboard-stat.yellow {
  background-color: #ffb848;
}
.dashboard-stat.yellow.dashboard-stat-light:hover {
  background-color: #ffb034;
}
.dashboard-stat.yellow .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow .details .number {
  color: white;
}
.dashboard-stat.yellow .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow .more {
  color: white;
  background-color: #ffb034;
}

/* General Element */
.bg-yellow {
  border-color: #ffb848 !important;
  background-image: none !important;
  background-color: #ffb848 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-yellow {
  color: #ffb848 !important;
}

/***
Yellow Gold
***/
/* Button */
.yellow-gold.btn {
  color: white;
  background-color: #e87e04;
  border-color: "";
}
.yellow-gold.btn:hover, .yellow-gold.btn:focus, .yellow-gold.btn:active, .yellow-gold.btn.active {
  color: white;
  background-color: #c06803;
}
.open .yellow-gold.btn.dropdown-toggle {
  color: white;
  background-color: #c06803;
}
.yellow-gold.btn:active, .yellow-gold.btn.active {
  background-image: none;
}
.open .yellow-gold.btn.dropdown-toggle {
  background-image: none;
}
.yellow-gold.btn.disabled, .yellow-gold.btn.disabled:hover, .yellow-gold.btn.disabled:focus, .yellow-gold.btn.disabled:active, .yellow-gold.btn.disabled.active, .yellow-gold.btn[disabled], .yellow-gold.btn[disabled]:hover, .yellow-gold.btn[disabled]:focus, .yellow-gold.btn[disabled]:active, .yellow-gold.btn[disabled].active, fieldset[disabled] .yellow-gold.btn, fieldset[disabled] .yellow-gold.btn:hover, fieldset[disabled] .yellow-gold.btn:focus, fieldset[disabled] .yellow-gold.btn:active, fieldset[disabled] .yellow-gold.btn.active {
  background-color: #e87e04;
}
.yellow-gold.btn .badge {
  color: #e87e04;
  background-color: white;
}

/* Button Strip */
.btn.yellow-gold-stripe {
  border-left: 3px solid #e87e04;
}

/* Portlet */
.portlet > .portlet-body.yellow-gold,
.portlet.yellow-gold {
  background-color: #e87e04;
}

.portlet.solid.yellow-gold > .portlet-title,
.portlet.solid.yellow-gold > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.yellow-gold > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.yellow-gold {
  border: 1px solid #fb9724;
  border-top: 0;
}
.portlet.box.yellow-gold > .portlet-title {
  background-color: #e87e04;
}
.portlet.box.yellow-gold > .portlet-title > .caption {
  color: white;
}
.portlet.box.yellow-gold > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.yellow-gold > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fcb460;
  color: #fdbf79;
}
.portlet.box.yellow-gold > .portlet-title > .actions .btn-default > i {
  color: #fdc788;
}
.portlet.box.yellow-gold > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdcb92;
  color: #fed7ab;
}

/* Statistic Block */
.dashboard-stat.yellow-gold {
  background-color: #e87e04;
}
.dashboard-stat.yellow-gold.dashboard-stat-light:hover {
  background-color: #d47304;
}
.dashboard-stat.yellow-gold .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-gold .details .number {
  color: white;
}
.dashboard-stat.yellow-gold .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-gold .more {
  color: white;
  background-color: #d47304;
}

/* General Element */
.bg-yellow-gold {
  border-color: #e87e04 !important;
  background-image: none !important;
  background-color: #e87e04 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-yellow-gold {
  color: #e87e04 !important;
}

/***
Yellow Casablanca
***/
/* Button */
.yellow-casablanca.btn {
  color: white;
  background-color: #f2784b;
  border-color: "";
}
.yellow-casablanca.btn:hover, .yellow-casablanca.btn:focus, .yellow-casablanca.btn:active, .yellow-casablanca.btn.active {
  color: white;
  background-color: #ef5b25;
}
.open .yellow-casablanca.btn.dropdown-toggle {
  color: white;
  background-color: #ef5b25;
}
.yellow-casablanca.btn:active, .yellow-casablanca.btn.active {
  background-image: none;
}
.open .yellow-casablanca.btn.dropdown-toggle {
  background-image: none;
}
.yellow-casablanca.btn.disabled, .yellow-casablanca.btn.disabled:hover, .yellow-casablanca.btn.disabled:focus, .yellow-casablanca.btn.disabled:active, .yellow-casablanca.btn.disabled.active, .yellow-casablanca.btn[disabled], .yellow-casablanca.btn[disabled]:hover, .yellow-casablanca.btn[disabled]:focus, .yellow-casablanca.btn[disabled]:active, .yellow-casablanca.btn[disabled].active, fieldset[disabled] .yellow-casablanca.btn, fieldset[disabled] .yellow-casablanca.btn:hover, fieldset[disabled] .yellow-casablanca.btn:focus, fieldset[disabled] .yellow-casablanca.btn:active, fieldset[disabled] .yellow-casablanca.btn.active {
  background-color: #f2784b;
}
.yellow-casablanca.btn .badge {
  color: #f2784b;
  background-color: white;
}

/* Button Strip */
.btn.yellow-casablanca-stripe {
  border-left: 3px solid #f2784b;
}

/* Portlet */
.portlet > .portlet-body.yellow-casablanca,
.portlet.yellow-casablanca {
  background-color: #f2784b;
}

.portlet.solid.yellow-casablanca > .portlet-title,
.portlet.solid.yellow-casablanca > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.yellow-casablanca > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.yellow-casablanca {
  border: 1px solid #f59c7b;
  border-top: 0;
}
.portlet.box.yellow-casablanca > .portlet-title {
  background-color: #f2784b;
}
.portlet.box.yellow-casablanca > .portlet-title > .caption {
  color: white;
}
.portlet.box.yellow-casablanca > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fac6b4;
  color: #fbd8cb;
}
.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default > i {
  color: #fce3da;
}
.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdeae3;
  color: #fffcfb;
}

/* Statistic Block */
.dashboard-stat.yellow-casablanca {
  background-color: #f2784b;
}
.dashboard-stat.yellow-casablanca.dashboard-stat-light:hover {
  background-color: #f16a38;
}
.dashboard-stat.yellow-casablanca .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-casablanca .details .number {
  color: white;
}
.dashboard-stat.yellow-casablanca .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-casablanca .more {
  color: white;
  background-color: #f16a38;
}

/* General Element */
.bg-yellow-casablanca {
  border-color: #f2784b !important;
  background-image: none !important;
  background-color: #f2784b !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-yellow-casablanca {
  color: #f2784b !important;
}

/***
Yellow Crusta
***/
/* Button */
.yellow-crusta.btn {
  color: white;
  background-color: #f3c200;
  border-color: "";
}
.yellow-crusta.btn:hover, .yellow-crusta.btn:focus, .yellow-crusta.btn:active, .yellow-crusta.btn.active {
  color: white;
  background-color: #caa100;
}
.open .yellow-crusta.btn.dropdown-toggle {
  color: white;
  background-color: #caa100;
}
.yellow-crusta.btn:active, .yellow-crusta.btn.active {
  background-image: none;
}
.open .yellow-crusta.btn.dropdown-toggle {
  background-image: none;
}
.yellow-crusta.btn.disabled, .yellow-crusta.btn.disabled:hover, .yellow-crusta.btn.disabled:focus, .yellow-crusta.btn.disabled:active, .yellow-crusta.btn.disabled.active, .yellow-crusta.btn[disabled], .yellow-crusta.btn[disabled]:hover, .yellow-crusta.btn[disabled]:focus, .yellow-crusta.btn[disabled]:active, .yellow-crusta.btn[disabled].active, fieldset[disabled] .yellow-crusta.btn, fieldset[disabled] .yellow-crusta.btn:hover, fieldset[disabled] .yellow-crusta.btn:focus, fieldset[disabled] .yellow-crusta.btn:active, fieldset[disabled] .yellow-crusta.btn.active {
  background-color: #f3c200;
}
.yellow-crusta.btn .badge {
  color: #f3c200;
  background-color: white;
}

/* Button Strip */
.btn.yellow-crusta-stripe {
  border-left: 3px solid #f3c200;
}

/* Portlet */
.portlet > .portlet-body.yellow-crusta,
.portlet.yellow-crusta {
  background-color: #f3c200;
}

.portlet.solid.yellow-crusta > .portlet-title,
.portlet.solid.yellow-crusta > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.yellow-crusta > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.yellow-crusta {
  border: 1px solid #ffd327;
  border-top: 0;
}
.portlet.box.yellow-crusta > .portlet-title {
  background-color: #f3c200;
}
.portlet.box.yellow-crusta > .portlet-title > .caption {
  color: white;
}
.portlet.box.yellow-crusta > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ffe064;
  color: #ffe57e;
}
.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default > i {
  color: #ffe88d;
}
.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ffea97;
  color: #ffefb1;
}

/* Statistic Block */
.dashboard-stat.yellow-crusta {
  background-color: #f3c200;
}
.dashboard-stat.yellow-crusta.dashboard-stat-light:hover {
  background-color: #dfb200;
}
.dashboard-stat.yellow-crusta .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-crusta .details .number {
  color: white;
}
.dashboard-stat.yellow-crusta .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-crusta .more {
  color: white;
  background-color: #dfb200;
}

/* General Element */
.bg-yellow-crusta {
  border-color: #f3c200 !important;
  background-image: none !important;
  background-color: #f3c200 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-yellow-crusta {
  color: #f3c200 !important;
}

/***
Yellow Lemon
***/
/* Button */
.yellow-lemon.btn {
  color: white;
  background-color: #f7ca18;
  border-color: "";
}
.yellow-lemon.btn:hover, .yellow-lemon.btn:focus, .yellow-lemon.btn:active, .yellow-lemon.btn.active {
  color: white;
  background-color: #deb308;
}
.open .yellow-lemon.btn.dropdown-toggle {
  color: white;
  background-color: #deb308;
}
.yellow-lemon.btn:active, .yellow-lemon.btn.active {
  background-image: none;
}
.open .yellow-lemon.btn.dropdown-toggle {
  background-image: none;
}
.yellow-lemon.btn.disabled, .yellow-lemon.btn.disabled:hover, .yellow-lemon.btn.disabled:focus, .yellow-lemon.btn.disabled:active, .yellow-lemon.btn.disabled.active, .yellow-lemon.btn[disabled], .yellow-lemon.btn[disabled]:hover, .yellow-lemon.btn[disabled]:focus, .yellow-lemon.btn[disabled]:active, .yellow-lemon.btn[disabled].active, fieldset[disabled] .yellow-lemon.btn, fieldset[disabled] .yellow-lemon.btn:hover, fieldset[disabled] .yellow-lemon.btn:focus, fieldset[disabled] .yellow-lemon.btn:active, fieldset[disabled] .yellow-lemon.btn.active {
  background-color: #f7ca18;
}
.yellow-lemon.btn .badge {
  color: #f7ca18;
  background-color: white;
}

/* Button Strip */
.btn.yellow-lemon-stripe {
  border-left: 3px solid #f7ca18;
}

/* Portlet */
.portlet > .portlet-body.yellow-lemon,
.portlet.yellow-lemon {
  background-color: #f7ca18;
}

.portlet.solid.yellow-lemon > .portlet-title,
.portlet.solid.yellow-lemon > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.yellow-lemon > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.yellow-lemon {
  border: 1px solid #f9d549;
  border-top: 0;
}
.portlet.box.yellow-lemon > .portlet-title {
  background-color: #f7ca18;
}
.portlet.box.yellow-lemon > .portlet-title > .caption {
  color: white;
}
.portlet.box.yellow-lemon > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fbe384;
  color: #fce99d;
}
.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default > i {
  color: #fcecac;
}
.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fceeb6;
  color: #fdf4ce;
}

/* Statistic Block */
.dashboard-stat.yellow-lemon {
  background-color: #f7ca18;
}
.dashboard-stat.yellow-lemon.dashboard-stat-light:hover {
  background-color: #f2c308;
}
.dashboard-stat.yellow-lemon .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-lemon .details .number {
  color: white;
}
.dashboard-stat.yellow-lemon .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-lemon .more {
  color: white;
  background-color: #f2c308;
}

/* General Element */
.bg-yellow-lemon {
  border-color: #f7ca18 !important;
  background-image: none !important;
  background-color: #f7ca18 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-yellow-lemon {
  color: #f7ca18 !important;
}

/***
Yellow Saffron
***/
/* Button */
.yellow-saffron.btn {
  color: white;
  background-color: #f4d03f;
  border-color: "";
}
.yellow-saffron.btn:hover, .yellow-saffron.btn:focus, .yellow-saffron.btn:active, .yellow-saffron.btn.active {
  color: white;
  background-color: #f2c718;
}
.open .yellow-saffron.btn.dropdown-toggle {
  color: white;
  background-color: #f2c718;
}
.yellow-saffron.btn:active, .yellow-saffron.btn.active {
  background-image: none;
}
.open .yellow-saffron.btn.dropdown-toggle {
  background-image: none;
}
.yellow-saffron.btn.disabled, .yellow-saffron.btn.disabled:hover, .yellow-saffron.btn.disabled:focus, .yellow-saffron.btn.disabled:active, .yellow-saffron.btn.disabled.active, .yellow-saffron.btn[disabled], .yellow-saffron.btn[disabled]:hover, .yellow-saffron.btn[disabled]:focus, .yellow-saffron.btn[disabled]:active, .yellow-saffron.btn[disabled].active, fieldset[disabled] .yellow-saffron.btn, fieldset[disabled] .yellow-saffron.btn:hover, fieldset[disabled] .yellow-saffron.btn:focus, fieldset[disabled] .yellow-saffron.btn:active, fieldset[disabled] .yellow-saffron.btn.active {
  background-color: #f4d03f;
}
.yellow-saffron.btn .badge {
  color: #f4d03f;
  background-color: white;
}

/* Button Strip */
.btn.yellow-saffron-stripe {
  border-left: 3px solid #f4d03f;
}

/* Portlet */
.portlet > .portlet-body.yellow-saffron,
.portlet.yellow-saffron {
  background-color: #f4d03f;
}

.portlet.solid.yellow-saffron > .portlet-title,
.portlet.solid.yellow-saffron > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.yellow-saffron > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.yellow-saffron {
  border: 1px solid #f7dc6f;
  border-top: 0;
}
.portlet.box.yellow-saffron > .portlet-title {
  background-color: #f4d03f;
}
.portlet.box.yellow-saffron > .portlet-title > .caption {
  color: white;
}
.portlet.box.yellow-saffron > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #faeaa9;
  color: #fbf0c1;
}
.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default > i {
  color: #fcf3d0;
}
.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdf6d9;
  color: #fefcf1;
}

/* Statistic Block */
.dashboard-stat.yellow-saffron {
  background-color: #f4d03f;
}
.dashboard-stat.yellow-saffron.dashboard-stat-light:hover {
  background-color: #f3cb2c;
}
.dashboard-stat.yellow-saffron .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-saffron .details .number {
  color: white;
}
.dashboard-stat.yellow-saffron .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-saffron .more {
  color: white;
  background-color: #f3cb2c;
}

/* General Element */
.bg-yellow-saffron {
  border-color: #f4d03f !important;
  background-image: none !important;
  background-color: #f4d03f !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-yellow-saffron {
  color: #f4d03f !important;
}

/***********
Purple Colors
************/
/***
Purple Default
***/
/* Button */
.purple.btn {
  color: white;
  background-color: #8e44ad;
  border-color: "";
}
.purple.btn:hover, .purple.btn:focus, .purple.btn:active, .purple.btn.active {
  color: white;
  background-color: #763890;
}
.open .purple.btn.dropdown-toggle {
  color: white;
  background-color: #763890;
}
.purple.btn:active, .purple.btn.active {
  background-image: none;
}
.open .purple.btn.dropdown-toggle {
  background-image: none;
}
.purple.btn.disabled, .purple.btn.disabled:hover, .purple.btn.disabled:focus, .purple.btn.disabled:active, .purple.btn.disabled.active, .purple.btn[disabled], .purple.btn[disabled]:hover, .purple.btn[disabled]:focus, .purple.btn[disabled]:active, .purple.btn[disabled].active, fieldset[disabled] .purple.btn, fieldset[disabled] .purple.btn:hover, fieldset[disabled] .purple.btn:focus, fieldset[disabled] .purple.btn:active, fieldset[disabled] .purple.btn.active {
  background-color: #8e44ad;
}
.purple.btn .badge {
  color: #8e44ad;
  background-color: white;
}

/* Button Strip */
.btn.purple-stripe {
  border-left: 3px solid #8e44ad;
}

/* Portlet */
.portlet > .portlet-body.purple,
.portlet.purple {
  background-color: #8e44ad;
}

.portlet.solid.purple > .portlet-title,
.portlet.solid.purple > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple {
  border: 1px solid #a563c1;
  border-top: 0;
}
.portlet.box.purple > .portlet-title {
  background-color: #8e44ad;
}
.portlet.box.purple > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}
.portlet.box.purple > .portlet-title > .actions .btn-default > i {
  color: #cfacde;
}
.portlet.box.purple > .portlet-title > .actions .btn-default:hover, .portlet.box.purple > .portlet-title > .actions .btn-default:focus, .portlet.box.purple > .portlet-title > .actions .btn-default:active, .portlet.box.purple > .portlet-title > .actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

/* Statistic Block */
.dashboard-stat.purple {
  background-color: #8e44ad;
}
.dashboard-stat.purple.dashboard-stat-light:hover {
  background-color: #823e9e;
}
.dashboard-stat.purple .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple .details .number {
  color: white;
}
.dashboard-stat.purple .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple .more {
  color: white;
  background-color: #823e9e;
}

/* General Element */
.bg-purple {
  border-color: #8e44ad !important;
  background-image: none !important;
  background-color: #8e44ad !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple {
  color: #8e44ad !important;
}

/***
Purple Plum
***/
/* Button */
.purple-plum.btn {
  color: white;
  background-color: #8775a7;
  border-color: "";
}
.purple-plum.btn:hover, .purple-plum.btn:focus, .purple-plum.btn:active, .purple-plum.btn.active {
  color: white;
  background-color: #725f95;
}
.open .purple-plum.btn.dropdown-toggle {
  color: white;
  background-color: #725f95;
}
.purple-plum.btn:active, .purple-plum.btn.active {
  background-image: none;
}
.open .purple-plum.btn.dropdown-toggle {
  background-image: none;
}
.purple-plum.btn.disabled, .purple-plum.btn.disabled:hover, .purple-plum.btn.disabled:focus, .purple-plum.btn.disabled:active, .purple-plum.btn.disabled.active, .purple-plum.btn[disabled], .purple-plum.btn[disabled]:hover, .purple-plum.btn[disabled]:focus, .purple-plum.btn[disabled]:active, .purple-plum.btn[disabled].active, fieldset[disabled] .purple-plum.btn, fieldset[disabled] .purple-plum.btn:hover, fieldset[disabled] .purple-plum.btn:focus, fieldset[disabled] .purple-plum.btn:active, fieldset[disabled] .purple-plum.btn.active {
  background-color: #8775a7;
}
.purple-plum.btn .badge {
  color: #8775a7;
  background-color: white;
}

/* Button Strip */
.btn.purple-plum-stripe {
  border-left: 3px solid #8775a7;
}

/* Portlet */
.portlet > .portlet-body.purple-plum,
.portlet.purple-plum {
  background-color: #8775a7;
}

.portlet.solid.purple-plum > .portlet-title,
.portlet.solid.purple-plum > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-plum > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-plum {
  border: 1px solid #a294bb;
  border-top: 0;
}
.portlet.box.purple-plum > .portlet-title {
  background-color: #8775a7;
}
.portlet.box.purple-plum > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-plum > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-plum > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}
.portlet.box.purple-plum > .portlet-title > .actions .btn-default > i {
  color: #d8d2e3;
}
.portlet.box.purple-plum > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-plum > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-plum > .portlet-title > .actions .btn-default:active, .portlet.box.purple-plum > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

/* Statistic Block */
.dashboard-stat.purple-plum {
  background-color: #8775a7;
}
.dashboard-stat.purple-plum.dashboard-stat-light:hover {
  background-color: #7c699f;
}
.dashboard-stat.purple-plum .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-plum .details .number {
  color: white;
}
.dashboard-stat.purple-plum .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-plum .more {
  color: white;
  background-color: #7c699f;
}

/* General Element */
.bg-purple-plum {
  border-color: #8775a7 !important;
  background-image: none !important;
  background-color: #8775a7 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-plum {
  color: #8775a7 !important;
}

/***
Purple Medium
***/
/* Button */
.purple-medium.btn {
  color: white;
  background-color: #bf55ec;
  border-color: "";
}
.purple-medium.btn:hover, .purple-medium.btn:focus, .purple-medium.btn:active, .purple-medium.btn.active {
  color: white;
  background-color: #b130e8;
}
.open .purple-medium.btn.dropdown-toggle {
  color: white;
  background-color: #b130e8;
}
.purple-medium.btn:active, .purple-medium.btn.active {
  background-image: none;
}
.open .purple-medium.btn.dropdown-toggle {
  background-image: none;
}
.purple-medium.btn.disabled, .purple-medium.btn.disabled:hover, .purple-medium.btn.disabled:focus, .purple-medium.btn.disabled:active, .purple-medium.btn.disabled.active, .purple-medium.btn[disabled], .purple-medium.btn[disabled]:hover, .purple-medium.btn[disabled]:focus, .purple-medium.btn[disabled]:active, .purple-medium.btn[disabled].active, fieldset[disabled] .purple-medium.btn, fieldset[disabled] .purple-medium.btn:hover, fieldset[disabled] .purple-medium.btn:focus, fieldset[disabled] .purple-medium.btn:active, fieldset[disabled] .purple-medium.btn.active {
  background-color: #bf55ec;
}
.purple-medium.btn .badge {
  color: #bf55ec;
  background-color: white;
}

/* Button Strip */
.btn.purple-medium-stripe {
  border-left: 3px solid #bf55ec;
}

/* Portlet */
.portlet > .portlet-body.purple-medium,
.portlet.purple-medium {
  background-color: #bf55ec;
}

.portlet.solid.purple-medium > .portlet-title,
.portlet.solid.purple-medium > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-medium > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-medium {
  border: 1px solid #d083f1;
  border-top: 0;
}
.portlet.box.purple-medium > .portlet-title {
  background-color: #bf55ec;
}
.portlet.box.purple-medium > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-medium > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-medium > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e5baf7;
  color: #eed1fa;
}
.portlet.box.purple-medium > .portlet-title > .actions .btn-default > i {
  color: #f3dffb;
}
.portlet.box.purple-medium > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-medium > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-medium > .portlet-title > .actions .btn-default:active, .portlet.box.purple-medium > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f6e8fc;
  color: white;
}

/* Statistic Block */
.dashboard-stat.purple-medium {
  background-color: #bf55ec;
}
.dashboard-stat.purple-medium.dashboard-stat-light:hover {
  background-color: #b843ea;
}
.dashboard-stat.purple-medium .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-medium .details .number {
  color: white;
}
.dashboard-stat.purple-medium .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-medium .more {
  color: white;
  background-color: #b843ea;
}

/* General Element */
.bg-purple-medium {
  border-color: #bf55ec !important;
  background-image: none !important;
  background-color: #bf55ec !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-medium {
  color: #bf55ec !important;
}

/***
Purple Studio
***/
/* Button */
.purple-studio.btn {
  color: white;
  background-color: #8e44ad;
  border-color: "";
}
.purple-studio.btn:hover, .purple-studio.btn:focus, .purple-studio.btn:active, .purple-studio.btn.active {
  color: white;
  background-color: #763890;
}
.open .purple-studio.btn.dropdown-toggle {
  color: white;
  background-color: #763890;
}
.purple-studio.btn:active, .purple-studio.btn.active {
  background-image: none;
}
.open .purple-studio.btn.dropdown-toggle {
  background-image: none;
}
.purple-studio.btn.disabled, .purple-studio.btn.disabled:hover, .purple-studio.btn.disabled:focus, .purple-studio.btn.disabled:active, .purple-studio.btn.disabled.active, .purple-studio.btn[disabled], .purple-studio.btn[disabled]:hover, .purple-studio.btn[disabled]:focus, .purple-studio.btn[disabled]:active, .purple-studio.btn[disabled].active, fieldset[disabled] .purple-studio.btn, fieldset[disabled] .purple-studio.btn:hover, fieldset[disabled] .purple-studio.btn:focus, fieldset[disabled] .purple-studio.btn:active, fieldset[disabled] .purple-studio.btn.active {
  background-color: #8e44ad;
}
.purple-studio.btn .badge {
  color: #8e44ad;
  background-color: white;
}

/* Button Strip */
.btn.purple-studio-stripe {
  border-left: 3px solid #8e44ad;
}

/* Portlet */
.portlet > .portlet-body.purple-studio,
.portlet.purple-studio {
  background-color: #8e44ad;
}

.portlet.solid.purple-studio > .portlet-title,
.portlet.solid.purple-studio > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-studio > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-studio {
  border: 1px solid #a563c1;
  border-top: 0;
}
.portlet.box.purple-studio > .portlet-title {
  background-color: #8e44ad;
}
.portlet.box.purple-studio > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-studio > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-studio > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}
.portlet.box.purple-studio > .portlet-title > .actions .btn-default > i {
  color: #cfacde;
}
.portlet.box.purple-studio > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-studio > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-studio > .portlet-title > .actions .btn-default:active, .portlet.box.purple-studio > .portlet-title > .actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

/* Statistic Block */
.dashboard-stat.purple-studio {
  background-color: #8e44ad;
}
.dashboard-stat.purple-studio.dashboard-stat-light:hover {
  background-color: #823e9e;
}
.dashboard-stat.purple-studio .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-studio .details .number {
  color: white;
}
.dashboard-stat.purple-studio .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-studio .more {
  color: white;
  background-color: #823e9e;
}

/* General Element */
.bg-purple-studio {
  border-color: #8e44ad !important;
  background-image: none !important;
  background-color: #8e44ad !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-studio {
  color: #8e44ad !important;
}

/***
Purple Wisteria
***/
/* Button */
.purple-wisteria.btn {
  color: white;
  background-color: #9b59b6;
  border-color: "";
}
.purple-wisteria.btn:hover, .purple-wisteria.btn:focus, .purple-wisteria.btn:active, .purple-wisteria.btn.active {
  color: white;
  background-color: #8646a0;
}
.open .purple-wisteria.btn.dropdown-toggle {
  color: white;
  background-color: #8646a0;
}
.purple-wisteria.btn:active, .purple-wisteria.btn.active {
  background-image: none;
}
.open .purple-wisteria.btn.dropdown-toggle {
  background-image: none;
}
.purple-wisteria.btn.disabled, .purple-wisteria.btn.disabled:hover, .purple-wisteria.btn.disabled:focus, .purple-wisteria.btn.disabled:active, .purple-wisteria.btn.disabled.active, .purple-wisteria.btn[disabled], .purple-wisteria.btn[disabled]:hover, .purple-wisteria.btn[disabled]:focus, .purple-wisteria.btn[disabled]:active, .purple-wisteria.btn[disabled].active, fieldset[disabled] .purple-wisteria.btn, fieldset[disabled] .purple-wisteria.btn:hover, fieldset[disabled] .purple-wisteria.btn:focus, fieldset[disabled] .purple-wisteria.btn:active, fieldset[disabled] .purple-wisteria.btn.active {
  background-color: #9b59b6;
}
.purple-wisteria.btn .badge {
  color: #9b59b6;
  background-color: white;
}

/* Button Strip */
.btn.purple-wisteria-stripe {
  border-left: 3px solid #9b59b6;
}

/* Portlet */
.portlet > .portlet-body.purple-wisteria,
.portlet.purple-wisteria {
  background-color: #9b59b6;
}

.portlet.solid.purple-wisteria > .portlet-title,
.portlet.solid.purple-wisteria > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-wisteria > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-wisteria {
  border: 1px solid #b07cc6;
  border-top: 0;
}
.portlet.box.purple-wisteria > .portlet-title {
  background-color: #9b59b6;
}
.portlet.box.purple-wisteria > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-wisteria > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #caa7d8;
  color: #d5b9e0;
}
.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default > i {
  color: #dbc3e5;
}
.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:active, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dfcae8;
  color: #eadcf0;
}

/* Statistic Block */
.dashboard-stat.purple-wisteria {
  background-color: #9b59b6;
}
.dashboard-stat.purple-wisteria.dashboard-stat-light:hover {
  background-color: #924dae;
}
.dashboard-stat.purple-wisteria .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-wisteria .details .number {
  color: white;
}
.dashboard-stat.purple-wisteria .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-wisteria .more {
  color: white;
  background-color: #924dae;
}

/* General Element */
.bg-purple-wisteria {
  border-color: #9b59b6 !important;
  background-image: none !important;
  background-color: #9b59b6 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-wisteria {
  color: #9b59b6 !important;
}

/***
Purple Seance
***/
/* Button */
.purple-seance.btn {
  color: white;
  background-color: #9a12b3;
  border-color: "";
}
.purple-seance.btn:hover, .purple-seance.btn:focus, .purple-seance.btn:active, .purple-seance.btn.active {
  color: white;
  background-color: #7a0e8e;
}
.open .purple-seance.btn.dropdown-toggle {
  color: white;
  background-color: #7a0e8e;
}
.purple-seance.btn:active, .purple-seance.btn.active {
  background-image: none;
}
.open .purple-seance.btn.dropdown-toggle {
  background-image: none;
}
.purple-seance.btn.disabled, .purple-seance.btn.disabled:hover, .purple-seance.btn.disabled:focus, .purple-seance.btn.disabled:active, .purple-seance.btn.disabled.active, .purple-seance.btn[disabled], .purple-seance.btn[disabled]:hover, .purple-seance.btn[disabled]:focus, .purple-seance.btn[disabled]:active, .purple-seance.btn[disabled].active, fieldset[disabled] .purple-seance.btn, fieldset[disabled] .purple-seance.btn:hover, fieldset[disabled] .purple-seance.btn:focus, fieldset[disabled] .purple-seance.btn:active, fieldset[disabled] .purple-seance.btn.active {
  background-color: #9a12b3;
}
.purple-seance.btn .badge {
  color: #9a12b3;
  background-color: white;
}

/* Button Strip */
.btn.purple-seance-stripe {
  border-left: 3px solid #9a12b3;
}

/* Portlet */
.portlet > .portlet-body.purple-seance,
.portlet.purple-seance {
  background-color: #9a12b3;
}

.portlet.solid.purple-seance > .portlet-title,
.portlet.solid.purple-seance > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-seance > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-seance {
  border: 1px solid #c217e1;
  border-top: 0;
}
.portlet.box.purple-seance > .portlet-title {
  background-color: #9a12b3;
}
.portlet.box.purple-seance > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-seance > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-seance > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #d349ed;
  color: #d960ef;
}
.portlet.box.purple-seance > .portlet-title > .actions .btn-default > i {
  color: #dc6ef0;
}
.portlet.box.purple-seance > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-seance > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-seance > .portlet-title > .actions .btn-default:active, .portlet.box.purple-seance > .portlet-title > .actions .btn-default.active {
  border: 1px solid #de77f1;
  color: #e48ef4;
}

/* Statistic Block */
.dashboard-stat.purple-seance {
  background-color: #9a12b3;
}
.dashboard-stat.purple-seance.dashboard-stat-light:hover {
  background-color: #8a10a0;
}
.dashboard-stat.purple-seance .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-seance .details .number {
  color: white;
}
.dashboard-stat.purple-seance .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-seance .more {
  color: white;
  background-color: #8a10a0;
}

/* General Element */
.bg-purple-seance {
  border-color: #9a12b3 !important;
  background-image: none !important;
  background-color: #9a12b3 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-seance {
  color: #9a12b3 !important;
}

/***
Purple Intense
***/
/* Button */
.purple-intense.btn {
  color: white;
  background-color: #8775a7;
  border-color: "";
}
.purple-intense.btn:hover, .purple-intense.btn:focus, .purple-intense.btn:active, .purple-intense.btn.active {
  color: white;
  background-color: #725f95;
}
.open .purple-intense.btn.dropdown-toggle {
  color: white;
  background-color: #725f95;
}
.purple-intense.btn:active, .purple-intense.btn.active {
  background-image: none;
}
.open .purple-intense.btn.dropdown-toggle {
  background-image: none;
}
.purple-intense.btn.disabled, .purple-intense.btn.disabled:hover, .purple-intense.btn.disabled:focus, .purple-intense.btn.disabled:active, .purple-intense.btn.disabled.active, .purple-intense.btn[disabled], .purple-intense.btn[disabled]:hover, .purple-intense.btn[disabled]:focus, .purple-intense.btn[disabled]:active, .purple-intense.btn[disabled].active, fieldset[disabled] .purple-intense.btn, fieldset[disabled] .purple-intense.btn:hover, fieldset[disabled] .purple-intense.btn:focus, fieldset[disabled] .purple-intense.btn:active, fieldset[disabled] .purple-intense.btn.active {
  background-color: #8775a7;
}
.purple-intense.btn .badge {
  color: #8775a7;
  background-color: white;
}

/* Button Strip */
.btn.purple-intense-stripe {
  border-left: 3px solid #8775a7;
}

/* Portlet */
.portlet > .portlet-body.purple-intense,
.portlet.purple-intense {
  background-color: #8775a7;
}

.portlet.solid.purple-intense > .portlet-title,
.portlet.solid.purple-intense > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-intense > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-intense {
  border: 1px solid #a294bb;
  border-top: 0;
}
.portlet.box.purple-intense > .portlet-title {
  background-color: #8775a7;
}
.portlet.box.purple-intense > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-intense > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-intense > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}
.portlet.box.purple-intense > .portlet-title > .actions .btn-default > i {
  color: #d8d2e3;
}
.portlet.box.purple-intense > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-intense > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-intense > .portlet-title > .actions .btn-default:active, .portlet.box.purple-intense > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

/* Statistic Block */
.dashboard-stat.purple-intense {
  background-color: #8775a7;
}
.dashboard-stat.purple-intense.dashboard-stat-light:hover {
  background-color: #7c699f;
}
.dashboard-stat.purple-intense .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-intense .details .number {
  color: white;
}
.dashboard-stat.purple-intense .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-intense .more {
  color: white;
  background-color: #7c699f;
}

/* General Element */
.bg-purple-intense {
  border-color: #8775a7 !important;
  background-image: none !important;
  background-color: #8775a7 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-intense {
  color: #8775a7 !important;
}

/***
Purple Sharp
***/
/* Button */
.purple-sharp.btn {
  color: white;
  background-color: #796799;
  border-color: "";
}
.purple-sharp.btn:hover, .purple-sharp.btn:focus, .purple-sharp.btn:active, .purple-sharp.btn.active {
  color: white;
  background-color: #665681;
}
.open .purple-sharp.btn.dropdown-toggle {
  color: white;
  background-color: #665681;
}
.purple-sharp.btn:active, .purple-sharp.btn.active {
  background-image: none;
}
.open .purple-sharp.btn.dropdown-toggle {
  background-image: none;
}
.purple-sharp.btn.disabled, .purple-sharp.btn.disabled:hover, .purple-sharp.btn.disabled:focus, .purple-sharp.btn.disabled:active, .purple-sharp.btn.disabled.active, .purple-sharp.btn[disabled], .purple-sharp.btn[disabled]:hover, .purple-sharp.btn[disabled]:focus, .purple-sharp.btn[disabled]:active, .purple-sharp.btn[disabled].active, fieldset[disabled] .purple-sharp.btn, fieldset[disabled] .purple-sharp.btn:hover, fieldset[disabled] .purple-sharp.btn:focus, fieldset[disabled] .purple-sharp.btn:active, fieldset[disabled] .purple-sharp.btn.active {
  background-color: #796799;
}
.purple-sharp.btn .badge {
  color: #796799;
  background-color: white;
}

/* Button Strip */
.btn.purple-sharp-stripe {
  border-left: 3px solid #796799;
}

/* Portlet */
.portlet > .portlet-body.purple-sharp,
.portlet.purple-sharp {
  background-color: #796799;
}

.portlet.solid.purple-sharp > .portlet-title,
.portlet.solid.purple-sharp > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-sharp > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-sharp {
  border: 1px solid #9486ad;
  border-top: 0;
}
.portlet.box.purple-sharp > .portlet-title {
  background-color: #796799;
}
.portlet.box.purple-sharp > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-sharp > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4aac6;
  color: #c2b9d0;
}
.portlet.box.purple-sharp > .portlet-title > .actions .btn-default > i {
  color: #cac3d6;
}
.portlet.box.purple-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cfc9db;
  color: #ddd8e5;
}

/* Statistic Block */
.dashboard-stat.purple-sharp {
  background-color: #796799;
}
.dashboard-stat.purple-sharp.dashboard-stat-light:hover {
  background-color: #6f5f8d;
}
.dashboard-stat.purple-sharp .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-sharp .details .number {
  color: white;
}
.dashboard-stat.purple-sharp .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-sharp .more {
  color: white;
  background-color: #6f5f8d;
}

/* General Element */
.bg-purple-sharp {
  border-color: #796799 !important;
  background-image: none !important;
  background-color: #796799 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-sharp {
  color: #796799 !important;
}

/***
Purple Soft
***/
/* Button */
.purple-soft.btn {
  color: white;
  background-color: #8877a9;
  border-color: "";
}
.purple-soft.btn:hover, .purple-soft.btn:focus, .purple-soft.btn:active, .purple-soft.btn.active {
  color: white;
  background-color: #736097;
}
.open .purple-soft.btn.dropdown-toggle {
  color: white;
  background-color: #736097;
}
.purple-soft.btn:active, .purple-soft.btn.active {
  background-image: none;
}
.open .purple-soft.btn.dropdown-toggle {
  background-image: none;
}
.purple-soft.btn.disabled, .purple-soft.btn.disabled:hover, .purple-soft.btn.disabled:focus, .purple-soft.btn.disabled:active, .purple-soft.btn.disabled.active, .purple-soft.btn[disabled], .purple-soft.btn[disabled]:hover, .purple-soft.btn[disabled]:focus, .purple-soft.btn[disabled]:active, .purple-soft.btn[disabled].active, fieldset[disabled] .purple-soft.btn, fieldset[disabled] .purple-soft.btn:hover, fieldset[disabled] .purple-soft.btn:focus, fieldset[disabled] .purple-soft.btn:active, fieldset[disabled] .purple-soft.btn.active {
  background-color: #8877a9;
}
.purple-soft.btn .badge {
  color: #8877a9;
  background-color: white;
}

/* Button Strip */
.btn.purple-soft-stripe {
  border-left: 3px solid #8877a9;
}

/* Portlet */
.portlet > .portlet-body.purple-soft,
.portlet.purple-soft {
  background-color: #8877a9;
}

.portlet.solid.purple-soft > .portlet-title,
.portlet.solid.purple-soft > .portlet-body {
  border: 0;
  color: white;
}
.portlet.solid.purple-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.remove {
  background-image: url(../images/img3/portlet-remove-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.config {
  background-image: url(../images/img3/portlet-config-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.reload {
  background-image: url(../images/img3/portlet-reload-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.expand {
  background-image: url(../images/img3/portlet-expand-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.collapse {
  background-image: url(../images/img3/portlet-collapse-icon-white.png);
}

.portlet.box.purple-soft {
  border: 1px solid #a396bd;
  border-top: 0;
}
.portlet.box.purple-soft > .portlet-title {
  background-color: #8877a9;
}
.portlet.box.purple-soft > .portlet-title > .caption {
  color: white;
}
.portlet.box.purple-soft > .portlet-title > .caption > i {
  color: white;
}
.portlet.box.purple-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c4bcd4;
  color: #d2cbde;
}
.portlet.box.purple-soft > .portlet-title > .actions .btn-default > i {
  color: #dad5e4;
}
.portlet.box.purple-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-soft > .portlet-title > .actions .btn-default:active, .portlet.box.purple-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dfdbe8;
  color: #edebf2;
}

/* Statistic Block */
.dashboard-stat.purple-soft {
  background-color: #8877a9;
}
.dashboard-stat.purple-soft.dashboard-stat-light:hover {
  background-color: #7d6ba1;
}
.dashboard-stat.purple-soft .visual > i {
  color: white;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-soft .details .number {
  color: white;
}
.dashboard-stat.purple-soft .details .desc {
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-soft .more {
  color: white;
  background-color: #7d6ba1;
}

/* General Element */
.bg-purple-soft {
  border-color: #8877a9 !important;
  background-image: none !important;
  background-color: #8877a9 !important;
  color: white !important;
}

/* General Text & Icon Font Colors */
.font-purple-soft {
  color: #8877a9 !important;
}

/***
Transparent Button
***/
.btn-transparent.btn {
  background: transparent;
  color: #888;
}
