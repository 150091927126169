.directory {
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    align-items: start;
    padding:0;
    list-style:none;
    row-gap:25px;
    column-gap:15px;

    &__education {
        margin-top: 1rem;

        ul {
            padding: 0;
            list-style: none;
        }
    }

    &__description {
        margin-top:1rem;
    }
    

    &__entry {
        display: flex;
        flex-wrap: wrap;
    }
    &__name {
        color: #036;
        font-weight: 700;
        font-family:bebas neue, Raleway, arial;
        font-size:1.7rem;
        margin-bottom:.5rem;
        text-decoration:none;
        line-height:1;

        a {
            color: #036;
            font-weight: 700;

            &:hover, 
            &:focus {
                color: #3076b3;
            }
        }
     
    }
    

    &__info {
        font-family:raleway, arial, sans-serif;
        flex: 1 1 100%;
    }
    &__title {
        text-align: left;
        font-family: Raleway,Arial,sans-serif;
        color: #444;
        margin-bottom:1rem;
        font-weight: bold;
        color: #707070;
    }
    &__location {
        margin-top:7px;
        display:flex;
        align-items:flex-start;
        span {
            font-size:1rem;
            padding-top:3px;
            padding-right:3px;
        }
    }
    &__photo {
        img {
            width:100%;
            max-width:100%;
            height:auto;
        }
    }
    &__phone {
        display:flex;
        align-items:flex-start;
        span {
            font-size:1rem;
            padding-top:3px;
            padding-right:3px;
        }
    }
    &__email {
        display:flex;
        align-items:flex-start;
        span {
            font-size:1rem;
            padding-top:3px;
            padding-right:3px;
        }
    }


}

.directory--full {
    display:flex;
    flex-wrap:wrap;

    .directory__heading {
        flex:0 0 100%;
    }
    .directory__name {
        font-size:2.5rem;
    }

    .directory__wrap {
        display:flex;
        flex-wrap:wrap;
        @media (min-width: @screen-sm-min) {
            flex-wrap:nowrap;
        }
    }
    .directory__photo {
        flex:0 0 185px;
        margin-right:1rem;
    }
    .directory__info {
        flex: 1 1 auto;
    }


    
}

