.triptych {
	.make-row();
}
.triptych__text {
		font-family:Arial, sans-serif;
		font-size:1rem!important;
		line-height:1.5;
	}

.triptych__panel {
		.make-xs-column(12);
		.make-sm-column(4);
		margin-bottom:2rem;
		@media (min-width: @screen-sm-min) {
			margin-bottom:0;
		}
	}

.triptych__heading {
	font-family:Raleway, Arial, sans-serif;
	font-weight:500;
	margin-top:20px;
	color:#333;
	margin:20px 10px;
	@media (min-width: @screen-sm-min) {
	margin:10px 10px;
	}
}
