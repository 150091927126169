.loading {
    opacity: 0;
    transition: all .25s ease-in;
    min-height: 300px;
}
.loaded {
    opacity: 1;
    transition: all .25s ease-in;
}
canvas {
    display: inline-block;
    margin-bottom: 30px;}
