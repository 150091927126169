.links {
    ul {padding-left:0;}
    li {
      margin-bottom:.6rem;
    }
    // a {
    //   &:after {
    //     content: " >>"
    //   }
    //   color: #003398;
    // }
    padding:0 1rem 1rem 1rem;
    margin-left:0;
    list-style:none;
  }