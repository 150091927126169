.event {
    display: flex;
    text-align: left;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 25px 0 25px 0;

    a {
        display:block;
    }

    &__date {
        font-family: 'bebas Neue', Arial, sans-serif;
        text-align: center;
        flex: 0 0 68px;
        color: #002265;
    }

    &__info {
        margin: 0px 0 0 25px;
        flex: 1 1 auto;
        font-family: 'Raleway', Arial, sans-serif;
    }

    &__day {
        font-size: 4.5rem;
        line-height: 1;
    }

    &__month {
        font-size: 2rem;
        line-height: 1;
    }

    &__title {
        font-weight: bold;
        color: #002265;
    }
}