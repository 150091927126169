.figure {
	position:relative;

	&__caption {
		position:absolute;
		bottom:0;
		font-size:1rem;
		color:white!important;
		background-color:rgba(51,51,51,0.8);
		padding:5px 10px 5px 10px;
		text-decoration:none;
		width:100%;
		font-style:normal;
		text-align:left;
		font-family:raleway,arial,sans-serif;
		a {
			color:white;
			&:hover {
				color:white;
			};
		}
	}
}

