.people {
    justify-content:center;

    &--left {
       justify-content:flex-start; 
    }
    .person {
        margin-bottom:25px;
        
    }

    &--1 {
        .person {
        flex: 0 1 100%;
        }
    }
    
    &--3 {
        .person {
            flex: 0 1 33%;
        }
    }

    &--4 {
        .person {
            flex: 0 25%;
        }
    }
}

.person {
    display:flex;
    max-width:100%;
    flex: 1 1 auto;

    &--half {
        flex:1 1 45%;
    }

    &__photo {
        flex:0 0 130px;
        margin-right:15px;

        img {
            width:100%;
            height:auto;
        }
    }

    &__info {
        flex:1 1 auto;
        text-align:left;
        font-family: Raleway,Arial,sans-serif;
        color:#444;
    }

    &__education {
        margin-top:7px;
    }

    &__cv {
        &::before {
            content: '\f15b';
            font-family: 'Font Awesome 5 Free';
            margin-right: 5px;
            font-weight:900;
        }

        a {
            color:#003366;
            font-weight:bold; 

            &:hover, 
            &:focus {
                color: #3076b3;
            }
        }
    }

    &__name {
        color:#003366;
        font-weight:bold;

        a {
            color:#003366;
            font-weight:bold; 

            &:hover,
            &focus  {
                color: #3076b3;
            }
        }
    }

    &__contact {
        margin-top:10px;
    }

    &__phone {
        display:flex;
        &::before {
            content: '\f095';
            font-family: 'Font Awesome 5 Free';
            margin-right: 5px;
            font-weight:900;
        }
    }

    &__email {
        display:flex;
        &::before {
            content: '\f0e0';
            font-family: 'Font Awesome 5 Free';
            margin-right: 5px;
            font-weight:900;
        }

        a {
            color:#003366;
            font-weight:bold; 

            &:hover, 
            &:focus {
                color: #3076b3;
            }
        }
    }

    &__location {
        display:flex;
        margin-top:7px;
        &::before {
            content: '\f1ad';
            font-family: 'Font Awesome 5 Free';
            margin-right: 5px;
            font-weight:900;
        }
    }


}