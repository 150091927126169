.link-grid {
	display:flex;
	flex-wrap:wrap;
	align-content:center;
	position:relative;
	margin:0;
	padding:0; 
	&__item {
		flex:0 0 50%;
		list-style:none;
		padding:5px;
		max-height:180px;
		@media (min-width: @screen-sm-min) {
		flex:0 0 25%;	
		}

	}
	&__inner {
		position:relative;
		display:block;
		width:100%;
		height:100%;
	}
	&__image {
		width: 100%;
		height: 100%!important;
		position:relative;
		object-fit:cover;
	}
	&__link {
		display:block;
		background-color: rgba(51,51,51,0.6);
		text-decoration:none!important;
		height:100%;
		&:after {
			content:"";
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			background-color:rgba(51,51,51,0.7;);
			z-index:0;
		}
		&:hover {
			transition: all .25s ease-in; 
			&:after {
				background-color:lighten(rgba(51,51,51,0.7), 20%);
				transition: all .25s ease; 
			}
		}

	}
	&__link-text {
		text-decoration:none;
		color:white;
		font-family:raleway,arial,sans-serif;
		font-size:1.6rem;
		right: 0;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		z-index:1;
	}



}