.event-calendar {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    text-align: left;
    justify-content:space-evenly;
    padding-left:0;

    @media (min-width: @screen-sm-min) {
        max-height: 450px;
        flex-wrap: wrap;
    }

    &__event {
        flex: 0 0 auto;
        height: 100px;
    
        @media (min-width: @screen-sm-min) {
            width: 50%;
        }
    }


}
