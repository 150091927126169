.service {
    flex: 1 1 auto;
    width: 370px;
    height: 330px;
    border-left: #036 solid 10px;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
    text-align:left;
    position:relative;

    &__wrapper {
        padding: 2rem 2rem 0.7rem 2rem;
    }

    &__title {
        font-family:'Raleway', arial, sans-serif;
        font-size: 1.7rem;
        font-weight: 400;
        color:#002265;
        margin: 1.2rem 0!important;
    }

    &__logo {
       // width: 100%;
       // max-height: 30%;
       // width: auto;
       // max-height: 100px;
        //height:100px;
        height: 65px;
        width: 100%;

        img {
            max-height: 65px;
            max-width: 100%;
            width: auto;
        }
    }

    &__title {
        font-family: 'Raleway', Arial, sans-serif;
        font-weight: 500;
        color: #002265;

    }

    &__description {    
        font-family: 'Raleway', Arial, san-serif;
        font-size: 1.1rem;   
        max-height:300px;
        height:80px;

    }
    &__link {
        padding-left:2rem;
    }
    &__link a {
        .button-variant(#ffffff; #002265; #002265);
        .button-size(0.6rem; 1rem; 1.4rem; @line-height-base; @btn-border-radius-base);
    
        font-family: raleway, arial, sans-serif;
        font-weight: 300;
        text-decoration: none !important;
        text-align: center;
        transition: all .15s ease-in;
        display: inline-block;
        text-align: right;
        float: right;
        text-decoration: none!important;
        border: 2px solid;
        border-color: #036;
        border-right:none;

        &::after {
            content: '\f054';
            font-family: 'Font Awesome 5 Free';
            margin-left: 5px;
            font-weight:900;
        }
        
        &:focus,
        &:hover {
            background-color: #fff;
            border-color: #036;
            color: #036;
            font-weight:500;
        }
    }
}