.flex-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    padding-left: 0;


    @media (min-width: @screen-sm-min) {
        justify-content:space-around;
    }

    @media (min-width: @screen-lg-min) {
        justify-content:flex-start;
        margin: 0px -7px 0 -7px;
    }
}

.flex-list__item {
    flex: 0 1 auto;
    margin-bottom: 1.3rem;
    margin:7px;

}