.cta {
  padding: 1rem 0;

  &--centered {
    text-align: center;
  }

  &--row-3 {
    display:flex;
    flex-wrap:wrap;
    
    .cta__button {
      font-size:1rem;
      flex-basis:100%;
    }
    @media (min-width: @screen-sm-min) {
      flex-wrap:nowrap;
      .cta__button {
        flex-basis:33%;
      }

    }
  }


}

.cta--padded {
  margin-top: 3rem;
}

.cta__button {
  .button-variant(#ffffff; #002265; #002265);
  .button-size(0.5em; 2.5em; 1.4rem; @line-height-base; @btn-border-radius-base);
  //.cta--vertical.cta__button();
  display:block;
  margin:8px auto;
  font-family: raleway, arial, sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  text-decoration: none !important;
  text-align: center;
  transition:all .15s ease-in;
  border: #002265 2px solid;

  @media (min-width: @screen-sm-min) {
    display: inline-block;
    font-size: 1.8rem;
    margin: 0.2rem 0.2rem;
  }

  &:focus,
  &:hover {
    background-color: #fff;
    border: #036 2px solid;
    color: #036;
  }
}

.cta__button--smaller {
  font-size:1rem;
  @media (min-width: @screen-sm-min) {
    font-size: 1.4rem;
  }
}

.cta__button--short {
  margin:8px 10%;
}

.cta--vertical {
  .cta__button {
    display: block;
    margin: 4px auto;
    margin-bottom: 10px;
  }
}

.cta__button--dark {
  .button-variant(#ffffff; #002265; #002265);
  .button-size(0.5em; 1.5em; 1.4rem; @line-height-base; 10px);

  border: 2px solid #fff;
  border-radius: 10px !important;

  @media (min-width: @screen-sm-min) {
    font-size: 2rem;
  }
}

.cta__button--fixed {
  width: 300px;
}

.cta__button--clear {
  .button-variant(#ffffff; rgba(0, 0, 0, 0); #ffffff);
  .button-size(0.5em; 0.7em; 1.3rem; @line-height-base; @btn-border-radius-base);

  border: 3px solid #fff;
  font-weight:600;

  &::after {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    margin-left: 5px;
    font-weight:900;
  }

  &:focus,
  &:hover {
    background-color: #fff;
    border: 3px solid #fff;
    color: #036;
  }
}
