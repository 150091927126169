@import "../css/bootstrap.min.css";
@import "../css/components.css";
@import "../css/style.css";
@import "../css/style-responsive.css";
@import "../css/blue.css";
@import "../css/custom.css";
@import "../css/wysiwyg.css";
.clearfix:before,
.clearfix:after,
.page-section__box:before,
.page-section__box:after,
.page-section__fluid-box:before,
.page-section__fluid-box:after,
.page-section__row:before,
.page-section__row:after,
.triptych:before,
.triptych:after {
  display: table;
  content: " ";
}
.clearfix:after,
.page-section__box:after,
.page-section__fluid-box:after,
.page-section__row:after,
.triptych:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
/* section block */
.page-section {
  width: 100%;
  padding: 2rem 0;
  font-family: 'Raleway', Arial, sans-serif;
}
@media (min-width: 768px) {
  .page-section {
    padding: 5rem 0;
  }
}
.page-section img {
  width: 100%;
  height: auto;
}
.page-section .address {
  margin: 2rem 0 1.5rem 0;
}
.page-section--top {
  padding-top: 0;
}
@media (min-width: 768px) {
  .page-section--top {
    padding-top: 0;
  }
}
.page-section--collapse {
  padding: 0 0;
}
.page-section--raleway {
  font-family: 'Raleway', Arial, sans-serif;
}
.page-section--blue {
  background-color: #002265;
  color: white;
}
@media (min-width: 768px) {
}
.page-section--blue a {
  color: white;
}
.page-section--beige {
  background-color: #f9f1e1;
}
.page-section--lightgrey {
  background-color: #EFEFEF;
}
.page-section--center {
  text-align: center;
}
@media (min-width: 768px) {
  .page-section--fixed {
    background-attachment: fixed;
  }
}
.page-section--background-1 {
  background: url('https://grayson.edu/sunbackground.jpg');
  background-size: cover;
  background-position: center;
  padding-bottom: 3rem;
  color: #FFF;
  position: relative;
}
@media (min-width: 768px) {
}
.page-section--background-1:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.7);
  z-index: 0;
}
.page-section--background-2 {
  background-image: url('https://grayson.edu/dolphinbackground.jpg');
  background-size: cover;
  background-position: center;
  padding-bottom: 3rem;
  color: #FFF;
  position: relative;
}
@media (min-width: 768px) {
}
.page-section--background-2:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.7);
  z-index: 0;
}
.page-section__box {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .page-section__box {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .page-section__box {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .page-section__box {
    width: 1170px;
  }
}
.page-section__fluid-box {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 1;
}
.page-section__row {
  margin-right: -15px;
  margin-left: -15px;
}
.page-section__row--limited {
  max-width: 800px;
  margin: auto;
}
.page-section__header {
  font-family: 'Raleway', 'Arial', 'Sans-serif';
  font-size: 2.3rem;
  color: #002265;
  font-weight: 300;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .page-section__header {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }
}
.page-section__header--white {
  color: #FFF;
}
@media (min-width: 768px) {
  .page-section__highlight {
    font-size: 1.5rem;
  }
}
.page-section__highlight--center {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  margin: auto;
  float: none!important;
}
@media (min-width: 768px) {
  .page-section__highlight--center {
    float: left;
    width: 83.33333333%;
  }
}
.page-section__col-6 {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .page-section__col-6 {
    float: left;
    width: 50%;
  }
}
.page-section__col-6--flip-top {
  order: 1;
}
.page-section__col-6--flip-bottom {
  order: 0;
}
.page-section__col-12 {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.cta {
  padding: 1rem 0;
}
.cta--centered {
  text-align: center;
}
.cta--row-3 {
  display: flex;
  flex-wrap: wrap;
}
.cta--row-3 .cta__button {
  font-size: 1rem;
  flex-basis: 100%;
}
@media (min-width: 768px) {
  .cta--row-3 {
    flex-wrap: nowrap;
  }
  .cta--row-3 .cta__button {
    flex-basis: 33%;
  }
}
.cta--padded {
  margin-top: 3rem;
}
.cta__button {
  color: #ffffff;
  background-color: #002265;
  border-color: #002265;
  padding: 0.5em 2.5em;
  line-height: 1.42857143;
  border-radius: 4px;
  display: block;
  margin: 8px auto;
  font-family: raleway, arial, sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  text-decoration: none !important;
  text-align: center;
  transition: all 0.15s ease-in;
  border: #002265 2px solid;
}
.cta__button:focus,
.cta__button.focus {
  color: #ffffff;
  background-color: #001132;
  border-color: #000000;
}
.cta__button:hover {
  color: #ffffff;
  background-color: #001132;
  border-color: #000d28;
}
.cta__button:active,
.cta__button.active,
.open > .dropdown-toggle.cta__button {
  color: #ffffff;
  background-color: #001132;
  background-image: none;
  border-color: #000d28;
}
.cta__button:active:hover,
.cta__button.active:hover,
.open > .dropdown-toggle.cta__button:hover,
.cta__button:active:focus,
.cta__button.active:focus,
.open > .dropdown-toggle.cta__button:focus,
.cta__button:active.focus,
.cta__button.active.focus,
.open > .dropdown-toggle.cta__button.focus {
  color: #ffffff;
  background-color: #00050e;
  border-color: #000000;
}
.cta__button.disabled:hover,
.cta__button[disabled]:hover,
fieldset[disabled] .cta__button:hover,
.cta__button.disabled:focus,
.cta__button[disabled]:focus,
fieldset[disabled] .cta__button:focus,
.cta__button.disabled.focus,
.cta__button[disabled].focus,
fieldset[disabled] .cta__button.focus {
  background-color: #002265;
  border-color: #002265;
}
.cta__button .badge {
  color: #002265;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .cta__button {
    display: inline-block;
    font-size: 1.8rem;
    margin: 0.2rem 0.2rem;
  }
}
.cta__button:focus,
.cta__button:hover {
  background-color: #fff;
  border: #036 2px solid;
  color: #036;
}
.cta__button--smaller {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .cta__button--smaller {
    font-size: 1.4rem;
  }
}
.cta__button--short {
  margin: 8px 10%;
}
.cta--vertical .cta__button {
  display: block;
  margin: 4px auto;
  margin-bottom: 10px;
}
.cta__button--dark {
  color: #ffffff;
  background-color: #002265;
  border-color: #002265;
  padding: 0.5em 1.5em;
  font-size: 1.4rem;
  line-height: 1.42857143;
  border-radius: 10px;
  border: 2px solid #fff;
  border-radius: 10px !important;
}
.cta__button--dark:focus,
.cta__button--dark.focus {
  color: #ffffff;
  background-color: #001132;
  border-color: #000000;
}
.cta__button--dark:hover {
  color: #ffffff;
  background-color: #001132;
  border-color: #000d28;
}
.cta__button--dark:active,
.cta__button--dark.active,
.open > .dropdown-toggle.cta__button--dark {
  color: #ffffff;
  background-color: #001132;
  background-image: none;
  border-color: #000d28;
}
.cta__button--dark:active:hover,
.cta__button--dark.active:hover,
.open > .dropdown-toggle.cta__button--dark:hover,
.cta__button--dark:active:focus,
.cta__button--dark.active:focus,
.open > .dropdown-toggle.cta__button--dark:focus,
.cta__button--dark:active.focus,
.cta__button--dark.active.focus,
.open > .dropdown-toggle.cta__button--dark.focus {
  color: #ffffff;
  background-color: #00050e;
  border-color: #000000;
}
.cta__button--dark.disabled:hover,
.cta__button--dark[disabled]:hover,
fieldset[disabled] .cta__button--dark:hover,
.cta__button--dark.disabled:focus,
.cta__button--dark[disabled]:focus,
fieldset[disabled] .cta__button--dark:focus,
.cta__button--dark.disabled.focus,
.cta__button--dark[disabled].focus,
fieldset[disabled] .cta__button--dark.focus {
  background-color: #002265;
  border-color: #002265;
}
.cta__button--dark .badge {
  color: #002265;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .cta__button--dark {
    font-size: 2rem;
  }
}
.cta__button--fixed {
  width: 300px;
}
.cta__button--clear {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffffff;
  padding: 0.5em 0.7em;
  font-size: 1.3rem;
  line-height: 1.42857143;
  border-radius: 4px;
  border: 3px solid #fff;
  font-weight: 600;
}
.cta__button--clear:focus,
.cta__button--clear.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bfbfbf;
}
.cta__button--clear:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #e0e0e0;
}
.cta__button--clear:active,
.cta__button--clear.active,
.open > .dropdown-toggle.cta__button--clear {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-color: #e0e0e0;
}
.cta__button--clear:active:hover,
.cta__button--clear.active:hover,
.open > .dropdown-toggle.cta__button--clear:hover,
.cta__button--clear:active:focus,
.cta__button--clear.active:focus,
.open > .dropdown-toggle.cta__button--clear:focus,
.cta__button--clear:active.focus,
.cta__button--clear.active.focus,
.open > .dropdown-toggle.cta__button--clear.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bfbfbf;
}
.cta__button--clear.disabled:hover,
.cta__button--clear[disabled]:hover,
fieldset[disabled] .cta__button--clear:hover,
.cta__button--clear.disabled:focus,
.cta__button--clear[disabled]:focus,
fieldset[disabled] .cta__button--clear:focus,
.cta__button--clear.disabled.focus,
.cta__button--clear[disabled].focus,
fieldset[disabled] .cta__button--clear.focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffffff;
}
.cta__button--clear .badge {
  color: rgba(0, 0, 0, 0);
  background-color: #ffffff;
}
.cta__button--clear::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  margin-left: 5px;
  font-weight: 900;
}
.cta__button--clear:focus,
.cta__button--clear:hover {
  background-color: #fff;
  border: 3px solid #fff;
  color: #036;
}
.figure {
  position: relative;
}
.figure__caption {
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: white!important;
  background-color: rgba(51, 51, 51, 0.8);
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  width: 100%;
  font-style: normal;
  text-align: left;
  font-family: raleway, arial, sans-serif;
}
.figure__caption a {
  color: white;
}
.figure__caption a:hover {
  color: white;
}
.triptych {
  margin-right: -15px;
  margin-left: -15px;
}
.triptych__text {
  font-family: Arial, sans-serif;
  font-size: 1rem!important;
  line-height: 1.5;
}
.triptych__panel {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .triptych__panel {
    float: left;
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .triptych__panel {
    margin-bottom: 0;
  }
}
.triptych__heading {
  font-family: Raleway, Arial, sans-serif;
  font-weight: 500;
  margin-top: 20px;
  color: #333;
  margin: 20px 10px;
}
@media (min-width: 768px) {
  .triptych__heading {
    margin: 10px 10px;
  }
}
.link-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  margin: 0;
  padding: 0;
}
.link-grid__item {
  flex: 0 0 50%;
  list-style: none;
  padding: 5px;
  max-height: 180px;
}
@media (min-width: 768px) {
  .link-grid__item {
    flex: 0 0 25%;
  }
}
.link-grid__inner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.link-grid__image {
  width: 100%;
  height: 100%!important;
  position: relative;
  object-fit: cover;
}
.link-grid__link {
  display: block;
  background-color: rgba(51, 51, 51, 0.6);
  text-decoration: none!important;
  height: 100%;
}
.link-grid__link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.7);
  z-index: 0;
}
.link-grid__link:hover {
  transition: all 0.25s ease-in;
}
.link-grid__link:hover:after {
  background-color: rgba(102, 102, 102, 0.7);
  transition: all 0.25s ease;
}
.link-grid__link-text {
  text-decoration: none;
  color: white;
  font-family: raleway, arial, sans-serif;
  font-size: 1.6rem;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.cta-block {
  width: 100%;
  height: auto;
  background-color: #002265;
  color: white;
  text-align: center;
}
.cta-block__box {
  margin: 2rem 0;
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}
.cta-block__headline {
  margin: 0 0 1.4rem 0;
  display: inline-block;
  font-weight: 600;
  font-size: 1.8rem;
}
.cta-block__content {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.cta-block__button {
  color: #002265;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  padding: 0.5em 2.5em;
  font-size: 1.3rem;
  line-height: 1.42857143;
  border-radius: 4px;
  text-decoration: none!important;
  font-weight: 600;
  font-family: raleway, arial, sans-serif;
  font-size: 1.1rem;
  text-align: center;
  display: inline-block;
}
.cta-block__button:focus,
.cta-block__button.focus {
  color: #002265;
  background-color: #e6e6e6;
  border-color: #bfbfbf;
}
.cta-block__button:hover {
  color: #002265;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.cta-block__button:active,
.cta-block__button.active,
.open > .dropdown-toggle.cta-block__button {
  color: #002265;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #e0e0e0;
}
.cta-block__button:active:hover,
.cta-block__button.active:hover,
.open > .dropdown-toggle.cta-block__button:hover,
.cta-block__button:active:focus,
.cta-block__button.active:focus,
.open > .dropdown-toggle.cta-block__button:focus,
.cta-block__button:active.focus,
.cta-block__button.active.focus,
.open > .dropdown-toggle.cta-block__button.focus {
  color: #002265;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}
.cta-block__button.disabled:hover,
.cta-block__button[disabled]:hover,
fieldset[disabled] .cta-block__button:hover,
.cta-block__button.disabled:focus,
.cta-block__button[disabled]:focus,
fieldset[disabled] .cta-block__button:focus,
.cta-block__button.disabled.focus,
.cta-block__button[disabled].focus,
fieldset[disabled] .cta-block__button.focus {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
.cta-block__button .badge {
  color: #FFFFFF;
  background-color: #002265;
}
.cta-block__button:after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  margin-left: 5px;
}
@media (min-width: 768px) {
  .cta-block__button {
    font-size: 1.4rem;
  }
}
.item-list dt {
  font-size: 1.3rem;
  margin: 1rem;
  font-family: Raleway;
  margin: 1rem 0;
}
.item-list dd {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
}
.sequence thead th:nth-child(2) {
  width: 15%;
}
.hero {
  clear: both;
  display: block;
  margin-bottom: 1rem;
  overflow-y: hidden;
  /*
  &__box {
    .container-fixed();

    @media (min-width: @screen-sm-min) {
      width: @container-sm;
    }

    @media (min-width: @screen-md-min) {
      width: @container-md;
    }

    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }

    position: relative;
    z-index: 1;
  }

  &__row {
    .make-row();
  }
  */
}
.hero__wrapper {
  display: block;
  height: calc(100vw * 0.5625);
  max-height: calc(88.1vh - 120px);
  position: relative;
}
.hero__image {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.hero__overlay {
  display: block;
  background-color: #036;
}
@media (min-width: 768px) {
  .hero__overlay {
    bottom: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: transparent;
  }
  .hero__overlay--dark {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.hero__column--center {
  left: 50%;
  transform: translateX(-50%);
}
.hero__column--right {
  float: right;
}
.hero__column--left {
  float: left;
}
@media (max-width: 767px) {
  .hero__column {
    text-align: left;
  }
}
.hero__overlay-wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .hero__overlay-wrapper {
    position: absolute;
    padding: 30px 0;
  }
  .hero__overlay-wrapper--top {
    top: 0;
  }
  .hero__overlay-wrapper--middle {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .hero__overlay-wrapper--bottom {
    bottom: 0;
  }
}
.hero__cta {
  margin-bottom: 0px;
}
.hero__content {
  color: #fff;
  font-family: 'Raleway', Arial, sans-serif;
  font-size: 1.2rem;
  margin: 0.5rem;
  padding: 30px;
}
@media (min-width: 768px) {
  .hero__content {
    font-size: 1.5rem;
  }
  .hero__content--background {
    background-color: rgba(0, 34, 101, 0.7);
  }
}
.hero__headline {
  color: #fff;
  font-family: 'bebas neue', Arial, sans-serif;
  font-size: 2.3rem;
  margin: 0.1em 0;
}
@media (min-width: 768px) {
  .hero__headline {
    font-size: 4rem;
    margin: 0;
  }
}
.accordion-group--grayson {
  border-color: #002265 !important;
  background-color: white;
}
.career-data .accordion-inner {
  padding: 0;
}
@media (min-width: 768px) {
  .career-data .accordion-inner {
    padding: 20px 15px;
  }
}
.career-data__heading {
  font-family: Raleway, Arial, sans-serif;
  color: #002265;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .career-data__heading {
    font-size: 1.6rem;
  }
}
.career-data__heading[aria-expanded="true"] .accordion__icon {
  transform: rotate(90deg);
}
.career-data__heading::before {
  font-size: 1.2rem !important;
}
@media (min-width: 768px) {
  .career-data__heading::before {
    font-size: 1.6rem;
  }
}
.career-data__section {
  margin-bottom: 3rem;
  padding: 0 2rem;
}
.career-data__section p {
  margin-bottom: 0;
}
.career-data__section::after {
  content: "";
  width: 100px;
  position: relative;
  display: inline-block;
  height: 2px;
  background-color: #999;
  top: 1rem;
}
.career-data__section--last::after {
  display: none;
}
.career-data__data {
  font-family: Raleway, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}
.career-data__data--chart {
  font-family: Raleway, Arial, sans-serif;
  color: #333;
  font-size: 1.3rem;
  text-align: center;
}
.career-data__stat {
  vertical-align: middle;
  font-size: 1rem;
}
.career-data__stat--big {
  font-family: bebas neue, Arial, sans-serif;
  font-size: 1.8rem;
  color: #002265;
  font-style: normal;
  display: block;
}
@media (min-width: 768px) {
  .career-data__stat--big {
    padding: 0rem 0.8em;
    vertical-align: middle;
    display: inline;
    font-size: 1.8rem;
  }
}
.page-heading {
  height: 250px;
  margin-bottom: 0px;
  clear: both;
  background-size: cover;
  position: relative;
}
@media (min-width: 768px) {
  .page-heading {
    height: 400px;
    margin-bottom: 40px;
  }
}
.box-title {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 34, 101, 0.6);
}
@media (min-width: 768px) {
  .box-title {
    position: absolute;
    bottom: -33px;
    height: auto;
    background: none;
  }
}
.box-title__inner {
  position: absolute;
  bottom: 0;
  box-shadow: none;
  background: none;
  padding-top: 31px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -10px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .box-title__inner {
    position: static;
    display: inline-block;
    background: #002265;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);
  }
}
.box-title__title {
  font-size: 3rem;
  font-family: "bebas neue", "impact", sans-serif;
  color: white;
  display: inline-block;
  position: relative;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .box-title__title {
    font-size: 4rem;
  }
}
.box-title__title:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: -11px;
  left: 0;
  border-bottom: 2px solid #999;
}
.box-title__breadcrumbs {
  display: inline-block;
  margin-top: 9px;
  color: white;
  font-size: 0.9rem;
  font-family: "raleway", "arial", sans-serif;
}
.countdown {
  background: #ffffa9;
  color: black;
  font-weight: bold;
}
.countdown__text {
  padding: 3px 0;
  display: block;
}
.countdown__message a {
  color: black;
  text-decoration: underline!important;
}
.icon-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Raleway", Arial, sans-serif;
  justify-content: space-between;
  align-items: baseline;
}
.icon-row__link {
  display: block!important;
  width: 100%!important;
  height: 100%;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .icon-row {
    flex-wrap: nowrap;
  }
}
.icon-row__item {
  flex: 1 0 50%;
}
@media (min-width: 768px) {
  .icon-row__item {
    flex: auto;
    max-width: 200px;
  }
}
.icon-row__icon {
  min-height: 150px;
  min-width: 150px;
  max-width: 175px;
  width: 100%;
  padding: 1em;
}
.icon-row__icon--white {
  stroke: #fff;
}
.icon-row__icon--white path {
  stroke: #fff;
}
.icon-row__icon--white polygon {
  stroke: #fff;
}
.icon-row__icon--white polyline {
  stroke: #fff;
}
.icon-row__text {
  font-family: "Raleway", Arial, sans-serif;
}
.service {
  flex: 1 1 auto;
  width: 370px;
  height: 330px;
  border-left: #036 solid 10px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  text-align: left;
  position: relative;
}
.service__wrapper {
  padding: 2rem 2rem 0.7rem 2rem;
}
.service__title {
  font-family: 'Raleway', arial, sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  color: #002265;
  margin: 1.2rem 0 !important;
}
.service__logo {
  height: 65px;
  width: 100%;
}
.service__logo img {
  max-height: 65px;
  max-width: 100%;
  width: auto;
}
.service__title {
  font-family: 'Raleway', Arial, sans-serif;
  font-weight: 500;
  color: #002265;
}
.service__description {
  font-family: 'Raleway', Arial, san-serif;
  font-size: 1.1rem;
  max-height: 300px;
  height: 80px;
}
.service__link {
  padding-left: 2rem;
}
.service__link a {
  color: #ffffff;
  background-color: #002265;
  border-color: #002265;
  padding: 0.6rem 1rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  border-radius: 4px;
  font-family: raleway, arial, sans-serif;
  font-weight: 300;
  text-decoration: none !important;
  text-align: center;
  transition: all 0.15s ease-in;
  display: inline-block;
  text-align: right;
  float: right;
  text-decoration: none!important;
  border: 2px solid;
  border-color: #036;
  border-right: none;
}
.service__link a:focus,
.service__link a.focus {
  color: #ffffff;
  background-color: #001132;
  border-color: #000000;
}
.service__link a:hover {
  color: #ffffff;
  background-color: #001132;
  border-color: #000d28;
}
.service__link a:active,
.service__link a.active,
.open > .dropdown-toggle.service__link a {
  color: #ffffff;
  background-color: #001132;
  background-image: none;
  border-color: #000d28;
}
.service__link a:active:hover,
.service__link a.active:hover,
.open > .dropdown-toggle.service__link a:hover,
.service__link a:active:focus,
.service__link a.active:focus,
.open > .dropdown-toggle.service__link a:focus,
.service__link a:active.focus,
.service__link a.active.focus,
.open > .dropdown-toggle.service__link a.focus {
  color: #ffffff;
  background-color: #00050e;
  border-color: #000000;
}
.service__link a.disabled:hover,
.service__link a[disabled]:hover,
fieldset[disabled] .service__link a:hover,
.service__link a.disabled:focus,
.service__link a[disabled]:focus,
fieldset[disabled] .service__link a:focus,
.service__link a.disabled.focus,
.service__link a[disabled].focus,
fieldset[disabled] .service__link a.focus {
  background-color: #002265;
  border-color: #002265;
}
.service__link a .badge {
  color: #002265;
  background-color: #ffffff;
}
.service__link a::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  margin-left: 5px;
  font-weight: 900;
}
.service__link a:focus,
.service__link a:hover {
  background-color: #fff;
  border-color: #036;
  color: #036;
  font-weight: 500;
}
.flex-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  padding-left: 0;
}
@media (min-width: 768px) {
  .flex-list {
    justify-content: space-around;
  }
}
@media (min-width: 1200px) {
  .flex-list {
    justify-content: flex-start;
    margin: 0px -7px 0 -7px;
  }
}
.flex-list__item {
  flex: 0 1 auto;
  margin-bottom: 1.3rem;
  margin: 7px;
}
.event-calendar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  text-align: left;
  justify-content: space-evenly;
  padding-left: 0;
}
@media (min-width: 768px) {
  .event-calendar {
    max-height: 450px;
    flex-wrap: wrap;
  }
}
.event-calendar__event {
  flex: 0 0 auto;
  height: 100px;
}
@media (min-width: 768px) {
  .event-calendar__event {
    width: 50%;
  }
}
.event {
  display: flex;
  text-align: left;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 25px 0 25px 0;
}
.event a {
  display: block;
}
.event__date {
  font-family: 'bebas Neue', Arial, sans-serif;
  text-align: center;
  flex: 0 0 68px;
  color: #002265;
}
.event__info {
  margin: 0px 0 0 25px;
  flex: 1 1 auto;
  font-family: 'Raleway', Arial, sans-serif;
}
.event__day {
  font-size: 4.5rem;
  line-height: 1;
}
.event__month {
  font-size: 2rem;
  line-height: 1;
}
.event__title {
  font-weight: bold;
  color: #002265;
}
.people {
  justify-content: center;
}
.people--left {
  justify-content: flex-start;
}
.people .person {
  margin-bottom: 25px;
}
.people--1 .person {
  flex: 0 1 100%;
}
.people--3 .person {
  flex: 0 1 33%;
}
.people--4 .person {
  flex: 0 25%;
}
.person {
  display: flex;
  max-width: 100%;
  flex: 1 1 auto;
}
.person--half {
  flex: 1 1 45%;
}
.person__photo {
  flex: 0 0 130px;
  margin-right: 15px;
}
.person__photo img {
  width: 100%;
  height: auto;
}
.person__info {
  flex: 1 1 auto;
  text-align: left;
  font-family: Raleway, Arial, sans-serif;
  color: #444;
}
.person__education {
  margin-top: 7px;
}
.person__cv::before {
  content: '\f15b';
  font-family: 'Font Awesome 5 Free';
  margin-right: 5px;
  font-weight: 900;
}
.person__cv a {
  color: #003366;
  font-weight: bold;
}
.person__cv a:hover,
.person__cv a:focus {
  color: #3076b3;
}
.person__name {
  color: #003366;
  font-weight: bold;
}
.person__name a {
  color: #003366;
  font-weight: bold;
}
.person__name a:hover,
.person__name afocus {
  color: #3076b3;
}
.person__contact {
  margin-top: 10px;
}
.person__phone {
  display: flex;
}
.person__phone::before {
  content: '\f095';
  font-family: 'Font Awesome 5 Free';
  margin-right: 5px;
  font-weight: 900;
}
.person__email {
  display: flex;
}
.person__email::before {
  content: '\f0e0';
  font-family: 'Font Awesome 5 Free';
  margin-right: 5px;
  font-weight: 900;
}
.person__email a {
  color: #003366;
  font-weight: bold;
}
.person__email a:hover,
.person__email a:focus {
  color: #3076b3;
}
.person__location {
  display: flex;
  margin-top: 7px;
}
.person__location::before {
  content: '\f1ad';
  font-family: 'Font Awesome 5 Free';
  margin-right: 5px;
  font-weight: 900;
}
.text-box-list {
  list-style: none;
  padding-left: 0px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 40px 0;
}
.text-box {
  width: 370px;
  height: 210px;
  color: white;
  font-family: 'Raleway', Arial, sans-serif;
  background: #036;
  font-weight: 500;
  position: relative;
  border: #999 solid 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
}
.text-box span {
  padding: 20px;
  font-size: 150%;
}
.text-box::before {
  content: "";
  display: block;
  position: relative;
  height: 210px;
  background-image: url('https://grayson.edu/about-us/institutional-effectiveness/quality-enhancement-plan/triangle.svg');
  background-repeat: no-repeat;
  fill: #999;
  left: 0px;
  flex: 0 0 13%;
  background-size: contain;
}
.pathway-icons {
  --arts: #f8a326;
  --business: #8b3c73;
  --health: #df463e;
  --industrial: #464155;
  --public: #91d6e5;
  --stem: #008244;
}
.pathway-icons__items:hover {
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.16);
  border-radius: 13px!important;
  transition: all 0.25s ease-in;
}
.pathway-icons__items svg #Layer_1 path {
  transition: all 0.25s ease-in;
}
.pathway-icons__items .arts-and-humanities:hover svg #Layer_1 path {
  fill: #cb7b07;
}
.pathway-icons__items .business-and-entrepreneurship:hover svg #Layer_1 path {
  fill: #562547;
}
.pathway-icons__items .health-sciences:hover svg #Layer_1 path {
  fill: #b3251e;
}
.pathway-icons__items .industrial-technologies:hover svg #Layer_1 path {
  fill: #22202a;
}
.pathway-icons__items .public-services:hover svg #Layer_1 path {
  fill: #53bfd6;
}
.pathway-icons__items .stem:hover svg #Layer_1 path {
  fill: #00351c;
}
.pathway {
  text-align: left;
  font-family: 'Raleway', Arial, sans-serif;
  font-size: 1rem;
  padding: 1.6em 1.4em;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  border-left: 10px solid;
  --arts: #f8a326;
  --business: #8b3c73;
  --health: #df463e;
  --industrial: #464155;
  --public: #91d6e5;
  --stem: #008244;
}
.pathway__icon {
  flex: 0 0 auto;
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
@media (min-width: 768px) {
  .pathway__icon {
    width: 100px;
    height: 100px;
  }
}
.pathway__title {
  font-family: 'Bebas Neue';
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .pathway__title {
    font-size: 3rem;
  }
}
.pathway__area-heading {
  font-family: 'Raleway', Arial, sans-serif;
  color: #002265;
}
.pathway__heading {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.pathway__areas {
  list-style: none;
  padding-left: 0;
}
@media (min-width: 1200px) {
  .pathway__areas--columns {
    column-count: 2;
  }
}
.pathway__area {
  margin-bottom: 8px;
}
.pathway__links {
  margin-left: 15px;
  display: inline-block;
}
.pathway__main {
  display: block;
}
.pathway__main a {
  color: #ffffff;
  background-color: #002265;
  border-color: #002265;
  padding: 0.6rem 1rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  border-radius: 4px;
  font-family: raleway, arial, sans-serif;
  font-weight: 300;
  text-decoration: none !important;
  text-align: center;
  transition: all 0.15s ease-in;
  display: inline-block;
  text-align: right;
  text-decoration: none!important;
  border: 2px solid;
  border-color: #036;
  border-right: none;
}
.pathway__main a:focus,
.pathway__main a.focus {
  color: #ffffff;
  background-color: #001132;
  border-color: #000000;
}
.pathway__main a:hover {
  color: #ffffff;
  background-color: #001132;
  border-color: #000d28;
}
.pathway__main a:active,
.pathway__main a.active,
.open > .dropdown-toggle.pathway__main a {
  color: #ffffff;
  background-color: #001132;
  background-image: none;
  border-color: #000d28;
}
.pathway__main a:active:hover,
.pathway__main a.active:hover,
.open > .dropdown-toggle.pathway__main a:hover,
.pathway__main a:active:focus,
.pathway__main a.active:focus,
.open > .dropdown-toggle.pathway__main a:focus,
.pathway__main a:active.focus,
.pathway__main a.active.focus,
.open > .dropdown-toggle.pathway__main a.focus {
  color: #ffffff;
  background-color: #00050e;
  border-color: #000000;
}
.pathway__main a.disabled:hover,
.pathway__main a[disabled]:hover,
fieldset[disabled] .pathway__main a:hover,
.pathway__main a.disabled:focus,
.pathway__main a[disabled]:focus,
fieldset[disabled] .pathway__main a:focus,
.pathway__main a.disabled.focus,
.pathway__main a[disabled].focus,
fieldset[disabled] .pathway__main a.focus {
  background-color: #002265;
  border-color: #002265;
}
.pathway__main a .badge {
  color: #002265;
  background-color: #ffffff;
}
.pathway__main a::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  margin-left: 5px;
  font-weight: 900;
}
.pathway__main a:focus,
.pathway__main a:hover {
  background-color: #fff;
  border-color: #036;
  color: #036;
  font-weight: 500;
}
.pathways__area-heading {
  font-family: 'Raleway', Arial, sans-serif;
  color: #002265;
  font-weight: 500;
}
.sortable [data-sort] {
  text-decoration: underline;
  cursor: pointer;
}
.sortable [data-sort]:hover,
.sortable [data-sort]:focus {
  background-color: #002265;
  color: #fff;
}
.sortable .ascending {
  background-color: #e0e0e0;
}
.sortable .ascending:before {
  content: '▼';
  padding-right: 6px;
  text-decoration: none;
}
.sortable .descending {
  background-color: #e0e0e0;
}
.sortable .descending:before {
  content: '▲';
  padding-right: 6px;
  text-decoration: none;
}
.controls {
  text-align: center;
  font-size: 1.1rem;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .controls {
    font-size: 1.7rem;
  }
}
.controls label {
  font-size: 1.1rem;
}
@media (min-width: 768px) {
  .controls label {
    font-size: 1.7rem;
  }
}
.controls select {
  max-width: 100%;
}
.controls--column {
  text-align: left;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .controls--column {
    font-size: 1.4rem;
  }
}
.controls--column label {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .controls--column label {
    font-size: 1.2rem;
  }
}
.loading {
  opacity: 0;
  transition: all 0.25s ease-in;
  min-height: 300px;
}
.loaded {
  opacity: 1;
  transition: all 0.25s ease-in;
}
canvas {
  display: inline-block;
  margin-bottom: 30px;
}
/*Not yet needed*/
/*override for components*/
.pagination .active > a,
.pagination .active > a:hover {
  background: #002265;
  border-color: #ddd;
  color: #fff;
}
.orgs {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.club {
  display: flex;
  margin: 3rem 0;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .club {
    flex-wrap: nowrap;
  }
}
.club__graphic {
  width: 160px;
  max-width: 160px;
  flex: 0 0 auto;
  margin-right: 1.2rem;
}
.club__graphic img {
  width: 160px;
  height: 160px;
  object-fit: contain;
  border-radius: 100%!important;
  margin-bottom: 0.4rem;
}
.club__name {
  text-align: left;
  font-weight: bold;
  color: #002265;
  font-family: Raleway, arial, sans-serif;
  font-size: 1.2rem;
}
.club__advisors {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .club__advisors {
    flex-wrap: nowrap;
  }
}
.club__info {
  width: 100%;
  text-align: left;
  margin-top: 1rem;
}
.club__advisor {
  padding-left: 0;
  padding-right: 2rem;
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .club__advisor {
    flex-basis: auto;
  }
}
.club__description {
  margin-top: 1rem;
}
.font-weight-bold {
  font-weight: bold;
}
.sidebar h2 {
  color: white;
  background-color: #002265;
  padding: 1rem;
  margin: 0;
}
.sidebar .sidebar__content {
  background-color: #ececec;
  padding: 1rem;
}
.sidebar dd {
  margin-bottom: 0.5rem;
}
.links {
  padding: 0 1rem 1rem 1rem;
  margin-left: 0;
  list-style: none;
}
.links ul {
  padding-left: 0;
}
.links li {
  margin-bottom: 0.6rem;
}
.sl-gallery {
  padding-left: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
.sl-gallery__person {
  padding: 5px;
  text-align: left;
  max-width: 380px;
}
.sl-gallery__name {
  margin: 0;
  color: #002265;
  font-family: 'Bebas Neue', 'Raleway';
  margin: 1rem 0 0 0 !important;
  font-size: 2rem !important;
}
.sl-gallery__title {
  color: #707070;
  font-weight: bold;
  font-size: 1.1rem;
}
.sl-gallery__contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.sl-gallery__contact-info {
  flex: 1 1 auto;
}
.directory {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  align-items: start;
  padding: 0;
  list-style: none;
  row-gap: 25px;
  column-gap: 15px;
}
.directory__education {
  margin-top: 1rem;
}
.directory__education ul {
  padding: 0;
  list-style: none;
}
.directory__description {
  margin-top: 1rem;
}
.directory__entry {
  display: flex;
  flex-wrap: wrap;
}
.directory__name {
  color: #036;
  font-weight: 700;
  font-family: bebas neue, Raleway, arial;
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  line-height: 1;
}
.directory__name a {
  color: #036;
  font-weight: 700;
}
.directory__name a:hover,
.directory__name a:focus {
  color: #3076b3;
}
.directory__info {
  font-family: raleway, arial, sans-serif;
  flex: 1 1 100%;
}
.directory__title {
  text-align: left;
  font-family: Raleway, Arial, sans-serif;
  color: #444;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #707070;
}
.directory__location {
  margin-top: 7px;
  display: flex;
  align-items: flex-start;
}
.directory__location span {
  font-size: 1rem;
  padding-top: 3px;
  padding-right: 3px;
}
.directory__photo img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.directory__phone {
  display: flex;
  align-items: flex-start;
}
.directory__phone span {
  font-size: 1rem;
  padding-top: 3px;
  padding-right: 3px;
}
.directory__email {
  display: flex;
  align-items: flex-start;
}
.directory__email span {
  font-size: 1rem;
  padding-top: 3px;
  padding-right: 3px;
}
.directory--full {
  display: flex;
  flex-wrap: wrap;
}
.directory--full .directory__heading {
  flex: 0 0 100%;
}
.directory--full .directory__name {
  font-size: 2.5rem;
}
.directory--full .directory__wrap {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .directory--full .directory__wrap {
    flex-wrap: nowrap;
  }
}
.directory--full .directory__photo {
  flex: 0 0 185px;
  margin-right: 1rem;
}
.directory--full .directory__info {
  flex: 1 1 auto;
}
