.cta-block {
	width:100%;
	height:auto;
	background-color:#002265;
	color:white;
	text-align:center;
	&__box {
		margin:2rem 0;	
		display:inline-block;
		width:100%;
		padding:0 20px;
	}
	&__headline {
		&:extend(.page-section__header);
		margin:0 0 1.4rem 0;
		display:inline-block;
		font-weight:600;
		font-size:1.8rem;
	}
	&__content {
		font-size:1.2rem;
		margin-bottom:.5rem;
	}
	&__button {
		.button-variant(#002265; #FFFFFF; #FFFFFF);
		.button-size(0.5em; 2.5em; 1.3rem; @line-height-base; @btn-border-radius-base);
		text-decoration:none!important;
		font-weight:600;
		font-family:raleway, arial, sans-serif;
		font-size:1.1rem;
		text-align:center;
		display:inline-block;
		&:after {
			content:'\f054';
			font-family:'Font Awesome 5 Free';
			margin-left: 5px;
		}
		@media (min-width: @screen-sm-min) {
			font-size:1.4rem;
		}

	}
}