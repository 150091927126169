.controls {
    text-align:center;
    font-size:1.1rem;
    padding-bottom:15px;

    @media (min-width: @screen-sm-min) {
        font-size:1.7rem;
    }

    label {
        font-size:1.1rem;

        @media (min-width: @screen-sm-min) {
            font-size:1.7rem;
        }
    }

    select {
        max-width:100%;
    }

    &--column {
        text-align:left;
        font-size:1rem;

        @media (min-width: @screen-sm-min) {
            font-size:1.4rem;
        }

        label {
            font-size:1rem;
    
            @media (min-width: @screen-sm-min) {
                font-size:1.2rem;
            }
        }
    }

    
}