.sortable {

    [data-sort] {
        text-decoration:underline;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color:#002265;
            color:#fff;
        }
    }

    .ascending {
        background-color:#e0e0e0;
        &:before {
            content:'▼';
            padding-right:6px;
            text-decoration:none;

        }
    }

    .descending {
        background-color:#e0e0e0;
        &:before {
            content:'▲';
            padding-right:6px;
            text-decoration:none;

        }

    }
}