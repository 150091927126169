.img-right {
	float: right;   
}

.img-left {
	float: left;   
}

.blue-headline {
	color: #00205b;   
}